import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  CircularProgress,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Button,
  Select,
  Chip,
  InputLabel,
  MenuItem,
  Switch,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
import LabsHeader from '../components/LabsHeader';
import InfoTable from '../components/InfoTable';
import { listCourses as listCoursesAction } from '../actions/courseActions';
import { listBatches as listBatchesAction } from '../actions/batchActions';
import { listUsers as listUsersAction } from '../actions/userActions';
import {
  listStudents as listStudentsAction,
  createStudent as createStudentAction,
  updateStudent as updateStudentAction,
  deleteStudent as deleteStudentAction,
  updateStudentStatus as updateStudentStatusAction,
} from '../actions/studentActions';
import {
  STUDENT_CREATE_RESET,
  STUDENT_UPDATE_RESET,
} from '../constants/studentConstants';
import { SlideTransition, ZoomTransition } from '../components/Transitions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[200],
    padding: '50px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: 'calc(100vh - 206px)',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  notifSpacing: {
    marginBottom: '10px',
  },
}));

/**
 * Component for showing/adding/editing students
 */
const UsersScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Dialog State
  const [createPopup, setCreatePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);

  // Form and Local State
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [ips, setIps] = useState([]);
  const [ports, setPorts] = useState([]);
  const [connectionType, setConnectionType] = useState('ips-only');
  const [singleIp, setSingleIp] = useState('');
  const [singlePort, setSinglePort] = useState('');
  const [active, setActive] = useState(true);
  const [course, setCourse] = useState('');
  const [batch, setBatch] = useState('');
  const [trainer, setTrainer] = useState('');
  const [bookAccess, setBookAccess] = useState(false);
  const [corporateStudent, setCorporateStudent] = useState(false);
  const [updateUsername, setUpdateUsername] = useState('');
  const [updatePassword, setUpdatePassword] = useState('');
  const [updateName, setUpdateName] = useState('');
  const [updateEmail, setUpdateEmail] = useState('');
  const [updatePhone, setUpdatePhone] = useState('');
  const [updateIps, setUpdateIps] = useState([]);
  const [updatePorts, setUpdatePorts] = useState([]);
  // const [updateConnectionType, setUpdateConnectionType] = useState('ips-only');
  const [updateCourse, setUpdateCourse] = useState('');
  const [updateBatch, setUpdateBatch] = useState('');
  const [updateTrainer, setUpdateTrainer] = useState('');
  const [updateSingleIp, setUpdateSingleIp] = useState('');
  const [updateSinglePort, setUpdateSinglePort] = useState('');
  const [updateActive, setUpdateActive] = useState(true);
  const [updateBookAccess, setUpdateBookAccess] = useState(false);
  const [updateCorporateStudent, setUpdateCorporateStudent] = useState(false);
  const [updateStudentId, setUpdateStudentId] = useState('');
  const [toDeleteStudent, setToDeleteStudent] = useState('');

  // Redux State Getters
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const studentList = useSelector((state) => state.studentList);
  const { loading, error, students } = studentList;
  const usersList = useSelector((state) => state.userList);
  const {
    loading: allUsersLoading,
    error: allUsersError,
    users: allUsers,
  } = usersList;
  const coursesList = useSelector((state) => state.coursesList);
  const {
    loading: allCoursesLoading,
    error: allCoursesError,
    courses: allCourses,
  } = coursesList;
  const batchesList = useSelector((state) => state.batchesList);
  const {
    loading: allBatchesLoading,
    error: allBatchesError,
    batches: allBatches,
  } = batchesList;
  const studentCreate = useSelector((state) => state.studentCreate);
  const {
    loading: createLoading,
    error: createError,
    success: createSuccess,
    student: createdStudent,
  } = studentCreate;
  const studentUpdate = useSelector((state) => state.studentUpdate);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    student: updatedStudent,
  } = studentUpdate;
  const studentDelete = useSelector((state) => state.studentDelete);
  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
  } = studentDelete;
  const studentStatus = useSelector((state) => state.studentStatus);
  const { error: studentStatusError, success: studentStatusSuccess } =
    studentStatus;

  // Lifecycle events
  useEffect(() => {
    if (userInfo) {
      dispatch(listStudentsAction());
      dispatch(listCoursesAction());
      dispatch(listBatchesAction());
      userInfo.role === 'admin' && dispatch(listUsersAction());
    } else {
      history.push('/admin/login');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
    if (createSuccess) {
      handleCreatePopupClose();
      setUsername('');
      setPassword('');
      setName('');
      setCourse('');
      setEmail('');
      setPhone('');
      setBatch('');
      setTrainer('');
      setIps([]);
      setPorts([]);
      setSingleIp('');
      setSinglePort('');
      setActive(true);
      setBookAccess(false);
      setCorporateStudent(false);
      dispatch({ type: STUDENT_CREATE_RESET });
    }
    if (updateSuccess) {
      handleEditPopupClose();
      setUpdateUsername('');
      setUpdatePassword('');
      setUpdateName('');
      setUpdateEmail('');
      setUpdatePhone('');
      setUpdateIps([]);
      setUpdatePorts([]);
      setUpdateSingleIp('');
      setUpdateSinglePort('');
      setUpdateActive(true);
      setUpdateBookAccess(false);
      setUpdateCorporateStudent(false);
      setUpdateCourse('');
      setUpdateBatch('');
      setUpdateTrainer('');
      dispatch({ type: STUDENT_UPDATE_RESET });
    }
    if (deleteSuccess) {
      handleDeletePopupClose();
      setToDeleteStudent({});
    }
  }, [
    dispatch,
    history,
    userInfo,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    studentStatusSuccess,
    createdStudent,
    updatedStudent,
  ]);

  // Popup Management
  const handleCreatePopupClick = () => {
    setCreatePopup(true);
  };
  const handleCreatePopupClose = () => {
    setCreatePopup(false);
  };
  const handleEditPopupClick = () => {
    setEditPopup(true);
  };
  const handleEditPopupClose = () => {
    setEditPopup(false);
    setUpdateUsername('');
    setUpdatePassword('');
    setUpdateName('');
    setUpdateEmail('');
    setUpdatePhone('');
    setUpdateIps([]);
    setUpdatePorts([]);
    setUpdateSingleIp('');
    setUpdateSinglePort('');
    setUpdateActive(true);
    setUpdateBookAccess(false);
    setUpdateCorporateStudent(false);
    setUpdateCourse('');
    setUpdateBatch('');
    setUpdateTrainer('');
  };
  const handleDeletePopupClick = (student) => {
    setDeletePopup(true);
    setToDeleteStudent(student);
  };
  const handleDeletePopupClose = () => {
    setDeletePopup(false);
  };

  // Action Handlers
  const handleCreateStudent = (e) => {
    e.preventDefault();
    const formData = {
      username,
      password,
      name,
      email,
      phone,
      network: {
        ips,
        ports,
        singleIp,
        singlePort,
      },
      course,
      batch,
      trainer,
      active,
      corporateStudent,
      bookAccess,
    };
    // console.log(formData);
    dispatch(createStudentAction(formData));
  };
  const handleEditStudent = (e, id) => {
    students.forEach((student) => {
      if (student._id === id) {
        setUpdateStudentId(student._id);
        setUpdateUsername(student.username);
        setUpdatePassword(student.password);
        setUpdateName(student.name);
        setUpdateEmail(student.email);
        setUpdatePhone(student.phone);
        setUpdateIps(student.network.ips);
        setUpdatePorts(student.network.ports);
        setUpdateSingleIp(student.network.singleIp);
        setUpdateSinglePort(student.network.singlePort);
        setUpdateActive(student.active);
        setUpdateCourse(student.course._id);
        setUpdateBatch(student.batch._id);
        setUpdateTrainer(student.trainer._id);
        setUpdateBookAccess(student.bookAccess);
        setUpdateCorporateStudent(student.corporateStudent);
      }
    });
    handleEditPopupClick();
  };
  const handleUpdateStudent = (e) => {
    e.preventDefault();
    const formData = {
      _id: updateStudentId,
      username: updateUsername,
      password: updatePassword,
      name: updateName,
      email: updateEmail,
      phone: updatePhone,
      network: {
        ips: updateIps,
        ports: updatePorts,
        singleIp: updateSingleIp,
        singlePort: updateSinglePort,
      },
      active: updateActive,
      bookAccess: updateBookAccess,
      corporateStudent: updateCorporateStudent,
    };
    dispatch(updateStudentAction(formData));
  };
  const handleDeleteStudent = (id) => {
    dispatch(deleteStudentAction(id));
    handleDeletePopupClose();
  };
  const handleUpdateStudentStatus = (id) => {
    dispatch(updateStudentStatusAction(id));
  };

  return (
    <>
      <LabsHeader
        pageTitle={
          userInfo && userInfo.role === 'admin'
            ? `${students ? 'Students (' + students.length + ' total)' : ''}`
            : 'Students'
        }
        backBtn={{ action: () => history.push('/admin/dashboard') }}
        tertiaryBtn={{
          label: 'Add New Student',
          action: () => handleCreatePopupClick(),
          style: 'contained',
        }}
        secondaryBtn={{
          label: 'Generate Students',
          link: '/admin/studentgenerate',
          color: 'secondary',
          style: 'contained',
        }}
      />
      <section className={classes.section}>
        <Container>
          {studentStatusError && (
            <Alert severity="error" style={{ marginBottom: '20px' }}>
              {studentStatusError}
            </Alert>
          )}
          {loading ||
          allCoursesLoading ||
          allBatchesLoading ||
          allUsersLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 255px)',
              }}
            >
              <CircularProgress />
            </div>
          ) : error || allCoursesError || allUsersError | allBatchesError ? (
            <Alert severity="error">
              {error || allCoursesError || allUsersError || allBatchesError}
            </Alert>
          ) : (
            <InfoTable
              columns={[
                'username',
                'batch',
                'course',
                'active',
                'trainer',
                'actions',
              ]}
              rows={
                students &&
                students.map((student) => ({
                  _id: student._id,
                  username: student.username,
                  batch: student.batch.name,
                  course: (
                    <Chip label={student.course.name} variant="outlined" />
                  ),
                  active: (
                    <Chip
                      label={student.active ? 'Activated' : 'Deactivated'}
                      color={student.active ? 'primary' : 'secondary'}
                      size="small"
                      clickable
                      onClick={() => handleUpdateStudentStatus(student._id)}
                    />
                  ),
                  trainer: student.trainer && student.trainer.name,
                  actions: [
                    {
                      kind: 'action',
                      label: 'Edit',
                      type: 'primary',
                      action: (e) => handleEditStudent(e, student._id),
                    },
                    {
                      kind: 'action',
                      label: 'Delete',
                      type: 'secondary',
                      action: () => handleDeletePopupClick(student),
                    },
                  ],
                }))
              }
            />
          )}
          {/* Add new student dialog */}
          <Dialog
            scroll="body"
            open={createPopup}
            onClose={handleCreatePopupClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={SlideTransition}
          >
            <form onSubmit={handleCreateStudent}>
              <DialogTitle id="form-dialog-title">Add New Student</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Create a new student account. You can also just bulk generate
                  students using the bulk generator form.
                </DialogContentText>
                {createLoading && (
                  <LinearProgress
                    color="primary"
                    className={classes.notifSpacing}
                  />
                )}
                {createError && (
                  <Alert severity="error" className={classes.notifSpacing}>
                    {createError}
                  </Alert>
                )}
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="username"
                  type="text"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="password"
                  type="password"
                  label="Password"
                  name="password"
                  autoComplete="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {/* Name and Phone */}
                <div style={{ display: 'flex', gap: '10px', marginTop: '3px' }}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="name"
                    type="text"
                    label="Name (optional)"
                    name="name"
                    autoComplete="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="email"
                    type="email"
                    label="Email Address (optional)"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                {/* Phone and Active Status */}
                <div style={{ display: 'flex', gap: '10px', marginTop: '3px' }}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="phone"
                    type="tel"
                    label="Phone No. (optional)"
                    name="phone"
                    autoComplete="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <FormControlLabel
                    value="top"
                    control={
                      <Switch
                        checked={active}
                        onChange={(e) => setActive(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Active"
                    labelPlacement="end"
                  />
                </div>
                {/* Book Access and Corporate Student */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    marginTop: '3px',
                  }}
                >
                  <FormControlLabel
                    value="top"
                    control={
                      <Switch
                        checked={bookAccess}
                        onChange={(e) => setBookAccess(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Provide Book Access"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="top"
                    control={
                      <Switch
                        checked={corporateStudent}
                        onChange={(e) => setCorporateStudent(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Corporate Student"
                    labelPlacement="end"
                  />
                </div>
                {/* Course & Batch */}
                <div style={{ display: 'flex', gap: '10px', marginTop: '3px' }}>
                  <FormControl
                    variant="outlined"
                    style={{ width: '100%' }}
                    margin="dense"
                    required
                    className={classes.formControl}
                  >
                    <InputLabel id="studentCourse">Course</InputLabel>
                    <Select
                      labelId="studentCourse"
                      id="course"
                      name="course"
                      value={course}
                      onChange={(e) => setCourse(e.target.value)}
                      label="Course *"
                    >
                      {allCourses &&
                        allCourses.map((course) => (
                          <MenuItem key={course._id} value={course._id}>
                            {course.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    style={{ width: '100%' }}
                    margin="dense"
                    required
                    className={classes.formControl}
                  >
                    <InputLabel id="studentBatch">Batch</InputLabel>
                    <Select
                      labelId="studentBatch"
                      disabled={!course}
                      id="batch"
                      name="batch"
                      value={batch}
                      onChange={(e) => {
                        setBatch(e.target.value);
                        setTrainer(
                          allUsers.find(
                            (user) =>
                              allBatches.find((b) => b._id === e.target.value)
                                .trainer._id === user._id
                          )._id
                        );
                      }}
                      label="Batch *"
                    >
                      {allBatches &&
                        allBatches
                          .filter((batch) => batch.course._id === course)
                          .map((batch) => (
                            <MenuItem key={batch._id} value={batch._id}>
                              {batch.name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                  {userInfo && userInfo.role === 'admin' && (
                    <FormControl
                      variant="outlined"
                      style={{ width: '100%' }}
                      margin="dense"
                      disabled
                      required
                      className={classes.formControl}
                    >
                      <InputLabel id="studentTrainer">Trainer</InputLabel>
                      <Select
                        labelId="studentTrainer"
                        id="trainer"
                        name="trainer"
                        value={trainer}
                        onChange={(e) => setTrainer(e.target.value)}
                        label="Trainer *"
                      >
                        {allUsers &&
                          allUsers.map((user) => (
                            <MenuItem key={user._id} value={user._id}>
                              {user.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
                {/* Network/VNC fields */}
                <Divider style={{ margin: '20px 0 10px' }} />
                <FormControl
                  variant="outlined"
                  style={{ width: '100%' }}
                  margin="dense"
                  className={classes.formControl}
                >
                  <InputLabel id="userConnectionType">
                    Connection Type
                  </InputLabel>
                  <Select
                    labelId="userConnectionType"
                    id="connectionType"
                    name="connectionType"
                    value={connectionType}
                    onChange={(e) => setConnectionType(e.target.value)}
                    label="Connection Type"
                  >
                    <MenuItem value={'ips-only'}>IPs Only</MenuItem>
                    <MenuItem value={'ips-ports'}>IPs and Ports</MenuItem>
                    <MenuItem value={'ports-single-ip'}>
                      Ports (single IP)
                    </MenuItem>
                    <MenuItem value={'ips-single-port'}>
                      IPs (single port)
                    </MenuItem>
                  </Select>
                </FormControl>
                <div style={{ display: 'flex', gap: '10px', marginTop: '3px' }}>
                  <TextField
                    style={{
                      display:
                        connectionType === 'ips-only' ||
                        connectionType === 'ips-ports' ||
                        connectionType === 'ips-single-port'
                          ? 'block'
                          : 'none',
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="ips"
                    type="text"
                    label="IP Address(s)"
                    name="ips"
                    autoComplete="ips"
                    value={ips ? ips.join(',') : ''}
                    onChange={(e) => setIps(e.target.value.split(','))}
                    helperText="IP Address of the student. May contain more than one, (comma separated). Ex. 192.168.3.1,192.168.3.2"
                  />
                  <TextField
                    style={{
                      display:
                        connectionType === 'ports-single-ip' ||
                        connectionType === 'ips-ports'
                          ? 'block'
                          : 'none',
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="ports"
                    type="text"
                    label="Port No(s)"
                    name="ports"
                    autoComplete="ports"
                    value={ports ? ports.join(',') : ''}
                    onChange={(e) => setPorts(e.target.value.split(','))}
                    helperText="Port no. for the student. May contain more than one, (comma separated). Ex. 1401,1402"
                  />
                </div>
                <div style={{ display: 'flex', gap: '10px', marginTop: '3px' }}>
                  <TextField
                    style={{
                      display:
                        connectionType === 'ports-single-ip' ? 'block' : 'none',
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="singleIp"
                    type="text"
                    label="Single IP (Optional)"
                    name="singleIp"
                    autoComplete="singleIp"
                    value={singleIp}
                    onChange={(e) => setSingleIp(e.target.value)}
                  />
                  <TextField
                    style={{
                      display:
                        connectionType === 'ips-single-port' ? 'block' : 'none',
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="singlePort"
                    type="text"
                    label="Single Port (Optional)"
                    name="singlePort"
                    autoComplete="singlePort"
                    value={singlePort}
                    onChange={(e) => setSinglePort(e.target.value)}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCreatePopupClose} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Create Student
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          {/* Update student dialog */}
          <Dialog
            scroll="body"
            open={editPopup}
            onClose={handleEditPopupClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={SlideTransition}
          >
            <form onSubmit={handleUpdateStudent}>
              <DialogTitle id="form-dialog-title">
                Edit Student — {updateUsername}
              </DialogTitle>
              <DialogContent>
                {updateLoading && (
                  <LinearProgress
                    color="primary"
                    className={classes.notifSpacing}
                  />
                )}
                {updateError && (
                  <Alert severity="error" className={classes.notifSpacing}>
                    {updateError}
                  </Alert>
                )}
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="username"
                  type="text"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  value={updateUsername}
                  onChange={(e) => setUpdateUsername(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  id="password"
                  type="password"
                  label="Password"
                  name="password"
                  autoComplete="password"
                  value={updatePassword}
                  onChange={(e) => setUpdatePassword(e.target.value)}
                />
                {/* Name and Email */}
                <div style={{ display: 'flex', gap: '10px', marginTop: '3px' }}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="name"
                    type="text"
                    label="Name"
                    name="name"
                    autoComplete="name"
                    value={updateName}
                    onChange={(e) => setUpdateName(e.target.value)}
                  />
                  {userInfo && userInfo.role === 'admin' && (
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      id="email"
                      type="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={updateEmail}
                      onChange={(e) => setUpdateEmail(e.target.value)}
                    />
                  )}
                </div>
                {/* Phone and Active Status */}
                <div style={{ display: 'flex', gap: '10px', marginTop: '3px' }}>
                  {userInfo && userInfo.role === 'admin' && (
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      id="phone"
                      type="tel"
                      label="Phone No."
                      name="phone"
                      autoComplete="phone"
                      value={updatePhone}
                      onChange={(e) => setUpdatePhone(e.target.value)}
                    />
                  )}
                  <FormControlLabel
                    value="top"
                    control={
                      <Switch
                        checked={updateActive}
                        onChange={(e) => setUpdateActive(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Active"
                    labelPlacement="end"
                  />
                </div>
                {/* Book Access and Corporate Student */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '3px',
                    gap: '10px',
                  }}
                >
                  <FormControlLabel
                    value="top"
                    control={
                      <Switch
                        checked={updateBookAccess}
                        onChange={(e) => setUpdateBookAccess(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Provide Book Access"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="top"
                    control={
                      <Switch
                        checked={updateCorporateStudent}
                        onChange={(e) =>
                          setUpdateCorporateStudent(e.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label="Corporate Student"
                    labelPlacement="end"
                  />
                </div>
                {/* Course & Batch */}
                <div style={{ display: 'flex', gap: '10px', marginTop: '3px' }}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    disabled
                    id="courseReadOnly"
                    type="text"
                    label="Course *"
                    name="courseReadOnly"
                    value={
                      allCourses &&
                      allCourses.find((c) => c._id === updateCourse)
                        ? allCourses.find((c) => c._id === updateCourse).name
                        : ''
                    }
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    disabled
                    id="batchReadOnly"
                    type="text"
                    label="Batch *"
                    name="batchReadOnly"
                    value={
                      allBatches &&
                      allBatches.find((b) => b._id === updateBatch)
                        ? allBatches.find((b) => b._id === updateBatch).name
                        : ''
                    }
                  />
                  {userInfo && userInfo.role === 'admin' && (
                    <TextField
                      variant="outlined"
                      margin="dense"
                      disabled
                      id="trainerReadOnly"
                      type="text"
                      label="Trainer *"
                      name="trainerReadOnly"
                      value={
                        allUsers &&
                        allUsers.find((u) => u._id === updateTrainer)
                          ? allUsers.find((u) => u._id === updateTrainer).name
                          : ''
                      }
                    />
                  )}
                </div>
                {/* Network/VNC fields */}
                <Divider style={{ margin: '20px 0 10px' }} />
                <div style={{ display: 'flex', gap: '10px', marginTop: '3px' }}>
                  <TextField
                    style={{ display: updateIps ? 'block' : 'none' }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="ips"
                    type="text"
                    label="IP Address(s)"
                    name="ips"
                    disabled
                    autoComplete="ips"
                    value={updateIps ? updateIps.join(',') : ''}
                  />
                  <TextField
                    style={{ display: updatePorts ? 'block' : 'none' }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    disabled
                    id="ports"
                    type="text"
                    label="Port No(s)"
                    name="ports"
                    autoComplete="ports"
                    value={updatePorts ? updatePorts.join(',') : ''}
                  />
                </div>
                <div style={{ display: 'flex', gap: '10px', marginTop: '3px' }}>
                  <TextField
                    style={{ display: updateSingleIp ? 'block' : 'none' }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="singleIp"
                    type="text"
                    label="IP Address(s)"
                    name="singleIp"
                    disabled
                    autoComplete="singleIp"
                    value={updateSingleIp}
                  />
                  <TextField
                    style={{ display: updateSinglePort ? 'block' : 'none' }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="singlePort"
                    type="text"
                    label="Single Port"
                    disabled
                    name="singlePort"
                    autoComplete="singlePort"
                    value={updateSinglePort}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleEditPopupClose} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Update
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          {/* Delete student dialog */}
          <Dialog
            TransitionComponent={ZoomTransition}
            open={deletePopup}
            onClose={handleDeletePopupClose}
          >
            <DialogTitle id="delete-dialog-title">Delete Student</DialogTitle>
            <DialogContent>
              {deleteLoading && (
                <LinearProgress
                  color="secondary"
                  className={classes.notifSpacing}
                />
              )}
              {deleteError && (
                <Alert severity="error" className={classes.notifSpacing}>
                  {deleteError}
                </Alert>
              )}
              <DialogContentText id="delete-dialog-description">
                Are you sure you want to delete student{' '}
                <strong>{toDeleteStudent.name}</strong>? <br />
                This action is permanent!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleDeletePopupClose}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleDeleteStudent(toDeleteStudent._id)}
                color="secondary"
              >
                Confirm Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </section>
    </>
  );
};

export default UsersScreen;
