import axios from 'axios';
import {
  ASSESSMENT_LIST_REQUEST,
  ASSESSMENT_LIST_SUCCESS,
  ASSESSMENT_LIST_FAIL,
  ASSESSMENT_DETAILS_REQUEST,
  ASSESSMENT_DETAILS_SUCCESS,
  ASSESSMENT_DETAILS_FAIL,
  ASSESSMENT_CREATE_REQUEST,
  ASSESSMENT_CREATE_SUCCESS,
  ASSESSMENT_CREATE_FAIL,
  ASSESSMENT_UPDATE_REQUEST,
  ASSESSMENT_UPDATE_SUCCESS,
  ASSESSMENT_UPDATE_FAIL,
  ASSESSMENT_DELETE_REQUEST,
  ASSESSMENT_DELETE_SUCCESS,
  ASSESSMENT_DELETE_FAIL,
  CURRENT_ASSESSMENT_QUESTION,
  ASSESSMENTS_BY_COURSE_REQUEST,
  ASSESSMENTS_BY_COURSE_SUCCESS,
  ASSESSMENTS_BY_COURSE_FAIL,
} from '../constants/assessmentsConstants';
import { snackActions } from '../utils/SnackBarUtils';

export const listAssessments = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ASSESSMENT_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/api/assessments', config);

    dispatch({ type: ASSESSMENT_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: ASSESSMENT_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listAssessmentDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ASSESSMENT_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const {
      studentLogin: { studentInfo },
    } = getState();

    if (userInfo) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`/api/assessments/${id}`, config);

      dispatch({ type: ASSESSMENT_DETAILS_SUCCESS, payload: data });
    } else if (studentInfo) {
      const config = {
        headers: {
          Authorization: `Bearer ${studentInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/assessments/${id}/student`,
        config
      );

      dispatch({ type: ASSESSMENT_DETAILS_SUCCESS, payload: data });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const createAssessment = (assessment) => async (dispatch, getState) => {
  try {
    dispatch({ type: ASSESSMENT_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/assessments`, assessment, config);

    dispatch({ type: ASSESSMENT_CREATE_SUCCESS, payload: data });
    snackActions.success(`${data.title} assessment created.`);
  } catch (err) {
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;

    dispatch({
      type: ASSESSMENT_CREATE_FAIL,
      payload: errMessage,
    });

    snackActions.error(errMessage);
  }
};

export const updateAssessment = (assessment) => async (dispatch, getState) => {
  try {
    dispatch({ type: ASSESSMENT_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/assessments/${assessment._id}`,
      assessment,
      config
    );

    dispatch({ type: ASSESSMENT_UPDATE_SUCCESS, payload: data });

    snackActions.success(`${data.title} exam updated.`);
  } catch (err) {
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;

    dispatch({
      type: ASSESSMENT_UPDATE_FAIL,
      payload: errMessage,
    });

    snackActions.error(errMessage);
  }
};

export const deleteAssessment = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ASSESSMENT_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/assessments/${id}`, config);

    dispatch({ type: ASSESSMENT_DELETE_SUCCESS });

    snackActions.success(`Assessment deleted.`);
  } catch (err) {
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;

    dispatch({
      type: ASSESSMENT_DELETE_FAIL,
      payload: errMessage,
    });

    snackActions.error(errMessage);
  }
};

export const currentQuestionLoader = (question) => async (dispatch) => {
  dispatch({ type: CURRENT_ASSESSMENT_QUESTION, payload: question });
};

export const listAssessmentsByCourse = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ASSESSMENTS_BY_COURSE_REQUEST });

    const {
      studentLogin: { studentInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${studentInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/assessments/course/${studentInfo.course._id}`,
      config
    );

    dispatch({ type: ASSESSMENTS_BY_COURSE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: ASSESSMENTS_BY_COURSE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
