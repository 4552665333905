import {
	Icon,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { WebAsset } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	centering: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

const GenerateInstructions = ({ course }) => {
	const classes = useStyles();

	return (
		<Paper
			style={{ width: '40%' }}
			className={course === '' ? classes.centering : null}>
			{(course === 'CCNA Module 1' || course === 'CCNP ENCOR') && (
				<TableContainer>
					<Table size='small'>
						<TableHead>
							<TableRow>
								<TableCell
									align='center'
									size='medium'
									colSpan={2}
									style={{ fontSize: '1rem' }}>
									Instructions for {course}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableHead>
							<TableRow>
								<TableCell>Field</TableCell>
								<TableCell>Value</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>Count</TableCell>
								<TableCell>No. of accounts to generate</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>Connection Type</TableCell>
								<TableCell>IPs Only</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell style={{ whiteSpace: 'nowrap' }}>
									IP/Port per student
								</TableCell>
								<TableCell>1</TableCell>
							</TableRow>
						</TableBody>
						<TableHead>
							<TableRow>
								<TableCell>Server</TableCell>
								<TableCell>IP Range</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>192.168.1.240</TableCell>
								<TableCell>192.168.2.161 - 192.168.2.199</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>192.168.1.238</TableCell>
								<TableCell>192.168.2.131 - 192.168.2.160</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>192.168.1.228</TableCell>
								<TableCell>192.168.2.101 - 192.168.2.130</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{course === 'Linux' && (
				<TableContainer>
					<Table size='small'>
						<TableHead>
							<TableRow>
								<TableCell
									align='center'
									size='medium'
									colSpan={2}
									style={{ fontSize: '1rem' }}>
									Instructions for {course}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableHead>
							<TableRow>
								<TableCell>Field</TableCell>
								<TableCell>Value</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>Count</TableCell>
								<TableCell>No. of accounts to generate</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>Connection Type</TableCell>
								<TableCell>Ports Only</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell style={{ whiteSpace: 'nowrap' }}>
									IP/Port per student
								</TableCell>
								<TableCell>4</TableCell>
							</TableRow>
						</TableBody>
						<TableHead>
							<TableRow>
								<TableCell>Server</TableCell>
								<TableCell>Port Range</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>192.168.1.238</TableCell>
								<TableCell>3001 - 3399</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>192.168.1.245</TableCell>
								<TableCell>3501 - 3899</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{course === 'Openstack' && (
				<TableContainer>
					<Table size='small'>
						<TableHead>
							<TableRow>
								<TableCell
									align='center'
									size='medium'
									colSpan={2}
									style={{ fontSize: '1rem' }}>
									Instructions for {course}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableHead>
							<TableRow>
								<TableCell>Field</TableCell>
								<TableCell>Value</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>Count</TableCell>
								<TableCell>No. of accounts to generate</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>Connection Type</TableCell>
								<TableCell>Ports Only</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell style={{ whiteSpace: 'nowrap' }}>
									IP/Port per student
								</TableCell>
								<TableCell>2</TableCell>
							</TableRow>
						</TableBody>
						<TableHead>
							<TableRow>
								<TableCell>Server</TableCell>
								<TableCell>Port Range</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>192.168.1.240</TableCell>
								<TableCell>3401 - 3499</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>192.168.1.245</TableCell>
								<TableCell>3901 - 3999</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{course === 'VMware' && (
				<TableContainer>
					<Table size='small'>
						<TableHead>
							<TableRow>
								<TableCell
									align='center'
									size='medium'
									colSpan={2}
									style={{ fontSize: '1rem' }}>
									Instructions for {course}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableHead>
							<TableRow>
								<TableCell>Field</TableCell>
								<TableCell>Value</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>Count</TableCell>
								<TableCell>No. of accounts to generate</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>Connection Type</TableCell>
								<TableCell>Ports Only</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell style={{ whiteSpace: 'nowrap' }}>
									IP/Port per student
								</TableCell>
								<TableCell>5</TableCell>
							</TableRow>
						</TableBody>
						<TableHead>
							<TableRow>
								<TableCell>Server</TableCell>
								<TableCell>Port Range</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>192.168.1.240</TableCell>
								<TableCell>12001 - 12499</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>192.168.1.245</TableCell>
								<TableCell>12501 - 12999</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{course === 'VMware' && (
				<TableContainer>
					<Table size='small'>
						<TableHead>
							<TableRow>
								<TableCell
									align='center'
									size='medium'
									colSpan={2}
									style={{ fontSize: '1rem' }}>
									Instructions for {course}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableHead>
							<TableRow>
								<TableCell>Field</TableCell>
								<TableCell>Value</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>Count</TableCell>
								<TableCell>No. of accounts to generate</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>Connection Type</TableCell>
								<TableCell>Ports Only</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell style={{ whiteSpace: 'nowrap' }}>
									IP/Port per student
								</TableCell>
								<TableCell>4</TableCell>
							</TableRow>
						</TableBody>
						<TableHead>
							<TableRow>
								<TableCell>Server</TableCell>
								<TableCell>Port Range</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>192.168.1.240</TableCell>
								<TableCell>7001 - 7499</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>192.168.1.245</TableCell>
								<TableCell>7501 - 7999</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{course === 'SCOR' && (
				<TableContainer>
					<Table size='small'>
						<TableHead>
							<TableRow>
								<TableCell
									align='center'
									size='medium'
									colSpan={2}
									style={{ fontSize: '1rem' }}>
									Instructions for {course}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableHead>
							<TableRow>
								<TableCell>Field</TableCell>
								<TableCell>Value</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>Count</TableCell>
								<TableCell>No. of accounts to generate</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>Connection Type</TableCell>
								<TableCell>Ports Only</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell style={{ whiteSpace: 'nowrap' }}>
									IP/Port per student
								</TableCell>
								<TableCell>1</TableCell>
							</TableRow>
						</TableBody>
						<TableHead>
							<TableRow>
								<TableCell>Server</TableCell>
								<TableCell>Port Range</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>192.168.1.240</TableCell>
								<TableCell>4001 - 4499</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>192.168.1.245</TableCell>
								<TableCell>4501 - 4999</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{course === 'CCNA Module 2' && (
				<TableContainer>
					<Table size='small'>
						<TableHead>
							<TableRow>
								<TableCell
									align='center'
									size='medium'
									colSpan={2}
									style={{ fontSize: '1rem' }}>
									Instructions for {course}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableHead>
							<TableRow>
								<TableCell>Field</TableCell>
								<TableCell>Value</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>Count</TableCell>
								<TableCell>No. of accounts to generate</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>Connection Type</TableCell>
								<TableCell>Ports Only</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell style={{ whiteSpace: 'nowrap' }}>
									IP/Port per student
								</TableCell>
								<TableCell>1</TableCell>
							</TableRow>
						</TableBody>
						<TableHead>
							<TableRow>
								<TableCell>Server</TableCell>
								<TableCell>Port Range</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>192.168.1.228</TableCell>
								<TableCell>2001 - 2099</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>192.168.1.238</TableCell>
								<TableCell>2101 - 2199</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>192.168.1.240</TableCell>
								<TableCell>2201 - 2299</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>192.168.1.245</TableCell>
								<TableCell>2501 - 2599</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{course === 'Python' && (
				<TableContainer>
					<Table size='small'>
						<TableHead>
							<TableRow>
								<TableCell
									align='center'
									size='medium'
									colSpan={2}
									style={{ fontSize: '1rem' }}>
									Instructions for {course}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableHead>
							<TableRow>
								<TableCell>Field</TableCell>
								<TableCell>Value</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>Count</TableCell>
								<TableCell>No. of accounts to generate</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>Connection Type</TableCell>
								<TableCell>Ports Only</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell style={{ whiteSpace: 'nowrap' }}>
									IP/Port per student
								</TableCell>
								<TableCell>1</TableCell>
							</TableRow>
						</TableBody>
						<TableHead>
							<TableRow>
								<TableCell>Server</TableCell>
								<TableCell>Port Range</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>192.168.1.228</TableCell>
								<TableCell>14001 - 14499</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>192.168.1.245</TableCell>
								<TableCell>14501 - 14999</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{course === 'SD-WAN' && (
				<TableContainer>
					<Table size='small'>
						<TableHead>
							<TableRow>
								<TableCell
									align='center'
									size='medium'
									colSpan={2}
									style={{ fontSize: '1rem' }}>
									Instructions for {course}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableHead>
							<TableRow>
								<TableCell>Field</TableCell>
								<TableCell>Value</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>Count</TableCell>
								<TableCell>No. of accounts to generate</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>Connection Type</TableCell>
								<TableCell>Ports Only</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell style={{ whiteSpace: 'nowrap' }}>
									IP/Port per student
								</TableCell>
								<TableCell>1</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell style={{ whiteSpace: 'nowrap' }}>IP Range</TableCell>
								<TableCell>192.168.3.201 - 192.168.3.254</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{course === 'DevNet' && (
				<TableContainer>
					<Table size='small'>
						<TableHead>
							<TableRow>
								<TableCell
									align='center'
									size='medium'
									colSpan={2}
									style={{ fontSize: '1rem' }}>
									Instructions for {course}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableHead>
							<TableRow>
								<TableCell>Field</TableCell>
								<TableCell>Value</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>Count</TableCell>
								<TableCell>No. of accounts to generate</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell>Connection Type</TableCell>
								<TableCell>Ports Only</TableCell>
							</TableRow>
							<TableRow hover>
								<TableCell style={{ whiteSpace: 'nowrap' }}>
									IP/Port per student
								</TableCell>
								<TableCell>1</TableCell>
							</TableRow>
						</TableBody>
						<TableHead>
							<TableRow>
								<TableCell>Server</TableCell>
								<TableCell>Port Range</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow hover>
								<TableCell>192.168.1.240</TableCell>
								<TableCell>6001 - 6020</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			)}
			{course === '' && (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'column',
						userSelect: 'none',
					}}>
					<div>
						<Icon
							component={WebAsset}
							style={{
								fontSize: '4rem',
								color: grey[400],
								marginBottom: '-3px',
								opacity: '0.8',
							}}
						/>
					</div>
					<Typography
						variant='h5'
						align='center'
						style={{
							lineHeight: '1.2',
							color: grey[400],
							fontWeight: '400',
							opacity: '0.8',
						}}>
						Select a course to see
						<br />
						instructions
					</Typography>
				</div>
			)}
		</Paper>
	);
};

export default GenerateInstructions;
