/**
 * @fileOverview   Reducer for the Students Schema to perform CRUD operations
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import {
  STUDENT_LOGIN_REQUEST,
  STUDENT_LOGIN_SUCCESS,
  STUDENT_LOGIN_FAIL,
  STUDENT_LOGOUT,
  STUDENT_LIST_REQUEST,
  STUDENT_LIST_SUCCESS,
  STUDENT_LIST_FAIL,
  STUDENT_LIST_RESET,
  STUDENT_CREATE_REQUEST,
  STUDENT_CREATE_SUCCESS,
  STUDENT_CREATE_FAIL,
  STUDENT_CREATE_RESET,
  STUDENT_UPDATE_REQUEST,
  STUDENT_UPDATE_SUCCESS,
  STUDENT_UPDATE_FAIL,
  STUDENT_UPDATE_RESET,
  STUDENT_DELETE_REQUEST,
  STUDENT_DELETE_SUCCESS,
  STUDENT_DELETE_FAIL,
  BULK_GEN_STUDENT_REQUEST,
  BULK_GEN_STUDENT_SUCCESS,
  BULK_GEN_STUDENT_FAIL,
  BULK_GEN_STUDENTS_RESET,
  STUDENT_STATUS_REQUEST,
  STUDENT_STATUS_SUCCESS,
  STUDENT_STATUS_FAIL,
  STUDENT_AUTH_CHECK_REQUEST,
  STUDENT_AUTH_CHECK_SUCCESS,
  STUDENT_AUTH_CHECK_FAIL,
  STUDENT_AUTH_CHECK_RESET,
  STUDENT_PROFILE_REQUEST,
  STUDENT_PROFILE_SUCCESS,
  STUDENT_PROFILE_FAIL,
  STUDENT_PROFILE_RESET,
  STUDENT_PROFILE_UPDATE_REQUEST,
  STUDENT_PROFILE_UPDATE_SUCCESS,
  STUDENT_PROFILE_UPDATE_FAIL,
} from '../constants/studentConstants';

/**
 * Login student and get student data and new token from backend
 *
 * @param {Object} state Initial state of studentLogin in the store.
 * @param {Object} action Object with payload with the logged in student data or an error
 * @return {Object} New state object
 */
export const studentLoginReducer = (state = { studentInfo: {} }, action) => {
  switch (action.type) {
    case STUDENT_LOGIN_REQUEST:
      return { loading: true, ...state };
    case STUDENT_LOGIN_SUCCESS:
      return { loading: false, studentInfo: action.payload };
    case STUDENT_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case STUDENT_LOGOUT:
      return { studentInfo: {} };
    default:
      return state;
  }
};

/**
 * Get the list of all students (Admin only)
 *
 * @param {Object} state Initial state of studentList in the store.
 * @param {Object} action Object with payload of all students or an error.
 * @return {Object} New state object
 */
export const studentListReducer = (state = { students: [] }, action) => {
  switch (action.type) {
    case STUDENT_LIST_REQUEST:
      return { loading: true };
    case STUDENT_LIST_SUCCESS:
      return { loading: false, students: action.payload };
    case STUDENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case STUDENT_LIST_RESET:
      return { students: [] };
    default:
      return state;
  }
};

/**
 * Create a new student
 *
 * @param {Object} state Initial state of student in the store.
 * @param {Object} action Object with payload of the newly created student.
 * @return {Object} New state object
 */
export const studentCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case STUDENT_CREATE_REQUEST:
      return { loading: true };
    case STUDENT_CREATE_SUCCESS:
      return { loading: false, success: true, student: action.payload };
    case STUDENT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case STUDENT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

/**
 * Update an existing student
 *
 * @param {Object} state Initial state of student in the store.
 * @param {Object} action Object with payload of the newly updated student.
 * @return {Object} New state object
 */
export const studentUpdateReducer = (state = { student: {} }, action) => {
  switch (action.type) {
    case STUDENT_UPDATE_REQUEST:
      return { loading: true, ...state };
    case STUDENT_UPDATE_SUCCESS:
      return { loading: false, success: true, student: action.payload };
    case STUDENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case STUDENT_UPDATE_RESET:
      return { course: {} };
    default:
      return state;
  }
};

/**
 * Delete an existing student
 *
 * @param {Object} state Initial state of student in the store.
 * @param {Object} action (optional) Object with payload of an optional error.
 * @return {Object} New state object
 */
export const studentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case STUDENT_DELETE_REQUEST:
      return { loading: true };
    case STUDENT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case STUDENT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

/**
 * Bulk generate new students
 *
 * @param {Object} state Initial state of students in the store.
 * @param {Object} action Object with payload of the newly created student.
 * @return {Object} New state object
 */
export const studentsBulkCreateReducer = (
  state = { generatedStudents: [] },
  action
) => {
  switch (action.type) {
    case BULK_GEN_STUDENT_REQUEST:
      return { loading: true };
    case BULK_GEN_STUDENT_SUCCESS:
      return {
        loading: false,
        success: true,
        generatedStudents: action.payload,
      };
    case BULK_GEN_STUDENT_FAIL:
      return { loading: false, error: action.payload };
    case BULK_GEN_STUDENTS_RESET:
      return { generatedStudents: [] };
    default:
      return state;
  }
};

/**
 * Activate/Deactivate student
 *
 * @param {Object} state Initial state of student in the store.
 * @param {Object} action (optional) Object with payload of an optional error.
 * @return {Object} New state object
 */
export const studentStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case STUDENT_STATUS_REQUEST:
      return { loading: true };
    case STUDENT_STATUS_SUCCESS:
      return { loading: false, success: true };
    case STUDENT_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

/**
 * Check student's authentication state on page load
 *
 * @param {Object} state Initial state of student in the store.
 * @param {Object} action (optional) Object with payload of an optional error.
 * @return {Object} New state object
 */
export const studentAuthCheckReducer = (
  state = { studentInfo: {} },
  action
) => {
  switch (action.type) {
    case STUDENT_AUTH_CHECK_REQUEST:
      return { loading: true };
    case STUDENT_AUTH_CHECK_SUCCESS:
      return { loading: false, success: true, studentInfo: action.payload };
    case STUDENT_AUTH_CHECK_FAIL:
      return { loading: false, error: action.payload };
    case STUDENT_AUTH_CHECK_RESET:
      return {};
    default:
      return state;
  }
};

/**
 * Get student profile information
 *
 * @param {Object} state Initial state of studentList in the store.
 * @param {Object} action Object with payload of all students or an error.
 * @return {Object} New state object
 */
export const studentProfileReducer = (state = { profile: {} }, action) => {
  switch (action.type) {
    case STUDENT_PROFILE_REQUEST:
      return { loading: true };
    case STUDENT_PROFILE_SUCCESS:
      return { loading: false, success: true, profile: action.payload };
    case STUDENT_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    case STUDENT_PROFILE_RESET:
      return { profile: {} };
    default:
      return state;
  }
};

/**
 * Update student profile information
 *
 * @param {Object} state Initial state of studentList in the store.
 * @param {Object} action Object with payload of all students or an error.
 * @return {Object} New state object
 */
export const studentUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case STUDENT_PROFILE_UPDATE_REQUEST:
      return { loading: true };
    case STUDENT_PROFILE_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case STUDENT_PROFILE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
