import {
  ASSESSMENT_LIST_REQUEST,
  ASSESSMENT_LIST_SUCCESS,
  ASSESSMENT_LIST_FAIL,
  ASSESSMENT_LIST_RESET,
  ASSESSMENT_DETAILS_REQUEST,
  ASSESSMENT_DETAILS_SUCCESS,
  ASSESSMENT_DETAILS_FAIL,
  ASSESSMENT_DETAILS_RESET,
  ASSESSMENT_CREATE_REQUEST,
  ASSESSMENT_CREATE_SUCCESS,
  ASSESSMENT_CREATE_FAIL,
  ASSESSMENT_CREATE_RESET,
  ASSESSMENT_UPDATE_REQUEST,
  ASSESSMENT_UPDATE_SUCCESS,
  ASSESSMENT_UPDATE_FAIL,
  ASSESSMENT_UPDATE_RESET,
  ASSESSMENT_DELETE_REQUEST,
  ASSESSMENT_DELETE_SUCCESS,
  ASSESSMENT_DELETE_FAIL,
  CURRENT_ASSESSMENT_QUESTION,
  ASSESSMENTS_BY_COURSE_REQUEST,
  ASSESSMENTS_BY_COURSE_SUCCESS,
  ASSESSMENTS_BY_COURSE_FAIL,
} from '../constants/assessmentsConstants';

export const assessmentListReducer = (state = { assessments: [] }, action) => {
  switch (action.type) {
    case ASSESSMENT_LIST_REQUEST:
      return { loading: true, assessments: [] };
    case ASSESSMENT_LIST_SUCCESS:
      return { loading: false, assessments: action.payload };
    case ASSESSMENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case ASSESSMENT_LIST_RESET:
      return { assessments: [] };
    default:
      return state;
  }
};

export const assessmentDetailsReducer = (
  state = { assessment: { questions: [] } },
  action
) => {
  switch (action.type) {
    case ASSESSMENT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ASSESSMENT_DETAILS_SUCCESS:
      return { loading: false, assessment: action.payload };
    case ASSESSMENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case ASSESSMENT_DETAILS_RESET:
      return { questions: [] };
    default:
      return state;
  }
};

export const assessmentCreateReducer = (state = { questions: [] }, action) => {
  switch (action.type) {
    case ASSESSMENT_CREATE_REQUEST:
      return { loading: true };
    case ASSESSMENT_CREATE_SUCCESS:
      return { loading: false, success: true, assessment: action.payload };
    case ASSESSMENT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ASSESSMENT_CREATE_RESET:
      return { questions: [] };
    default:
      return state;
  }
};

export const assessmentUpdateReducer = (state = { questions: [] }, action) => {
  switch (action.type) {
    case ASSESSMENT_UPDATE_REQUEST:
      return { loading: true, ...state };
    case ASSESSMENT_UPDATE_SUCCESS:
      return { loading: false, success: true, assessment: action.payload };
    case ASSESSMENT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ASSESSMENT_UPDATE_RESET:
      return { questions: [] };
    default:
      return state;
  }
};

export const assessmentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ASSESSMENT_DELETE_REQUEST:
      return { loading: true };
    case ASSESSMENT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ASSESSMENT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const assessmentQuestionReducer = (state = { options: [] }, action) => {
  switch (action.type) {
    case CURRENT_ASSESSMENT_QUESTION:
      return { success: true, question: action.payload };
    default:
      return state;
  }
};

export const assessmentsListByCourseReducer = (
  state = { assessments: [] },
  action
) => {
  switch (action.type) {
    case ASSESSMENTS_BY_COURSE_REQUEST:
      return { loading: true, assessments: [] };
    case ASSESSMENTS_BY_COURSE_SUCCESS:
      return { loading: false, assessments: action.payload };
    case ASSESSMENTS_BY_COURSE_FAIL:
      return { loading: false, assessments: action.payload };
    default:
      return state;
  }
};
