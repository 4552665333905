/**
 * @fileOverview   Reducer for the Feedback Schema to perform CRUD operations
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import {
  FEEDBACK_LIST_REQUEST,
  FEEDBACK_LIST_SUCCESS,
  FEEDBACK_LIST_FAIL,
  FEEDBACK_LIST_RESET,
  FEEDBACK_DETAILS_REQUEST,
  FEEDBACK_DETAILS_SUCCESS,
  FEEDBACK_DETAILS_FAIL,
  FEEDBACK_DETAILS_RESET,
  FEEDBACK_CREATE_REQUEST,
  FEEDBACK_CREATE_SUCCESS,
  FEEDBACK_CREATE_FAIL,
  FEEDBACK_CREATE_RESET,
  FEEDBACK_DELETE_REQUEST,
  FEEDBACK_DELETE_SUCCESS,
  FEEDBACK_DELETE_FAIL,
} from '../constants/feedbackConstants';

/**
 * Get all feedbacks
 *
 * @param {Object} state Initial state of feedbacks in the store.
 * @param {Object} action Object with payload of the fetched feedbacks.
 * @return {Object} New state object
 */
export const feedbackListReducer = (state = { feedbacks: [] }, action) => {
  switch (action.type) {
    case FEEDBACK_LIST_REQUEST:
      return { loading: true, feedbacks: [] };
    case FEEDBACK_LIST_SUCCESS:
      return { loading: false, feedbacks: action.payload };
    case FEEDBACK_LIST_FAIL:
      return { loading: false, error: action.payload };
    case FEEDBACK_LIST_RESET:
      return { feedbacks: [] };
    default:
      return state;
  }
};

/**
 * Get data of a single feedback
 *
 * @param {Object} state Initial state of feedback in the store.
 * @param {Object} action Object with payload of the single feedback.
 * @return {Object} New state object
 */
export const feedbackDetailsReducer = (
  state = { feedback: { trainingFeedback: [], instructorFeedback: [] } },
  action
) => {
  switch (action.type) {
    case FEEDBACK_DETAILS_REQUEST:
      return { ...state, loading: true };
    case FEEDBACK_DETAILS_SUCCESS:
      return { loading: false, feedback: action.payload };
    case FEEDBACK_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case FEEDBACK_DETAILS_RESET:
      return { trainingFeedback: [], instructorFeedback: [] };
    default:
      return state;
  }
};

/**
 * Create a new feedback
 *
 * @param {Object} state Initial state of feedback in the store.
 * @param {Object} action Object with payload of the newly created feedback.
 * @return {Object} New state object
 */
export const feedbackCreateReducer = (
  state = { trainingFeedback: [], instructorFeedback: [] },
  action
) => {
  switch (action.type) {
    case FEEDBACK_CREATE_REQUEST:
      return { loading: true };
    case FEEDBACK_CREATE_SUCCESS:
      return { loading: false, success: true, feedback: action.payload };
    case FEEDBACK_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case FEEDBACK_CREATE_RESET:
      return { trainingFeedback: [], instructorFeedback: [] };
    default:
      return state;
  }
};

/**
 * Delete an existing feedback
 *
 * @param {Object} state Initial state of feedback in the store.
 * @param {Object} action (optional) Object with payload of an optional error.
 * @return {Object} New state object
 */
export const feedbackDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case FEEDBACK_DELETE_REQUEST:
      return { loading: true };
    case FEEDBACK_DELETE_SUCCESS:
      return { loading: false, success: true };
    case FEEDBACK_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
