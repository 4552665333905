import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  CircularProgress,
  LinearProgress,
  Dialog,
  DialogContent,
  TextField,
  Button,
  Select,
  Paper,
  Chip,
  InputLabel,
  MenuItem,
  FormControl,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItem,
  ListSubheader,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import { DeleteTwoTone } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import LabsHeader from '../components/LabsHeader';
import InfoTable from '../components/InfoTable';
import { listCourses as listCoursesAction } from '../actions/courseActions';
import {
  listAssessments as listAssessmentsAction,
  createAssessment as createAssessmentAction,
  updateAssessment as updateAssessmentAction,
  deleteAssessment as deleteAssessmentAction,
  currentQuestionLoader,
} from '../actions/assessmentActions';
import {
  ASSESSMENT_CREATE_RESET,
  ASSESSMENT_UPDATE_RESET,
} from '../constants/assessmentsConstants';
import { SlideTransition, ZoomTransition } from '../components/Transitions';
import { CURRENT_ASSESSMENT_QUESTION } from '../constants/assessmentsConstants';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';
import AssessmentQuestion from '../components/AssessmentQuestion';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[200],
    padding: '50px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: 'calc(100vh - 206px)',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  appBar: {
    position: 'relative',
  },
  chip: {
    margin: 2,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  notifSpacing: {
    marginBottom: '10px',
  },
  spacer: {
    height: '20px',
  },
  markupText: {
    '& textarea': {
      fontFamily: 'monospace',
    },
  },
}));

const AssessmentScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  /* ----------------------------- Dialog State ---------------------------- */
  const [createPopup, setCreatePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);

  /* -------------------------- Form and Local State -------------------------- */
  const [no, setNo] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [course, setCourse] = useState('');
  const [questions, setQuestions] = useState([]);
  const [updateNo, setUpdateNo] = useState('');
  const [updateTitle, setUpdateTitle] = useState('');
  const [updateDescription, setUpdateDescription] = useState('');
  const [updateCourse, setUpdateCourse] = useState('');
  const [updateQuestions, setUpdateQuestions] = useState([]);

  const [updateAssessmentId, setUpdateAssessmentId] = useState('');
  const [toDeleteAssessment, setToDeleteAssessment] = useState('');

  /* --------------------------- Redux State Getters -------------------------- */
  const useLogin = useSelector((state) => state.userLogin);
  const { userInfo } = useLogin;

  const assessmentsList = useSelector((state) => state.assessmentsList);
  const { loading, error, assessments } = assessmentsList;

  const coursesList = useSelector((state) => state.coursesList);
  const {
    loading: allCoursesLoading,
    error: allCoursesError,
    courses: allCourses,
  } = coursesList;

  const assessmentCreate = useSelector((state) => state.assessmentCreate);
  const {
    loading: createLoading,
    error: createError,
    success: createSuccess,
    assessment: createdAssessment,
  } = assessmentCreate;

  const assessmentUpdate = useSelector((state) => state.assessmentUpdate);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    assessment: updatedAssessment,
  } = assessmentUpdate;

  const assessmentDelete = useSelector((state) => state.assessmentDelete);
  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
  } = assessmentDelete;

  const assessmentQuestion = useSelector((state) => state.assessmentQuestion);
  const { success, question } = assessmentQuestion;

  /* -------------------------------------------------------------------------- */
  /*                              Lifecycle events                              */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (userInfo) {
      dispatch(listAssessmentsAction());
      dispatch(listCoursesAction());
    } else {
      history.push('/admin/login');
    }

    dispatch({ type: HEADER_FOOTER_SHOW });

    if (createSuccess) {
      handleCreatePopupClose();
      setCreatePopup(false);
      setNo('');
      setTitle('');
      setDescription('');
      setCourse('');
      setQuestions([]);
      dispatch({ type: ASSESSMENT_CREATE_RESET });
    }

    if (updateSuccess) {
      handleEditPopupClose();
      setUpdateNo('');
      setUpdateTitle('');
      setUpdateDescription('');
      setUpdateCourse('');
      setUpdateQuestions([]);
      dispatch({ type: ASSESSMENT_UPDATE_RESET });
    }

    if (deleteSuccess) {
      handleDeletePopupClose();
      setToDeleteAssessment('');
    }
  }, [
    dispatch,
    history,
    userInfo,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    updatedAssessment,
    questions,
  ]);

  /* ---------------------------- Popup Management ---------------------------- */
  const handleCreatePopupClick = () => {
    setCreatePopup(true);
  };

  const handleCreatePopupClose = () => {
    setCreatePopup(false);
    dispatch({ type: CURRENT_ASSESSMENT_QUESTION, payload: { options: [] } });
  };

  const handleEditPopupClick = () => {
    setEditPopup(true);
  };

  const handleEditPopupClose = () => {
    setEditPopup(false);
    setUpdateNo('');
    setUpdateTitle('');
    setUpdateDescription('');
    setUpdateCourse('');
    setUpdateQuestions([]);
    dispatch({ type: CURRENT_ASSESSMENT_QUESTION, payload: { options: [] } });
  };

  const handleDeletePopupClick = (assessment) => {
    console.log(assessment);
    setToDeleteAssessment(assessment);
    setDeletePopup(true);
  };

  const handleDeletePopupClose = () => {
    setDeletePopup(false);
  };

  /* ----------------------------- Action Handlers ---------------------------- */
  const handleCreateAssessment = (e) => {
    e.preventDefault();
    const formData = {
      no,
      title,
      description,
      questions,
      course,
    };

    dispatch(createAssessmentAction(formData));
  };

  const handleEditAssessment = (_e, id) => {
    assessments.forEach((assessment) => {
      if (assessment._id === id) {
        setUpdateAssessmentId(assessment._id);
        setUpdateNo(assessment.no);
        setUpdateTitle(assessment.title);
        setUpdateDescription(assessment.description);
        setUpdateCourse(assessment.course._id);
        setUpdateQuestions(assessment.questions);
      }
    });

    handleEditPopupClick();
  };

  const handleUpdateAssessment = (e) => {
    e.preventDefault();
    const formData = {
      _id: updateAssessmentId,
      no: updateNo,
      title: updateTitle,
      description: updateDescription,
      course: updateCourse,
      questions: updateQuestions,
    };

    dispatch(updateAssessmentAction(formData));
  };

  const handleDeleteAssessment = (id) => {
    dispatch(deleteAssessmentAction(id));
    handleDeletePopupClose();
  };

  const handleAddQuestion = ({
    title,
    questionBody,
    questionNo,
    type,
    answerInput,
    image,
    options,
    imageSize,
    answerInputCaseSensitive,
    multipleSelectable,
  }) => {
    setQuestions([
      ...questions,
      {
        title,
        questionBody,
        questionNo,
        type,
        answerInput,
        image,
        options,
        imageSize,
        answerInputCaseSensitive,
        multipleSelectable,
      },
    ]);
  };

  const handleUpdateQuestion = ({
    title,
    questionBody,
    questionNo,
    type,
    answerInput,
    image,
    options,
    imageSize,
    answerInputCaseSensitive,
    multipleSelectable,
  }) => {
    const cleanedQuestions = updateQuestions.filter(
      (q) => q.questionNo !== questionNo
    );
    setUpdateQuestions([
      ...cleanedQuestions,
      {
        title,
        questionBody,
        questionNo,
        type,
        answerInput,
        image,
        options,
        imageSize,
        answerInputCaseSensitive,
        multipleSelectable,
      },
    ]);
  };

  const handleEditQuestion = (question) => {
    dispatch(currentQuestionLoader(question));
  };

  // DELETE A QUESTION
  const handleDeleteQuestionOnUpdateScreen = (question) => {
    const questionList = updateQuestions.filter(
      (q) => q.questionNo !== question.questionNo
    );
    setUpdateQuestions(questionList);
  };
  // DELETE A QUESTION
  const handleDeleteQuestionOnCreateScreen = (question) => {
    const questionList = questions.filter(
      (q) => q.questionNo !== question.questionNo
    );
    setQuestions(questionList);
  };

  /* -------------------------------------------------------------------------- */
  /*                                     JSX                                    */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <LabsHeader
        pageTitle={
          userInfo && userInfo.role === 'admin'
            ? `${
                assessments
                  ? 'Assessments (' + assessments.length + ' total)'
                  : ''
              }`
            : 'assessments'
        }
        backBtn={{ action: () => history.push('/admin/dashboard') }}
        tertiaryBtn={{
          label: 'Create Assessment',
          action: () => handleCreatePopupClick(),
          style: 'contained',
        }}
      />
      <section className={classes.section}>
        <Container>
          {loading && allCoursesLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 255px)',
              }}
            >
              <CircularProgress />
            </div>
          ) : error || allCoursesError ? (
            <Alert severity="error">{error || allCoursesError}</Alert>
          ) : (
            <InfoTable
              columns={['title', 'course', 'questions', 'actions']}
              rows={
                assessments &&
                assessments.map((assessment) => ({
                  _id: assessment._id,
                  title: (
                    <span style={{ fontWeight: '500' }}>
                      {assessment.title}
                    </span>
                  ),
                  course: (
                    <Chip label={assessment.course.name} variant="outlined" />
                  ),
                  questions:
                    assessment.questionCount === 0
                      ? 0
                      : assessment.questionCount,
                  actions: [
                    ...(userInfo && userInfo.accessLevel >= 7
                      ? [
                          {
                            kind: 'action',
                            label: 'Edit',
                            type: 'primary',
                            action: (e) =>
                              handleEditAssessment(e, assessment._id),
                          },
                        ]
                      : []),
                    ...(userInfo && userInfo.role === 'admin'
                      ? [
                          {
                            kind: 'action',
                            label: 'Delete',
                            type: 'secondary',
                            action: () => handleDeletePopupClick(assessment),
                          },
                        ]
                      : []),
                  ],
                }))
              }
            />
          )}

          {/* -------------------------------------------------------------------------- */
          /*                         Add new assessments dialog                         */
          /* -------------------------------------------------------------------------- */}
          <Dialog
            fullScreen
            open={createPopup}
            onClose={handleCreatePopupClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={SlideTransition}
          >
            <form onSubmit={handleCreateAssessment}>
              <AppBar className={classes.appBar} color="primary">
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleCreatePopupClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    Create Assessment
                  </Typography>
                  <Button color="inherit" type="submit">
                    Create
                  </Button>
                </Toolbar>
              </AppBar>
              <DialogContent>
                {createLoading && (
                  <LinearProgress
                    color="primary"
                    className={classes.notifSpacing}
                  />
                )}
                {createError && (
                  <Alert severity="error" className={classes.notifSpacing}>
                    {createError}
                  </Alert>
                )}

                {/* ------------------------ Create New - Form Content ----------------------- */}
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="assessmentNo"
                    type="text"
                    label="No."
                    name="assessmentNo"
                    autoComplete="no"
                    autoFocus
                    value={no}
                    onChange={(e) => setNo(e.target.value)}
                    style={{ width: '15%' }}
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="title"
                    type="text"
                    label="Title"
                    name="title"
                    autoComplete="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    style={{ width: '30%' }}
                    required
                    className={classes.formControl}
                  >
                    <InputLabel id="assessmentCourse">Course</InputLabel>
                    <Select
                      labelId="assessmentCourse"
                      id="course"
                      name="course"
                      value={course}
                      onChange={(e) => setCourse(e.target.value)}
                      label="Course *"
                    >
                      {allCourses &&
                        allCourses.map((c) => (
                          <MenuItem key={c._id} value={c._id}>
                            {c.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <TextField
                  variant="outlined"
                  margin="dense"
                  multiline
                  rows="5"
                  fullWidth
                  id="assessmentDesc"
                  type="text"
                  label="Assessment Description"
                  name="assessmentDesc"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />

                {/* -------------------------------------------------------------------------- */
                /*                               Show Questions                               */
                /* -------------------------------------------------------------------------- */}
                <Paper
                  variant="outlined"
                  elevation={0}
                  style={{ marginTop: '0.5rem' }}
                >
                  <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        Questions
                      </ListSubheader>
                    }
                  >
                    {questions.length > 0 ? (
                      <>
                        {questions.map((question) => (
                          <ListItem
                            button
                            onClick={() => handleEditQuestion(question)}
                          >
                            <ListItemIcon>
                              <p style={{ margin: 0, padding: 0 }}>
                                {question.questionNo}
                              </p>
                            </ListItemIcon>
                            <ListItemText primary={question.title} />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() =>
                                  handleDeleteQuestionOnCreateScreen(question)
                                }
                              >
                                <DeleteTwoTone />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </>
                    ) : (
                      <ListItem>
                        <ListItemText primary="No question yet" />
                      </ListItem>
                    )}
                  </List>
                </Paper>

                {/* ---------------------------- Add Questions Box --------------------------- */}
                <AssessmentQuestion callback={handleAddQuestion} />
              </DialogContent>
            </form>
          </Dialog>

          {/* -------------------------------------------------------------------------- */
          /*                         Update Assessment Dialogue                         */
          /* -------------------------------------------------------------------------- */}
          <Dialog
            fullScreen
            open={editPopup}
            onClose={handleEditPopupClose}
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={SlideTransition}
          >
            <form onSubmit={handleUpdateAssessment}>
              <AppBar className={classes.appBar} color="primary">
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleEditPopupClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    Edit Assessment
                  </Typography>
                  <Button color="inherit" type="submit">
                    Update
                  </Button>
                </Toolbar>
              </AppBar>
              <DialogContent>
                {updateLoading && (
                  <LinearProgress
                    color="primary"
                    className={classes.notifSpacing}
                  />
                )}
                {updateError && (
                  <Alert severity="error" className={classes.notifSpacing}>
                    {updateError}
                  </Alert>
                )}

                {/* ------------------------ Create New - Form Content ----------------------- */}
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                >
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="assessmentNo"
                    type="text"
                    label="No."
                    name="assessmentNo"
                    autoComplete="no"
                    autoFocus
                    value={updateNo}
                    onChange={(e) => setUpdateNo(e.target.value)}
                    style={{ width: '15%' }}
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="title"
                    type="text"
                    label="Title"
                    name="title"
                    autoComplete="title"
                    value={updateTitle}
                    onChange={(e) => setUpdateTitle(e.target.value)}
                  />
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    style={{ width: '30%' }}
                    required
                    className={classes.formControl}
                  >
                    <InputLabel id="assessmentCourse">Course</InputLabel>
                    <Select
                      labelId="assessmentCourse"
                      id="course"
                      name="course"
                      value={updateCourse}
                      onChange={(e) => setUpdateCourse(e.target.value)}
                      label="Course *"
                    >
                      {allCourses &&
                        allCourses.map((c) => (
                          <MenuItem key={c._id} value={c._id}>
                            {c.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <TextField
                  variant="outlined"
                  margin="dense"
                  multiline
                  rows="5"
                  fullWidth
                  id="assessmentDesc"
                  type="text"
                  label="Assessment Description"
                  name="assessmentDesc"
                  value={updateDescription}
                  onChange={(e) => setUpdateDescription(e.target.value)}
                />

                {/* ----------------------------- Show Questions ----------------------------- */}
                <Paper
                  variant="outlined"
                  elevation="0"
                  style={{ marginTop: '0.5rem' }}
                >
                  <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        Questions
                      </ListSubheader>
                    }
                  >
                    {updateQuestions.length > 0 ? (
                      <>
                        {updateQuestions
                          .sort((a, b) => +a.questionNo - +b.questionNo)
                          .map((question) => (
                            <ListItem
                              button
                              onClick={(e) => handleEditQuestion(question)}
                            >
                              <ListItemIcon>
                                <p style={{ padding: 0, margin: 0 }}>
                                  {question.questionNo}
                                </p>
                              </ListItemIcon>
                              <ListItemText primary={question.title} />
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() =>
                                    handleDeleteQuestionOnUpdateScreen(question)
                                  }
                                >
                                  <DeleteTwoTone />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                      </>
                    ) : (
                      <ListItem>
                        <ListItemText primary="No question yet" />
                      </ListItem>
                    )}
                  </List>
                </Paper>

                {/* ---------------------------- Add Questions Box --------------------------- */}
                <AssessmentQuestion callback={handleUpdateQuestion} />
              </DialogContent>
            </form>
          </Dialog>

          {/* Delete Assessment dialog */}
          <Dialog
            TransitionComponent={ZoomTransition}
            open={deletePopup}
            onClose={handleDeletePopupClose}
          >
            <DialogTitle id="delete-dialog-title">
              Delete Assessment
            </DialogTitle>
            <DialogContent>
              {deleteLoading && (
                <LinearProgress
                  color="secondary"
                  className={classes.progressSpacing}
                />
              )}
              {deleteError && <Alert severity="error">{deleteError}</Alert>}
              <DialogContentText id="delete-dialog-description">
                Are you sure? This will completey delete this assessment and all
                it's questions.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleDeletePopupClose}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleDeleteAssessment(toDeleteAssessment._id)}
                color="secondary"
              >
                Confirm Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </section>
    </>
  );
};

export default AssessmentScreen;
