import { makeStyles, Typography } from '@material-ui/core';
import { blue, grey } from '@material-ui/core/colors';

import ciscoLogo from '../cisco-logo.svg';
import rstLogo from '../rst-logo.svg';
import signImg from '../sign.png';

const useStyles = makeStyles((_theme) => ({
	outer: {
		backgroundColor: 'white',
		padding: '30px',
	},
	inner: {
		backgroundColor: 'white',
		padding: '60px 100px 50px 100px',
		border: `30px solid #1c485c`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
}));

const Certificate = ({
	name,
	course,
	courseNameOnCertificate,
	courseDateOnCertificate,
	isCorporateTrainingBatch,
	companyNameOnCertificate,
}) => {
	console.log('BITCH', isCorporateTrainingBatch);
	const classes = useStyles();

	return (
		<div className={classes.outer}>
			<div className={classes.inner}>
				<div className={classes.title}>
					<img src={rstLogo} alt='RST Forum Logo' style={{ height: '85px' }} />
					<Typography
						variant='h4'
						component='h4'
						style={{
							fontWeight: '900',
							fontColor: `blue[900]`,
							textTransform: 'uppercase',
							fontSize: '2.2rem',
							marginTop: '2.4rem',
						}}>
						Routing Switching Tigers Forum
					</Typography>

					<Typography
						variant='h4'
						component='h4'
						style={{
							fontWeight: 'bold',
							fontColor: `blue[900]`,
							textTransform: 'uppercase',
							fontSize: '1.7rem',
							marginTop: '4rem',
							marginBottom: '20px',
							opacity: '0.7',
						}}>
						Certificate of completion
					</Typography>
				</div>
				<div
					style={{
						height: '10px',
						width: '50px',
						display: 'inline-block',
						backgroundColor: 'transparent',
					}}></div>
				<div
					style={{
						textAlign: 'center',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
					}}>
					<Typography
						variant='h3'
						style={{
							color: blue[900],
							fontWeight: 'bold',
							marginBottom: '20px',
							fontSize: '3.3rem',
						}}>
						{name}
					</Typography>
					<Typography variant='h5' style={{ color: grey[800] }}>
						has successfully completed the training for
					</Typography>
					<Typography
						variant='h4'
						style={{
							color: blue['A700'],
							fontWeight: 'bold',
							margin: '0.5rem 0',
						}}>
						{courseNameOnCertificate
							? courseNameOnCertificate
							: `${course} Training`}
					</Typography>
					{isCorporateTrainingBatch ? (
						<Typography variant='h5' style={{ color: grey[800] }}>
							<span
								dangerouslySetInnerHTML={{ __html: companyNameOnCertificate }}
							/>
						</Typography>
					) : null}
					<strong style={{ fontSize: '18px', marginTop: '10px' }}>
						{courseDateOnCertificate && <span>Date: </span>}
						{courseDateOnCertificate
							? courseDateOnCertificate
							: 'May 5th to May 6th, 2022'}
					</strong>
				</div>
				{/* Logos */}
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginTop: '50px',
						width: '100%',
						alignItems: 'center',
					}}>
					{isCorporateTrainingBatch && (
						<></>
						// <img src={ciscoLogo} alt='Cisco Logo' style={{ height: '80px' }} />
						// <img
						// 	src={'https://labs.rstforum.net/uploads/image-1681662192685.png'}
						// 	alt='Red Hat Logo'
						// 	style={{ height: '60px' }}
						// />
					)}

					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
						}}>
						<Typography variant='body1'>
							{/* <strong>
								{courseDateOnCertificate && <span>Date: </span>}
								{courseDateOnCertificate
									? courseDateOnCertificate
									: 'May 5th to May 6th, 2022'}
							</strong> */}
						</Typography>
						<img
							style={{
								marginTop: '10px',
								height: '85px',
								marginBottom: '0px',
								color: grey[800],
							}}
							src={signImg}
							alt='Sign'
						/>
						<Typography variant='body1' style={{ color: grey[700] }}>
							Course Director, RSTForum
						</Typography>
					</div>
				</div>

				<span
					style={{
						textAlign: 'center',
						fontSize: '20px',
						fontWeight: 'bold',
						opacity: '70%',
						position: 'relative',
						height: '20px',
						bottom: '-31px',
					}}>
					www.rstforum.net
				</span>
			</div>
		</div>
	);
};

export default Certificate;
