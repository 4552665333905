import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import LabsHeader from '../components/LabsHeader';
import {
  makeStyles,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  Input,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Box,
  TextField,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { filterRegistrations as filterRegistrationsAction } from '../actions/registrationActions';
import {
  courses as allCoursesList,
  batchTiming as batchTimingList,
  batch as batchesList,
} from '../data/registration-form-data';

import logo from '../rst-logo.svg';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[200],
    padding: '60px 20px',
    width: '100%',
  },
  mainContent: {
    padding: '2rem',
    background: grey[100],
    width: '100%',
    minHeight: 'calc(100vh - 202px)',
    overflow: 'auto',
  },
  pageContent: {
    padding: '3rem 4rem',
    width: '90%',
    margin: '0 auto',
  },
  attendanceTable: {
    '& tr, th, td': {
      border: '1px solid rgba(0,0,0,1)',
      padding: '4px 10px',
    },
  },
}));

const RegistrationsManagementScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const printPageRef = useRef();

  /* -------------------------- Local State Variables ------------------------- */
  const [course, setCourse] = useState('');
  const [batch, setBatch] = useState('');
  const [timing, setTiming] = useState('');
  const [startingDate, setStartingDate] = useState(null);
  const [instructors, setInstructors] = useState('');

  const [registrationData, setRegistrationData] = useState([]);

  /* -------------------------------------------------------------------------- */
  /*                              Redux State Data                              */
  /* -------------------------------------------------------------------------- */
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const registrationFilter = useSelector((state) => state.registrationFilter);
  const { loading, error, registrations } = registrationFilter;

  /* -------------------------------------------------------------------------- */
  /*                              Life Cycle Hooks                              */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    }

    dispatch({ type: HEADER_FOOTER_SHOW });

    let emptyValues = [];
    if (registrations.length > 0 && registrations.length <= 20) {
      for (let i = registrations.length; i < 20; i++) {
        // emptyValues.length = 0;
        emptyValues.push({ _id: i, name: '', mobileNo: '' });
      }
    } else if (registrations.length > 20 && registrations.length <= 40) {
      for (let i = registrations.length; i < 40; i++) {
        // emptyValues.length = 0;
        emptyValues.push({ _id: i, name: '', mobileNo: '' });
      }
    } else if (registrations.length > 40 && registrations.length <= 60) {
      for (let i = registrations.length; i < 60; i++) {
        // emptyValues.length = 0;
        emptyValues.push({ _id: i, name: '', mobileNo: '' });
      }
    } else if (registrations.length > 60 && registrations.length <= 80) {
      for (let i = registrations.length; i < 80; i++) {
        // emptyValues.length = 0;
        emptyValues.push({ _id: i, name: '', mobileNo: '' });
      }
    } else if (registrations.length > 80 && registrations.length <= 100) {
      for (let i = registrations.length; i < 100; i++) {
        // emptyValues.length = 0;
        emptyValues.push({ _id: i, name: '', mobileNo: '' });
      }
    }
    setRegistrationData([...registrations, ...emptyValues]);
  }, [dispatch, history, userInfo, registrations]);

  const handleDateChange = (date) => {
    setStartingDate(date);
  };

  const handleListGenerate = (e) => {
    e.preventDefault();
    const data = { course, batch, timing, startingDate };
    dispatch(filterRegistrationsAction(data));
  };

  // console.log(registrations);
  // console.log(registrationData);

  return (
    <>
      <LabsHeader
        pageTitle='Student Registrations List'
        backBtn={{ action: () => history.goBack() }}
        customJSX={
          <ReactToPrint
            trigger={() => (
              <Button variant='contained' color='primary'>
                Print Attendance Sheet
              </Button>
            )}
            content={() => printPageRef.current}
          />
        }
      />

      <section className={classes.section}>
        <Container
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          {/* Form */}
          <Box component={Paper} padding='30px' width='100%'>
            <form onSubmit={handleListGenerate}>
              <div>
                <Typography
                  component='h2'
                  variant='h5'
                  style={{ marginBottom: '20px', fontWeight: '500' }}
                >
                  Generate Registration List
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    marginBottom: '4px',
                  }}
                >
                  <FormControl
                    variant='outlined'
                    style={{ width: '100%' }}
                    margin='dense'
                    className={classes.formControl}
                  >
                    <InputLabel id='course'>Select course</InputLabel>
                    <Select
                      labelId='course'
                      id='courseEnrolledFor'
                      name='courseEnrolledFor'
                      value={course}
                      onChange={(e) => setCourse(e.target.value)}
                      label='Select course'
                    >
                      {allCoursesList.map((course) => (
                        <MenuItem key={course} value={course}>
                          {course}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    variant='outlined'
                    style={{ width: '100%' }}
                    margin='dense'
                    className={classes.formControl}
                  >
                    <InputLabel id='batch'>Select batch</InputLabel>
                    <Select
                      labelId='batch'
                      id='batchEnrolledFor'
                      name='batchEnrolledFor'
                      value={batch}
                      onChange={(e) => setBatch(e.target.value)}
                      label='Select batch'
                    >
                      {batchesList.map((batch) => (
                        <MenuItem value={batch} key={batch}>
                          {batch}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                  }}
                >
                  {/* TIME */}
                  <FormControl
                    variant='outlined'
                    style={{ width: '100%' }}
                    margin='dense'
                    className={classes.formControl}
                  >
                    <InputLabel id='batchTiming'>
                      Select batch timing
                    </InputLabel>
                    <Select
                      labelId='batchTiming'
                      id='timing'
                      name='timing'
                      value={timing}
                      onChange={(e) => setTiming(e.target.value)}
                      label='Select batch timing'
                    >
                      {batchTimingList.map((timing) => (
                        <MenuItem value={timing} key={timing}>
                          {timing}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    id='instructors'
                    type='text'
                    label='Enter Instructor(s) Names'
                    name='instructors'
                    autoComplete='instructors'
                    value={instructors}
                    onChange={(e) => setInstructors(e.target.value)}
                  />
                </div>
              </div>
              {/* Starting Date */}
              <div style={{ width: '50%', marginBottom: '1rem' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin='normal'
                    id='date-picker-dialog'
                    label='Select Batch Starting Date'
                    format='dd/MM/yyyy'
                    value={startingDate}
                    fullWidth={true}
                    onChange={handleDateChange}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <Button type='submit' variant='contained' color='primary'>
                Generate List
              </Button>
            </form>
          </Box>
        </Container>
        <div style={{ height: '3rem' }} />
        <Container>
          <Paper
            ref={printPageRef}
            elevation={false}
            style={{ width: '842px', margin: '0 auto' }}
          >
            {/* -------------------------------------------------------------------------- */
            /*                                PAPER SHEET 1                               */
            /* -------------------------------------------------------------------------- */}

            {registrations &&
            registrationData &&
            registrationData.length > 0 ? (
              <>
                {/* Table One */}
                <div
                  style={{
                    width: '842px',
                    height: '1190px',
                    borderBottom: '1px dotted lightgray',
                  }}
                >
                  {/* Inner Div with spaces */}
                  <div style={{ padding: '2rem' }}>
                    <Table size='small' className={classes.attendanceTable}>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center' colSpan={4}>
                            <Typography
                              variant='h6'
                              style={{
                                fontWeight: '600',
                                textTransform: 'uppercase',
                              }}
                            >
                              Attendance Sheet
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell size='small' variant='head'>
                            Course Name
                          </TableCell>
                          <TableCell variant='head' size='small'>
                            {course}
                          </TableCell>
                          <TableCell size='small' variant='head'>
                            Start Date
                          </TableCell>
                          <TableCell variant='head' size='small'>
                            {startingDate
                              ? startingDate.toString().substring(0, 15)
                              : ''}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell size='small' variant='head'>
                            Batch Time
                          </TableCell>
                          <TableCell variant='head' size='small'>
                            {timing}
                          </TableCell>
                          <TableCell size='small' variant='head'>
                            Instructors
                          </TableCell>
                          <TableCell variant='head' size='small'>
                            <span style={{ textTransform: 'capitalize' }}>
                              {instructors}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <div style={{ height: '2rem' }} />
                    <Table size='small' className={classes.attendanceTable}>
                      <TableHead>
                        <TableRow>
                          <TableCell>R.N.</TableCell>
                          <TableCell align='left'>Student Name</TableCell>
                          <TableCell align='left'>1</TableCell>
                          <TableCell align='left'>2</TableCell>
                          <TableCell align='left'>3</TableCell>
                          <TableCell align='left'>4</TableCell>
                          <TableCell align='left'>5</TableCell>
                          <TableCell align='left'>6</TableCell>
                          <TableCell align='left'>7</TableCell>
                          <TableCell align='left'>8</TableCell>
                          <TableCell align='left'>9</TableCell>
                          <TableCell align='left'>10</TableCell>
                          <TableCell
                            align='center'
                            colSpan={2}
                            style={{ width: '20%' }}
                          >
                            Payment
                          </TableCell>
                          {/* <TableCell align="left">Payment</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {registrationData.slice(0, 20).map((student, idx) => (
                          <>
                            <TableRow key={student._id}>
                              <TableCell
                                align='left'
                                style={{
                                  width: '1%',
                                  fontWeight: '500',
                                }}
                              >
                                {idx + 1}
                              </TableCell>
                              <TableCell
                                style={{
                                  display: 'block',
                                  border: 0,
                                  fontSize: '0.8rem',
                                  minHeight: '44px',
                                }}
                                align='left'
                              >
                                <span
                                  style={{
                                    textTransform: 'uppercase',
                                    fontWeight: '500',
                                    fontSize: '0.8rem',
                                  }}
                                >
                                  {student.name}
                                </span>
                                <br />
                                {student.mobileNo}
                              </TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell
                                align='left'
                                style={{ width: '1%' }}
                              ></TableCell>
                              <TableCell
                                align='left'
                                style={{ width: '10%' }}
                              ></TableCell>
                              <TableCell
                                align='left'
                                style={{ width: '11%' }}
                              ></TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '0 2.2rem',
                      marginTop: '-0.5rem',
                    }}
                  >
                    <img
                      src={logo}
                      width='70px'
                      style={{ filter: 'grayscale(1)' }}
                    />
                    <p
                      style={{
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '1rem',
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      www.rstforum.net
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <div style={{ padding: '2rem' }}>
                <p
                  style={{
                    opacity: '70%',
                    padding: 0,
                    margin: 0,
                    textAlign: 'center',
                  }}
                >
                  Nothing to display
                </p>
              </div>
            )}

            {/* -------------------------------------------------------------------------- */
            /*                                PAPER SHEET 2                               */
            /* -------------------------------------------------------------------------- */}
            {registrations && registrationData && registrationData.length > 20 && (
              <>
                {/* Table One */}
                <div
                  style={{
                    width: '842px',
                    height: '1190px',
                    borderBottom: '1px dotted lightgray',
                  }}
                >
                  {/* Inner Div with spaces */}
                  <div style={{ padding: '2rem' }}>
                    <Table size='small' className={classes.attendanceTable}>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center' colSpan={4}>
                            <Typography
                              variant='h6'
                              style={{
                                fontWeight: '600',
                                textTransform: 'uppercase',
                              }}
                            >
                              Attendance Sheet
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell size='small' variant='head'>
                            Course Name
                          </TableCell>
                          <TableCell variant='head' size='small'>
                            {course}
                          </TableCell>
                          <TableCell size='small' variant='head'>
                            Start Date
                          </TableCell>
                          <TableCell variant='head' size='small'>
                            {startingDate
                              ? startingDate.toString().substring(0, 15)
                              : ''}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell size='small' variant='head'>
                            Batch Time
                          </TableCell>
                          <TableCell variant='head' size='small'>
                            {timing}
                          </TableCell>
                          <TableCell size='small' variant='head'>
                            Instructors
                          </TableCell>
                          <TableCell variant='head' size='small'>
                            <span style={{ textTransform: 'capitalize' }}>
                              {instructors}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <div style={{ height: '2rem' }} />
                    <Table size='small' className={classes.attendanceTable}>
                      <TableHead>
                        <TableRow>
                          <TableCell>R.N.</TableCell>
                          <TableCell align='left'>Student Name</TableCell>
                          <TableCell align='left'>1</TableCell>
                          <TableCell align='left'>2</TableCell>
                          <TableCell align='left'>3</TableCell>
                          <TableCell align='left'>4</TableCell>
                          <TableCell align='left'>5</TableCell>
                          <TableCell align='left'>6</TableCell>
                          <TableCell align='left'>7</TableCell>
                          <TableCell align='left'>8</TableCell>
                          <TableCell align='left'>9</TableCell>
                          <TableCell align='left'>10</TableCell>
                          <TableCell
                            align='center'
                            colSpan={2}
                            style={{ width: '20%' }}
                          >
                            Payment
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {registrationData.slice(20, 40).map((student, idx) => (
                          <>
                            <TableRow key={student._id}>
                              <TableCell
                                align='left'
                                style={{
                                  width: '1%',
                                  fontWeight: '500',
                                }}
                              >
                                {idx + 21}
                              </TableCell>
                              <TableCell
                                style={{
                                  display: 'block',
                                  border: 0,
                                  fontSize: '0.8rem',
                                  minHeight: '44px',
                                }}
                                align='left'
                              >
                                <span
                                  style={{
                                    textTransform: 'uppercase',
                                    fontWeight: '500',
                                    fontSize: '0.8rem',
                                  }}
                                >
                                  {student.name}
                                </span>
                                <br />
                                {student.mobileNo}
                              </TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell
                                align='left'
                                style={{ width: '1%' }}
                              ></TableCell>
                              <TableCell
                                align='left'
                                style={{ width: '10%' }}
                              ></TableCell>
                              <TableCell
                                align='left'
                                style={{ width: '11%' }}
                              ></TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '0 2.2rem',
                      marginTop: '-0.5rem',
                    }}
                  >
                    <img
                      src={logo}
                      width='70px'
                      style={{ filter: 'grayscale(1)' }}
                    />
                    <p
                      style={{
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '1rem',
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      www.rstforum.net
                    </p>
                  </div>
                </div>
              </>
            )}

            {/* -------------------------------------------------------------------------- */
            /*                                PAPER SHEET 3                               */
            /* -------------------------------------------------------------------------- */}
            {registrations && registrationData && registrationData.length > 40 && (
              <>
                {/* Table One */}
                <div
                  style={{
                    width: '842px',
                    height: '1190px',
                    borderBottom: '1px dotted lightgray',
                  }}
                >
                  {/* Inner Div with spaces */}
                  <div style={{ padding: '2rem' }}>
                    <Table size='small' className={classes.attendanceTable}>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center' colSpan={4}>
                            <Typography
                              variant='h6'
                              style={{
                                fontWeight: '600',
                                textTransform: 'uppercase',
                              }}
                            >
                              Attendance Sheet
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell size='small' variant='head'>
                            Course Name
                          </TableCell>
                          <TableCell variant='head' size='small'>
                            {course}
                          </TableCell>
                          <TableCell size='small' variant='head'>
                            Start Date
                          </TableCell>
                          <TableCell variant='head' size='small'>
                            {startingDate
                              ? startingDate.toString().substring(0, 15)
                              : ''}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell size='small' variant='head'>
                            Batch Time
                          </TableCell>
                          <TableCell variant='head' size='small'>
                            {timing}
                          </TableCell>
                          <TableCell size='small' variant='head'>
                            Instructors
                          </TableCell>
                          <TableCell variant='head' size='small'>
                            <span style={{ textTransform: 'capitalize' }}>
                              {instructors}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <div style={{ height: '2rem' }} />
                    <Table size='small' className={classes.attendanceTable}>
                      <TableHead>
                        <TableRow>
                          <TableCell>R.N.</TableCell>
                          <TableCell align='left'>Student Name</TableCell>
                          <TableCell align='left'>1</TableCell>
                          <TableCell align='left'>2</TableCell>
                          <TableCell align='left'>3</TableCell>
                          <TableCell align='left'>4</TableCell>
                          <TableCell align='left'>5</TableCell>
                          <TableCell align='left'>6</TableCell>
                          <TableCell align='left'>7</TableCell>
                          <TableCell align='left'>8</TableCell>
                          <TableCell align='left'>9</TableCell>
                          <TableCell align='left'>10</TableCell>
                          <TableCell
                            align='center'
                            colSpan={2}
                            style={{ width: '20%' }}
                          >
                            Payment
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {registrationData.slice(40, 60).map((student, idx) => (
                          <>
                            <TableRow key={student._id}>
                              <TableCell
                                align='left'
                                style={{
                                  width: '1%',
                                  fontWeight: '500',
                                }}
                              >
                                {idx + 41}
                              </TableCell>
                              <TableCell
                                style={{
                                  display: 'block',
                                  border: 0,
                                  fontSize: '0.8rem',
                                  minHeight: '44px',
                                }}
                                align='left'
                              >
                                <span
                                  style={{
                                    textTransform: 'uppercase',
                                    fontWeight: '500',
                                    fontSize: '0.8rem',
                                  }}
                                >
                                  {student.name}
                                </span>
                                <br />
                                {student.mobileNo}
                              </TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell
                                align='left'
                                style={{ width: '1%' }}
                              ></TableCell>
                              <TableCell
                                align='left'
                                style={{ width: '10%' }}
                              ></TableCell>
                              <TableCell
                                align='left'
                                style={{ width: '11%' }}
                              ></TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '0 2.2rem',
                      marginTop: '-0.5rem',
                    }}
                  >
                    <img
                      src={logo}
                      width='70px'
                      style={{ filter: 'grayscale(1)' }}
                    />
                    <p
                      style={{
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '1rem',
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      www.rstforum.net
                    </p>
                  </div>
                </div>
              </>
            )}

            {/* -------------------------------------------------------------------------- */
            /*                                PAPER SHEET 3                               */
            /* -------------------------------------------------------------------------- */}
            {registrations && registrationData && registrationData.length > 60 && (
              <>
                {/* Table One */}
                <div
                  style={{
                    width: '842px',
                    height: '1190px',
                    borderBottom: '1px dotted lightgray',
                  }}
                >
                  {/* Inner Div with spaces */}
                  <div style={{ padding: '2rem' }}>
                    <Table size='small' className={classes.attendanceTable}>
                      <TableHead>
                        <TableRow>
                          <TableCell align='center' colSpan={4}>
                            <Typography
                              variant='h6'
                              style={{
                                fontWeight: '600',
                                textTransform: 'uppercase',
                              }}
                            >
                              Attendance Sheet
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell size='small' variant='head'>
                            Course Name
                          </TableCell>
                          <TableCell variant='head' size='small'>
                            {course}
                          </TableCell>
                          <TableCell size='small' variant='head'>
                            Start Date
                          </TableCell>
                          <TableCell variant='head' size='small'>
                            {startingDate
                              ? startingDate.toString().substring(0, 15)
                              : ''}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell size='small' variant='head'>
                            Batch Time
                          </TableCell>
                          <TableCell variant='head' size='small'>
                            {timing}
                          </TableCell>
                          <TableCell size='small' variant='head'>
                            Instructors
                          </TableCell>
                          <TableCell variant='head' size='small'>
                            <span style={{ textTransform: 'capitalize' }}>
                              {instructors}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <div style={{ height: '2rem' }} />
                    <Table size='small' className={classes.attendanceTable}>
                      <TableHead>
                        <TableRow>
                          <TableCell>R.N.</TableCell>
                          <TableCell align='left'>Student Name</TableCell>
                          <TableCell align='left'>1</TableCell>
                          <TableCell align='left'>2</TableCell>
                          <TableCell align='left'>3</TableCell>
                          <TableCell align='left'>4</TableCell>
                          <TableCell align='left'>5</TableCell>
                          <TableCell align='left'>6</TableCell>
                          <TableCell align='left'>7</TableCell>
                          <TableCell align='left'>8</TableCell>
                          <TableCell align='left'>9</TableCell>
                          <TableCell align='left'>10</TableCell>
                          <TableCell
                            align='center'
                            colSpan={2}
                            style={{ width: '20%' }}
                          >
                            Payment
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {registrationData.slice(60, 80).map((student, idx) => (
                          <>
                            <TableRow key={student._id}>
                              <TableCell
                                align='left'
                                style={{
                                  width: '1%',
                                  fontWeight: '500',
                                }}
                              >
                                {idx + 61}
                              </TableCell>
                              <TableCell
                                style={{
                                  display: 'block',
                                  border: 0,
                                  fontSize: '0.8rem',
                                  minHeight: '44px',
                                }}
                                align='left'
                              >
                                <span
                                  style={{
                                    textTransform: 'uppercase',
                                    fontWeight: '500',
                                    fontSize: '0.8rem',
                                  }}
                                >
                                  {student.name}
                                </span>
                                <br />
                                {student.mobileNo}
                              </TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell align='left'></TableCell>
                              <TableCell
                                align='left'
                                style={{ width: '1%' }}
                              ></TableCell>
                              <TableCell
                                align='left'
                                style={{ width: '10%' }}
                              ></TableCell>
                              <TableCell
                                align='left'
                                style={{ width: '11%' }}
                              ></TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '0 2.2rem',
                      marginTop: '-0.5rem',
                    }}
                  >
                    <img
                      src={logo}
                      width='70px'
                      style={{ filter: 'grayscale(1)' }}
                    />
                    <p
                      style={{
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '1rem',
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      www.rstforum.net
                    </p>
                  </div>
                </div>
              </>
            )}
          </Paper>
        </Container>
      </section>
    </>
  );
};

export default RegistrationsManagementScreen;
