import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import watermark from '../watermark-text.png';

const useStyles = makeStyles((_theme) => ({
  container: {
    position: 'relative',
  },
  paper: {
    // padding: '50px',
    width: '90%',
    margin: '0 auto',
    fontSize: '115%',
    position: 'relative',
    '& pre': {
      fontSize: '14px',
      backgroundColor: grey[100],
      padding: '20px 30px',
      borderRadius: '5px',
      marginBottom: '25px',
      color: grey[800],
      whiteSpace: 'pre-wrap',
    },
    '& h4': {
      marginTop: '40px',
      marginBottom: '20px',
      fontSize: '1.2rem',
      color: blue[900],
    },
    '& h2': {
      marginTop: '70px',
      fontSize: '2rem',
    },
    '& h1': {
      color: blue[800],
      fontSize: '2.5rem',
      paddingBottom: '10px',
      borderBottom: `2px solid ${blue[800]}`,
    },
    '& p': {
      color: grey[800],
    },
    '& table': {
      borderCollapse: 'collapse',
    },
    '& th': {
      border: '1px solid #dddddd',
      textAlign: 'left',
      padding: '8px',
    },
    '& td': {
      border: '1px solid #dddddd',
      textAlign: 'left',
      padding: '8px',
    },
    '& figure': {
      margin: 0,
      padding: 0,
    },
  },
  watermark: {
    height: '100%',
    width: '90%',
    background: `rgba(0,0,0,0) url(${watermark}) repeat-y top center`,
    opacity: '0.4',
    position: 'absolute',
    top: '0',
    left: '5%',
  },
}));

const LabGuide = ({ content, padded = true, disableWatermark = false }) => {
  const classes = useStyles();
  const [newContent, setNewContent] = useState(null);

  console.log(disableWatermark);

  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo } = studentLogin;

  useEffect(() => {
    const portExists = window.location.origin.split(':')[2];
    if (portExists === '9001' || portExists === '9002') {
      setNewContent(
        content &&
          content.replaceAll(
            'https://labs.rstforum.net/',
            `https://labs.rstforum.net:${portExists}/`
          )
      );
    }
  });

  return (
    <Container className="prhdn">
      <div className={classes.container}>
        <Paper
          elevation={1}
          className={classes.paper}
          style={{
            padding: padded ? '50px' : '0',
            userSelect: studentInfo.corporateStudent ? 'text' : 'none',
          }}>
          <div
            dangerouslySetInnerHTML={{
              __html: newContent ? newContent : content,
            }}
          />
        </Paper>
        {studentInfo && !disableWatermark && (
          <div className={classes.watermark}></div>
        )}
      </div>
    </Container>
  );
};

export default LabGuide;
