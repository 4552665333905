export const vncPortNoGen = (port, ip) => {
  let ipArr = ip.split('.');
  let lastOct = ip.split('.')[ipArr.length - 1];
  let nLastOct =
    lastOct.length === 2
      ? String('0') + String(lastOct)
      : lastOct.length === 1
      ? String('00') + String(lastOct)
      : lastOct;
  let ipNum = ipArr[ipArr.length - 2] + nLastOct;

  let fourthDig = +ipNum[3];
  let thirdDig = +ipNum[2];
  let secondDig = +ipNum[1];
  let firstDig = +ipNum[0];

  // let firstDigMul = firstDig <= 3 ? 6000 : 4000;
  let firstDigMul = 3000;

  let genIp =
    firstDig * firstDigMul + secondDig * 1000 + thirdDig * 100 + fourthDig * 10;
  let genPort = +port + genIp;

  return genPort;
};

export const vncPortConv = (portArr, ip) => {
  return portArr.map((port) => vncPortNoGen(port, ip));
};
