/**
 * @fileOverview   Actions to perform CRUD operations on the Books Schema
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import axios from 'axios';
import {
  BOOK_LIST_REQUEST,
  BOOK_LIST_SUCCESS,
  BOOK_LIST_FAIL,
  BOOK_DETAILS_REQUEST,
  BOOK_DETAILS_SUCCESS,
  BOOK_DETAILS_FAIL,
  BOOK_CREATE_REQUEST,
  BOOK_CREATE_SUCCESS,
  BOOK_CREATE_FAIL,
  BOOK_UPDATE_REQUEST,
  BOOK_UPDATE_SUCCESS,
  BOOK_UPDATE_FAIL,
  BOOK_DELETE_REQUEST,
  BOOK_DELETE_SUCCESS,
  BOOK_DELETE_FAIL,
  BOOK_DETAILS_BY_COURSE_REQUEST,
  BOOK_DETAILS_BY_COURSE_SUCCESS,
  BOOK_DETAILS_BY_COURSE_FAIL,
  BOOK_DETAILS_BY_COURSE_RESET,
} from '../constants/bookConstants';
import { snackActions } from '../utils/SnackBarUtils';

/**
 * Returns all the books in the database.
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const listBooks = () => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: BOOK_LIST_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Array<Object>} All books in the database */
    const { data } = await axios.get('/api/books', config);

    // Dispatch success object to the reducer.
    // Payload is the list of all books
    dispatch({ type: BOOK_LIST_SUCCESS, payload: data });
  } catch (err) {
    // Dispatch an error object to the reducer.
    dispatch({
      type: BOOK_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

/**
 * Get a book from the database by id
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const listBookDetails = (id) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: BOOK_DETAILS_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();
    const {
      studentLogin: { studentInfo },
    } = getState();

    if (userInfo) {
      /** @type {Object} Axios configurations */
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      /** @type {Object} The resulting book from the database */
      const { data } = await axios.get(`/api/books/${id}`, config);
      // Dispatch success object to the reducer.
      // Payload is the book object from the database
      dispatch({ type: BOOK_DETAILS_SUCCESS, payload: data });
    } else if (studentInfo) {
      /** @type {Object} Axios configurations */
      const config = {
        headers: {
          Authorization: `Bearer ${studentInfo.token}`,
        },
      };
      /** @type {Object} The resulting book from the database */
      const { data } = await axios.get(`/api/books/${id}/student`, config);
      // Dispatch success object to the reducer.
      // Payload is the book object from the database
      dispatch({ type: BOOK_DETAILS_SUCCESS, payload: data });
    }
  } catch (err) {
    // Dispatch an error object to the reducer.
    dispatch({
      type: BOOK_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

/**
 * Action to create a new book. (Trainer/Admin)
 * @param {Object} book New book object that is to be created
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const createBook = (book) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: BOOK_CREATE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Object} Newly create book object */
    const { data } = await axios.post(`/api/books`, book, config);

    // Dispatch success object to the reducer.
    // Payload is the newly created book object returned from the backend
    dispatch({ type: BOOK_CREATE_SUCCESS, payload: data });
    snackActions.success(`${data.title} book created.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: BOOK_CREATE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Action to update an existing book (by _id). (Trainers/Admins).
 * @param {Object} book Updated book object to be sent to the backend
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const updateBook = (book) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: BOOK_UPDATE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Object} Newly updated book object */
    const { data } = await axios.put(`/api/books/${book._id}`, book, config);

    // Dispatch success object to the reducer.
    dispatch({ type: BOOK_UPDATE_SUCCESS, payload: data });
    snackActions.success(`${data.title} book updated.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: BOOK_UPDATE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Action to delete an existing book (by _id) (Trainers/Admins)
 * @param {string} id Mongoose _id of the book that is to be deleted
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const deleteBook = (id) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: BOOK_DELETE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // Axios delete request to backend.
    await axios.delete(`/api/books/${id}`, config);
    // Dispatch success object to the reducer.
    dispatch({ type: BOOK_DELETE_SUCCESS });
    snackActions.success(`Book deleted.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: BOOK_DELETE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

export const listBookDetailsByCourse =
  (courseId) => async (dispatch, getState) => {
    try {
      dispatch({ type: BOOK_DETAILS_BY_COURSE_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(`/api/books/course/${courseId}`, config);
      dispatch({ type: BOOK_DETAILS_BY_COURSE_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: BOOK_DETAILS_BY_COURSE_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };
