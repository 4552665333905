/**
 * @fileOverview   Action functions to perform CRUD operations for the Batches Schema
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import axios from 'axios';
import {
  BATCH_LIST_REQUEST,
  BATCH_LIST_SUCCESS,
  BATCH_LIST_FAIL,
  BATCH_CREATE_REQUEST,
  BATCH_CREATE_SUCCESS,
  BATCH_CREATE_FAIL,
  BATCH_UPDATE_REQUEST,
  BATCH_UPDATE_SUCCESS,
  BATCH_UPDATE_FAIL,
  BATCH_DELETE_REQUEST,
  BATCH_DELETE_SUCCESS,
  BATCH_DELETE_FAIL,
  BATCH_STATUS_REQUEST,
  BATCH_STATUS_SUCCESS,
  BATCH_STATUS_FAIL,
} from '../constants/batchConstants';
import { snackActions } from '../utils/SnackBarUtils';

/**
 * Returns all the batches in the database. (ADMIN only)
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const listBatches = () => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: BATCH_LIST_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Array<Object>} All batches in the database */
    const { data } = await axios.get('/api/batches', config);

    // Dispatch success object to the reducer.
    // Payload is the list of all batches
    dispatch({ type: BATCH_LIST_SUCCESS, payload: data });
  } catch (err) {
    // Dispatch an error object to the reducer.
    dispatch({
      type: BATCH_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

/**
 * Action to create a new batch. (ADMIN only)
 * @param {Object} batch New batch object that is to be created
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const createBatch = (batch) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: BATCH_CREATE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Object} Newly create batch object */
    const { data } = await axios.post('/api/batches', batch, config);

    // Dispatch success object to the reducer.
    // Payload is the newly created batch object returned from the backend
    dispatch({ type: BATCH_CREATE_SUCCESS, payload: data });
    snackActions.success(
      `Batch created. Go to 'Generate Students' page to generate accounts.`
    );
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: BATCH_CREATE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Action to update an existing batch (by _id). (ADMIN only).
 * @param {Object} batch Updated batch object to be sent to the backend
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const updateBatch = (batch) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: BATCH_UPDATE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Object} Newly updated batch object */
    const { data } = await axios.put(
      `/api/batches/${batch._id}`,
      batch,
      config
    );
    // Dispatch success object to the reducer.
    // Payload is the newly updated batch object returned from the backend
    dispatch({ type: BATCH_UPDATE_SUCCESS, payload: data });
    snackActions.success(`${data.name} batch updated.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: BATCH_UPDATE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Action to delete an existing batch (by _id).
 * @param {Object} batch Batch object from the server.
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const deleteBatch = (id) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: BATCH_DELETE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    // Axios delete request to backend.
    await axios.delete(`/api/batches/${id}`, config);
    snackActions.success(`Batch deleted.`);
    // Dispatch success object to the reducer.
    dispatch({ type: BATCH_DELETE_SUCCESS });
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: BATCH_DELETE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Action to activate/deactivate a batch
 * @param {Object} batch Batch object from the server.
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const updateBatchStatus = (id) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: BATCH_STATUS_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    // Axios put request to backend.
    await axios.get(`/api/batches/${id}/activate`, config);
    // Dispatch success object to the reducer.
    dispatch({ type: BATCH_STATUS_SUCCESS });
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: BATCH_STATUS_FAIL,
      payload: errMessage,
    });
  }
};
