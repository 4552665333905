export const courses = [
  'CCNA Module 1',
  'CCNA Module 2',
  'CCNP ENCOR',
  'SD-WAN',
  'CCNP SCORE',
  'DevNet',
  'Linux (RHCSA & RHCE)',
  'Full Stack Development',
  'Python',
  'CEH',
  'Network Service Orchestrator (NSO)',
  'Elastic Services Controller (ESC)',
  'Palo Alto',
  'Penetration Testing',
  'Cyber Security',
  'Check Point CCSA',
  'OpenStack',
  'VMware',
  'Azure Administration (AZ104)',
  'Java',
  'Java Framework',
  'Windows Server 2019',
  'C',
  'C++',
  'SQL Development',
  'Data Science using R',
  'SDN',
  'Cumulus Linux',
  'Digital Network Architecture (DNA)',
  'Application Center Infrastructure (ACI)',
  'DevOps',
  'Ansible',
  'VMware VCP-DCV 6',
  'SAN & DR',
  'Cisco Firepower',
  'Cisco Identity Services Engine (ISE)',
  'Cisco Email Security Appliance (ESA)',
  'Cisco Cloud Security Professional (CCSP)',
  'AWS Certified Solutions Architect - Associate',
  'AWS Certified Solutions Architect - Professional',
  'AWS Certified SysOps Administrator',
  'AWS Certified Advanced Networking',
  'AWS Certified DevOps Engineer',
  'AWS Developer Associate',
  'Office 365',
  'Azure Architect Technologies (AZ-300)',
  'Azure Architect Design (AZ-301)',
  'Azure DevOps Engineer Expert (AZ-400)',
  'Azure Solutions Architect Expert',
  'SUSE Linux',
  'OpenShift',
  'GNU Bash',
  'JNCIA - Junos',
  'JNCIS Service Provider',
  'JNCIS Enterprise R&S',
  'JNCIS Service Provider',
  'JNCIP Enterprise R&S',
  'JNCIP Service Provider',
  'JNCIE Enterprise R&S',
  'JNCIE Service Provider',
  'Huawei HCIA - R&S',
  'Huawei HCIP - R&S IERS (H12-221)',
  'Huawei HCIP - R&S IENP (H12-222)',
  'Huawei HCIP - R&S IEEP (H12-223)',
  'Huawei HCIE - R&S (H12-261)',
  'CompTIA A+ N+',
  'F5 Load Balancer',
  'Internet of Things (IOT)',
];

export const batchTiming = [
  'Morning 10:00 - 4:00',
  'Morning 10:00 - 2:00',
  'Morning 8:00 - 10:30',
  'Morning 10:00 - 12:30',
  'Afternoon 1:00 - 3:30',
  'Afternoon 5:00 - 7:30',
  'Evening 7:00 - 9:30',
  'Evening 7:30 - 10:00',
];

export const batch = [
  'Daily (Mon-Fri)',
  'Weekend Saturday',
  'Weekend Sunday',
  'Weekend Sat-Sun',
  'Fast Track',
];

export const termsAndConditionsEnglish = {
  training: [
    'RSTForum reserves the right to cancel/postpone the class.',
    'Certificate of participation will be awarded only with a minimum, 90% attendance.',
    'All attendees are to observe the Copyright Law on intellectual properties such as software and courseware from RSTForum and respective vendors.',
    'RSTForum reserves the right to change/alter the sequence and content of courses.',
    'Video recording is absolutely not allowed for both online and classroom lectures.',
    'Candidates can repeat the enrolled course only once till the end of the life of the course, or until a new version/content/module is updated.',
    'Candidates are only allowed to repeat lectures physically in the classroom.',
    'Candidates are not allowed to repeat lectures and labs online.',
  ],
  fees: [
    'No fees refund.',
    'Right to admission reserved.',
    'Candidates will have to make full fees payment within the first 3 days of course commencement.',
    'In case of incomplete fees payment, RSTForum reserves the right to refuse training to the candidate.',
    'Exam fees are not included in course fees.',
    'The cost of online lab access and lab training material is included in the course fees.',
    "Fees (partial/full) cannot be transferred to another candidate's account.",
    'Students enrolled for old courses that they could not attend will have to pay a revised fees structure to attend lectures.',
    'Course fees do not include placement services. For placement services, contact the helpdesk.',
  ],
  placements: [
    "We are a training company and not a placement agency, so we don't offer or gurantee any job or placements post course completion.",
  ],
};

export const termsAndConditionsMarathi = {
  training: [
    'RSTForum वर्ग रद्द करण्याचा/पुढे ढकलण्याचा अधिकार राखून ठेवतो.',
    'सहभागाचे प्रमाणपत्र फक्त किमान, 90% उपस्थितीसह दिले जाईल.',
    'सर्व उपस्थितांनी RSTForum आणि संबंधित विक्रेत्यांकडून सॉफ्टवेअर आणि कोर्सवेअर सारख्या बौद्धिक गुणधर्मांवर कॉपीराइट कायदा पाळावा.',
    'RSTForum अभ्यासक्रमांचा क्रम आणि सामग्री बदलण्याचा/बदलण्याचा अधिकार राखून ठेवतो.',
    'ऑनलाइन आणि वर्ग दोन्ही लेक्चरची व्हिडिओ रेकॉर्डिंग करण्यास पूर्णपणे परवानगी नाही.',
    'कोणतेही शुल्क परतावा नाही.',
    'प्रवेशाचा अधिकार राखीव आहे.',
    'उमेदवारांना कोर्स सुरू झाल्याच्या पहिल्या 3 दिवसात पूर्ण फी भरावी लागेल.',
    'अपूर्ण शुल्क भरणा झाल्यास, आरएसटीफोरम उमेदवाराला प्रशिक्षण नाकारण्याचा अधिकार राखून ठेवतो.',
    'परीक्षेची फी कोर्स फी मध्ये समाविष्ट नाही.',
    'ऑनलाईन लॅब प्रवेश आणि प्रयोगशाळा प्रशिक्षण साहित्याचा खर्च अभ्यासक्रमाच्या फीमध्ये समाविष्ट आहे.',
  ],
  fees: [
    'उमेदवार कोर्सच्या आयुष्याच्या समाप्तीपर्यंत किंवा नवीन आवृत्ती/सामग्री/मॉड्यूल अद्ययावत होईपर्यंत केवळ एकदाच नोंदणीकृत कोर्सची पुनरावृत्ती करू शकतो.',
    'उमेदवारांना केवळ वर्गात शारीरिकदृष्ट्या व्याख्यानांची पुनरावृत्ती करण्याची परवानगी आहे.',
    'उमेदवारांना ऑनलाइन व्याख्याने आणि प्रयोगशाळेची पुनरावृत्ती करण्याची परवानगी नाही.',
    'शुल्क (आंशिक/पूर्ण) दुसऱ्या उमेदवाराच्या खात्यात हस्तांतरित केले जाऊ शकत नाही.',
    'जुन्या अभ्यासक्रमांसाठी नावनोंदणी केलेल्या विद्यार्थ्यांना ते उपस्थित राहू शकले नाहीत त्यांना व्याख्यानांना उपस्थित राहण्यासाठी सुधारित शुल्क रचना भरावी लागेल.',
    'कोर्स फीमध्ये प्लेसमेंट सेवांचा समावेश नाही. प्लेसमेंट सेवांसाठी, हेल्पडेस्कशी संपर्क साधा.',
  ],
  placements: [
    'आम्ही एक प्रशिक्षण कंपनी आहोत आणि प्लेसमेंट एजन्सी नाही, म्हणून आम्ही कोर्स पूर्ण केल्यानंतर कोणतीही नोकरी किंवा प्लेसमेंट ऑफर किंवा हमी देत ​​नाही.',
  ],
};

export const termsAndConditionsHindi = {
  training: [
    'कक्षा को रद्द/स्थगित करने का अधिकार RSTForum के पास सुरक्षित है।',
    'भागीदारी का प्रमाण पत्र केवल न्यूनतम, 90% उपस्थिति के साथ प्रदान किया जाएगा।',
    'सभी उपस्थित लोगों को आरएसटीफ़ोरम और संबंधित विक्रेताओं से सॉफ़्टवेयर और कोर्सवेयर जैसे बौद्धिक संपदा पर कॉपीराइट कानून का पालन करना है।',
    'RSTForum पाठ्यक्रमों के क्रम और सामग्री को बदलने/बदलने का अधिकार सुरक्षित रखता है।',
    'ऑनलाइन और कक्षा दोनों व्याख्यानों के लिए वीडियो रिकॉर्डिंग की बिल्कुल अनुमति नहीं है।',
    'अभ्यर्थी नामांकित पाठ्यक्रम को पाठ्यक्रम के जीवन के अंत तक, या एक नया संस्करण/सामग्री/मॉड्यूल अपडेट होने तक केवल एक बार दोहरा सकते हैं।',
    'उम्मीदवारों को कक्षा में केवल शारीरिक रूप से व्याख्यान दोहराने की अनुमति है।',
    'उम्मीदवारों को व्याख्यान और प्रयोगशालाओं को ऑनलाइन दोहराने की अनुमति नहीं है।',
  ],
  fees: [
    'कोई शुल्क वापसी नहीं।',
    'प्रवेश का अधिकार सुरक्षित।',
    'उम्मीदवारों को कोर्स शुरू होने के पहले 3 दिनों के भीतर पूरी फीस का भुगतान करना होगा।',
    'अपूर्ण शुल्क भुगतान के मामले में, आरएसटीफोरम उम्मीदवार को प्रशिक्षण देने से मना करने का अधिकार सुरक्षित रखता है।',
    'परीक्षा शुल्क पाठ्यक्रम शुल्क में शामिल नहीं है।',
    'ऑनलाइन लैब एक्सेस और लैब प्रशिक्षण सामग्री की लागत पाठ्यक्रम शुल्क में शामिल है।',
    'शुल्क (आंशिक/पूर्ण) किसी अन्य उम्मीदवार के खाते में स्थानांतरित नहीं किया जा सकता है।',
    'पुराने पाठ्यक्रमों के लिए नामांकित छात्र, जिनमें वे शामिल नहीं हो सके, उन्हें व्याख्यान में भाग लेने के लिए एक संशोधित शुल्क संरचना का भुगतान करना होगा।',
    'कोर्स फीस में प्लेसमेंट सेवाएं शामिल नहीं हैं। प्लेसमेंट सेवाओं के लिए हेल्पडेस्क से संपर्क करें।',
  ],
  placements: [
    'हम एक प्रशिक्षण कंपनी हैं और प्लेसमेंट एजेंसी नहीं हैं, इसलिए हम कोर्स पूरा होने के बाद किसी भी नौकरी या प्लेसमेंट की पेशकश या गारंटी नहीं देते हैं।',
  ],
};
