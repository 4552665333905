import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  CircularProgress,
  LinearProgress,
  InputAdornment,
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  Box,
  Switch,
  FormControl,
  FormControlLabel,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  FormHelperText,
} from '@material-ui/core';
import { blue, grey } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
import LabsHeader from '../components/LabsHeader';
import { listBatches as listBatchesAction } from '../actions/batchActions';
import { bulkGenerateStudents as bulkGenerateStudentsAction } from '../actions/studentActions';
import DownloadList from '../components/DownloadList';
import { SlideTransition } from '../components/Transitions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';
import GenerateInstructions from '../components/GenerateInstructions';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[200],
    padding: '50px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: 'calc(100vh - 206px)',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  notifSpacing: {
    marginBottom: '10px',
  },
  spacer: {
    height: '30px',
  },
  blue: {
    color: theme.palette.getContrastText(blue['A400']),
    backgroundColor: blue['A400'],
  },
}));

/**
 * Component for showing/adding/editing courses
 */
const BulkGenerateScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Result Popup
  const [popup, setPopup] = useState(false);

  // Form and Local State
  const [count, setCount] = useState(1);
  const [ipStart, setIpStart] = useState('');
  const [portStart, setPortStart] = useState('');
  const [singleIp, setSingleIp] = useState('');
  const [singlePort, setSinglePort] = useState('');
  const [connectionCount, setConnectionCount] = useState(1);
  const [batchId, setBatchId] = useState('');
  const [courseId, setCourseId] = useState('');
  const [connectionType, setConnectionType] = useState('ips-only');
  const [activeState, setActiveState] = useState(true);
  const [bookAccess, setBookAccess] = useState(false);
  const [corporateStudent, setCorporateStudent] = useState(false);
  const [currentBatchName, setCurrentBatchName] = useState('');
  const [portsPerStudent, setPortsPerStudent] = useState(1); // NEW EVE + EXT VNC CODE
  const [singleIpAddr, setSingleIpAddr] = useState(''); // NEW EVE + EXT VNC CODE

  // Redux State Getters
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const batchesList = useSelector((state) => state.batchesList);
  const {
    loading: allBatchesLoading,
    error: allBatchesError,
    batches: allBatches,
  } = batchesList;
  const studentsBulkCreate = useSelector((state) => state.studentsBulkCreate);
  const {
    loading: bulkCreateLoading,
    error: bulkCreateError,
    success: bulkCreateSuccess,
    generatedStudents,
  } = studentsBulkCreate;

  console.log({
    studentCount: count,
    ipStart: `192.168.${ipStart.split('.')[0]}.${ipStart.split('.')[1]}`,
    portStart,
    singleIp,
    singlePort,
    connectionCount,
    batchId,
    courseId,
    connectionType,
    activeState,
    bookAccess,
    corporateStudent,
    portsPerStudent,
    singleIpAddr,
  });

  // Lifecycle events
  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role === 'admin' || userInfo.role === 'trainer')
    ) {
      dispatch(listBatchesAction());
    } else {
      history.push('/admin/login');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
    if (bulkCreateSuccess) {
      setCount(1);
      setIpStart('');
      setPortStart('');
      setSingleIp('');
      setSinglePort('');
      setConnectionCount(1);
      setBatchId('');
      setConnectionType('ips-only');
      setActiveState(true);
      setBookAccess(false);
      setCorporateStudent(false);
      setPortsPerStudent(1);
      setSingleIpAddr('');
      // dispatch({ type: BULK_GEN_STUDENTS_RESET });
    }
  }, [dispatch, history, userInfo, bulkCreateSuccess, generatedStudents]);

  // Action Handlers
  const handleBulkGenerate = (e) => {
    e.preventDefault();
    const formData = {
      studentCount: count,
      ipStart: `192.168.${ipStart.split('.')[0]}.${ipStart.split('.')[1]}`,
      portStart,
      singleIp,
      singlePort,
      connectionCount,
      batchId,
      courseId,
      connectionType,
      activeState,
      bookAccess,
      corporateStudent,
      portsPerStudent,
      singleIpAddr,
    };
    setCurrentBatchName(
      allBatches && allBatches.filter((b) => b._id === batchId)[0].name
    );
    dispatch(bulkGenerateStudentsAction(formData));
  };

  // Popup handlers
  const handlePopupOpen = () => {
    setPopup(true);
  };
  const handlePopupClose = () => {
    setPopup(false);
  };

  return (
    <>
      <LabsHeader
        pageTitle="Generate Students"
        backBtn={{ action: history.goBack }}
        secondaryBtn={{
          label: 'All Students',
          link: '/admin/students',
          style: 'contained',
        }}
      />
      <section className={classes.section}>
        <Container>
          {allBatchesLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 255px)',
              }}
            >
              <CircularProgress />
            </div>
          ) : allBatchesError ? (
            <Alert severity="error">{allBatchesError}</Alert>
          ) : (
            <div style={{ display: 'flex', gap: '30px' }}>
              <Box component={Paper} style={{ width: '60%' }} padding="30px">
                <Typography
                  component="h2"
                  variant="h6"
                  style={{ marginBottom: '20px' }}
                >
                  Generate Student Accounts
                </Typography>
                <form onSubmit={handleBulkGenerate}>
                  {bulkCreateLoading && (
                    <LinearProgress
                      color="primary"
                      className={classes.notifSpacing}
                    />
                  )}
                  {bulkCreateError && (
                    <Alert severity="error" className={classes.notifSpacing}>
                      {bulkCreateError}
                    </Alert>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      gap: '20px',
                      marginBottom: '4px',
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      style={{ width: '100%' }}
                      margin="dense"
                      className={classes.formControl}
                    >
                      <InputLabel id="studentBatch">Batch</InputLabel>
                      <Select
                        labelId="studentBatch"
                        id="batch"
                        name="batch"
                        autoFocus
                        value={batchId}
                        onChange={(e) => {
                          setBatchId(e.target.value);
                          setCourseId(
                            allBatches.find(
                              (batch) => batch._id === e.target.value
                            ).course._id
                          );
                        }}
                        label="Batch *"
                      >
                        {allBatches &&
                          allBatches.map((batch) => (
                            <MenuItem key={batch._id} value={batch._id}>
                              {batch.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      disabled
                      id="courseId"
                      type="text"
                      label="Course"
                      name="courseId"
                      autoComplete="courseId"
                      value={
                        courseId
                          ? allBatches &&
                            allBatches.find(
                              (batch) => batch.course._id === courseId
                            )
                            ? allBatches.find(
                                (batch) => batch.course._id === courseId
                              ).course.name
                            : courseId
                          : courseId
                      }
                      onChange={(e) => setCourseId(e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '20px',
                      marginBottom: '7px',
                    }}
                  >
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      id="count"
                      type="text"
                      label="Count"
                      name="count"
                      autoComplete="count"
                      value={count}
                      onChange={(e) => setCount(e.target.value)}
                    />
                    <FormControl
                      variant="outlined"
                      style={{ width: '100%' }}
                      margin="dense"
                      className={classes.formControl}
                    >
                      <InputLabel id="userConnectionType">
                        Connection Type
                      </InputLabel>
                      <Select
                        labelId="userConnectionType"
                        id="connectionType"
                        name="connectionType"
                        value={connectionType}
                        onChange={(e) => setConnectionType(e.target.value)}
                        label="Connection Type"
                      >
                        <MenuItem value={'ips-only'}>IPs Only</MenuItem>
                        <MenuItem value={'ips-ports'}>IPs and Ports</MenuItem>
                        {/* NEW CODE EVE + EXT VNC */}
                        <MenuItem value={'multi-port-single-ip'}>
                          Multi-Port, single IP
                        </MenuItem>
                        <MenuItem value={'ports-single-ip'}>
                          Ports Only
                        </MenuItem>
                        <MenuItem value={'ips-single-port'}>
                          IPs (single port)
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '20px',
                      marginBottom: '7px',
                    }}
                  >
                    {/* ##################### */}
                    {/* START ------ NEW EVE + EXTERNAL VNC CODE ------ */}
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      id="portsPerStudent"
                      type="number"
                      label="Ports Per Student"
                      name="portsPerStudent"
                      autoComplete="portsPerStudent"
                      value={portsPerStudent}
                      onChange={(e) => setPortsPerStudent(e.target.value)}
                      style={{
                        display:
                          connectionType === 'multi-port-single-ip'
                            ? 'block'
                            : 'none',
                      }}
                    />
                    <TextField
                      variant="outlined"
                      margin="dense"
                      style={{
                        display:
                          connectionType === 'multi-port-single-ip'
                            ? 'block'
                            : 'none',
                      }}
                      fullWidth
                      id="singleIpAddr"
                      type="string"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            192.168.
                          </InputAdornment>
                        ),
                      }}
                      label="Single IP (3rd &amp; 4th octet)"
                      name="singleIpAddr"
                      placeholder="3.1"
                      autoComplete="singleIpAddr"
                      value={singleIpAddr}
                      onChange={(e) => setSingleIpAddr(e.target.value)}
                    />
                    {/* END ------ NEW EVE + EXTERNAL VNC CODE */}
                    {/* ##################### */}
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      id="connectionCount"
                      type="number"
                      label="IP/Port per student"
                      name="connectionCount"
                      autoComplete="connectionCount"
                      value={connectionCount}
                      onChange={(e) => setConnectionCount(e.target.value)}
                      style={{
                        display:
                          connectionType === 'multi-port-single-ip'
                            ? 'none'
                            : 'block',
                      }}
                    />
                    <TextField
                      variant="outlined"
                      margin="dense"
                      style={{
                        display:
                          connectionType === 'ips-only' ||
                          connectionType === 'ips-ports' ||
                          connectionType === 'ips-single-port'
                            ? 'block'
                            : 'none',
                      }}
                      disabled={
                        connectionType === 'ips-only' ||
                        connectionType === 'ips-ports' ||
                        connectionType === 'ips-single-port'
                          ? false
                          : true
                      }
                      fullWidth
                      id="ipStart"
                      type="string"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            192.168.
                          </InputAdornment>
                        ),
                      }}
                      label="IP Start (3rd &amp; 4th octet)"
                      name="ipStart"
                      placeholder="3.1"
                      autoComplete="ipStart"
                      value={ipStart}
                      onChange={(e) => setIpStart(e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      margin="dense"
                      style={{
                        display:
                          connectionType === 'ports-single-ip' ||
                          connectionType === 'ips-ports' ||
                          connectionType === 'multi-port-single-ip'
                            ? 'block'
                            : 'none',
                      }}
                      disabled={
                        connectionType === 'ports-single-ip' ||
                        connectionType === 'ips-ports' ||
                        connectionType === 'multi-port-single-ip'
                          ? false
                          : true
                      }
                      fullWidth
                      id="portStart"
                      type="number"
                      placeholder="1401"
                      label="Port Start"
                      name="portStart"
                      autoComplete="portStart"
                      value={portStart}
                      onChange={(e) => setPortStart(e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      display:
                        connectionType === 'ips-only' ||
                        connectionType === 'ips-ports' ||
                        connectionType === 'ports-single-ip' ||
                        connectionType === 'multi-port-single-ip'
                          ? 'none'
                          : 'flex',
                      gap: '20px',
                      marginBottom: '7px',
                    }}
                  >
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      disabled={
                        connectionType === 'ports-single-ip' ? false : true
                      }
                      id="singleIp"
                      placeholder="Ex. 192.168.1.240"
                      type="text"
                      label="Single IP Address"
                      name="singleIp"
                      autoComplete="singleIp"
                      value={singleIp}
                      onChange={(e) => setSingleIp(e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      disabled={
                        connectionType === 'ips-single-port' ? false : true
                      }
                      id="singlePort"
                      placeholder="Ex. 14001"
                      type="number"
                      label="Single Port No."
                      name="singlePort"
                      autoComplete="singlePort"
                      value={singlePort}
                      onChange={(e) => setSinglePort(e.target.value)}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      value="top"
                      style={{ alignSelf: 'center', margin: '4px auto 0' }}
                      control={
                        <Switch
                          checked={activeState}
                          onChange={(e) => setActiveState(e.target.checked)}
                          color="primary"
                        />
                      }
                      label="Generate Activated Accounts"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="top"
                      style={{ alignSelf: 'center', margin: '4px auto 0 10px' }}
                      control={
                        <Switch
                          checked={bookAccess}
                          onChange={(e) => setBookAccess(e.target.checked)}
                          color="primary"
                        />
                      }
                      label="Provide Book Access"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="top"
                      style={{ alignSelf: 'center', margin: '4px auto 0 10px' }}
                      control={
                        <Switch
                          checked={corporateStudent}
                          onChange={(e) =>
                            setCorporateStudent(e.target.checked)
                          }
                          color="primary"
                        />
                      }
                      label="Corporate Student"
                      labelPlacement="end"
                    />
                  </div>
                  <div className={classes.spacer}></div>
                  <Button type="submit" variant="contained" color="primary">
                    Generate Students
                  </Button>
                  {generatedStudents && generatedStudents.length > 0 && (
                    <Button
                      style={{ marginLeft: '10px' }}
                      type="button"
                      variant="outlined"
                      color="primary"
                      onClick={handlePopupOpen}
                    >
                      View Generated Accounts
                    </Button>
                  )}
                </form>
              </Box>
              <GenerateInstructions
                course={
                  allBatches &&
                  allBatches.find((batch) => batch.course._id === courseId)
                    ? allBatches.find((batch) => batch.course._id === courseId)
                        .course.name
                    : ''
                }
              />
            </div>
          )}
        </Container>
        <Dialog
          scroll="paper"
          open={popup}
          onClose={handlePopupClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          disableEscapeKeyDown
          TransitionComponent={SlideTransition}
        >
          <DialogTitle id="form-dialog-title">
            Generated Students Details
          </DialogTitle>
          <DialogContent>
            <List dense>
              {generatedStudents &&
                generatedStudents.map((s, i) => (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className={classes.blue}>{i + 1}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          <Typography variant="body1">
                            Username: &nbsp; <strong>{s.username}</strong>
                          </Typography>
                          <Typography variant="body1">
                            Password: &nbsp; <strong>{s.password}</strong>
                          </Typography>
                          {s.ips && (
                            <Typography variant="body1">
                              IP(s): &nbsp; {s.ips.join(' ')}
                            </Typography>
                          )}
                          {s.ports && (
                            <Typography variant="body1">
                              Port(s): &nbsp; {s.ports.join(' ')}
                            </Typography>
                          )}
                        </>
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePopupClose} color="secondary">
              Cancel
            </Button>
            {generatedStudents && (
              <DownloadList
                list={generatedStudents}
                filename={`${currentBatchName} ${new Date()
                  .toLocaleDateString()
                  .split('/')
                  .join('-')}`}
              />
            )}
          </DialogActions>
        </Dialog>
      </section>
    </>
  );
};

export default BulkGenerateScreen;
