import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Link as RouterLink } from 'react-router-dom';
import html2canvas from 'html2canvas';
import {
  makeStyles,
  Container,
  // Button,
  Paper,
  // Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import LabsHeader from '../components/LabsHeader';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';
import Certificate from '../components/Certificate';
import jsPDF from 'jspdf';

const useStyles = makeStyles((_theme) => ({
  section: {
    backgroundColor: grey[200],
    padding: '50px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: 'calc(100vh - 206px)',
  },
}));

/**
 * Component for showing/adding/editing courses
 */
const StudentCertificateScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const certicateRef = useRef();

  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo } = studentLogin;

  useEffect(() => {
    if (!studentInfo) {
      history.push('/login');
    }
    if (studentInfo && !studentInfo.batch.enableCertificates) {
      history.push('/dashboard');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [dispatch, history, studentInfo]);

  const printBtn = () => {
    html2canvas(certicateRef.current, { width: 1232, height: 890 }).then(
      (canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'px',
          format: [700, 510],
        });
        pdf.addImage(imgData, 'PNG', 0, 0);
        pdf.save('certificate.pdf');
      }
    );
  };

  return (
    <>
      <LabsHeader
        pageTitle='Certificate'
        backBtn={{ action: () => history.push('/dashboard') }}
        secondaryBtn={{
          label: 'Edit Profile',
          link: '/profile',
          style: 'outlined',
        }}
        tertiaryBtn={{
          label: 'Download',
          action: () => printBtn(),
          style: 'contained',
        }}
      />
      <section className={classes.section}>
        <Container>
          <Paper ref={certicateRef}>
            <Certificate
              isCorporateTrainingBatch={
                studentInfo && studentInfo.batch.isCorporateTrainingBatch
              }
              name={
                studentInfo && studentInfo.name ? studentInfo.name : 'Your Name'
              }
              companyNameOnCertificate={
                studentInfo && studentInfo.batch.companyNameOnCertificate
              }
              course={studentInfo && studentInfo.course.name}
              courseNameOnCertificate={
                studentInfo && studentInfo.batch.courseNameOnCertificate
              }
              courseDateOnCertificate={
                studentInfo && studentInfo.batch.courseDateOnCertificate
              }
            />
          </Paper>
          {/* {studentInfo && studentInfo.providedFeedback ? (
            <Paper ref={certicateRef}>
              <Certificate
                name={
                  studentInfo && studentInfo.name
                    ? studentInfo.name
                    : 'Your Name'
                }
                course={studentInfo && studentInfo.course.name}
              />
            </Paper>
          ) : (
            <div style={{ display: 'flex', textAlign: 'center' }}>
              <Typography variant="h5" style={{ marginBottom: '1rem' }}>
                Please <strong>provide your feedback</strong> to download
                certificate.
              </Typography>
              <Button component={RouterLink} to="/feedback">
                Provide Feedback
              </Button>
            </div>
          )} */}
        </Container>
      </section>
    </>
  );
};

export default StudentCertificateScreen;
