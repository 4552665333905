import { useSnackbar } from 'notistack';
import React from 'react';

const InnerSnackbarUtilsConfigurator = (props) => {
  props.setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef;
const setUseSnackbarRef = (useSnackbarRefProp) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
  return (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
  );
};

export const snackActions = {
  success(msg) {
    this.toast(msg, false, 'success');
  },
  warning(msg) {
    this.toast(msg, false, 'warning');
  },
  info(msg) {
    this.toast(msg, false, 'info');
  },
  error(msg) {
    this.toast(msg, false, 'error');
  },
  toast(msg, persist = false, variant = 'default') {
    useSnackbarRef.enqueueSnackbar(msg, { variant, persist: persist });
  },
};
