import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import LabsHeader from '../components/LabsHeader';
import {
  makeStyles,
  Button,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Switch,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';
import {
  listVideoDetails,
  getNextVideoDetails,
  getPrevVideoDetails,
  listVideosByCourse,
} from '../actions/videoActions';
import {
  VIDEO_NEXT_RESET,
  VIDEO_PREV_RESET,
} from '../constants/videoConstants';

import { setAddrForResources } from '../utils/imageMapper';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[100],
    minHeight: 'calc(100vh - 206px)',
    display: 'flex',
  },
  sidebar: {
    width: '20%',
    backgroundColor: 'white',
    borderRight: `1px solid ${grey[300]}`,
    paddingTop: '1rem',
    height: 'calc(100vh - 206px)',
    overflow: 'auto'
  },
  sideSpace: {
    padding: '0 1rem',
    marginBottom: '0.7rem',
  },
  mainContent: {
    padding: '2rem',
    background: grey[100],
    width: '80%',
    height: 'calc(100vh - 202px)',
    overflow: 'auto',
  },
  pageContent: {
    '& video': {
      padding: '3rem',
    },
    '& h2': {
      marginTop: '0',
      fontSize: '2rem',
      fontWeight: '700',
    },
    '& h3': {
      fontSize: '1.3rem',
      marginTop: '2.5rem',
    },
    '& p': {
      fontSize: '0.9rem',
      lineHeight: '1.7',
    },
    '& img': {
      width: '100%!important',
      margin: '1rem 0',
    },
    '& li': {
      fontSize: '0.9rem',
      marginBottom: '0.7rem',
    },
    '& pre': {
      padding: '1.2rem',
      backgroundColor: '#f1f1f1',
      whiteSpace: 'pre-wrap',
    },
  },
  description: {
    padding: '2rem 0',
    fontSize: '1rem',
  },
  pageNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '36.5px',
  },
}));

const StudentVideoScreen = ({ history, match }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [lang, setLang] = useState({
    english: true,
    hindi: false,
  });

  const handleLangChange = (e) => {
    if (e.target.checked) {
      setLang({ english: true, hindi: false });
    } else {
      setLang({ hindi: true, english: false });
    }
  };

  console.log(lang);

  const courseId = match.params.courseId;
  const videoId = match.params.videoId;

  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo } = studentLogin;

  const videoDetails = useSelector((state) => state.videoDetails);
  const { loading, error, video } = videoDetails;

  const videosByCourse = useSelector((state) => state.videosByCourse);
  const {
    loading: allVideosLoading,
    error: allVideosError,
    videos: allVideos,
  } = videosByCourse;

  const prevVideoData = useSelector((state) => state.prevVideo);
  const {
    loading: prevVideoLoading,
    error: prevVideoError,
    success: prevVideoSuccess,
    prevVideo,
  } = prevVideoData;

  const nextVideoData = useSelector((state) => state.nextVideo);
  const {
    loading: nextVideoLoading,
    error: nextVideoError,
    success: nextVideoSuccess,
    nextVideo,
  } = nextVideoData;

  useEffect(() => {
    dispatch({ type: VIDEO_PREV_RESET });
    dispatch({ type: VIDEO_NEXT_RESET });
  }, [dispatch, VIDEO_PREV_RESET, VIDEO_NEXT_RESET]);

  useEffect(() => {
    if (studentInfo) {
      dispatch(listVideoDetails(videoId));
      dispatch(listVideosByCourse(studentInfo.course._id));
    } else {
      history.push('/login');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [dispatch, history, studentInfo, videoId, lang]);

  useEffect(() => {
    if (video && video.no) {
      dispatch(getPrevVideoDetails(courseId, +video.no - 1));
      dispatch(getNextVideoDetails(courseId, +video.no + 1));
    }
  }, [dispatch, video]);

  return (
    <>
      <LabsHeader
        pageTitle={video ? video.title : ''}
        backBtn={{ action: () => history.push('/videos') }}
        customJSX={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}>
            <span>Hindi</span>

            <Switch
              checked={lang.english}
              onChange={handleLangChange}
              name="language"
              color="primary"
            />
            <span>English</span>
          </div>
        }
      />
      {video && allVideos && (
        <section className={classes.section}>
          <div className={classes.sidebar} style={{ height: "calc(100vh - 202px)", overflow: 'auto' }}>
            <List>
              <div className={classes.sideSpace}>
                <Typography variant="h6" style={{ fontSize: '1.2rem' }}>
                  Video Index
                </Typography>
              </div>
              {allVideos &&
                allVideos.map((vd) => (
                  <ListItem
                    key={vd._id}
                    button
                    selected={videoId && vd._id === videoId}
                    onClick={() =>
                      history.push(
                        `/videos/${studentInfo.course._id}/${vd._id}`
                      )
                    }>
                    <ListItemText primary={vd.title} />
                  </ListItem>
                ))}
            </List>
          </div>

          {/* MAIN CONTENT */}
          <div className={classes.mainContent}>
            {/* Switch */}
            <Paper
              style={{ width: '75%', margin: '0 auto', padding: 0 }}
              elevation={false}>
              {video && lang.english ? (
                video.englishVideoUrl ? (
                  <video
                    controlsList="nodownload"
                    style={{
                      position: 'relative',
                      zIndex: '11',
                      width: '100%',
                      aspectRatio: '16 / 9',
                    }}
                    controls={true}>
                    <source
                      src={video && video.englishVideoUrl}
                      controls></source>
                  </video>
                ) : video.englishVideoUrlYouTube ? (
                  <div
                    style={{ aspectRatio: '16 / 9' }}
                    dangerouslySetInnerHTML={{
                      __html: video.englishVideoUrlYouTube,
                    }}
                  />
                ) : (
                  <div style={{ padding: '2rem' }}>
                    <p style={{ textAlign: 'center' }}>
                      <strong>No Video Available</strong>
                    </p>
                  </div>
                )
              ) : video.hindiVideoUrl ? (
                <video
                  controlsList="nodownload"
                  style={{
                    position: 'relative',
                    zIndex: '11',
                    width: '100%',
                    aspectRatio: '16 / 9',
                  }}
                  controls={true}>
                  <source src={video && video.hindiVideoUrl} controls></source>
                </video>
              ) : video.hindiVideoUrlYouTube ? (
                <div
                  style={{ aspectRatio: '16 / 9' }}
                  dangerouslySetInnerHTML={{
                    __html: video.hindiVideoUrlYouTube,
                  }}
                />
              ) : (
                <div style={{ padding: '2rem' }}>
                  <p style={{ textAlign: 'center' }}>
                    <strong>No Video Available</strong>
                  </p>
                </div>
              )}
            </Paper>
          </div>
        </section>
      )}
    </>
  );
};

export default StudentVideoScreen;
