import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';

export const DownloadList = ({ list, filename }) => {
  const [downloadLink, setDownloadLink] = useState('');

  const makeTextFile = () => {
    const data = new Blob(
      [
        list
          .map(
            (li) =>
              `Username: ${li.username}\nPassword: ${li.password}\n${
                li.ips
                  ? 'Ip(s): ' + li.ips.join(' ')
                  : li.ports
                  ? 'Port(s): ' + li.ports.join(' ')
                  : li.singleIp
                  ? 'IP Address: ' + li.singleIp
                  : 'Ip(s): ' +
                    li.ips.join(' ') +
                    '\n' +
                    'Ports(s): ' +
                    li.ports.join(' ')
              }`
          )
          .join('\n\n'),
      ],
      { type: 'text/plain' }
    );
    if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink);
    setDownloadLink(window.URL.createObjectURL(data));
  };

  useEffect(() => {
    makeTextFile();
  }, [list]);

  return (
    <Button color="primary" download={`${filename}.txt`} href={downloadLink}>
      Download
    </Button>
  );
};

export default DownloadList;
