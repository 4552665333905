/**
 * @fileOverview   Actions to perform CRUD operations on the Pages Schema
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import axios from 'axios';
import {
  PAGE_LIST_REQUEST,
  PAGE_LIST_SUCCESS,
  PAGE_LIST_FAIL,
  PAGE_DETAILS_REQUEST,
  PAGE_DETAILS_SUCCESS,
  PAGE_DETAILS_FAIL,
  PAGE_CREATE_REQUEST,
  PAGE_CREATE_SUCCESS,
  PAGE_CREATE_FAIL,
  PAGE_UPDATE_REQUEST,
  PAGE_UPDATE_SUCCESS,
  PAGE_UPDATE_FAIL,
  PAGE_DELETE_REQUEST,
  PAGE_DELETE_SUCCESS,
  PAGE_DELETE_FAIL,
  PAGE_NEXT_REQUEST,
  PAGE_NEXT_SUCCESS,
  PAGE_NEXT_FAIL,
  PAGE_PREV_REQUEST,
  PAGE_PREV_SUCCESS,
  PAGE_PREV_FAIL,
} from '../constants/pageConstants';
import { snackActions } from '../utils/SnackBarUtils';

/**
 * Returns all the pages in the database.
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const listPages = () => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: PAGE_LIST_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Array<Object>} All pages in the database */
    const { data } = await axios.get('/api/pages', config);

    // Dispatch success object to the reducer.
    // Payload is the list of all pages
    dispatch({ type: PAGE_LIST_SUCCESS, payload: data });
  } catch (err) {
    // Dispatch an error object to the reducer.
    dispatch({
      type: PAGE_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

/**
 * Get a page from the database by id
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const listPageDetails = (id) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: PAGE_DETAILS_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();
    const {
      studentLogin: { studentInfo },
    } = getState();

    if (userInfo) {
      /** @type {Object} Axios configurations */
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      /** @type {Object} The resulting page from the database */
      const { data } = await axios.get(`/api/pages/${id}`, config);
      // Dispatch success object to the reducer.
      // Payload is the page object from the database
      dispatch({ type: PAGE_DETAILS_SUCCESS, payload: data });
    } else if (studentInfo) {
      /** @type {Object} Axios configurations */
      const config = {
        headers: {
          Authorization: `Bearer ${studentInfo.token}`,
        },
      };
      /** @type {Object} The resulting page from the database */
      const { data } = await axios.get(`/api/pages/${id}/student`, config);
      // Dispatch success object to the reducer.
      // Payload is the page object from the database
      dispatch({ type: PAGE_DETAILS_SUCCESS, payload: data });
    }
  } catch (err) {
    // Dispatch an error object to the reducer.
    dispatch({
      type: PAGE_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

/**
 * Action to create a new page. (Trainer/Admin)
 * @param {Object} page New page object that is to be created
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const createPage = (page) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: PAGE_CREATE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Object} Newly create page object */
    const { data } = await axios.post(`/api/pages`, page, config);

    // Dispatch success object to the reducer.
    // Payload is the newly created page object returned from the backend
    dispatch({ type: PAGE_CREATE_SUCCESS, payload: data });
    snackActions.success(`${data.title} page created.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: PAGE_CREATE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Action to update an existing page (by _id). (Trainers/Admins).
 * @param {Object} page Updated page object to be sent to the backend
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const updatePage = (page) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: PAGE_UPDATE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Object} Newly updated page object */
    const { data } = await axios.put(`/api/pages/${page._id}`, page, config);

    // Dispatch success object to the reducer.
    dispatch({ type: PAGE_UPDATE_SUCCESS, payload: data });
    snackActions.success(`${data.title} page updated.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: PAGE_UPDATE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Action to delete an existing page (by _id) (Trainers/Admins)
 * @param {string} id Mongoose _id of the page that is to be deleted
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const deletePage = (id) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: PAGE_DELETE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // Axios delete request to backend.
    await axios.delete(`/api/pages/${id}`, config);
    // Dispatch success object to the reducer.
    dispatch({ type: PAGE_DELETE_SUCCESS });
    snackActions.success(`Page deleted.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: PAGE_DELETE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Action to get prev page details (Trainers/Admins)
 * @param {string} bookId Book id the page
 * @param {string} pageNo Page no the page
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const getPrevPageDetails =
  (bookId, pageNo) => async (dispatch, getState) => {
    try {
      // Dispatch request object to the reducer.
      dispatch({ type: PAGE_PREV_REQUEST });

      /**
       * Extracting logged-in user state from the Redux store
       * @type {Object}
       */
      const {
        studentLogin: { studentInfo },
      } = getState();

      /** @type {Object} Axios configurations */
      const config = {
        headers: {
          Authorization: `Bearer ${studentInfo.token}`,
        },
      };

      // Axios delete request to backend.
      const { data } = await axios.get(
        `/api/pages/${bookId}/${pageNo}`,
        config
      );
      // Dispatch success object to the reducer.
      dispatch({ type: PAGE_PREV_SUCCESS, payload: data });
    } catch (err) {
      // Dispatch an error object to the reducer.
      const errMessage =
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message;
      dispatch({
        type: PAGE_PREV_FAIL,
        payload: errMessage,
      });
    }
  };

/**
 * Action to get next page details (Trainers/Admins)
 * @param {string} bookId Book id the page
 * @param {string} pageNo Page no the page
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const getNextPageDetails =
  (bookId, pageNo) => async (dispatch, getState) => {
    try {
      // Dispatch request object to the reducer.
      dispatch({ type: PAGE_NEXT_REQUEST });

      /**
       * Extracting logged-in user state from the Redux store
       * @type {Object}
       */
      const {
        studentLogin: { studentInfo },
      } = getState();

      /** @type {Object} Axios configurations */
      const config = {
        headers: {
          Authorization: `Bearer ${studentInfo.token}`,
        },
      };

      // Axios delete request to backend.
      const { data } = await axios.get(
        `/api/pages/${bookId}/${pageNo}`,
        config
      );
      // Dispatch success object to the reducer.
      dispatch({ type: PAGE_NEXT_SUCCESS, payload: data });
    } catch (err) {
      // Dispatch an error object to the reducer.
      const errMessage =
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message;
      dispatch({
        type: PAGE_NEXT_FAIL,
        payload: errMessage,
      });
    }
  };
