import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  makeStyles,
  Container,
  CircularProgress,
  Chip,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { grey } from "@material-ui/core/colors";
import LabsHeader from "../components/LabsHeader";
import InfoTable from "../components/InfoTable";
import { listLabsByCourse as listLabsByCourseAction } from "../actions/labActions";
import { HEADER_FOOTER_SHOW } from "../constants/interfaceConstants";

const useStyles = makeStyles((theme) => ({
  section: {
    padding: "50px 20px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: grey[200],
    minHeight: "calc(100vh - 202px)",
    userSelect: "none",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  appBar: {
    position: "relative",
  },
  chip: {
    margin: 2,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  notifSpacing: {
    marginBottom: "10px",
  },
  spacer: {
    height: "20px",
  },
}));

/**
 * Component for showing/adding/editing labs (user side)
 */
const StudentLabsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Redux State Getters
  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo } = studentLogin;
  const labsListByCourse = useSelector((state) => state.labsListByCourse);
  const { loading, error, labs } = labsListByCourse;

  // Lifecycle events
  useEffect(() => {
    if (studentInfo) {
      dispatch(listLabsByCourseAction());
    } else {
      history.push("/");
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [dispatch, history, studentInfo]);

  useEffect(() => {
    document.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
    return () =>
      document.removeEventListener("contextmenu", (event) => {
        event.preventDefault();
      });
  }, []);


  return (
    <>
      <LabsHeader
        pageTitle={`${studentInfo && studentInfo.course.name} Labs`}
        vmInfo={false}
        backBtn={{ action: () => history.push("/dashboard") }}
      />
      <section className={classes.section}>
        <Container>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "calc(100vh - 255px)",
              }}
            >
              <CircularProgress />
            </div>
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : (
            <InfoTable
              columns={["no.", "name", "course", "actions"]}
              rows={
                labs &&
                labs.map((lab) => ({
                  _id: lab._id,
                  no: `Lab ${lab.no}`,
                  name: <span style={{ fontWeight: "500" }}>{lab.name}</span>,
                  course: <Chip label={lab.course.name} variant="outlined" />,
                  actions: [
                    {
                      kind: "nav",
                      label: "Access Lab",
                      type: "primary",
                      link: `/lab/${lab._id}`,
                    },
                  ],
                  index: lab.index,
                }))
              }
              sorting={(a, b) => {
                console.log(a, b)
                return (+a.index < +b.index ? -1 : +a.index > +b.index ? 1 : 0)
              }}
            />
          )}
        </Container>
      </section>
    </>
  );
};

export default StudentLabsScreen;
