/**
 * @fileOverview   Action functions to perform CRUD operations for the Courses Schema
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import axios from 'axios';
import {
  COURSE_LIST_REQUEST,
  COURSE_LIST_SUCCESS,
  COURSE_LIST_FAIL,
  COURSE_CREATE_REQUEST,
  COURSE_CREATE_SUCCESS,
  COURSE_CREATE_FAIL,
  COURSE_UPDATE_REQUEST,
  COURSE_UPDATE_SUCCESS,
  COURSE_UPDATE_FAIL,
  COURSE_DELETE_REQUEST,
  COURSE_DELETE_SUCCESS,
  COURSE_DELETE_FAIL,
} from '../constants/courseConstants';
import { snackActions } from '../utils/SnackBarUtils';

/**
 * Returns all the courses in the database. (ADMIN only)
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const listCourses = () => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: COURSE_LIST_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Array<Object>} All courses in the database */
    const { data } = await axios.get('/api/courses', config);

    // Dispatch success object to the reducer.
    // Payload is the list of all courses
    dispatch({ type: COURSE_LIST_SUCCESS, payload: data });
  } catch (err) {
    // Dispatch an error object to the reducer.
    dispatch({
      type: COURSE_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

/**
 * Action to create a new course. (ADMIN only)
 * @param {Object} course New course object that is to be created
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const createCourse = (course) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: COURSE_CREATE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Object} Newly create course object */
    const { data } = await axios.post('/api/courses', course, config);

    // Dispatch success object to the reducer.
    // Payload is the newly created course object returned from the backend
    dispatch({ type: COURSE_CREATE_SUCCESS, payload: data });
    snackActions.success(`${data.name} course created.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: COURSE_CREATE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Action to update an existing course (by _id). (ADMIN only).
 * @param {Object} course Updated course object to be sent to the backend
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const updateCourse = (course) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: COURSE_UPDATE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Object} Newly updated course object */
    const { data } = await axios.put(
      `/api/courses/${course._id}`,
      course,
      config
    );
    // Dispatch success object to the reducer.
    // Payload is the newly updated course object returned from the backend
    dispatch({ type: COURSE_UPDATE_SUCCESS, payload: data });
    snackActions.success(`${data.name} course updated.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: COURSE_UPDATE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Action to delete an existing course (by _id).
 * @param {Object} course Course object from the server.
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const deleteCourse = (id) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: COURSE_DELETE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    // Axios delete request to backend.
    await axios.delete(`/api/courses/${id}`, config);
    // Dispatch success object to the reducer.
    dispatch({ type: COURSE_DELETE_SUCCESS });
    snackActions.success(`Course deleted.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: COURSE_DELETE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};
