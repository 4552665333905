import { forwardRef } from 'react';
import { Zoom, Slide, Grow } from '@material-ui/core';

export const SlideTransition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ZoomTransition = forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

export const GrowTransition = forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});
