import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  CircularProgress,
  LinearProgress,
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  Box,
  FormControl,
  Paper,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { blue, grey } from '@material-ui/core/colors';
import { Alert } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import LabsHeader from '../components/LabsHeader';
import { createRegistration } from '../actions/registrationActions';
import {
  courses as allCoursesList,
  batchTiming as batchTimingList,
  batch as batchesList,
  termsAndConditionsEnglish,
  termsAndConditionsMarathi,
  termsAndConditionsHindi,
} from '../data/registration-form-data';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[200],
    padding: '80px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: 'calc(100vh - 206px)',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  notifSpacing: {
    marginBottom: '10px',
  },
  spacer: {
    height: '30px',
  },
  blue: {
    color: theme.palette.getContrastText(blue['A400']),
    backgroundColor: blue['A400'],
  },
}));

const StudentRegistrationScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Form and Local State
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [email, setEmail] = useState('');
  const [courseEnrolledFor, setCourseEnrolledFor] = useState('');
  const [batchEnrolledFor, setBatchEnrolledFor] = useState('');
  const [timeEnrolledFor, setTimeEnrolledFor] = useState('');
  const [agreeGeneral, setAgreeGeneral] = useState(false);
  const [agreeFees, setAgreeFees] = useState(false);
  const [agreePlacements, setAgreePlacements] = useState(false);
  const [agreeGeneralMarathi, setAgreeGeneralMarathi] = useState(false);
  const [agreeFeesMarathi, setAgreeFeesMarathi] = useState(false);
  const [agreePlacementsMarathi, setAgreePlacementsMarathi] = useState(false);
  const [agreeGeneralHindi, setAgreeGeneralHindi] = useState(false);
  const [agreeFeesHindi, setAgreeFeesHindi] = useState(false);
  const [agreePlacementsHindi, setAgreePlacementsHindi] = useState(false);
  const [startingDate, setStartingDate] = useState(new Date());

  // Redux State Getters
  const registrationCreate = useSelector((state) => state.registrationCreate);
  const { loading, success, error } = registrationCreate;

  // Lifecycle events
  useEffect(() => {
    if (success) {
      setName('');
      setAddress('');
      setMobileNo('');
      setEmail('');
      setCourseEnrolledFor('');
      setBatchEnrolledFor('');
      setTimeEnrolledFor('');
    }
  }, [dispatch, success]);

  // Action Handlers
  const handleRegisterStudent = (e) => {
    e.preventDefault();
    dispatch(
      createRegistration({
        name,
        address,
        mobileNo,
        email,
        courseEnrolledFor,
        batchEnrolledFor,
        timeEnrolledFor,
        startingDate,
      })
    );
  };

  const handleDateChange = (date) => {
    setStartingDate(date);
  };

  return (
    <>
      <LabsHeader pageTitle='Student Registration Form' />
      <section className={classes.section}>
        <Container style={{ display: 'flex', justifyContent: 'center' }}>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 255px)',
              }}
            >
              <CircularProgress />
            </div>
          ) : error ? (
            <Alert severity='error'>{error}</Alert>
          ) : (
            <Box component={Paper} padding='30px' width='70%'>
              <Typography
                component='h2'
                variant='h6'
                style={{ marginBottom: '20px' }}
              >
                Please fill the form below to register and enroll:
              </Typography>
              <form onSubmit={handleRegisterStudent}>
                {loading && (
                  <LinearProgress
                    color='primary'
                    className={classes.notifSpacing}
                  />
                )}
                {error && (
                  <Alert severity='error' className={classes.notifSpacing}>
                    {error}
                  </Alert>
                )}
                <TextField
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  required
                  id='name'
                  type='text'
                  label='Full Name'
                  name='name'
                  autoComplete='name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  variant='outlined'
                  margin='dense'
                  fullWidth
                  required
                  id='address'
                  type='text'
                  label='Address'
                  name='address'
                  autoComplete='address'
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    marginBottom: '4px',
                  }}
                >
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    required
                    id='mobileNo'
                    type='text'
                    label='Mobile No.'
                    name='mobileNo'
                    autoComplete='mobileNo'
                    value={mobileNo}
                    onChange={(e) => setMobileNo(e.target.value)}
                  />
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    required
                    id='email'
                    type='email'
                    label='Email Address'
                    name='email'
                    autoComplete='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <FormControl
                  variant='outlined'
                  style={{ width: '100%' }}
                  margin='dense'
                  required
                  className={classes.formControl}
                >
                  <InputLabel id='course'>
                    Select the course your want to enroll into
                  </InputLabel>
                  <Select
                    labelId='course'
                    id='courseEnrolledFor'
                    name='courseEnrolledFor'
                    value={courseEnrolledFor}
                    onChange={(e) => setCourseEnrolledFor(e.target.value)}
                    label='Select the course your want to enroll into'
                  >
                    {allCoursesList.map((course) => (
                      <MenuItem key={course} value={course}>
                        {course}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    marginBottom: '4px',
                  }}
                >
                  <FormControl
                    variant='outlined'
                    style={{ width: '100%' }}
                    margin='dense'
                    required
                    className={classes.formControl}
                  >
                    <InputLabel id='batch'>
                      Select batch you want to enroll for
                    </InputLabel>
                    <Select
                      labelId='batch'
                      id='batchEnrolledFor'
                      name='batchEnrolledFor'
                      value={batchEnrolledFor}
                      onChange={(e) => setBatchEnrolledFor(e.target.value)}
                      label='Select batch you want to enroll for'
                    >
                      {batchesList.map((batch) => (
                        <MenuItem value={batch} key={batch}>
                          {batch}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* TIME */}
                  <FormControl
                    variant='outlined'
                    style={{ width: '100%' }}
                    margin='dense'
                    className={classes.formControl}
                  >
                    <InputLabel id='batchTiming'>
                      Select batch timing
                    </InputLabel>
                    <Select
                      labelId='batchTiming'
                      id='timeEnrolledFor'
                      name='timeEnrolledFor'
                      value={timeEnrolledFor}
                      onChange={(e) => setTimeEnrolledFor(e.target.value)}
                      label='Select batch timing'
                    >
                      {batchTimingList.map((timing) => (
                        <MenuItem value={timing} key={timing}>
                          {timing}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div style={{ height: '1.5rem' }}></div>

                {/* Starting Date */}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin='normal'
                    id='date-picker-dialog'
                    label='Select Batch Starting Date'
                    format='dd/MM/yyyy'
                    value={startingDate}
                    onChange={handleDateChange}
                  />
                </MuiPickersUtilsProvider>
                <p>
                  Please enter the correct batch starting date. Ask your trainer
                  or RSTForum helpdesk for any queries.
                </p>

                <div className={classes.spacer}></div>
                <div>
                  <Typography component='h4' variant='h6'>
                    Terms &amp; Conditions
                  </Typography>
                  <Typography>
                    Please read and scroll within the below block and click on
                    the checkboxes to agree to our T&C.
                  </Typography>
                  <div style={{ height: '10px' }}></div>
                  <Paper
                    variant='outlined'
                    style={{
                      height: '280px',
                      overflow: 'auto',
                      padding: '25px',
                    }}
                  >
                    <Typography
                      component='h5'
                      variant='body1'
                      style={{
                        color: 'gray.400!important',
                        textTransform: 'uppercase',
                      }}
                    >
                      <strong>Training & General Terms and Conditions</strong>
                    </Typography>
                    <ul>
                      {termsAndConditionsEnglish.training.map((term) => (
                        <li style={{ marginBottom: '7px' }} key={term}>
                          {term}
                        </li>
                      ))}
                    </ul>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={agreeGeneral}
                            onChange={(e) => setAgreeGeneral(e.target.checked)}
                          />
                        }
                        label="I agree to the above 'training/general' terms and conditions"
                        style={{ fontWeight: 'bold' }}
                      />
                    </FormGroup>
                    <div className={classes.spacer}></div>
                    <Typography
                      component='h5'
                      variant='body1'
                      style={{
                        color: 'gray.400!important',
                        textTransform: 'uppercase',
                      }}
                    >
                      <strong>Fees Terms &amp; Conditions</strong>
                    </Typography>
                    <ul>
                      {termsAndConditionsEnglish.fees.map((term) => (
                        <li key={term} style={{ marginBottom: '7px' }}>
                          {term}
                        </li>
                      ))}
                    </ul>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={agreeFees}
                            onChange={(e) => setAgreeFees(e.target.checked)}
                          />
                        }
                        label="I agree to the above 'fees' terms and conditions"
                        style={{ fontWeight: 'bold' }}
                      />
                    </FormGroup>
                    <div className={classes.spacer}></div>
                    <Typography
                      component='h5'
                      variant='body1'
                      style={{
                        color: 'gray.400!important',
                        textTransform: 'uppercase',
                      }}
                    >
                      <strong>Placements Terms &amp; Conditions</strong>
                    </Typography>
                    <ul>
                      {termsAndConditionsEnglish.placements.map((term) => (
                        <li key={term} style={{ marginBottom: '7px' }}>
                          {term}
                        </li>
                      ))}
                    </ul>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={agreePlacements}
                            onChange={(e) =>
                              setAgreePlacements(e.target.checked)
                            }
                          />
                        }
                        label="I agree to the above 'placements' terms and conditions"
                        style={{ fontWeight: 'bold' }}
                      />
                    </FormGroup>
                  </Paper>
                  <div className={classes.spacer}></div>

                  {/* Marathi */}
                  <Typography component='h4' variant='h6'>
                    नियम आणि अटी
                  </Typography>
                  <Typography>
                    कृपया खालील ब्लॉकमध्ये वाचा आणि स्क्रोल करा आणि आमच्या अटी
                    आणि शर्तींशी सहमत होण्यासाठी चेकबॉक्सवर क्लिक करा.
                  </Typography>
                  <div style={{ height: '10px' }}></div>
                  <Paper
                    variant='outlined'
                    style={{
                      height: '280px',
                      overflow: 'auto',
                      padding: '25px',
                    }}
                  >
                    <Typography
                      component='h5'
                      variant='body1'
                      style={{
                        color: 'gray.400!important',
                      }}
                    >
                      <strong>प्रशिक्षण आणि सामान्य नियम आणि अटी</strong>
                    </Typography>
                    <ul style={{ fontSize: '16px' }}>
                      {termsAndConditionsMarathi.training.map((term) => (
                        <li key={term} style={{ marginBottom: '7px' }}>
                          {term}
                        </li>
                      ))}
                    </ul>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={agreeGeneralMarathi}
                            onChange={(e) =>
                              setAgreeGeneralMarathi(e.target.checked)
                            }
                          />
                        }
                        label="मी वरील 'प्रशिक्षण/सामान्य' अटी व शर्तींशी सहमत आहे"
                        style={{ fontWeight: 'bold' }}
                      />
                    </FormGroup>
                    <div className={classes.spacer}></div>
                    <Typography
                      component='h5'
                      variant='body1'
                      style={{
                        color: 'gray.400!important',
                      }}
                    >
                      <strong>शुल्क अटी आणि अटी</strong>
                    </Typography>
                    <ul style={{ fontSize: '16px' }}>
                      {termsAndConditionsMarathi.fees.map((term) => (
                        <li key={term} style={{ marginBottom: '7px' }}>
                          {term}
                        </li>
                      ))}
                    </ul>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={agreeFeesMarathi}
                            onChange={(e) =>
                              setAgreeFeesMarathi(e.target.checked)
                            }
                          />
                        }
                        label="मी वरील 'शुल्क' अटी व शर्तींशी सहमत आहे"
                        style={{ fontWeight: 'bold' }}
                      />
                    </FormGroup>
                    <div className={classes.spacer}></div>
                    <Typography
                      component='h5'
                      variant='body1'
                      style={{
                        color: 'gray.400!important',
                      }}
                    >
                      <strong>प्लेसमेंट अटी आणि नियम</strong>
                    </Typography>
                    <ul style={{ fontSize: '16px' }}>
                      {termsAndConditionsMarathi.placements.map((term) => (
                        <li key={term} style={{ marginBottom: '7px' }}>
                          {term}
                        </li>
                      ))}
                    </ul>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={agreePlacementsMarathi}
                            onChange={(e) =>
                              setAgreePlacementsMarathi(e.target.checked)
                            }
                          />
                        }
                        label="मी वरील 'प्लेसमेंट' अटी व शर्तींशी सहमत आहे"
                        style={{ fontWeight: 'bold' }}
                      />
                    </FormGroup>
                  </Paper>
                  <div className={classes.spacer}></div>

                  {/* Hindi */}
                  <Typography component='h4' variant='h6'>
                    नियम एवं शर्तें
                  </Typography>
                  <Typography>
                    कृपया नीचे दिए गए ब्लॉक में पढ़ें और स्क्रॉल करें और हमारे
                    नियमों और शर्तों से सहमत होने के लिए चेकबॉक्स पर क्लिक करें।
                  </Typography>
                  <div style={{ height: '10px' }}></div>
                  <Paper
                    variant='outlined'
                    style={{
                      height: '280px',
                      overflow: 'auto',
                      padding: '25px',
                    }}
                  >
                    <Typography
                      component='h5'
                      variant='body1'
                      style={{
                        color: 'gray.400!important',
                      }}
                    >
                      <strong>प्रशिक्षण और सामान्य नियम और शर्तें</strong>
                    </Typography>
                    <ul style={{ fontSize: '16px' }}>
                      {termsAndConditionsHindi.training.map((term) => (
                        <li key={term} style={{ marginBottom: '7px' }}>
                          {term}
                        </li>
                      ))}
                    </ul>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={agreeGeneralHindi}
                            onChange={(e) =>
                              setAgreeGeneralHindi(e.target.checked)
                            }
                          />
                        }
                        label="मैं उपरोक्त 'प्रशिक्षण/सामान्य' नियम और शर्तों से सहमत हूं"
                        style={{ fontWeight: 'bold' }}
                      />
                    </FormGroup>
                    <div className={classes.spacer}></div>
                    <Typography
                      component='h5'
                      variant='body1'
                      style={{
                        color: 'gray.400!important',
                      }}
                    >
                      <strong>शुल्क नियम और शर्तें</strong>
                    </Typography>
                    <ul style={{ fontSize: '16px' }}>
                      {termsAndConditionsHindi.fees.map((term) => (
                        <li key={term} style={{ marginBottom: '7px' }}>
                          {term}
                        </li>
                      ))}
                    </ul>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={agreeFeesHindi}
                            onChange={(e) =>
                              setAgreeFeesHindi(e.target.checked)
                            }
                          />
                        }
                        label="मैं उपरोक्त 'शुल्क' नियमों और शर्तों से सहमत हूं"
                        style={{ fontWeight: 'bold' }}
                      />
                    </FormGroup>
                    <div className={classes.spacer}></div>
                    <Typography
                      component='h5'
                      variant='body1'
                      style={{
                        color: 'gray.400!important',
                      }}
                    >
                      <strong>प्लेसमेंट नियम और शर्तें</strong>
                    </Typography>
                    <ul style={{ fontSize: '16px' }}>
                      {termsAndConditionsHindi.placements.map((term) => (
                        <li key={term} style={{ marginBottom: '7px' }}>
                          {term}
                        </li>
                      ))}
                    </ul>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={agreePlacementsHindi}
                            onChange={(e) =>
                              setAgreePlacementsHindi(e.target.checked)
                            }
                          />
                        }
                        label="मैं उपरोक्त 'नियुक्तियों' के नियमों और शर्तों से सहमत हूं"
                        style={{ fontWeight: 'bold' }}
                      />
                    </FormGroup>
                  </Paper>
                </div>
                <div className={classes.spacer}></div>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={
                    !(
                      (agreeGeneral && agreeFees && agreePlacements) ||
                      (agreeGeneralMarathi &&
                        agreeFeesMarathi &&
                        agreePlacementsMarathi) ||
                      (agreeGeneralHindi &&
                        agreeFeesHindi &&
                        agreePlacementsHindi)
                    )
                  }
                >
                  Submit Registration
                </Button>
              </form>
            </Box>
          )}
        </Container>
      </section>
    </>
  );
};

export default StudentRegistrationScreen;
