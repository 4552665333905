import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import LabsHeader from '../components/LabsHeader';
import LabGuide from '../components/LabGuide';
import { listLabDetails } from '../actions/labActions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';

const useStyles = makeStyles((theme) => ({
  section: {
    padding: '60px 0',
  },
}));

const LabGuideScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const labDetails = useSelector((state) => state.labDetails);
  const { loading, error, lab } = labDetails;

  useEffect(() => {
    dispatch(listLabDetails(match.params.id));
    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [match, dispatch]);


  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'calc(100vh - 255px)',
          }}
        >
          <CircularProgress />
        </div>
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <>
          <LabsHeader
            pageTitle={lab.name}
            backBtn={{ action: history.goBack }}
            // backBtn={{ link: `/lab/${lab._id}` }}
            secondaryBtn={{ label: 'Back to all labs', link: '/' }}
            size="small"
          />
          yo
          <Container className={classes.section}>
            <LabGuide
              content={lab.labGuide}
              padded={lab.showPadd && lab.showPadd}
              disableWatermark={lab.disableWatermark}
            />
          </Container>
        </>
      )}
    </>
  );
};

export default LabGuideScreen;
