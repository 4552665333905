/**
 * @fileOverview   Reducer for the Registration Schema to perform CRUD operations
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import {
  REGISTRATION_LIST_REQUEST,
  REGISTRATION_LIST_SUCCESS,
  REGISTRATION_LIST_FAIL,
  REGISTRATION_DETAILS_REQUEST,
  REGISTRATION_DETAILS_SUCCESS,
  REGISTRATION_DETAILS_FAIL,
  REGISTRATION_DETAILS_RESET,
  REGISTRATION_CREATE_REQUEST,
  REGISTRATION_CREATE_SUCCESS,
  REGISTRATION_CREATE_FAIL,
  REGISTRATION_UPDATE_REQUEST,
  REGISTRATION_UPDATE_SUCCESS,
  REGISTRATION_UPDATE_FAIL,
  REGISTRATION_UPDATE_RESET,
  REGISTRATION_DELETE_REQUEST,
  REGISTRATION_DELETE_SUCCESS,
  REGISTRATION_DELETE_FAIL,
  REGISTRATION_FILTER_REQUEST,
  REGISTRATION_FILTER_SUCCESS,
  REGISTRATION_FILTER_FAIL,
} from '../constants/registrationConstants';

export const registrationsListReducer = (
  state = { registrations: [] },
  action
) => {
  switch (action.type) {
    case REGISTRATION_LIST_REQUEST:
      return { loading: true, registrations: [] };
    case REGISTRATION_LIST_SUCCESS:
      return { loading: false, registrations: action.payload };
    case REGISTRATION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const registrationDetailsReducer = (
  state = { registration: {} },
  action
) => {
  switch (action.type) {
    case REGISTRATION_DETAILS_REQUEST:
      return { ...state, loading: true };
    case REGISTRATION_DETAILS_SUCCESS:
      return { loading: false, registration: action.payload };
    case REGISTRATION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case REGISTRATION_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const registrationCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case REGISTRATION_CREATE_REQUEST:
      return { loading: true };
    case REGISTRATION_CREATE_SUCCESS:
      return { loading: false, success: true, registration: action.payload };
    case REGISTRATION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const registrationUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case REGISTRATION_UPDATE_REQUEST:
      return { loading: true, ...state };
    case REGISTRATION_UPDATE_SUCCESS:
      return { loading: false, success: true, registration: action.payload };
    case REGISTRATION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case REGISTRATION_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const registrationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case REGISTRATION_DELETE_REQUEST:
      return { loading: true };
    case REGISTRATION_DELETE_SUCCESS:
      return { loading: false, success: true };
    case REGISTRATION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const registrationsFilterReducer = (
  state = { registrations: [] },
  action
) => {
  switch (action.type) {
    case REGISTRATION_FILTER_REQUEST:
      return { loading: true, registrations: [] };
    case REGISTRATION_FILTER_SUCCESS:
      return { loading: false, registrations: action.payload };
    case REGISTRATION_FILTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
