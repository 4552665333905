export const REGISTRATION_LIST_REQUEST = 'REGISTRATION_LIST_REQUEST';
export const REGISTRATION_LIST_SUCCESS = 'REGISTRATION_LIST_SUCCESS';
export const REGISTRATION_LIST_FAIL = 'REGISTRATION_LIST_FAIL';

export const REGISTRATION_DETAILS_REQUEST = 'REGISTRATION_DETAILS_REQUEST';
export const REGISTRATION_DETAILS_SUCCESS = 'REGISTRATION_DETAILS_SUCCESS';
export const REGISTRATION_DETAILS_FAIL = 'REGISTRATION_DETAILS_FAIL';
export const REGISTRATION_DETAILS_RESET = 'REGISTRATION_DETAILS_RESET';

export const REGISTRATION_CREATE_REQUEST = 'REGISTRATION_CREATE_REQUEST';
export const REGISTRATION_CREATE_SUCCESS = 'REGISTRATION_CREATE_SUCCESS';
export const REGISTRATION_CREATE_FAIL = 'REGISTRATION_CREATE_FAIL';

export const REGISTRATION_UPDATE_REQUEST = 'REGISTRATION_UPDATE_REQUEST';
export const REGISTRATION_UPDATE_SUCCESS = 'REGISTRATION_UPDATE_SUCCESS';
export const REGISTRATION_UPDATE_FAIL = 'REGISTRATION_UPDATE_FAIL';
export const REGISTRATION_UPDATE_RESET = 'REGISTRATION_UPDATE_RESET';

export const REGISTRATION_DELETE_REQUEST = 'REGISTRATION_DELETE_REQUEST';
export const REGISTRATION_DELETE_SUCCESS = 'REGISTRATION_DELETE_SUCCESS';
export const REGISTRATION_DELETE_FAIL = 'REGISTRATION_DELETE_FAIL';

export const REGISTRATION_FILTER_REQUEST = 'REGISTRATION_FILTER_REQUEST';
export const REGISTRATION_FILTER_SUCCESS = 'REGISTRATION_FILTER_SUCCESS';
export const REGISTRATION_FILTER_FAIL = 'REGISTRATION_FILTER_FAIL';
