import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  LinearProgress,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from '@material-ui/lab/Alert';
import { login } from '../actions/studentActions';
import { HEADER_FOOTER_HIDE } from '../constants/interfaceConstants';
import { blue, grey } from '@material-ui/core/colors';
import logoc from '../logo_c.svg';

const useStyles = makeStyles((theme) => ({
  bg: {
    background: grey[200],
  },
  section: {
    padding: '50px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    alignContent: 'center',
  },
  loginBox: {
    padding: '25px 30px',
    // borderRadius: '10px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '32rem',
    '& h1': {
      marginBottom: theme.spacing(2),
    },
  },
  avatar: {
    marginBottom: theme.spacing(1),
    backgroundColor: blue['A700'],
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  alert: {
    width: '100%',
    margin: '5px 0 15px',
  },
  logo: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& img': {
      height: '44px',
      marginBottom: theme.spacing(2),
    },
    '& h5': {
      fontWeight: '800',
      color: grey[900],
    },
  },
  userLoginMessage: {
    marginTop: '15px',
    fontWeight: '500',
  },
}));

const StudentLoginScreen = ({ history, location }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  const studentLogin = useSelector((state) => state.studentLogin);
  const { loading, error, studentInfo } = studentLogin;

  const classes = useStyles();

  const redirect = location.search
    ? location.search.split('=')[1]
    : '/dashboard';

  useEffect(() => {
    if (studentInfo) {
      history.push(redirect);
    }
    dispatch({ type: HEADER_FOOTER_HIDE });
  }, [history, redirect, studentInfo, dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(username, password));
  };

  return (
    <div className={classes.bg}>
      <Container className={classes.section}>
        <div className={classes.logo}>
          <img src={logoc} alt="RST Forum" />
          <Typography variant="h5">Learning Delivery Platform</Typography>
        </div>
        <Paper className={classes.loginBox} elevation={1}>
          <Avatar className={classes.avatar} sizes="large">
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
          {error && (
            <Alert severity="error" className={classes.alert}>
              {error}
            </Alert>
          )}
          {loading && (
            <LinearProgress color="primary" className={classes.alert} />
          )}
          <form onSubmit={submitHandler}>
            <TextField
              variant="outlined"
              margin="none"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              disableElevation
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

export default StudentLoginScreen;
