import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LabsHeader from '../components/LabsHeader';
import {
  makeStyles,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';
import { listBookDetails } from '../actions/bookActions';
// import { listPageDetails } from '../actions/pageActions';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[100],
    // padding: '50px 30px',
    minHeight: 'calc(100vh - 206px)',
    display: 'flex',
  },
  sidebar: {
    width: '20%',
    backgroundColor: 'white',
    borderRight: `1px solid ${grey[300]}`,
    paddingTop: '1rem',
    height: 'calc(100vh - 206px)',
    overflow: 'auto'
  },
  sideSpace: {
    padding: '0 1rem',
  },
  mainContent: {
    padding: '2rem',
    background: grey[100],
    width: '80%',
    height: 'calc(100vh - 202px)',
    overflow: 'auto',
  },
  pageContent: {
    padding: '2rem',
  },
  description: {
    padding: '2rem 0',
    fontSize: '1rem',
  },
  bookMetaWrapper: {
    padding: '2rem',
  },
  bookMeta: {
    display: 'inline-block',
    width: 'auto',
    maxWidth: '80%',
  },
  displayTitle: {
    fontSize: '4rem',
    fontWeight: '400',
    lineHeight: '1.1',
    paddingBottom: '1rem',
    borderBottom: '0.3rem solid black',
    margin: 0,
  },
  studentGuide: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    margin: '1rem 0',
  },
  partModuleName: {
    fontSize: '1.8rem',
    fontWeight: '600',
    margin: '1rem 0',
  },
  metadatamain: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const BookDetailsScreen = ({ history, match }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo } = studentLogin;

  const bookDetails = useSelector((state) => state.bookDetails);
  const { loading, error, book } = bookDetails;

  const pagesList = useSelector((state) => state.pagesList);
  const {
    loading: allPagesLoading,
    error: allPagesError,
    pages: allPages,
  } = pagesList;

  useEffect(() => {
    if (studentInfo) {
      dispatch(listBookDetails(match.params.id));
    } else {
      history.push('/login');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [dispatch, history, studentInfo]);

  return (
    <>
      <LabsHeader
        pageTitle={book ? 'Book - ' + book.title : ''}
        backBtn={{ action: () => history.push('/dashboard') }}
      />
      {book && allPages && (
        <section className={classes.section}>
          <div className={classes.sidebar}>
            <div className={classes.sideSpace}>
              <Typography variant="h6">Table of Contents</Typography>
            </div>
            <List>
              {book &&
                book.pages.map((pg) => (
                  <ListItem
                    button
                    onClick={() =>
                      history.push(`/book/${book._id}/page/${pg._id}`)
                    }>
                    <ListItemText primary={pg.title} />
                  </ListItem>
                ))}
            </List>
          </div>
          <div className={classes.mainContent}>
            <Paper className={classes.pageContent}>
              {/* <div
                style={{
                  width: '100%',
                  height: '500px',
                  backgroundColor: grey[400],
                }}
              ></div> */}

              <div className={classes.bookMetaWrapper}>
                <div className={classes.bookMeta}>
                  <h1
                    className={classes.displayTitle}
                    dangerouslySetInnerHTML={{
                      __html: book && book.displayTitle && book.displayTitle,
                    }}></h1>
                  <div className={classes.metadatamain}>
                    <h4 className={classes.studentGuide}>Course Guide</h4>
                    <h6 className={classes.partModuleName}>
                      {book && book.partModuleName && book.partModuleName}
                    </h6>
                  </div>

                  <div style={{ marginTop: '9rem' }}>
                    <h6
                      style={{
                        fontSize: '1rem',
                        margin: 0,
                      }}>
                      Copyright Notice
                    </h6>
                    <p style={{ textAlign: 'justify' }}>
                      All rights reserved. No parts of this Course guide may be
                      reproduced in any form or by any electronic or mechanical
                      means, including information storage and retrieval
                      systems, without permission in writing from Routing
                      Switching Tigers Pvt. Ltd.
                    </p>
                  </div>

                  <div style={{ marginTop: '3.5rem' }}>
                    <h6
                      style={{
                        fontSize: '1rem',
                        margin: 0,
                      }}>
                      Disclaimer
                    </h6>
                    <p style={{ textAlign: 'justify' }}>
                      This course guide is designed to assist candidates to
                      facilitate technology learning. While every effort has
                      been made to ensure that all content is as complete and
                      accurate as possible, the enclosed content is presented on
                      an “as is” basis. Neither the authors nor RSTForum assume
                      any liability or responsibility to any person or entity
                      with respect to loss or damages incurred from the
                      information contained in this course guide. This course
                      guide was developed by RSTForum. Any similarities between
                      content presented in this course guide and any other
                      learning material is completely coincidental.
                    </p>
                  </div>
                </div>
              </div>

              {book.introVideo !== '' && (
                <video width="320" height="240" controls>
                  <source src={book.introVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: book.shortDescription,
                }}></div>
              {/* <div className={classes.description}>{book.shortDescription}</div> */}
            </Paper>
          </div>
        </section>
      )}
    </>
  );
};

export default BookDetailsScreen;
