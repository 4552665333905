/**
 * @fileOverview   Actions to perform CRUD operations on the Registration Schema
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import axios from 'axios';
import {
  REGISTRATION_LIST_REQUEST,
  REGISTRATION_LIST_SUCCESS,
  REGISTRATION_LIST_FAIL,
  REGISTRATION_DETAILS_REQUEST,
  REGISTRATION_DETAILS_SUCCESS,
  REGISTRATION_DETAILS_FAIL,
  REGISTRATION_CREATE_REQUEST,
  REGISTRATION_CREATE_SUCCESS,
  REGISTRATION_CREATE_FAIL,
  REGISTRATION_UPDATE_REQUEST,
  REGISTRATION_UPDATE_SUCCESS,
  REGISTRATION_UPDATE_FAIL,
  REGISTRATION_DELETE_REQUEST,
  REGISTRATION_DELETE_SUCCESS,
  REGISTRATION_DELETE_FAIL,
  REGISTRATION_FILTER_REQUEST,
  REGISTRATION_FILTER_SUCCESS,
  REGISTRATION_FILTER_FAIL,
} from '../constants/registrationConstants';
import { snackActions } from '../utils/SnackBarUtils';

export const listRegistrations = () => async (dispatch, getState) => {
  try {
    dispatch({ type: REGISTRATION_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/api/registrations', config);

    dispatch({ type: REGISTRATION_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: REGISTRATION_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listRegistrationDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: REGISTRATION_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/registrations/${id}`, config);
    dispatch({ type: REGISTRATION_DETAILS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: REGISTRATION_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const createRegistration =
  (studentDetails) => async (dispatch, getState) => {
    try {
      console.log('2 -> FIRST EXEC');
      dispatch({ type: REGISTRATION_CREATE_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `/api/registrations`,
        studentDetails,
        config
      );

      console.log('3 -> FIRST EXEC');

      dispatch({ type: REGISTRATION_CREATE_SUCCESS, payload: data });
      snackActions.success(`${data.name} registration created.`);
    } catch (err) {
      const errMessage =
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message;
      dispatch({
        type: REGISTRATION_CREATE_FAIL,
        payload: errMessage,
      });
      snackActions.error(errMessage);
    }
  };

export const updateRegistration =
  (studentDetails) => async (dispatch, getState) => {
    try {
      dispatch({ type: REGISTRATION_UPDATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/registrations/${studentDetails._id}`,
        studentDetails,
        config
      );

      dispatch({ type: REGISTRATION_UPDATE_SUCCESS, payload: data });
      snackActions.success(`${data.name} registration updated.`);
    } catch (err) {
      const errMessage =
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message;
      dispatch({
        type: REGISTRATION_UPDATE_FAIL,
        payload: errMessage,
      });
      snackActions.error(errMessage);
    }
  };

export const deleteRegistration = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: REGISTRATION_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/registrations/${id}`, config);

    dispatch({ type: REGISTRATION_DELETE_SUCCESS });

    snackActions.success(`Registration deleted.`);
  } catch (err) {
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: REGISTRATION_DELETE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

export const filterRegistrations = (query) => async (dispatch, getState) => {
  try {
    dispatch({ type: REGISTRATION_FILTER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      '/api/registrations/filter',
      query,
      config
    );

    dispatch({ type: REGISTRATION_FILTER_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: REGISTRATION_FILTER_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
