import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import LabsHeader from '../components/LabsHeader';
import {
  makeStyles,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';
import { listExamDetails, submitScores } from '../actions/examActions';
import { studentAuthCheck as studentAuthCheckAction } from '../actions/studentActions';
import logo from '../rst-logo.svg';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[100],
    minHeight: 'calc(100vh - 206px)',
    display: 'flex',
  },
  mainContent: {
    padding: '2rem',
    background: grey[100],
    width: '100%',
    minHeight: 'calc(100vh - 202px)',
    overflow: 'auto',
  },
  pageContent: {
    padding: '3rem 4rem',
    width: '90%',
    margin: '0 auto',
  },
}));

const StudentSingleExamResultScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const printPageRef = useRef();

  const id = match.params.id;

  const [loadedStudentAnswers, setLoadedStudentAnswers] = useState({});

  /* -------------------------------------------------------------------------- */
  /*                              Redux State Data                              */
  /* -------------------------------------------------------------------------- */
  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo: studentLoginInfo } = studentLogin;

  const studentAuthCheck = useSelector((state) => state.studentAuthCheck);
  const { studentInfo } = studentAuthCheck;

  const examDetails = useSelector((state) => state.examDetails);
  const { loading, error, exam } = examDetails;

  /* -------------------------------------------------------------------------- */
  /*                              Life Cycle Hooks                              */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (studentLoginInfo) {
      dispatch(studentAuthCheckAction());
      dispatch(listExamDetails(id));
    } else {
      history.push('/login');
    }

    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [dispatch, history, studentLoginInfo, id]);

  useEffect(() => {
    if (studentInfo && studentInfo.points && exam) {
      setLoadedStudentAnswers(
        studentInfo.points.find((p) => p.examId === exam._id)
      );
    }
  }, [exam, studentInfo]);

  // console.log(studentInfo && studentInfo.points);
  console.log(loadedStudentAnswers);

  return (
    <>
      <LabsHeader
        pageTitle={
          exam ? `Exam: ${exam.no} - ${exam.title} Results` : 'Exam Result'
        }
        backBtn={{ action: () => history.goBack() }}
        customJSX={
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary">
                Print Report
              </Button>
            )}
            content={() => printPageRef.current}
          />
        }
      />

      {exam && loadedStudentAnswers && (
        <section className={classes.section}>
          <div className={classes.mainContent}>
            <div style={{ margin: '0 auto' }}>
              <Paper className={classes.pageContent}>
                {/* PRINTING AREA */}
                <div className="resultPagePrint" ref={printPageRef}>
                  {/* ---------------------------------- LOGO ---------------------------------- */}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginBottom: '4rem',
                    }}
                  >
                    <img src={logo} width="100px" />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      gap: '2rem',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <Typography
                        variant="h5"
                        color="default"
                        style={{ fontSize: '1.2rem' }}
                      >
                        <strong>Exam: {exam.title}</strong>
                      </Typography>
                      <Typography variant="h5" style={{ fontSize: '1.2rem' }}>
                        <strong>Status:</strong> Completed
                      </Typography>
                      <Typography variant="h5" style={{ fontSize: '1.2rem' }}>
                        <strong>Date:</strong> {loadedStudentAnswers.date}
                      </Typography>
                      <div style={{ height: '1rem' }}></div>
                      <Typography variant="h5" style={{ fontSize: '1.2rem' }}>
                        <strong>Candidate Name:</strong>{' '}
                        {studentInfo && studentInfo.name}
                      </Typography>
                      <Typography variant="h5" style={{ fontSize: '1.2rem' }}>
                        <strong>Candidate Email:</strong>{' '}
                        {studentInfo && studentInfo.email}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="h5">
                        Your Average:{' '}
                        <strong>
                          {(loadedStudentAnswers.score /
                            (exam.questionCount * 10)) *
                            100}
                          %
                        </strong>
                      </Typography>
                    </div>
                  </div>
                  <div style={{ height: '4rem' }}></div>

                  {/* -------------------------------------------------------------------------- */
                  /*                        Individual Questions details                        */
                  /* -------------------------------------------------------------------------- */}
                  <Paper elevation={false} variant={'outlined'}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>No</TableCell>
                          <TableCell align="left">Question</TableCell>
                          <TableCell align="right">Your Answer</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {exam &&
                          studentInfo &&
                          studentInfo.points &&
                          loadedStudentAnswers &&
                          loadedStudentAnswers.questions?.length > 0 &&
                          exam.questions.map((question, idx) => {
                            return (
                              <TableRow key={question.questionNo}>
                                <TableCell align="left" style={{ width: '5%' }}>
                                  {question.questionNo}
                                </TableCell>
                                <TableCell align="left">
                                  <span style={{ fontSize: '1rem' }}>
                                    {question.title}
                                  </span>
                                  <br />
                                  <strong>Correct Answer:</strong>{' '}
                                  {question.answerInput}
                                </TableCell>
                                <TableCell align="right">
                                  {/* {
                                    loadedStudentAnswers.questions[idx]
                                      .selectedAnswer
                                  }
                                  <br /> */}
                                  <span
                                    style={{
                                      fontWeight: 'bold',
                                      textTransform: 'capitalize',
                                      color:
                                        loadedStudentAnswers.questions[idx] &&
                                          loadedStudentAnswers.questions[idx].result &&
                                          loadedStudentAnswers.questions[idx].result[0] === 'incorrect'
                                          ? 'red'
                                          : 'green',
                                    }}
                                  >
                                    {
                                      loadedStudentAnswers.questions[idx] &&
                                      loadedStudentAnswers.questions[idx].result &&
                                      loadedStudentAnswers.questions[idx].result[0]
                                    }
                                  </span>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        <TableRow style={{ backgroundColor: '#fbfbfb' }}>
                          <TableCell size="small" align="left"></TableCell>
                          <TableCell size="small" align="left" variant="head">
                            <strong>Total Score</strong>
                          </TableCell>
                          <TableCell size="small" align="right" variant="head">
                            {loadedStudentAnswers.score} points{' '}
                            <span>out of {exam.questionCount * 10}</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={{ backgroundColor: '#fbfbfb' }}>
                          <TableCell size="small" align="left"></TableCell>
                          <TableCell size="small" align="left" variant="head">
                            Your Average
                          </TableCell>
                          <TableCell size="small" align="right" variant="head">
                            {(loadedStudentAnswers.score /
                              (exam.questionCount * 10)) *
                              100}
                            %
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Paper>
                  <div style={{ height: '1rem' }}></div>
                </div>
              </Paper>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default StudentSingleExamResultScreen;
