import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import axios from 'axios';
import {
  makeStyles,
  Container,
  CircularProgress,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Select,
  Chip,
  InputLabel,
  MenuItem,
  FormControl,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import LabsHeader from '../components/LabsHeader';
import InfoTable from '../components/InfoTable';
import { listCourses as listCoursesAction } from '../actions/courseActions';
import {
  listPages as listPagesAction,
  createPage as createPageAction,
  updatePage as updatePageAction,
  deletePage as deletePageAction,
} from '../actions/pageActions';
import { listBooks as listBooksActions } from '../actions/bookActions';
import {
  PAGE_CREATE_RESET,
  PAGE_UPDATE_RESET,
} from '../constants/pageConstants';
import { SlideTransition, ZoomTransition } from '../components/Transitions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[200],
    padding: '50px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: 'calc(100vh - 206px)',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  appBar: {
    position: 'relative',
  },
  chip: {
    margin: 2,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  notifSpacing: {
    marginBottom: '10px',
  },
  spacer: {
    height: '20px',
  },
  markupText: {
    '& textarea': {
      fontFamily: 'monospace',
    },
  },
}));

/**
 * Component for showing/adding/editing books (user side)
 */
const BooksScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Dialog State
  const [createPopup, setCreatePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);

  // Form and Local State
  const [no, setNo] = useState('');
  const [title, setTitle] = useState('');
  const [chapter, setChapter] = useState('');
  const [content, setContent] = useState([]);
  const [book, setBook] = useState(0);
  const [useHtmlContent, setUseHtmlContent] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');

  const [updateNo, setUpdateNo] = useState('');
  const [updateTitle, setUpdateTitle] = useState('');
  const [updateChapter, setUpdateChapter] = useState('');
  const [updateContent, setUpdateContent] = useState('');
  const [updateBook, setUpdateBook] = useState('');
  const [updateUseHtmlContent, setUpdateUseHtmlContent] = useState(false);
  const [updateHtmlContent, setUpdateHtmlContent] = useState('');

  const [updatePageId, setUpdatePageId] = useState('');
  const [toDeletePage, setToDeletePage] = useState('');

  // Editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  // Redux State Getters
  const useLogin = useSelector((state) => state.userLogin);
  const { userInfo } = useLogin;
  const booksList = useSelector((state) => state.booksList);
  const {
    loading: booksLoading,
    error: booksError,
    books: allBooks,
  } = booksList;
  const pagesList = useSelector((state) => state.pagesList);
  const { loading, error, pages } = pagesList;
  const pageCreate = useSelector((state) => state.pageCreate);
  const {
    loading: createLoading,
    error: createError,
    success: createSuccess,
    page: createdPage,
  } = pageCreate;
  const pageUpdate = useSelector((state) => state.pageUpdate);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    page: updatedPage,
  } = pageUpdate;
  const pageDelete = useSelector((state) => state.pageDelete);
  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
  } = pageDelete;

  // Lifecycle events
  useEffect(() => {
    if (userInfo) {
      dispatch(listBooksActions());
      dispatch(listPagesAction());
      dispatch(listCoursesAction());
    } else {
      history.push('/admin/login');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
    if (createSuccess) {
      handleCreatePopupClose();
      setCreatePopup(false);
      setNo('');
      setTitle('');
      setChapter('');
      setContent('');
      setBook('');
      setUseHtmlContent(false);
      setHtmlContent('');
      dispatch({ type: PAGE_CREATE_RESET });
    }
    if (updateSuccess) {
      handleEditPopupClose();
      setUpdateNo('');
      setUpdateTitle('');
      setUpdateChapter('');
      setUpdateContent('');
      setUpdateBook('');
      setUpdateUseHtmlContent(false);
      setUpdateHtmlContent('');
      dispatch({ type: PAGE_UPDATE_RESET });
    }
    if (deleteSuccess) {
      handleDeletePopupClose();
      setToDeletePage({});
    }
  }, [
    dispatch,
    history,
    userInfo,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    createdPage,
    updatedPage,
  ]);

  // Popup Management
  const handleCreatePopupClick = () => {
    setCreatePopup(true);
  };
  const handleCreatePopupClose = () => {
    setCreatePopup(false);
  };
  const handleEditPopupClick = () => {
    setEditPopup(true);
  };
  const handleEditPopupClose = () => {
    setEditPopup(false);
    setUpdateNo('');
    setUpdateTitle('');
    setUpdateChapter('');
    setUpdateContent('');
    setUpdateBook('');
    setUpdateUseHtmlContent(false);
    setUpdateHtmlContent('');
  };
  const handleDeletePopupClick = (page) => {
    setToDeletePage(page);
    setDeletePopup(true);
  };
  const handleDeletePopupClose = () => {
    setDeletePopup(false);
  };

  // Action Handlers
  const handleCreatePage = (e) => {
    e.preventDefault();
    const formData = {
      no,
      title,
      chapter,
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      book,
      useHtmlContent,
      htmlContent,
    };
    dispatch(createPageAction(formData));
  };
  const handleEditPage = (_e, id) => {
    pages.forEach((page) => {
      if (page._id === id) {
        setUpdatePageId(page._id);
        setUpdateNo(page.no);
        setUpdateTitle(page.title);
        setUpdateChapter(page.chapter);
        const blocksFromHtml = htmlToDraft(page.content);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
        setUpdateBook(page.book._id);
        setUpdateUseHtmlContent(page.useHtmlContent);
        setUpdateHtmlContent(page.htmlContent);
      }
    });
    handleEditPopupClick();
  };
  const handleUpdatePage = (e) => {
    e.preventDefault();
    const formData = {
      _id: updatePageId,
      no: updateNo,
      title: updateTitle,
      chaper: updateChapter,
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      book: updateBook,
      useHtmlContent: updateUseHtmlContent,
      htmlContent: updateHtmlContent,
    };
    dispatch(updatePageAction(formData));
  };
  const handleDeletePage = (id) => {
    dispatch(deletePageAction(id));
    handleDeletePopupClose();
  };

  function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', '/api/upload');
      const data = new FormData();
      data.append('image', file);
      xhr.send(data);
      xhr.addEventListener('load', () => {
        const response = {
          data: { link: xhr.responseText },
        };
        resolve(response);
      });
      xhr.addEventListener('error', () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    });
  }

  console.log('ALL BOOKS', allBooks);
  console.log(pages);

  return (
    <>
      <LabsHeader
        pageTitle={
          userInfo && userInfo.role === 'admin'
            ? `${pages ? 'Pages (' + pages.length + ' total)' : ''}`
            : 'Pages'
        }
        backBtn={{ action: () => history.push('/admin/dashboard') }}
        secondaryBtn={{
          label: 'Manage Books',
          link: '/admin/books',
          color: 'secondary',
          style: 'contained',
        }}
        tertiaryBtn={{
          label: 'Add New Page',
          action: () => handleCreatePopupClick(),
          style: 'contained',
        }}
      />
      <section className={classes.section}>
        <Container>
          {loading && booksLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 255px)',
              }}>
              <CircularProgress />
            </div>
          ) : error || booksError ? (
            <Alert severity='error'>{error || booksError}</Alert>
          ) : (
            <InfoTable
              columns={['title', 'book', 'actions']}
              rows={
                pages &&
                pages.map((page) => ({
                  _id: page._id,
                  title: (
                    <span style={{ fontWeight: '500' }}>{page.title}</span>
                  ),
                  book: (
                    <Chip
                      label={
                        allBooks &&
                        allBooks.find((b) => b._id === page.book._id).title &&
                        allBooks.find((b) => b._id === page.book._id).title
                      }
                      variant='outlined'
                    />
                  ),
                  actions: [
                    ...(userInfo && userInfo.accessLevel >= 7
                      ? [
                          {
                            kind: 'action',
                            label: 'Edit',
                            type: 'primary',
                            action: (e) => handleEditPage(e, page._id),
                          },
                        ]
                      : []),
                    ...(userInfo && userInfo.role === 'admin'
                      ? [
                          {
                            kind: 'action',
                            label: 'Delete',
                            type: 'secondary',
                            action: () => handleDeletePopupClick(page),
                          },
                        ]
                      : []),
                  ],
                }))
              }
            />
          )}
          {/* Add new pages dialog */}
          <Dialog
            fullScreen
            open={createPopup}
            onClose={handleCreatePopupClose}
            aria-labelledby='form-dialog-title'
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={SlideTransition}>
            <form onSubmit={handleCreatePage}>
              <AppBar className={classes.appBar} color='primary'>
                <Toolbar>
                  <IconButton
                    edge='start'
                    color='inherit'
                    onClick={handleCreatePopupClose}
                    aria-label='close'>
                    <CloseIcon />
                  </IconButton>
                  <Typography variant='h6' className={classes.title}>
                    Add New Page
                  </Typography>
                  <Button color='inherit' type='submit'>
                    Add Page
                  </Button>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <DialogContentText style={{ marginTop: '20px' }}>
                  Create a new Page. Please follow the correct HTML formatting
                  for the Page.
                </DialogContentText>
                {createLoading && (
                  <LinearProgress
                    color='primary'
                    className={classes.notifSpacing}
                  />
                )}
                {createError && (
                  <Alert severity='error' className={classes.notifSpacing}>
                    {createError}
                  </Alert>
                )}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    id='no'
                    type='text'
                    label='Sr No.'
                    name='no'
                    autoComplete='no'
                    autoFocus
                    value={no}
                    onChange={(e) => setNo(e.target.value)}
                    style={{ width: '15%' }}
                  />
                  <TextField
                    variant='outlined'
                    margin='dense'
                    required
                    fullWidth
                    id='title'
                    type='text'
                    label='Title'
                    name='title'
                    autoComplete='title'
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                {/* <TextField
                  variant="outlined"
                  margin="dense"
                  multiline
                  rows="10"
                  fullWidth
                  id="content"
                  type="text"
                  label="Page Content"
                  name="content"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                /> */}
                {/* Multi-column Row */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  <FormControl
                    variant='outlined'
                    margin='dense'
                    style={{ width: '50%' }}
                    required
                    className={classes.formControl}>
                    <InputLabel id='pageBook'>Book</InputLabel>
                    <Select
                      labelId='pageBook'
                      id='book'
                      name='book'
                      value={book}
                      onChange={(e) => setBook(e.target.value)}
                      label='Book *'>
                      {allBooks &&
                        allBooks.map((c) => (
                          <MenuItem key={c._id} value={c._id}>
                            {c.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant='outlined'
                    disabled={!book}
                    margin='dense'
                    style={{ width: '50%' }}
                    required
                    className={classes.formControl}>
                    <InputLabel id='chapter'>Chapter</InputLabel>
                    <Select
                      labelId='pageChapter'
                      id='chapter'
                      name='chapter'
                      value={chapter}
                      onChange={(e) => setChapter(e.target.value)}
                      label='Chapter *'>
                      {allBooks &&
                        book &&
                        allBooks
                          .filter((b) => b._id === book)[0]
                          .chapters.map((c, i) => (
                            <MenuItem key={i} value={c}>
                              {c}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </div>
                {!useHtmlContent && (
                  <div
                    style={{
                      margin: '1rem 0',
                      border: '1px solid rgba(0, 0, 0, 0.23)',
                      borderRadius: '4px',
                    }}>
                    <Editor
                      wrapperClassName='dft-wrapper'
                      editorClassName='dft-editor'
                      editorState={editorState}
                      onEditorStateChange={onEditorStateChange}
                      toolbar={{
                        image: {
                          uploadCallback: uploadImageCallBack,
                          alt: { present: true, mandatory: true },
                        },
                      }}
                    />
                  </div>
                )}

                {/* NEW HTML CONTENT */}
                <FormGroup>
                  <FormControlLabel
                    label='Use HTML Content'
                    labelPlacement='right'
                    control={
                      <Switch
                        checked={useHtmlContent}
                        onChange={(e) => setUseHtmlContent(e.target.checked)}
                        name='useHtmlContent'
                        color='primary'
                      />
                    }
                  />
                </FormGroup>
                {useHtmlContent && (
                  <TextField
                    variant='outlined'
                    margin='dense'
                    multiline
                    rows='10'
                    fullWidth
                    id='htmlContent'
                    type='text'
                    label='HTML Content'
                    name='htmlContent'
                    className={classes.markupText}
                    autoComplete='htmlContent'
                    value={htmlContent}
                    onChange={(e) => setHtmlContent(e.target.value)}
                  />
                )}
              </DialogContent>
            </form>
          </Dialog>
          {/* Update book dialog */}
          <Dialog
            fullScreen
            open={editPopup}
            onClose={handleEditPopupClose}
            aria-labelledby='form-dialog-title'
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={SlideTransition}>
            <form onSubmit={handleUpdatePage}>
              <AppBar className={classes.appBar} color='primary'>
                <Toolbar>
                  <IconButton
                    edge='start'
                    color='inherit'
                    onClick={handleEditPopupClose}
                    aria-label='close'>
                    <CloseIcon />
                  </IconButton>
                  <Typography variant='h6' className={classes.title}>
                    Edit Page — "{updateTitle}"
                  </Typography>
                  <Button autoFocus color='inherit' type='submit'>
                    Update
                  </Button>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <div className={classes.spacer}></div>
                {updateLoading && (
                  <LinearProgress
                    color='primary'
                    className={classes.notifSpacing}
                  />
                )}
                {updateError && (
                  <Alert severity='error' className={classes.notifSpacing}>
                    {updateError}
                  </Alert>
                )}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    id='no'
                    type='text'
                    label='Sr. No.'
                    name='no'
                    autoComplete='no'
                    autoFocus
                    value={updateNo}
                    onChange={(e) => setUpdateNo(e.target.value)}
                    style={{ width: '15%' }}
                  />
                  <TextField
                    variant='outlined'
                    margin='dense'
                    required
                    fullWidth
                    id='updateTitle'
                    type='text'
                    label='Title'
                    name='title'
                    autoComplete='title'
                    value={updateTitle}
                    onChange={(e) => setUpdateTitle(e.target.value)}
                  />
                </div>
                {/* UPDATE - Multi-column Row */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  <FormControl
                    variant='outlined'
                    margin='dense'
                    style={{ width: '50%' }}
                    required
                    className={classes.formControl}>
                    <InputLabel id='updatePageBook'>Book</InputLabel>
                    <Select
                      labelId='updateBook'
                      id='book'
                      name='book'
                      value={updateBook}
                      onChange={(e) => setUpdateBook(e.target.value)}
                      label='Book *'>
                      {allBooks &&
                        allBooks.map((c) => (
                          <MenuItem key={c._id} value={c._id}>
                            {c.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant='outlined'
                    disabled={!updateBook}
                    margin='dense'
                    style={{ width: '50%' }}
                    required
                    className={classes.formControl}>
                    <InputLabel id='updatePageBook'>Chapter</InputLabel>
                    <Select
                      labelId='updatePageChapter'
                      id='chapter'
                      name='chapter'
                      value={updateChapter}
                      onChange={(e) => setUpdateChapter(e.target.value)}
                      label='Chapter *'>
                      {allBooks &&
                        updateBook &&
                        allBooks
                          .filter((b) => b._id === updateBook)[0]
                          .chapters.map((c, i) => (
                            <MenuItem key={i} value={c}>
                              {c}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </div>
                {!updateUseHtmlContent && (
                  <div
                    style={{
                      margin: '1rem 0',
                      border: '1px solid rgba(0, 0, 0, 0.23)',
                      borderRadius: '4px',
                    }}>
                    <Editor
                      wrapperClassName='dft-wrapper'
                      editorClassName='dft-editor'
                      editorState={editorState}
                      onEditorStateChange={onEditorStateChange}
                      toolbar={{
                        image: {
                          uploadCallback: uploadImageCallBack,
                          alt: { present: true, mandatory: true },
                        },
                      }}
                    />
                  </div>
                )}

                {/* NEW HTML CONTENT (UPDATE) */}
                <FormGroup>
                  <FormControlLabel
                    label='Use HTML Content'
                    labelPlacement='right'
                    control={
                      <Switch
                        checked={updateUseHtmlContent}
                        onChange={(e) =>
                          setUpdateUseHtmlContent(e.target.checked)
                        }
                        name='updateUseHtmlContent'
                        color='primary'
                      />
                    }
                  />
                </FormGroup>
                {updateUseHtmlContent && (
                  <TextField
                    variant='outlined'
                    margin='dense'
                    multiline
                    rows='10'
                    fullWidth
                    id='updateHtmlContent'
                    type='text'
                    label='HTML Content'
                    name='updateHtmlContent'
                    className={classes.markupText}
                    autoComplete='updateHtmlContent'
                    value={updateHtmlContent}
                    onChange={(e) => setUpdateHtmlContent(e.target.value)}
                  />
                )}
              </DialogContent>
            </form>
          </Dialog>
          {/* Delete page dialog */}
          <Dialog
            TransitionComponent={ZoomTransition}
            open={deletePopup}
            onClose={handleDeletePopupClose}>
            <DialogTitle id='delete-dialog-title'>Delete Book</DialogTitle>
            <DialogContent>
              {deleteLoading && (
                <LinearProgress
                  color='secondary'
                  className={classes.progressSpacing}
                />
              )}
              {deleteError && <Alert severity='error'>{deleteError}</Alert>}
              <DialogContentText id='delete-dialog-description'>
                Are you sure? Deleting book <strong>{toDeletePage.name}</strong>{' '}
                will remove this book and all it's pages permanently!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleDeletePopupClose}
                color='primary'
                autoFocus>
                Cancel
              </Button>
              <Button
                onClick={() => handleDeletePage(toDeletePage._id)}
                color='secondary'>
                Confirm Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </section>
    </>
  );
};

export default BooksScreen;
