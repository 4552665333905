import axios from 'axios';
import {
  VIDEO_LIST_REQUEST,
  VIDEO_LIST_SUCCESS,
  VIDEO_LIST_FAIL,
  VIDEO_DETAILS_REQUEST,
  VIDEO_DETAILS_SUCCESS,
  VIDEO_DETAILS_FAIL,
  VIDEO_CREATE_REQUEST,
  VIDEO_CREATE_SUCCESS,
  VIDEO_CREATE_FAIL,
  VIDEO_UPDATE_REQUEST,
  VIDEO_UPDATE_SUCCESS,
  VIDEO_UPDATE_FAIL,
  VIDEO_DELETE_REQUEST,
  VIDEO_DELETE_SUCCESS,
  VIDEO_DELETE_FAIL,
  VIDEO_LIST_BY_COURSE_REQUEST,
  VIDEO_LIST_BY_COURSE_SUCCESS,
  VIDEO_LIST_BY_COURSE_FAIL,
  VIDEO_NEXT_REQUEST,
  VIDEO_NEXT_SUCCESS,
  VIDEO_NEXT_FAIL,
  VIDEO_PREV_REQUEST,
  VIDEO_PREV_SUCCESS,
  VIDEO_PREV_FAIL,
} from '../constants/videoConstants';
import { snackActions } from '../utils/SnackBarUtils';

export const listVideos = () => async (dispatch, getState) => {
  try {
    dispatch({ type: VIDEO_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/api/videos', config);

    dispatch({ type: VIDEO_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: VIDEO_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listVideosByCourse = (courseId) => async (dispatch, getState) => {
  try {
    dispatch({ type: VIDEO_LIST_BY_COURSE_REQUEST });

    const {
      studentLogin: { studentInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${studentInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/videos/course/${courseId}`, config);

    dispatch({ type: VIDEO_LIST_BY_COURSE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: VIDEO_LIST_BY_COURSE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listVideoDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: VIDEO_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const {
      studentLogin: { studentInfo },
    } = getState();

    if (userInfo) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`/api/videos/${id}`, config);
      dispatch({ type: VIDEO_DETAILS_SUCCESS, payload: data });
    } else if (studentInfo) {
      const config = {
        headers: {
          Authorization: `Bearer ${studentInfo.token}`,
        },
      };
      const { data } = await axios.get(`/api/videos/${id}`, config);
      dispatch({ type: VIDEO_DETAILS_SUCCESS, payload: data });
    }
  } catch (err) {
    dispatch({
      type: VIDEO_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const createVideo = (video) => async (dispatch, getState) => {
  try {
    dispatch({ type: VIDEO_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/videos`, video, config);

    dispatch({ type: VIDEO_CREATE_SUCCESS, payload: data });
    snackActions.success(`${data.title} video created.`);
  } catch (err) {
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: VIDEO_CREATE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

export const updateVideo = (video) => async (dispatch, getState) => {
  try {
    dispatch({ type: VIDEO_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/videos/${video._id}`, video, config);

    dispatch({ type: VIDEO_UPDATE_SUCCESS, payload: data });
    snackActions.success(`${data.title} video updated.`);
  } catch (err) {
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: VIDEO_UPDATE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

export const deleteVideo = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: VIDEO_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/videos/${id}`, config);
    dispatch({ type: VIDEO_DELETE_SUCCESS });
    snackActions.success(`Video deleted.`);
  } catch (err) {
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: VIDEO_DELETE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

export const getNextVideoDetails =
  (courseId, videoNo) => async (dispatch, getState) => {
    try {
      dispatch({ type: VIDEO_NEXT_REQUEST });

      const {
        studentLogin: { studentInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${studentInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/videos/${courseId}/${videoNo}`,
        config
      );
      dispatch({ type: VIDEO_NEXT_SUCCESS, payload: data });
    } catch (err) {
      const errMessage =
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message;
      dispatch({
        type: VIDEO_NEXT_FAIL,
        payload: errMessage,
      });
    }
  };

export const getPrevVideoDetails =
  (courseId, videoNo) => async (dispatch, getState) => {
    try {
      dispatch({ type: VIDEO_PREV_REQUEST });

      const {
        studentLogin: { studentInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${studentInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/videos/${courseId}/${videoNo}`,
        config
      );
      dispatch({ type: VIDEO_PREV_SUCCESS, payload: data });
    } catch (err) {
      const errMessage =
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message;
      dispatch({
        type: VIDEO_PREV_FAIL,
        payload: errMessage,
      });
    }
  };
