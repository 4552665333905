/**
 * @fileOverview   Actions to perform CRUD operations on the Feedbacks Schema
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import axios from 'axios';
import {
  FEEDBACK_LIST_REQUEST,
  FEEDBACK_LIST_SUCCESS,
  FEEDBACK_LIST_FAIL,
  FEEDBACK_DETAILS_REQUEST,
  FEEDBACK_DETAILS_SUCCESS,
  FEEDBACK_DETAILS_FAIL,
  FEEDBACK_CREATE_REQUEST,
  FEEDBACK_CREATE_SUCCESS,
  FEEDBACK_CREATE_FAIL,
  FEEDBACK_DELETE_REQUEST,
  FEEDBACK_DELETE_SUCCESS,
  FEEDBACK_DELETE_FAIL,
} from '../constants/feedbackConstants';
import { snackActions } from '../utils/SnackBarUtils';

/**
 * Returns all the feedbacks in the database.
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const listFeedbacks = () => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: FEEDBACK_LIST_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Array<Object>} All feedbacks in the database */
    const { data } = await axios.get('/api/feedbacks', config);

    // Dispatch success object to the reducer.
    // Payload is the list of all feedbacks
    dispatch({ type: FEEDBACK_LIST_SUCCESS, payload: data });
  } catch (err) {
    // Dispatch an error object to the reducer.
    dispatch({
      type: FEEDBACK_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

/**
 * Get a feedback from the database by id
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const listFeedbackDetails = (id) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: FEEDBACK_DETAILS_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();
    const {
      studentLogin: { studentInfo },
    } = getState();

    if (userInfo) {
      /** @type {Object} Axios configurations */
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      /** @type {Object} The resulting feedback from the database */
      const { data } = await axios.get(`/api/feedbacks/${id}`, config);
      // Dispatch success object to the reducer.
      // Payload is the feedback object from the database
      dispatch({ type: FEEDBACK_DETAILS_SUCCESS, payload: data });
    } else if (studentInfo) {
      /** @type {Object} Axios configurations */
      const config = {
        headers: {
          Authorization: `Bearer ${studentInfo.token}`,
        },
      };
      /** @type {Object} The resulting feedbacks from the database */
      const { data } = await axios.get(`/api/feedbacks/${id}/student`, config);
      // Dispatch success object to the reducer.
      // Payload is the feedback object from the database
      dispatch({ type: FEEDBACK_DETAILS_SUCCESS, payload: data });
    }
  } catch (err) {
    // Dispatch an error object to the reducer.
    dispatch({
      type: FEEDBACK_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

/**
 * Action to create a new feedback. (Student)
 * @param {Object} feedback New feedback object that is to be created
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const createFeedback = (feedback) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: FEEDBACK_CREATE_REQUEST });

    /**
     * Extracting logged-in student state from the Redux store
     * @type {Object}
     */
    const {
      studentLogin: { studentInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${studentInfo.token}`,
      },
    };

    /** @type {Object} Newly create feedback object */
    const { data } = await axios.post(`/api/feedbacks`, feedback, config);

    // Dispatch success object to the reducer.
    // Payload is the newly created feedback object returned from the backend
    dispatch({ type: FEEDBACK_CREATE_SUCCESS, payload: data });
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: FEEDBACK_CREATE_FAIL,
      payload: errMessage,
    });
  }
};

/**
 * Action to delete an existing feedback (by _id) (admins)
 * @param {string} id Mongoose _id of the feedback that is to be deleted
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const deleteFeedback = (id) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: FEEDBACK_DELETE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // Axios delete request to backend.
    await axios.delete(`/api/feedbacks/${id}`, config);
    // Dispatch success object to the reducer.
    dispatch({ type: FEEDBACK_DELETE_SUCCESS });
    snackActions.success(`Feedback deleted.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: FEEDBACK_DELETE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};
