import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  userLoginReducer,
  userListReducer,
  userCreateReducer,
  userUpdateReducer,
  userDeleteReducer,
  userAuthCheckReducer,
  getStatReducer,
  pullMachinesReducer,
} from './reducers/userReducers';
import {
  coursesListReducer,
  courseCreateReducer,
  courseUpdateReducer,
  courseDeleteReducer,
} from './reducers/courseReducer';
import {
  batchesListReducer,
  batchCreateReducer,
  batchUpdateReducer,
  batchDeleteReducer,
  batchStatusReducer,
} from './reducers/batchReducer';
import {
  labsListReducer,
  labDetailsReducer,
  labCreateReducer,
  labUpdateReducer,
  labDeleteReducer,
  labsListByCourseReducer,
  labIouReducer,
  labVMStateReducer,
  eveNgNodesReducer,
  eveNgNodesStopReducer,
  eveNgNodesWipeReducer,
} from './reducers/labReducers';
import {
  studentLoginReducer,
  studentCreateReducer,
  studentListReducer,
  studentUpdateReducer,
  studentDeleteReducer,
  studentsBulkCreateReducer,
  studentStatusReducer,
  studentAuthCheckReducer,
  studentProfileReducer,
  studentUpdateProfileReducer,
} from './reducers/studentReducer';
import { headerFooterDisplayReducer } from './reducers/interfaceReducer';
import {
  booksListReducer,
  bookCreateReducer,
  bookDetailsReducer,
  bookUpdateReducer,
  bookDeleteReducer,
  bookDetailsByCourseReducer,
} from './reducers/bookReducer';
import {
  pagesListReducer,
  pageDetailsReducer,
  pageCreateReducer,
  pageUpdateReducer,
  pageDeleteReducer,
  prevPageReducer,
  nextPageReducer,
} from './reducers/pageReducer';
import {
  feedbackCreateReducer,
  feedbackDeleteReducer,
  feedbackDetailsReducer,
  feedbackListReducer,
} from './reducers/feedbackReducer';
import {
  registrationCreateReducer,
  registrationDeleteReducer,
  registrationDetailsReducer,
  registrationUpdateReducer,
  registrationsListReducer,
  registrationsFilterReducer,
} from './reducers/registrationReducer';
import {
  assessmentListReducer,
  assessmentCreateReducer,
  assessmentDetailsReducer,
  assessmentUpdateReducer,
  assessmentDeleteReducer,
  assessmentQuestionReducer,
  assessmentsListByCourseReducer,
} from './reducers/assessmentReducer';
import {
  examListReducer,
  examCreateReducer,
  examDetailsReducer,
  examUpdateReducer,
  examDeleteReducer,
  examQuestionReducer,
  examsListByCourseReducer,
  examSubmitScoreReducer,
  examGetStudentPointsReducer,
} from './reducers/examReducer';
import {
  videoListReducer,
  videoDetailsReducer,
  videoCreateReducer,
  videoUpdateReducer,
  videoDeleteReducer,
  videoListByCourseReducer,
  prevVideoReducer,
  nextVideoReducer,
  videoLanguageSelectorReducer,
} from './reducers/videoReducer';

const reducer = combineReducers({
  labsList: labsListReducer,
  labsListByCourse: labsListByCourseReducer,
  labDetails: labDetailsReducer,
  labCreate: labCreateReducer,
  labUpdate: labUpdateReducer,
  labDelete: labDeleteReducer,
  labIou: labIouReducer,
  labVMState: labVMStateReducer,
  eveNgNodes: eveNgNodesReducer,
  eveNgNodesStop: eveNgNodesStopReducer,
  eveNgNodesWipe: eveNgNodesWipeReducer,
  userLogin: userLoginReducer,
  userList: userListReducer,
  userCreate: userCreateReducer,
  userUpdate: userUpdateReducer,
  userDelete: userDeleteReducer,
  userAuthCheck: userAuthCheckReducer,
  pullMachines: pullMachinesReducer,
  getStat: getStatReducer,
  studentLogin: studentLoginReducer,
  studentList: studentListReducer,
  studentCreate: studentCreateReducer,
  studentUpdate: studentUpdateReducer,
  studentDelete: studentDeleteReducer,
  studentsBulkCreate: studentsBulkCreateReducer,
  studentStatus: studentStatusReducer,
  studentAuthCheck: studentAuthCheckReducer,
  studentProfile: studentProfileReducer,
  studentUdpateProfile: studentUpdateProfileReducer,
  coursesList: coursesListReducer,
  courseCreate: courseCreateReducer,
  courseUpdate: courseUpdateReducer,
  courseDelete: courseDeleteReducer,
  batchesList: batchesListReducer,
  batchCreate: batchCreateReducer,
  batchUpdate: batchUpdateReducer,
  batchDelete: batchDeleteReducer,
  batchStatus: batchStatusReducer,
  headerFooterDisplay: headerFooterDisplayReducer,
  booksList: booksListReducer,
  bookDetails: bookDetailsReducer,
  bookCreate: bookCreateReducer,
  bookUpdate: bookUpdateReducer,
  bookDelete: bookDeleteReducer,
  bookDetailsByCourse: bookDetailsByCourseReducer,
  pagesList: pagesListReducer,
  pageDetails: pageDetailsReducer,
  pageCreate: pageCreateReducer,
  pageUpdate: pageUpdateReducer,
  pageDelete: pageDeleteReducer,
  prevPage: prevPageReducer,
  nextPage: nextPageReducer,
  feedbacksList: feedbackListReducer,
  feedbackDetails: feedbackDetailsReducer,
  feedbackCreate: feedbackCreateReducer,
  feedbackDelete: feedbackDeleteReducer,
  registrationsList: registrationsListReducer,
  registrationDetails: registrationDetailsReducer,
  registrationCreate: registrationCreateReducer,
  registrationUpdate: registrationUpdateReducer,
  registrationDelete: registrationDeleteReducer,
  registrationFilter: registrationsFilterReducer,
  assessmentsList: assessmentListReducer,
  assessmentDetails: assessmentDetailsReducer,
  assessmentCreate: assessmentCreateReducer,
  assessmentUpdate: assessmentUpdateReducer,
  assessmentDelete: assessmentDeleteReducer,
  assessmentQuestion: assessmentQuestionReducer,
  assessmentsByCourse: assessmentsListByCourseReducer,
  examsList: examListReducer,
  examDetails: examDetailsReducer,
  examCreate: examCreateReducer,
  examUpdate: examUpdateReducer,
  examDelete: examDeleteReducer,
  examQuestion: examQuestionReducer,
  examsByCourse: examsListByCourseReducer,
  examSubmitScore: examSubmitScoreReducer,
  examGetStudentPoints: examGetStudentPointsReducer,
  videosList: videoListReducer,
  videoDetails: videoDetailsReducer,
  videoCreate: videoCreateReducer,
  videoUpdate: videoUpdateReducer,
  videoDelete: videoDeleteReducer,
  videosByCourse: videoListByCourseReducer,
  prevVideo: prevVideoReducer,
  nextVideo: nextVideoReducer,
  videoLanguage: videoLanguageSelectorReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;
const studentInfoFromStorage = localStorage.getItem('studentInfo')
  ? JSON.parse(localStorage.getItem('studentInfo'))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
  studentLogin: { studentInfo: studentInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
