/**
 * @fileOverview   Reducer for the Courses Schema to perform CRUD operations
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import {
  COURSE_LIST_REQUEST,
  COURSE_LIST_SUCCESS,
  COURSE_LIST_FAIL,
  COURSE_CREATE_REQUEST,
  COURSE_CREATE_SUCCESS,
  COURSE_CREATE_FAIL,
  COURSE_CREATE_RESET,
  COURSE_UPDATE_REQUEST,
  COURSE_UPDATE_SUCCESS,
  COURSE_UPDATE_FAIL,
  COURSE_UPDATE_RESET,
  COURSE_DELETE_REQUEST,
  COURSE_DELETE_SUCCESS,
  COURSE_DELETE_FAIL,
} from '../constants/courseConstants';

/**
 * Get the list of all courses
 *
 * @param {Object} state Initial state of coursesList in the store.
 * @param {Object} action Object with payload of all courses.
 * @return {Object} New state object
 */
export const coursesListReducer = (state = { courses: [] }, action) => {
  switch (action.type) {
    case COURSE_LIST_REQUEST:
      return { loading: true, courses: [] };
    case COURSE_LIST_SUCCESS:
      return { loading: false, courses: action.payload };
    case COURSE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

/**
 * Create a new course
 *
 * @param {Object} state Initial state of course in the store.
 * @param {Object} action Object with payload of the newly created course.
 * @return {Object} New state object
 */
export const courseCreateReducer = (state = { course: {} }, action) => {
  switch (action.type) {
    case COURSE_CREATE_REQUEST:
      return { loading: true, ...state };
    case COURSE_CREATE_SUCCESS:
      return { loading: false, success: true, course: action.payload };
    case COURSE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

/**
 * Update an existing course
 *
 * @param {Object} state Initial state of course in the store.
 * @param {Object} action Object with payload of the newly updated course.
 * @return {Object} New state object
 */
export const courseUpdateReducer = (state = { course: {} }, action) => {
  switch (action.type) {
    case COURSE_UPDATE_REQUEST:
      return { loading: true, ...state };
    case COURSE_UPDATE_SUCCESS:
      return { loading: false, success: true, course: action.payload };
    case COURSE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case COURSE_UPDATE_RESET:
      return { course: {} };
    default:
      return state;
  }
};

/**
 * Delete an existing course
 *
 * @param {Object} state Initial state of course in the store.
 * @param {Object} action (optional) Object with payload of an error.
 * @return {Object} New state object
 */
export const courseDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_DELETE_REQUEST:
      return { loading: true };
    case COURSE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case COURSE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
