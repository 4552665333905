import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  Box,
  Paper,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { blue, grey } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
import LabsHeader from '../components/LabsHeader';
import { listBookDetails } from '../actions/bookActions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { countryList } from '../data/countries';
import { timezones } from '../data/timezones';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[200],
    padding: '50px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: 'calc(100vh - 206px)',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  notifSpacing: {
    marginBottom: '10px',
  },
  spacer: {
    height: '30px',
  },
  blue: {
    color: theme.palette.getContrastText(blue['A400']),
    backgroundColor: blue['A400'],
  },
}));

/**
 * Component for showing/adding/editing courses
 */
const BulkGenerateScreen = ({ history, match }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Form and Local State
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [topic, setTopic] = useState('');
  const [message, setMessage] = useState('');
  const [country, setCountry] = useState(''); // new
  const [timezone, setTimezone] = useState(''); // new
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeSlot, setTimeSlot] = useState('');

  const [messageSuccess, setMessageSuccess] = useState(null);
  const [messageError, setMessageError] = useState(null);
  const [messageLoading, setMessageLoading] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  // Redux State Getters
  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo } = studentLogin;

  const bookDetails = useSelector((state) => state.bookDetails);
  const { loading, error, book } = bookDetails;

  // Lifecycle events
  useEffect(() => {
    if (studentInfo) {
      dispatch(listBookDetails(studentInfo.course.books[0]));
    } else {
      history.push('/login');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [dispatch, history, studentInfo]);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setMessageLoading(true);
      const { data } = await axios.post(
        '/api/students/trainerondemand',
        {
          fullName,
          email,
          phone,
          topic,
          message,
          selectedDate,
          timeSlot,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      setFullName('');
      setEmail('');
      setPhone('');
      setTopic('');
      setMessage('');
      setSelectedDate(new Date());
      setTimeSlot('');
      setMessageLoading(false);

      setMessageSuccess('Your request has been submitted successfully.');
    } catch (err) {
      setMessageError('Something went wrong. Please try again.');
    }
  };

  return (
    <>
      <LabsHeader
        pageTitle="Trainer On-Demand"
        backBtn={{ action: history.goBack }}
      />
      <section className={classes.section}>
        <Container>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Box component={Paper} padding="30px" style={{ width: '60%' }}>
              <Typography
                component="h2"
                variant="h6"
                style={{ marginBottom: '20px' }}>
                Schedule a private training session
              </Typography>
              <form onSubmit={(e) => onSubmit(e)}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '10px',
                  }}>
                  {/* Full Name */}
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="fullname"
                    type="text"
                    label="Full Name"
                    name="fullname"
                    autoComplete="fullname"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                  {/* Email */}
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="email"
                    type="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '10px',
                  }}>
                  {/* Phone */}
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="phone"
                    type="tel"
                    label="Phone No."
                    name="phone"
                    autoComplete="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  {/* Select Topic */}
                  <FormControl
                    variant="outlined"
                    style={{ width: '100%' }}
                    margin="dense"
                    className={classes.formControl}>
                    <InputLabel id="chapterTopic">Select Topic</InputLabel>
                    <Select
                      labelId="chapterTopic"
                      id="selectChapter"
                      name="selectChapter"
                      value={topic}
                      onChange={(e) => setTopic(e.target.value)}
                      label="selectChapter">
                      {book &&
                        book.chapters.map((chap) => (
                          <MenuItem value={chap} key={chap}>
                            {chap}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                {/* Message */}
                <TextField
                  variant="outlined"
                  margin="dense"
                  multiline
                  rows="10"
                  fullWidth
                  id="message"
                  type="text"
                  label="Enter your detailed query"
                  name="message"
                  className={classes.markupText}
                  autoComplete="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                {/* Country and Timezone */}
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'flex-end',
                  }}>
                  {/* ==== Select Country */}
                  <FormControl
                    variant="outlined"
                    style={{ width: '100%' }}
                    margin="dense"
                    className={classes.formControl}>
                    <InputLabel id="timeSlot">Select Country</InputLabel>
                    <Select
                      labelId="country"
                      id="selectCountry"
                      name="selectCountry"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      label="selectCountry">
                      {countryList.map((c) => (
                        <MenuItem key={c} value={c}>
                          {c}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* ==== Select Timezone */}
                  <FormControl
                    variant="outlined"
                    style={{ width: '100%' }}
                    margin="dense"
                    className={classes.formControl}>
                    <InputLabel id="timeSlot">Select Timezone</InputLabel>
                    <Select
                      labelId="timezone"
                      id="selectTimezone"
                      name="selectTimezone"
                      value={timezone}
                      onChange={(e) => setTimezone(e.target.value)}
                      label="selectTimezone">
                      {timezones.map((c) => (
                        <MenuItem key={c} value={c}>
                          {c}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {/* Date and Slot */}
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'flex-end',
                  }}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Select Date"
                    fullWidth
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                  {/* Select time */}
                  <FormControl
                    variant="outlined"
                    style={{ width: '100%' }}
                    margin="dense"
                    className={classes.formControl}>
                    <InputLabel id="timeSlot">Select Time Slot</InputLabel>
                    <Select
                      labelId="timeSlot"
                      id="selectTimeSlot"
                      name="selectTimeSlot"
                      value={timeSlot}
                      onChange={(e) => setTimeSlot(e.target.value)}
                      label="selectTimeSlot">
                      <MenuItem value="Not Selected">Please Select</MenuItem>
                      <MenuItem value="10 AM — 01 PM">10 AM — 12 PM</MenuItem>
                      <MenuItem value="01 AM — 04 PM">12 PM — 02 PM</MenuItem>
                      <MenuItem value="04 PM — 08 PM">02 PM — 04 PM</MenuItem>
                      <MenuItem value="08 PM — 12 AM">04 PM — 06 PM</MenuItem>
                      <MenuItem value="08 PM — 12 AM">06 PM — 08 PM</MenuItem>
                      <MenuItem value="08 PM — 12 AM">08 PM — 10 PM</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '30px',
                  }}>
                  {messageLoading ? (
                    <CircularProgress color="primary" />
                  ) : (
                    <Button type="submit" color="primary" variant="contained">
                      Schedule Session
                    </Button>
                  )}
                </div>
              </form>
              {messageSuccess && (
                <div style={{ marginTop: '20px' }}>
                  <Alert severity="success">{messageSuccess}</Alert>
                </div>
              )}
              {messageError && (
                <div style={{ marginTop: '20px' }}>
                  <Alert severity="error">{messageError}</Alert>
                </div>
              )}
            </Box>
          </div>
        </Container>
      </section>
    </>
  );
};

export default BulkGenerateScreen;
