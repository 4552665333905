import { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const TablePaginationActions = ({ count, page, rowsPerPage, onChangePage }) => {
  const classes = useStyles1();
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
  labels: {
    fontSize: '1rem',
  },
  font: {
    fontSize: '1.05rem',
    // textTransform: 'capitalize',
  },
  fontHighlight: {
    fontSize: '1rem',
    fontWeight: '500',
  },
});

const InfoTable = ({ columns, rows, label, dataType, sorting }) => {
  const classes = useStyles2();
  const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(
  //   rows.length > 20 ? 20 : rows.length
  // );
  const [rowsPerPage, setRowsPerPage] = useState(20);

  // rows = rows.sort((a, b) => (+a.no < +b.no ? -1 : 1));
  rows =
    rows.sort(sorting) ||
    rows.sort((a, b) => (+a.index < +b.index ? -1 : +a.index > +b.index ? 1 : 0));

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper} className="prhdn">
      <Table className={classes.table} aria-label="pagination table">
        <TableHead>
          <TableRow>
            {columns &&
              columns.map((column, index) =>
                column === 'actions' ? (
                  <TableCell
                    align="right"
                    key={index}
                    className={classes.labels}
                  ></TableCell>
                ) : (
                  <TableCell key={index} className={classes.labels}>
                    {column[0].toUpperCase() + column.slice(1)}
                  </TableCell>
                )
              )}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => (
            <TableRow hover key={row._id} style={{ transition: 'all 0.15s' }}>
              {Object.keys(row)
                .filter((key) => key !== '_id')
                .filter((key) => key !== 'index')
                .map((key, index) =>
                  index === 0 ? (
                    <TableCell
                      className={classes.font}
                      key={index}
                      style={{ fontWeight: '500' }}
                    >
                      {label} {row[key]}
                    </TableCell>
                  ) : key === 'actions' ? (
                    <TableCell
                      align="right"
                      className={classes.font}
                      key={index}
                    >
                      {row[key].map((btn, index) => {
                        return btn.kind === 'nav' ? (
                          <Button
                            disableElevation
                            component={RouterLink}
                            to={btn.link}
                            key={index}
                            variant="contained"
                            color={btn.type || 'primary'}
                            style={{ marginLeft: '10px' }}
                          >
                            {btn.label}
                          </Button>
                        ) : (
                          <Button
                            disableElevation
                            key={index}
                            variant="contained"
                            onClick={btn.action}
                            color={btn.type || 'primary'}
                            style={{ marginLeft: '10px' }}
                          >
                            {btn.label}
                          </Button>
                        );
                      })}
                    </TableCell>
                  ) : (
                    <TableCell key={index} className={classes.font}>
                      {row[key]}
                    </TableCell>
                  )
                )}
            </TableRow>
          ))}

          {/* {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )} */}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[
                rows.length,
                20,
                40,
                { label: 'All', value: -1 },
              ]}
              colSpan={columns.length}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default InfoTable;
