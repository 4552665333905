import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  CircularProgress,
  Switch,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { grey } from '@material-ui/core/colors';
import LabsHeader from '../components/LabsHeader';
import InfoTable from '../components/InfoTable';
import {
  listVideosByCourse,
  listVideosByCourse as listVideosByCourseAction,
} from '../actions/videoActions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';
import {
  VIDEO_LANGUAGE_ENGLISH,
  VIDEO_LANGUAGE_HINDI,
} from '../constants/videoConstants';

const useStyles = makeStyles((theme) => ({
  section: {
    padding: '50px 20px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: grey[200],
    minHeight: 'calc(100vh - 202px)',
    userSelect: 'none',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  appBar: {
    position: 'relative',
  },
  chip: {
    margin: 2,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  notifSpacing: {
    marginBottom: '10px',
  },
  spacer: {
    height: '20px',
  },
}));

const StudentVideoListScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Redux State Getters
  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo } = studentLogin;
  const videosByCourse = useSelector((state) => state.videosByCourse);
  const { loading, error, videos } = videosByCourse;

  // Lifecycle events
  useEffect(() => {
    if (studentInfo) {
      dispatch(listVideosByCourse(studentInfo.course._id));
    } else {
      history.push('/');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [dispatch, history, studentInfo]);

  useEffect(() => {
    document.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
    return () =>
      document.removeEventListener('contextmenu', (event) => {
        event.preventDefault();
      });
  }, []);

  return (
    <>
      <LabsHeader
        pageTitle={`${studentInfo && studentInfo.course.name} Videos`}
        vmInfo={false}
        backBtn={{ action: () => history.push('/dashboard') }}
      />
      <section className={classes.section}>
        {videos && videos.length > 0 ? (
          <Container>
            {studentInfo && loading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: 'calc(100vh - 255px)',
                }}>
                <CircularProgress />
              </div>
            ) : error ? (
              <Alert severity="error">{error}</Alert>
            ) : (
              <InfoTable
                columns={['no.', 'title', 'actions']}
                rows={
                  videos &&
                  videos.map((video) => ({
                    _id: video._id,
                    no: video.no,
                    title: (
                      <span style={{ fontWeight: '500' }}>{video.title}</span>
                    ),
                    actions: [
                      {
                        kind: 'nav',
                        label: 'Watch Video',
                        type: 'primary',
                        link: `/videos/${studentInfo.course._id}/${video._id}`,
                      },
                    ],
                  }))
                }
                sorting={(a, b) => {
                  return +a.no < +b.no ? -1 : +a.no > +b.no ? 1 : 0;
                }}
              />
            )}
          </Container>
        ) : (
          <p style={{ textAlign: 'center' }}>No Videos Available</p>
        )}
      </section>
    </>
  );
};

export default StudentVideoListScreen;
