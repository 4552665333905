/**
 * @fileOverview   Action functions to perform CRUD operations on the Users Schema
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import axios from 'axios';
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_AUTH_CHECK_REQUEST,
  USER_AUTH_CHECK_SUCCESS,
  USER_AUTH_CHECK_FAIL,
  USER_AUTH_CHECK_RESET,
  GET_STAT_REQUEST,
  GET_STAT_SUCCESS,
  GET_STAT_FAIL,
  GET_STAT_RESET,
  PULL_MACHINES_REQUEST,
  PULL_MACHINES_SUCCESS,
  PULL_MACHINES_FAIL,
} from '../constants/userConstants';
import { snackActions } from '../utils/SnackBarUtils';

/**
 * Action that attempts user login (sends username & password to the backend)
 * @returns {void} Dispatches action to the reducers, returns nothing
 * @param {string} email User/Trainer's email address
 * @param {string} password User/Trainer's password
 */
export const login = (email, password) => async (dispatch) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    /** @type {Object} User object from the database */
    const { data } = await axios.post(
      '/api/users/login',
      { email, password },
      config
    );

    // Dispatch success object to the reducer.
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    // Save user object to local storage
    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (err) {
    dispatch({
      // Dispatch an error object to the reducer.
      type: USER_LOGIN_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

/**
 * Logs the user out. Clears the redux state.
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const logout = () => (dispatch) => {
  // Remove user object from local storage
  localStorage.removeItem('userInfo');
  // Execute the logout reducer function
  dispatch({ type: USER_LOGOUT });
  // Execute the user reset reducer, which clear the state
  dispatch({ type: USER_AUTH_CHECK_RESET });
  dispatch({ type: USER_LIST_RESET });
  dispatch({ type: GET_STAT_RESET });
  document.location.href = '/admin/login';
};

/**
 * Returns all the users in the database. (ADMIN only)
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const listUsers = () => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({
      type: USER_LIST_REQUEST,
    });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Array<Object>} All users in the database */
    const { data } = await axios.get(`/api/users`, config);

    // Dispatch success object to the reducer.
    // Payload is the list of all users
    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // Dispatch an error object to the reducer.
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

/**
 * Action to create a new user. (ADMIN only)
 * @param {Object} user New user object that is to be created
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const createUser = (user) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: USER_CREATE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Object} Newly create user object */
    const { data } = await axios.post(`/api/users`, user, config);

    // Dispatch success object to the reducer.
    // Payload is the newly created user object returned from the backend
    dispatch({ type: USER_CREATE_SUCCESS, payload: data });
    snackActions.success(`User ${data.name} created.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: USER_CREATE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Action to update an existing user (by _id). (ADMIN only).
 * @param {Object} user Updated user object to be sent to the backend
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const updateUser = (user) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: USER_UPDATE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Object} Newly updated user object */
    const { data } = await axios.put(`/api/users/${user._id}`, user, config);

    // Dispatch success object to the reducer.
    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });
    snackActions.success(`User ${data.name} updated.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: USER_UPDATE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Action to delete an existing user (by _id).
 * @param {string} id Mongoose _id of the user that is to be deleted
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: USER_DELETE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // Axios delete request to backend.
    await axios.delete(`/api/users/${id}`, config);
    // Dispatch success object to the reducer.
    // Payload is the newly updated user object returned from the backend
    dispatch({ type: USER_DELETE_SUCCESS });
    snackActions.success(`User deleted.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: USER_DELETE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Action to check auth/token state of user on page load
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const userAuthCheck = () => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: USER_AUTH_CHECK_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    // Axios put request to backend.
    await axios.get(`/api/users/auth`, config);
    // Dispatch success object to the reducer.
    dispatch({ type: USER_AUTH_CHECK_SUCCESS });
  } catch (err) {
    // Dispatch an error object to the reducer.
    dispatch({
      type: USER_AUTH_CHECK_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

/**
 * Get statistics data
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const getStatistics = () => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: GET_STAT_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // Axios get request to backend.
    const { data } = await axios.get(`/api/users/stats`, config);
    // Dispatch success object to the reducer.
    // Payload is the newly updated user object returned from the backend
    dispatch({ type: GET_STAT_SUCCESS, payload: data });
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: GET_STAT_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

export const pullMachines = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PULL_MACHINES_REQUEST });

    const { data } = await axios.get(
      `https://labs.rstforum.net:9096/updatevmlist`
    );

    dispatch({ type: PULL_MACHINES_SUCCESS, payload: data });

    snackActions.success(data.message);
  } catch (err) {
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;

    dispatch({
      type: PULL_MACHINES_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};
