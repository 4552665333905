import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  CircularProgress,
  Chip,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { grey } from '@material-ui/core/colors';
import LabsHeader from '../components/LabsHeader';
import InfoTable from '../components/InfoTable';
import { listExamsByCourse as listExamsByCourseAction } from '../actions/examActions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';

const useStyles = makeStyles((theme) => ({
  section: {
    padding: '50px 20px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: grey[200],
    minHeight: 'calc(100vh - 202px)',
    userSelect: 'none',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  appBar: {
    position: 'relative',
  },
  chip: {
    margin: 2,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  notifSpacing: {
    marginBottom: '10px',
  },
  spacer: {
    height: '20px',
  },
}));

const StudentExamScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  /* --------------------------- Redux State Getters -------------------------- */
  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo } = studentLogin;
  const examsByCourse = useSelector((state) => state.examsByCourse);
  const { loading, error, exams } = examsByCourse;

  /* ---------------------------- Lifecycle events ---------------------------- */
  useEffect(() => {
    if (studentInfo) {
      dispatch(listExamsByCourseAction());
    } else {
      history.push('/');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [dispatch, history, studentInfo]);

  useEffect(() => {
    document.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
    return () =>
      document.removeEventListener('contextmenu', (event) => {
        event.preventDefault();
      });
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                     JSX                                    */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <LabsHeader
        pageTitle={`${studentInfo && studentInfo.course.name} Exams`}
        vmInfo={false}
        backBtn={{ action: () => history.push('/dashboard') }}
        tertiaryBtn={{
          label: 'View Past Results',
          link: '/exam-results',
          style: 'contained',
        }}
      />
      <section className={classes.section}>
        <Container>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 255px)',
              }}
            >
              <CircularProgress />
            </div>
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : (
            <InfoTable
              columns={['no.', 'title', 'course', 'actions']}
              rows={
                exams &&
                exams.map((exam) => ({
                  _id: exam._id,
                  no: `Exam ${exam.no}`,
                  title: (
                    <span style={{ fontWeight: '500' }}>{exam.title}</span>
                  ),
                  course: <Chip label={exam.course.name} variant="outlined" />,
                  actions: [
                    {
                      kind: 'nav',
                      label: 'Start Exam',
                      type: 'primary',
                      link: `/exams/${exam._id}`,
                    },
                  ],
                }))
              }
            />
          )}
        </Container>
      </section>
    </>
  );
};

export default StudentExamScreen;
