/**
 * @fileOverview   Actions to perform CRUD operations on the Labs Schema
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import axios from 'axios';
import {
  LAB_LIST_REQUEST,
  LAB_LIST_SUCCESS,
  LAB_LIST_FAIL,
  LAB_DETAILS_REQUEST,
  LAB_DETAILS_SUCCESS,
  LAB_DETAILS_FAIL,
  LAB_CREATE_REQUEST,
  LAB_CREATE_SUCCESS,
  LAB_CREATE_FAIL,
  LAB_UPDATE_REQUEST,
  LAB_UPDATE_SUCCESS,
  LAB_UPDATE_FAIL,
  LAB_DELETE_REQUEST,
  LAB_DELETE_SUCCESS,
  LAB_DELETE_FAIL,
  LABS_BY_COURSE_REQUEST,
  LABS_BY_COURSE_SUCCESS,
  LABS_BY_COURSE_FAIL,
  LAB_START_IOU_REQUEST,
  LAB_START_IOU_SUCCESS,
  LAB_START_IOU_FAIL,
  LAB_STOP_IOU_REQUEST,
  LAB_STOP_IOU_SUCCESS,
  LAB_STOP_IOU_FAIL,
  LAB_RESET_IOU_REQUEST,
  LAB_RESET_IOU_SUCCESS,
  LAB_RESET_IOU_FAIL,
  LAB_WIPE_IOU_REQUEST,
  LAB_WIPE_IOU_SUCCESS,
  LAB_WIPE_IOU_FAIL,
  LAB_POWER_ON_REQUEST,
  LAB_POWER_ON_SUCCESS,
  LAB_POWER_ON_FAIL,
  LAB_POWER_OFF_REQUEST,
  LAB_POWER_OFF_SUCCESS,
  LAB_POWER_OFF_FAIL,
  LAB_POWER_CHECK_REQUEST,
  LAB_POWER_CHECK_SUCCESS,
  LAB_POWER_CHECK_FAIL,
  LAB_START_NODES_REQUEST,
  LAB_START_NODES_SUCCESS,
  LAB_START_NODES_FAIL,
  LAB_STOP_NODES_REQUEST,
  LAB_STOP_NODES_SUCCESS,
  LAB_STOP_NODES_FAIL,
  LAB_WIPE_NODES_REQUEST,
  LAB_WIPE_NODES_SUCCESS,
  LAB_WIPE_NODES_FAIL
} from '../constants/labConstants';
import { snackActions } from '../utils/SnackBarUtils';
import { setWindowAddrForTelnetRequests } from '../utils/imageMapper';

/**
 * Returns all the labs in the database.
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const listLabs = () => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: LAB_LIST_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Array<Object>} All labs in the database */
    const { data } = await axios.get('/api/labs', config);

    // Dispatch success object to the reducer.
    // Payload is the list of all labs
    dispatch({ type: LAB_LIST_SUCCESS, payload: data });
  } catch (err) {
    // Dispatch an error object to the reducer.
    dispatch({
      type: LAB_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

/**
 * Get a lab from the database by id
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const listLabDetails = (id) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: LAB_DETAILS_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();
    const {
      studentLogin: { studentInfo },
    } = getState();

    if (userInfo) {
      /** @type {Object} Axios configurations */
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      /** @type {Object} The resulting lab from the database */
      const { data } = await axios.get(`/api/labs/${id}`, config);
      // Dispatch success object to the reducer.
      // Payload is the lab object from the database
      console.log(data)
      dispatch({ type: LAB_DETAILS_SUCCESS, payload: data });
    } else if (studentInfo) {
      /** @type {Object} Axios configurations */
      const config = {
        headers: {
          Authorization: `Bearer ${studentInfo.token}`,
        },
      };
      /** @type {Object} The resulting lab from the database */
      const { data } = await axios.get(`/api/labs/${id}/student`, config);
      // Dispatch success object to the reducer.
      // Payload is the lab object from the database
      dispatch({ type: LAB_DETAILS_SUCCESS, payload: data });
    }
  } catch (err) {
    // Dispatch an error object to the reducer.
    dispatch({
      type: LAB_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

/**
 * Action to create a new lab. (Trainer/Admin)
 * @param {Object} lab New lab object that is to be created
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const createLab = (lab) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: LAB_CREATE_REQUEST });
    console.log('I GOT DISPATCHED');

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Object} Newly create lab object */
    const { data } = await axios.post(`/api/labs`, lab, config);

    // Dispatch success object to the reducer.
    // Payload is the newly created lab object returned from the backend
    dispatch({ type: LAB_CREATE_SUCCESS, payload: data });
    snackActions.success(`${data.name} lab created.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: LAB_CREATE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Action to update an existing lab (by _id). (Trainers/Admins).
 * @param {Object} user Updated lab object to be sent to the backend
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const updateLab = (lab) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: LAB_UPDATE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Object} Newly updated lab object */
    const { data } = await axios.put(`/api/labs/${lab._id}`, lab, config);

    // Dispatch success object to the reducer.
    dispatch({ type: LAB_UPDATE_SUCCESS, payload: data });
    snackActions.success(`${data.name} lab updated.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: LAB_UPDATE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Action to delete an existing lab (by _id) (Trainers/Admins)
 * @param {string} id Mongoose _id of the lab that is to be deleted
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const deleteLab = (id) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: LAB_DELETE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // Axios delete request to backend.
    await axios.delete(`/api/labs/${id}`, config);
    // Dispatch success object to the reducer.
    dispatch({ type: LAB_DELETE_SUCCESS });
    snackActions.success(`Lab deleted.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: LAB_DELETE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Returns all the labs that belong to a certain course.
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const listLabsByCourse = () => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: LABS_BY_COURSE_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      studentLogin: { studentInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${studentInfo.token}`,
      },
    };

    /** @type {Array<Object>} All labs in the database */
    const { data } = await axios.get(
      `/api/labs/${studentInfo.course}/course`,
      config
    );

    // Dispatch success object to the reducer.
    // Payload is the list of all labs belonging to that course
    dispatch({ type: LABS_BY_COURSE_SUCCESS, payload: data });
  } catch (err) {
    // Dispatch an error object to the reducer.
    dispatch({
      type: LABS_BY_COURSE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const startLab = (data) => async (dispatch) => {
  try {
    dispatch({ type: LAB_START_IOU_REQUEST });
    snackActions.toast('Starting Lab...', true);

    const config = {
      headers: {
        'Content-Type': `application/json`,
      },
    };
    await axios.post(
      `${setWindowAddrForTelnetRequests()}:9096/start`,
      data,
      config
    );
    dispatch({ type: LAB_START_IOU_SUCCESS });
    snackActions.success('Lab started.');
  } catch (err) {
    dispatch({
      type: LAB_START_IOU_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
    snackActions.error('Operation Unsuccessful. Please try again.');
  }
};

export const stopLab = (data) => async (dispatch) => {
  try {
    dispatch({ type: LAB_STOP_IOU_REQUEST });
    snackActions.toast('Stopping Lab...', true);
    const config = {
      headers: {
        'Content-Type': `application/json`,
      },
    };
    await axios.post(
      `${setWindowAddrForTelnetRequests()}:9096/stop`,
      data,
      config
    );
    dispatch({ type: LAB_STOP_IOU_SUCCESS });
    snackActions.success('Lab stopped.');
  } catch (err) {
    dispatch({
      type: LAB_STOP_IOU_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
    snackActions.error('Operation Unsuccessful. Please try again.');
  }
};

export const resetLab = (data) => async (dispatch) => {
  try {
    dispatch({ type: LAB_RESET_IOU_REQUEST });
    snackActions.toast('Resetting Lab...', true);
    const config = {
      headers: {
        'Content-Type': `application/json`,
      },
    };
    await axios.post(
      `${setWindowAddrForTelnetRequests()}:9096/reset`,
      data,
      config
    );
    dispatch({ type: LAB_RESET_IOU_SUCCESS });
    snackActions.success('Lab has been reset.');
  } catch (err) {
    dispatch({
      type: LAB_RESET_IOU_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
    snackActions.error('Operation Unsuccessful. Please try again.');
  }
};

export const wipeLab = (data) => async (dispatch) => {
  try {
    dispatch({ type: LAB_WIPE_IOU_REQUEST });
    snackActions.toast('Wiping your configurations...', true);
    const config = {
      headers: {
        'Content-Type': `application/json`,
      },
    };
    await axios.post(
      `${setWindowAddrForTelnetRequests()}:9096/wipe`,
      data,
      config
    );
    dispatch({ type: LAB_WIPE_IOU_SUCCESS });
    snackActions.success('Your configs have been wiped.');
  } catch (err) {
    dispatch({
      type: LAB_WIPE_IOU_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
    snackActions.error('Operation Unsuccessful. Please try again.');
  }
};

export const powerOnLab = (data) => async (dispatch) => {
  try {
    dispatch({ type: LAB_POWER_ON_REQUEST });
    snackActions.toast('Activating Pod...', true);
    const config = {
      headers: {
        'Content-Type': `application/json`,
      },
    };
    await axios.post(
      `${setWindowAddrForTelnetRequests()}:9096/poweron`,
      data,
      config
    );
    dispatch({ type: LAB_POWER_ON_SUCCESS });

    snackActions.success('Pod activated');
  } catch (err) {
    dispatch({
      type: LAB_POWER_ON_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
    snackActions.error('Operation Unsuccessful. Please try again.');
  }
};

export const powerOffLab = (data) => async (dispatch) => {
  try {
    dispatch({ type: LAB_POWER_OFF_REQUEST });
    snackActions.toast('Deactivating Pod...', true);
    const config = {
      headers: {
        'Content-Type': `application/json`,
      },
    };
    await axios.post(
      `${setWindowAddrForTelnetRequests()}:9096/poweroff`,
      data,
      config
    );
    dispatch({ type: LAB_POWER_OFF_SUCCESS });
    snackActions.success('Pod deactivated');
  } catch (err) {
    dispatch({
      type: LAB_POWER_OFF_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
    snackActions.error('Operation Unsuccessful. Please try again.');
  }
};

export const checkBootStatus = (data) => async (dispatch) => {
  try {
    dispatch({ type: LAB_POWER_CHECK_REQUEST });
    const config = {
      headers: {
        'Content-Type': `application/json`,
      },
    };
    const res = await axios.post(
      `${setWindowAddrForTelnetRequests()}:9096/bootstatus`,
      data,
      config
    );
    dispatch({ type: LAB_POWER_CHECK_SUCCESS, payload: res.data.success });
  } catch (err) {
    dispatch({
      type: LAB_POWER_CHECK_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const startNodes = (data) => async (dispatch) => {
  try {
    dispatch({ type: LAB_START_NODES_REQUEST });
    snackActions.toast('Starting Nodes...', true);

    const config = {
      headers: {
        'Content-Type': `application/json`,
      },
    };
    await axios.post(
      `${setWindowAddrForTelnetRequests()}:9096/managenodes`,
      data,
      config
    );
    dispatch({ type: LAB_START_NODES_SUCCESS });
    snackActions.success('Nodes started.');
  } catch (err) {
    dispatch({
      type: LAB_START_NODES_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
    snackActions.error('Operation Unsuccessful. Please try again.');
  }
};

export const stopNodes = (data) => async (dispatch) => {
  try {
    dispatch({ type: LAB_STOP_NODES_REQUEST });
    snackActions.toast('Stopping Nodes...', true);

    const config = {
      headers: {
        'Content-Type': `application/json`,
      },
    };
    await axios.post(
      `${setWindowAddrForTelnetRequests()}:9096/managenodes`,
      data,
      config
    );
    dispatch({ type: LAB_STOP_NODES_SUCCESS });
    snackActions.success('Nodes stopped.');
  } catch (err) {
    dispatch({
      type: LAB_STOP_NODES_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
    snackActions.error('Operation Unsuccessful. Please try again.');
  }
};

export const wipeNodes = (data) => async (dispatch) => {
  try {
    dispatch({ type: LAB_WIPE_NODES_REQUEST });
    snackActions.toast('Wiping Nodes...', true);

    const config = {
      headers: {
        'Content-Type': `application/json`,
      },
    };
    await axios.post(
      `${setWindowAddrForTelnetRequests()}:9096/managenodes`,
      data,
      config
    );
    dispatch({ type: LAB_WIPE_NODES_SUCCESS });
    snackActions.success('Nodes wiped.');
  } catch (err) {
    dispatch({
      type: LAB_WIPE_NODES_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
    snackActions.error('Operation Unsuccessful. Please try again.');
  }
};
