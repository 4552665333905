import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LabsHeader from '../components/LabsHeader';
import {
  makeStyles,
  Button,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Checkbox,
  FormGroup,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Alert, AlertTitle } from '@material-ui/lab';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';
import { listAssessmentDetails } from '../actions/assessmentActions';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[100],
    minHeight: 'calc(100vh - 206px)',
    display: 'flex',
  },
  sidebar: {
    width: '14%',
    backgroundColor: 'white',
    borderRight: `1px solid ${grey[300]}`,
    paddingTop: '1rem',
  },
  sideSpace: {
    padding: '0 1rem',
    marginTop: '1rem',
    marginBottom: '0.7rem',
  },
  mainContent: {
    padding: '2rem',
    background: grey[100],
    width: '86%',
    height: 'calc(100vh - 202px)',
    overflow: 'auto',
  },
  pageContent: {
    padding: '2rem',
    '& h2': {
      marginTop: '0',
      fontSize: '1.7rem',
      fontWeight: '500',
    },
    '& h3': {
      fontSize: '1.3rem',
      marginTop: '2.5rem',
    },
    '& h6': {
      fontSize: '1rem',
      marginTop: '0',
      marginBottom: '0.2rem',
      opacity: '0.4',
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
    '& img': {
      width: '100%!important',
      margin: '1rem 0',
    },
    '& li': {
      fontSize: '0.9rem',
      marginBottom: '0.7rem',
    },
    '& pre': {
      padding: '1.2rem',
      backgroundColor: '#f1f1f1',
      whiteSpace: 'pre-wrap',
    },
  },
  description: {
    padding: '2rem 0',
    fontSize: '1rem',
  },
  pageNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '36.5px',
  },
}));

const AssessmentSingleScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [loadedQuestion, setLoadedQuestion] = useState({});
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [inputtedAnswer, setInputtedAnswer] = useState(null);
  const [checkAnswer, setCheckAnswer] = useState(null);
  const [multipleOptionsSelected, setMultipleOptionsSelected] = useState([]);
  // for displaying the correct answer
  const [displayCorrectAnswer, setDisplayCorrectAnswer] = useState(null);

  const id = match.params.id;

  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo } = studentLogin;

  const assessmentDetails = useSelector((state) => state.assessmentDetails);
  const { loading, error, assessment } = assessmentDetails;

  useEffect(() => {
    if (studentInfo) {
      dispatch(listAssessmentDetails(id));
    } else {
      history.push('/login');
    }

    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [dispatch, history, studentInfo, id, currentQuestion]);

  useEffect(() => {
    if (assessment) {
      setLoadedQuestion(
        assessment.questions.filter(
          (asm) => +asm.questionNo === +currentQuestion
        )[0]
      );
    }
  }, [assessment, checkAnswer]);

  useEffect(() => {
    setCheckAnswer(null);
    setSelectedAnswer(null);
    setDisplayCorrectAnswer(null);
  }, [loadedQuestion]);

  // console.log(assessment);

  /* ------------------------------ Check Answer ------------------------------ */
  const handleAnswerCheck = () => {
    if (
      loadedQuestion.type === 'choice' &&
      !loadedQuestion.multipleSelectable
    ) {
      setCheckAnswer(loadedQuestion.answerInput === selectedAnswer);
      setDisplayCorrectAnswer(loadedQuestion.answerInput.toString());
    }

    if (loadedQuestion.type === 'choice' && loadedQuestion.multipleSelectable) {
      setCheckAnswer(
        loadedQuestion.answerInput.split(',').sort().join(',') ===
          multipleOptionsSelected.sort().join(',')
      );
      setDisplayCorrectAnswer(
        loadedQuestion.answerInput.split(',').sort().join(', ')
      );
    }

    if (loadedQuestion.type === 'input') {
      if (inputtedAnswer) {
        if (loadedQuestion.answerInputCaseSensitive) {
          setCheckAnswer(
            loadedQuestion.answerInput.trim().toLowerCase() ===
              inputtedAnswer.trim().toLowerCase()
          );
        } else {
          setCheckAnswer(
            loadedQuestion.answerInput.trim() === inputtedAnswer.trim()
          );
        }
        setDisplayCorrectAnswer(loadedQuestion.answerInput.toString());
      }
    }
  };

  /* ---------------------- Handle multi-choice selection --------------------- */
  const handleMultipleChoiceSelection = (value, checked) => {
    if (checked) {
      setMultipleOptionsSelected([...multipleOptionsSelected, value]);
    } else {
      setMultipleOptionsSelected(
        multipleOptionsSelected.filter((o) => o !== value)
      );
    }
  };

  return (
    <>
      <LabsHeader
        pageTitle={
          assessment
            ? `Assessment ${assessment.no} - ${assessment.title}`
            : 'Assessment'
        }
        backBtn={{ action: () => history.goBack() }}
      />

      {assessment && (
        <section className={classes.section}>
          <div className={classes.sidebar}>
            <List style={{ height: 'calc(100vh - 202px)', overflow: 'auto' }}>
              <div className={classes.sideSpace}>
                <Typography variant="h6" style={{ fontSize: '1.2rem' }}>
                  Questions
                </Typography>
              </div>
              {assessment &&
                assessment.questions.map((q) => (
                  <ListItem
                    key={q.questionNo}
                    selected={+q.questionNo === currentQuestion}
                    button
                    onClick={() => setCurrentQuestion(+q.questionNo)}
                  >
                    <ListItemText primary={`Question ${q.questionNo}`} />
                  </ListItem>
                ))}
            </List>
          </div>
          <div className={classes.mainContent}>
            {/* Next/Prev Buttons */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '1rem',
              }}
            >
              <Button
                type="button"
                variant="outlined"
                color="default"
                size="small"
                onClick={() => setCurrentQuestion(+currentQuestion - 1)}
                disabled={
                  currentQuestion && +currentQuestion <= 1 ? true : false
                }
              >
                Previous Question
              </Button>
              <Button
                type="button"
                variant="outlined"
                color="default"
                size="small"
                onClick={() => setCurrentQuestion(+currentQuestion + 1)}
                disabled={
                  assessment &&
                  assessment.questionCount &&
                  currentQuestion >= +assessment.questionCount
                    ? true
                    : false
                }
              >
                Next Question
              </Button>
            </div>
            <Paper className={classes.pageContent}>
              {loadedQuestion ? (
                <>
                  <h6>Question {loadedQuestion.questionNo}</h6>
                  <h2>{loadedQuestion.title}</h2>
                  {loadedQuestion.questionBody && (
                    <Typography
                      variant="body2"
                      style={{
                        marginBottom: '1.5rem',
                        fontSize: '1rem',
                        opacity: '0.8',
                      }}
                    >
                      {loadedQuestion.questionBody}
                    </Typography>
                  )}
                  {loadedQuestion.image && (
                    <img
                      src={loadedQuestion.image}
                      style={{
                        maxWidth:
                          loadedQuestion.imageSize && loadedQuestion.imageSize
                            ? `${loadedQuestion.imageSize}%`
                            : '90%',
                        marginBottom: '2rem',
                      }}
                    />
                  )}
                  <form>
                    {/* Single-Choice */}
                    {loadedQuestion &&
                      loadedQuestion.type === 'choice' &&
                      !loadedQuestion.multipleSelectable && (
                        <FormControl component="fieldset">
                          <RadioGroup
                            value={selectedAnswer}
                            onChange={(e) => setSelectedAnswer(e.target.value)}
                          >
                            {loadedQuestion.options &&
                              loadedQuestion.options.map((option) => (
                                <FormControlLabel
                                  key={option}
                                  value={option}
                                  control={<Radio color="primary" />}
                                  label={option}
                                />
                              ))}
                          </RadioGroup>
                          <Button
                            type="button"
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                            onClick={() => handleAnswerCheck()}
                            style={{ marginTop: '2rem' }}
                          >
                            Check Answer
                          </Button>
                        </FormControl>
                      )}

                    {/* Multi-Choice */}
                    {loadedQuestion &&
                      loadedQuestion.type === 'choice' &&
                      loadedQuestion.multipleSelectable && (
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <FormGroup>
                            {loadedQuestion.options &&
                              loadedQuestion.options.map((option) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      color="primary"
                                      key={option}
                                      onChange={(e) =>
                                        handleMultipleChoiceSelection(
                                          e.target.value,
                                          e.target.checked
                                        )
                                      }
                                      value={option}
                                    />
                                  }
                                  label={option}
                                />
                              ))}
                          </FormGroup>
                          <Button
                            type="button"
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                            onClick={() => handleAnswerCheck()}
                            style={{ marginTop: '2rem' }}
                          >
                            Check Answer
                          </Button>
                        </FormControl>
                      )}

                    {/* Input Answer */}
                    {loadedQuestion && loadedQuestion.type === 'input' && (
                      <>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          multiline
                          rows="5"
                          id="inputtedAnswer"
                          type="text"
                          label="Type your answer"
                          name="inputtedAnswer"
                          autoFocus
                          value={inputtedAnswer}
                          onChange={(e) => setInputtedAnswer(e.target.value)}
                        />
                        <Button
                          type="button"
                          variant="outlined"
                          color="primary"
                          className={classes.button}
                          onClick={() => handleAnswerCheck()}
                          style={{ marginTop: '2rem' }}
                        >
                          Check Answer
                        </Button>
                      </>
                    )}
                    <div style={{ marginTop: '1rem' }}>
                      {checkAnswer !== null &&
                        (checkAnswer ? (
                          <Alert severity="success">
                            <AlertTitle>Correct Answer</AlertTitle>
                          </Alert>
                        ) : (
                          <Alert severity="error">
                            <AlertTitle>Wrong Answer</AlertTitle>
                          </Alert>
                        ))}
                    </div>
                    {checkAnswer !== null &&
                      !checkAnswer &&
                      displayCorrectAnswer && (
                        <div style={{ marginTop: '1rem' }}>
                          {displayCorrectAnswer && (
                            <Alert severity="success" variant="outlined">
                              <AlertTitle>The right answer is:</AlertTitle>
                              <h4
                                style={{
                                  fontSize: '1.5rem',
                                  margin: 0,
                                  padding: 0,
                                }}
                              >
                                <strong>{displayCorrectAnswer}</strong>
                              </h4>
                            </Alert>
                          )}
                        </div>
                      )}
                  </form>
                </>
              ) : (
                <p>No question selected</p>
              )}
            </Paper>
            {/* Next/Prev Buttons */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '1rem',
              }}
            >
              <Button
                type="button"
                variant="outlined"
                color="default"
                size="small"
                onClick={() => setCurrentQuestion(+currentQuestion - 1)}
                disabled={
                  currentQuestion && +currentQuestion <= 1 ? true : false
                }
              >
                Previous Question
              </Button>
              <Button
                type="button"
                variant="outlined"
                color="default"
                size="small"
                onClick={() => setCurrentQuestion(+currentQuestion + 1)}
                disabled={
                  assessment &&
                  assessment.questionCount &&
                  currentQuestion >= +assessment.questionCount
                    ? true
                    : false
                }
              >
                Next Question
              </Button>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default AssessmentSingleScreen;
