/**
 * @fileOverview   Action functions to perform CRUD operations on the Students Schema
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import axios from 'axios';
import {
  STUDENT_LOGIN_FAIL,
  STUDENT_LOGIN_REQUEST,
  STUDENT_LOGIN_SUCCESS,
  STUDENT_LOGOUT,
  STUDENT_LIST_REQUEST,
  STUDENT_LIST_SUCCESS,
  STUDENT_LIST_FAIL,
  STUDENT_LIST_RESET,
  STUDENT_CREATE_REQUEST,
  STUDENT_CREATE_SUCCESS,
  STUDENT_CREATE_FAIL,
  STUDENT_UPDATE_REQUEST,
  STUDENT_UPDATE_SUCCESS,
  STUDENT_UPDATE_FAIL,
  STUDENT_DELETE_REQUEST,
  STUDENT_DELETE_SUCCESS,
  STUDENT_DELETE_FAIL,
  BULK_GEN_STUDENT_REQUEST,
  BULK_GEN_STUDENT_SUCCESS,
  BULK_GEN_STUDENT_FAIL,
  STUDENT_STATUS_REQUEST,
  STUDENT_STATUS_SUCCESS,
  STUDENT_STATUS_FAIL,
  STUDENT_AUTH_CHECK_REQUEST,
  STUDENT_AUTH_CHECK_SUCCESS,
  STUDENT_AUTH_CHECK_FAIL,
  STUDENT_AUTH_CHECK_RESET,
  STUDENT_PROFILE_REQUEST,
  STUDENT_PROFILE_SUCCESS,
  STUDENT_PROFILE_FAIL,
  STUDENT_PROFILE_RESET,
  STUDENT_PROFILE_UPDATE_REQUEST,
  STUDENT_PROFILE_UPDATE_SUCCESS,
  STUDENT_PROFILE_UPDATE_FAIL,
} from '../constants/studentConstants';
import { LAB_LIST_RESET, LAB_DETAILS_RESET } from '../constants/labConstants';
import { snackActions } from '../utils/SnackBarUtils';

/**
 * Action that attempts student login (sends username & password to the backend)
 * @returns {void} Dispatches action to the reducers, returns nothing
 * @param {string} email Student's email address
 * @param {string} password Student's password
 */
export const login = (username, password) => async (dispatch) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({
      type: STUDENT_LOGIN_REQUEST,
    });

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    /** @type {Object} Student object from the database */
    const { data } = await axios.post(
      '/api/students/login',
      { username, password },
      config
    );

    // Dispatch success object to the reducer.
    dispatch({ type: STUDENT_LOGIN_SUCCESS, payload: data });
    // Save student object to local storage
    localStorage.setItem('studentInfo', JSON.stringify(data));
  } catch (err) {
    dispatch({
      // Dispatch an error object to the reducer.
      type: STUDENT_LOGIN_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

/**
 * Logs the student out. Clears the redux state.
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const logout = () => (dispatch) => {
  // Remove student object from local storage
  localStorage.removeItem('studentInfo');
  // Execute the logout reducer function
  dispatch({ type: STUDENT_LOGOUT });
  // Execute the student reset reducer, which clear the state
  dispatch({ type: STUDENT_AUTH_CHECK_RESET });
  dispatch({ type: STUDENT_LIST_RESET });
  dispatch({ type: LAB_LIST_RESET });
  dispatch({ type: LAB_DETAILS_RESET });
  dispatch({ type: STUDENT_PROFILE_RESET });
  document.location.href = '/';
};

/**
 * Returns all the students in the database. (ADMIN only)
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const listStudents = () => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({
      type: STUDENT_LIST_REQUEST,
    });

    /**
     * Extracting logged-in student state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Array<Object>} All students in the database */
    const { data } = await axios.get(`/api/students`, config);

    // Dispatch success object to the reducer.
    // Payload is the list of all students
    dispatch({
      type: STUDENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // Dispatch an error object to the reducer.
    dispatch({
      type: STUDENT_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

/**
 * Action to create a new student. (ADMIN only)
 * @param {Object} student New student object that is to be created
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const createStudent = (student) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: STUDENT_CREATE_REQUEST });

    /**
     * Extracting logged-in student state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Object} Newly create student object */
    const { data } = await axios.post(`/api/students`, student, config);

    // Dispatch success object to the reducer.
    // Payload is the newly created student object returned from the backend
    dispatch({ type: STUDENT_CREATE_SUCCESS, payload: data });
    snackActions.success(`${data.username} student created.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: STUDENT_CREATE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Action to update an existing student (by _id). (ADMIN only).
 * @param {Object} student Updated student object to be sent to the backend
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const updateStudent = (student) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: STUDENT_UPDATE_REQUEST });

    /**
     * Extracting logged-in student state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    /** @type {Object} Newly updated student object */
    const { data } = await axios.put(
      `/api/students/${student._id}`,
      student,
      config
    );

    // Dispatch success object to the reducer.
    dispatch({ type: STUDENT_UPDATE_SUCCESS, payload: data });
    snackActions.success(`${data.username} student updated.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: STUDENT_UPDATE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Action to delete an existing student (by _id).
 * @param {string} id Mongoose _id of the student that is to be deleted
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const deleteStudent = (id) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: STUDENT_DELETE_REQUEST });

    /**
     * Extracting logged-in student state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // Axios delete request to backend.
    await axios.delete(`/api/students/${id}`, config);
    // Dispatch success object to the reducer.
    // Payload is the newly updated student object returned from the backend
    dispatch({ type: STUDENT_DELETE_SUCCESS });
    snackActions.success(`Student deleted.`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: STUDENT_DELETE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};

/**
 * Action to bulk generate student accounts.
 * @param {string} data Bulk generation details
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const bulkGenerateStudents =
  (studentsData) => async (dispatch, getState) => {
    try {
      // Dispatch request object to the reducer.
      dispatch({ type: BULK_GEN_STUDENT_REQUEST });

      /**
       * Extracting logged-in student state from the Redux store
       * @type {Object}
       */
      const {
        userLogin: { userInfo },
      } = getState();

      /** @type {Object} Axios configurations */
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Axios post request to backend.
      const { data } = await axios.post(
        `/api/students/generate`,
        studentsData,
        config
      );
      // Dispatch success object to the reducer.
      // Payload is the newly updated student object returned from the backend
      dispatch({ type: BULK_GEN_STUDENT_SUCCESS, payload: data });
      snackActions.success(`Student accounts generated.`);
    } catch (err) {
      // Dispatch an error object to the reducer.
      const errMessage =
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message;
      dispatch({
        type: BULK_GEN_STUDENT_FAIL,
        payload: errMessage,
      });
      snackActions.error(errMessage);
    }
  };

/**
 * Action to activate/deactivate a student
 * @param {Object} id Student ID to make a request to
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const updateStudentStatus = (id) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: STUDENT_STATUS_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      userLogin: { userInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    // Axios put request to backend.
    await axios.get(`/api/students/${id}/activate`, config);
    // Dispatch success object to the reducer.
    dispatch({ type: STUDENT_STATUS_SUCCESS });
  } catch (err) {
    // Dispatch an error object to the reducer.
    dispatch({
      type: STUDENT_STATUS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

/**
 * Action to check auth/token state of student on page load
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const studentAuthCheck = () => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: STUDENT_AUTH_CHECK_REQUEST });

    /**
     * Extracting logged-in user state from the Redux store
     * @type {Object}
     */
    const {
      studentLogin: { studentInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${studentInfo.token}`,
      },
    };
    // Axios put request to backend.
    const { data } = await axios.get(`/api/students/auth`, config);
    // Dispatch success object to the reducer.
    dispatch({ type: STUDENT_AUTH_CHECK_SUCCESS, payload: data.info });
  } catch (err) {
    // Dispatch an error object to the reducer.
    dispatch({
      type: STUDENT_AUTH_CHECK_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

/**
 * Get student profile
 * @returns {void} Dispatches action to the reducers, returns nothing
 */
export const getProfile = () => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({
      type: STUDENT_PROFILE_REQUEST,
    });

    /**
     * Extracting logged-in student state from the Redux store
     * @type {Object}
     */
    const {
      studentLogin: { studentInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        Authorization: `Bearer ${studentInfo.token}`,
      },
    };

    /** @type {Object} Single Student Profile */
    const { data } = await axios.get(`/api/students/profile`, config);

    // Dispatch success object to the reducer.
    // Payload is the student profile data
    dispatch({
      type: STUDENT_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // Dispatch an error object to the reducer.
    dispatch({
      type: STUDENT_PROFILE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

/**
 * Update student profile
 * @param {Object} data Updated student object to be sent to the backend
 * @returns {void} Dispatches action to the reducers, returns nothing.
 */
export const updateProfile = (updatedData) => async (dispatch, getState) => {
  try {
    // Dispatch request object to the reducer.
    dispatch({ type: STUDENT_PROFILE_UPDATE_REQUEST });

    /**
     * Extracting logged-in student state from the Redux store
     * @type {Object}
     */
    const {
      studentLogin: { studentInfo },
    } = getState();

    /** @type {Object} Axios configurations */
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${studentInfo.token}`,
      },
    };

    /** @type {Object} Newly updated student object */
    const { data } = await axios.put(
      '/api/students/profile',
      updatedData,
      config
    );

    // Dispatch success object to the reducer.
    dispatch({ type: STUDENT_PROFILE_UPDATE_SUCCESS, payload: data });
    snackActions.success(`Profile updated`);
  } catch (err) {
    // Dispatch an error object to the reducer.
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    dispatch({
      type: STUDENT_PROFILE_UPDATE_FAIL,
      payload: errMessage,
    });
    snackActions.error(errMessage);
  }
};
