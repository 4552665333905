import {
  EXAM_LIST_REQUEST,
  EXAM_LIST_SUCCESS,
  EXAM_LIST_FAIL,
  EXAM_LIST_RESET,
  EXAM_DETAILS_REQUEST,
  EXAM_DETAILS_SUCCESS,
  EXAM_DETAILS_FAIL,
  EXAM_DETAILS_RESET,
  EXAM_CREATE_REQUEST,
  EXAM_CREATE_SUCCESS,
  EXAM_CREATE_FAIL,
  EXAM_CREATE_RESET,
  EXAM_UPDATE_REQUEST,
  EXAM_UPDATE_SUCCESS,
  EXAM_UPDATE_FAIL,
  EXAM_UPDATE_RESET,
  EXAM_DELETE_REQUEST,
  EXAM_DELETE_SUCCESS,
  EXAM_DELETE_FAIL,
  CURRENT_EXAM_QUESTION,
  EXAMS_BY_COURSE_REQUEST,
  EXAMS_BY_COURSE_SUCCESS,
  EXAMS_BY_COURSE_FAIL,
  EXAM_SUBMIT_SCORE_REQUEST,
  EXAM_SUBMIT_SCORE_SUCCESS,
  EXAM_SUBMIT_SCORE_FAIL,
  EXAM_GET_POINTS_REQUEST,
  EXAM_GET_POINTS_SUCESS,
  EXAM_GET_POINTS_FAIL,
} from '../constants/examsConstants';

export const examListReducer = (state = { exams: [] }, action) => {
  switch (action.type) {
    case EXAM_LIST_REQUEST:
      return { loading: true, exams: [] };
    case EXAM_LIST_SUCCESS:
      return { loading: false, exams: action.payload };
    case EXAM_LIST_FAIL:
      return { loading: false, error: action.payload };
    case EXAM_LIST_RESET:
      return { exams: [] };
    default:
      return state;
  }
};

export const examDetailsReducer = (
  state = { exam: { questions: [] } },
  action
) => {
  switch (action.type) {
    case EXAM_DETAILS_REQUEST:
      return { ...state, loading: true };
    case EXAM_DETAILS_SUCCESS:
      return { loading: false, exam: action.payload };
    case EXAM_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case EXAM_DETAILS_RESET:
      return { questions: [] };
    default:
      return state;
  }
};

export const examCreateReducer = (state = { questions: [] }, action) => {
  switch (action.type) {
    case EXAM_CREATE_REQUEST:
      return { loading: true };
    case EXAM_CREATE_SUCCESS:
      return { loading: false, success: true, exam: action.payload };
    case EXAM_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case EXAM_CREATE_RESET:
      return { questions: [] };
    default:
      return state;
  }
};

export const examUpdateReducer = (state = { questions: [] }, action) => {
  switch (action.type) {
    case EXAM_UPDATE_REQUEST:
      return { loading: true, ...state };
    case EXAM_UPDATE_SUCCESS:
      return { loading: false, success: true, exam: action.payload };
    case EXAM_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case EXAM_UPDATE_RESET:
      return { questions: [] };
    default:
      return state;
  }
};

export const examDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case EXAM_DELETE_REQUEST:
      return { loading: true };
    case EXAM_DELETE_SUCCESS:
      return { loading: false, success: true };
    case EXAM_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const examQuestionReducer = (state = {}, action) => {
  switch (action.type) {
    case CURRENT_EXAM_QUESTION:
      return { success: true, question: action.payload };
    default:
      return state;
  }
};

export const examsListByCourseReducer = (state = { exams: [] }, action) => {
  switch (action.type) {
    case EXAMS_BY_COURSE_REQUEST:
      return { loading: true, exams: [] };
    case EXAMS_BY_COURSE_SUCCESS:
      return { loading: false, exams: action.payload };
    case EXAMS_BY_COURSE_FAIL:
      return { loading: false, exams: action.payload };
    default:
      return state;
  }
};

export const examSubmitScoreReducer = (state = { points: [] }, action) => {
  switch (action.type) {
    case EXAM_SUBMIT_SCORE_REQUEST:
      return { loading: true, ...state };
    case EXAM_SUBMIT_SCORE_SUCCESS:
      return { loading: false, success: true, points: action.payload };
    case EXAM_SUBMIT_SCORE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const examGetStudentPointsReducer = (state = { points: [] }, action) => {
  switch (action.type) {
    case EXAM_GET_POINTS_REQUEST:
      return { loading: true };
    case EXAM_GET_POINTS_SUCESS:
      return { loading: false, success: true, points: action.payload };
    case EXAM_GET_POINTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
