import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  CircularProgress,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Select,
  Paper,
  Chip,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar,
  Divider,
  ListItemSecondaryAction,
  Icon,
} from '@material-ui/core';
import { grey, blue } from '@material-ui/core/colors';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import LabsHeader from '../components/LabsHeader';
import InfoTable from '../components/InfoTable';
import { listCourses as listCoursesAction } from '../actions/courseActions';
import {
  listBooks as listBooksAction,
  createBook as createBookAction,
  updateBook as updateBookAction,
  deleteBook as deleteBookAction,
} from '../actions/bookActions';
import {
  BOOK_CREATE_RESET,
  BOOK_UPDATE_RESET,
} from '../constants/bookConstants';
import { SlideTransition, ZoomTransition } from '../components/Transitions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[200],
    padding: '50px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: 'calc(100vh - 206px)',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  appBar: {
    position: 'relative',
  },
  chip: {
    margin: 2,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  notifSpacing: {
    marginBottom: '10px',
  },
  spacer: {
    height: '20px',
  },
  markupText: {
    '& textarea': {
      fontFamily: 'monospace',
    },
  },
  chaptersList: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  chaptersListBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  chapterNo: {
    color: theme.palette.getContrastText(blue[100]),
    backgroundColor: blue[100],
    // width: theme.spacing(3),
    // height: theme.spacing(3),
  },
  noChaptersDisplay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '0.3',
    flexDirection: 'column',
  },
}));

/**
 * Component for showing/adding/editing books (user side)
 */
const BooksScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Dialog State
  const [createPopup, setCreatePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [coverUploading, setCoverUploading] = useState(false);
  const [videoUploading, setVideoUploading] = useState(false);
  const [pdfUploading, setPdfUploading] = useState(false);

  // Form and Local State
  const [no, setNo] = useState('');
  const [title, setTitle] = useState('');
  const [displayTitle, setDisplayTitle] = useState('');
  const [partModuleName, setPartModuleName] = useState('');
  const [cover, setCover] = useState('');
  const [shortDesc, setShortDesc] = useState('');
  const [price, setPrice] = useState(0);
  const [chapters, setChapters] = useState([]);
  const [trainer, setTrainer] = useState([]);
  const [course, setCourse] = useState('');
  const [introVideo, setIntroVideo] = useState('');
  const [pdfDownload, setPdfDownload] = useState('');
  const [updateNo, setUpdateNo] = useState('');
  const [updateTitle, setUpdateTitle] = useState('');
  const [updateDisplayTitle, setUpdateDisplayTitle] = useState('');
  const [updatePartModuleName, setUpdatePartModuleName] = useState('');
  const [updateCover, setUpdateCover] = useState('');
  const [updateShortDesc, setUpdateShortDesc] = useState('');
  const [updatePrice, setUpdatePrice] = useState('');
  const [updateChapters, setUpdateChapters] = useState([]);
  const [updateTrainer, setUpdateTrainer] = useState('');
  const [updateCourse, setUpdateCourse] = useState('');
  const [updateIntroVideo, setUpdateIntroVideo] = useState('');
  const [updatePdfDownload, setUpdatePdfDownload] = useState('');
  const [updateBookId, setUpdateBookId] = useState('');
  const [toDeleteBook, setToDeleteBook] = useState('');

  const [chapAdder, setChapAdder] = useState('');

  // Redux State Getters
  const useLogin = useSelector((state) => state.userLogin);
  const { userInfo } = useLogin;
  const booksList = useSelector((state) => state.booksList);
  const { loading, error, books } = booksList;
  const coursesList = useSelector((state) => state.coursesList);
  const {
    loading: allCoursesLoading,
    error: allCoursesError,
    courses: allCourses,
  } = coursesList;
  const bookCreate = useSelector((state) => state.bookCreate);
  const {
    loading: createLoading,
    error: createError,
    success: createSuccess,
    book: createdBook,
  } = bookCreate;
  const bookUpdate = useSelector((state) => state.bookUpdate);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    book: updatedBook,
  } = bookUpdate;
  const bookDelete = useSelector((state) => state.bookDelete);
  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
  } = bookDelete;

  // Lifecycle events
  useEffect(() => {
    if (userInfo) {
      dispatch(listBooksAction());
      dispatch(listCoursesAction());
    } else {
      history.push('/admin/login');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
    if (createSuccess) {
      handleCreatePopupClose();
      setCreatePopup(false);
      setNo('');
      setTitle('');
      setDisplayTitle('');
      setPartModuleName('');
      setCover('');
      setShortDesc('');
      setPrice(0);
      setChapters([]);
      setTrainer('');
      setCourse('');
      setIntroVideo('');
      setPdfDownload('');
      dispatch({ type: BOOK_CREATE_RESET });
    }
    if (updateSuccess) {
      handleEditPopupClose();
      setUpdateNo('');
      setUpdateTitle('');
      setUpdateDisplayTitle('');
      setUpdatePartModuleName('');
      setUpdateCover('');
      setUpdateShortDesc('');
      setUpdatePrice(0);
      setUpdateChapters([]);
      setUpdateTrainer('');
      setUpdateCourse('');
      setUpdateIntroVideo('');
      setUpdatePdfDownload('');
      dispatch({ type: BOOK_UPDATE_RESET });
    }
    if (deleteSuccess) {
      handleDeletePopupClose();
      setToDeleteBook({});
    }
  }, [
    dispatch,
    history,
    userInfo,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    createdBook,
    updatedBook,
  ]);

  // Popup Management
  const handleCreatePopupClick = () => {
    setCreatePopup(true);
  };
  const handleCreatePopupClose = () => {
    setCreatePopup(false);
  };
  const handleEditPopupClick = () => {
    setEditPopup(true);
  };
  const handleEditPopupClose = () => {
    setEditPopup(false);
    setUpdateNo('');
    setUpdateTitle('');
    setUpdateDisplayTitle('');
    setUpdatePartModuleName('');
    setUpdateCover('');
    setUpdateShortDesc('');
    setUpdatePrice(0);
    setUpdateChapters([]);
    setUpdateTrainer('');
    setUpdateCourse('');
    setUpdateIntroVideo('');
    setUpdatePdfDownload('');
  };
  const handleDeletePopupClick = (book) => {
    setToDeleteBook(book);
    setDeletePopup(true);
  };
  const handleDeletePopupClose = () => {
    setDeletePopup(false);
  };

  // Action Handlers
  const handleCreateBook = (e) => {
    e.preventDefault();
    const formData = {
      no,
      title,
      displayTitle,
      partModuleName,
      shortDescription: shortDesc,
      price,
      chapters,
      trainer: userInfo._id,
      cover,
      course,
      introVideo,
      pdf: pdfDownload,
    };
    dispatch(createBookAction(formData));
  };
  const handleEditBook = (_e, id) => {
    books.forEach((book) => {
      if (book._id === id) {
        setUpdateBookId(book._id);
        setUpdateNo(book.no);
        setUpdateTitle(book.title);
        setUpdateDisplayTitle(book.displayTitle);
        setUpdatePartModuleName(book.partModuleName);
        setUpdateCover(book.cover);
        setUpdateShortDesc(book.shortDescription);
        setUpdatePrice(book.price);
        setUpdateChapters(book.chapters);
        setUpdateTrainer(book.trainer._id);
        setUpdateCourse(book.course._id);
        setUpdateIntroVideo(book.introVideo);
        setUpdatePdfDownload(book.pdf);
      }
    });
    handleEditPopupClick();
  };
  const handleUpdateBook = (e) => {
    e.preventDefault();
    const formData = {
      _id: updateBookId,
      no: updateNo,
      title: updateTitle,
      displayTitle: updateDisplayTitle,
      partModuleName: updatePartModuleName,
      shortDescription: updateShortDesc,
      price: updatePrice,
      chapters: updateChapters,
      trainer: userInfo._id,
      course: updateCourse,
      cover: updateCover,
      introVideo: updateIntroVideo,
      pdf: updatePdfDownload,
    };
    dispatch(updateBookAction(formData));
  };
  const handleDeleteBook = (id) => {
    dispatch(deleteBookAction(id));
    handleDeletePopupClose();
  };

  const uploadFileHandler = async (e, update, type) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    if (type === 'cover') {
      setCoverUploading(true);
      console.log('COVER 1');
    } else if (type === 'video') {
      setVideoUploading(true);
    } else if (type === 'pdf') {
      setPdfDownload(true);
    }
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload', formData, config);

      if (type === 'cover') {
        if (update) {
          console.log('COVER 2');
          setUpdateCover(data);
        } else {
          console.log('COVER 2');
          setCover(data);
        }
        setCoverUploading(false);
      } else if (type === 'video') {
        if (update) {
          setUpdateIntroVideo(data);
        } else {
          setIntroVideo(data);
        }
        setVideoUploading(false);
      } else if (type === 'pdf') {
        if (update) {
          setUpdatePdfDownload(data);
        } else {
          setPdfDownload(data);
        }
        setPdfUploading(false);
      }
    } catch (err) {
      console.error(err);
      setCoverUploading(false);
      setVideoUploading(false);
      setPdfUploading(false);
    }
  };

  const addChapter = (e) => {
    setChapters([...chapters, chapAdder]);
    setChapAdder('');
  };
  const updateChapter = (e) => {
    setUpdateChapters([...updateChapters, chapAdder]);
    setChapAdder('');
  };

  const deleteChapter = (chapter) => {
    setChapters(chapters.filter((chap) => chap !== chapter));
  };
  const deleteUpdatedChapter = (chapter) => {
    setUpdateChapters(updateChapters.filter((chap) => chap !== chapter));
  };

  return (
    <>
      <LabsHeader
        pageTitle={
          userInfo && userInfo.role === 'admin'
            ? `${books ? 'Books (' + books.length + ' total)' : ''}`
            : 'Books'
        }
        backBtn={{ action: () => history.push('/admin/dashboard') }}
        secondaryBtn={{
          label: 'Manage Pages',
          link: '/admin/pages',
          color: 'secondary',
          style: 'contained',
        }}
        tertiaryBtn={{
          label: 'Add New Book',
          action: () => handleCreatePopupClick(),
          style: 'contained',
        }}
      />
      <section className={classes.section}>
        <Container>
          {loading && allCoursesLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 255px)',
              }}>
              <CircularProgress />
            </div>
          ) : error || allCoursesError ? (
            <Alert severity='error'>{error || allCoursesError}</Alert>
          ) : (
            <InfoTable
              columns={['title', 'course', 'pages', 'actions']}
              rows={
                books &&
                books.map((book) => ({
                  _id: book._id,
                  title: (
                    <span style={{ fontWeight: '500' }}>{book.title}</span>
                  ),
                  course: <Chip label={book.course.name} variant='outlined' />,
                  pages: book.pages.length === 0 ? 0 : book.pages.length,
                  actions: [
                    ...(userInfo && userInfo.accessLevel >= 7
                      ? [
                          {
                            kind: 'action',
                            label: 'Edit',
                            type: 'primary',
                            action: (e) => handleEditBook(e, book._id),
                          },
                        ]
                      : []),
                    ...(userInfo && userInfo.role === 'admin'
                      ? [
                          {
                            kind: 'action',
                            label: 'Delete',
                            type: 'secondary',
                            action: () => handleDeletePopupClick(book),
                          },
                        ]
                      : []),
                  ],
                }))
              }
            />
          )}
          {/* Add new books dialog */}
          <Dialog
            fullScreen
            open={createPopup}
            onClose={handleCreatePopupClose}
            aria-labelledby='form-dialog-title'
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={SlideTransition}>
            <form onSubmit={handleCreateBook}>
              <AppBar className={classes.appBar} color='primary'>
                <Toolbar>
                  <IconButton
                    edge='start'
                    color='inherit'
                    onClick={handleCreatePopupClose}
                    aria-label='close'>
                    <CloseIcon />
                  </IconButton>
                  <Typography variant='h6' className={classes.title}>
                    Add New Book
                  </Typography>
                  <Button color='inherit' type='submit'>
                    Add Book
                  </Button>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <DialogContentText style={{ marginTop: '20px' }}>
                  Create a new Book. Please follow the correct HTML formatting
                  for the book.
                </DialogContentText>
                {createLoading && (
                  <LinearProgress
                    color='primary'
                    className={classes.notifSpacing}
                  />
                )}
                {createError && (
                  <Alert severity='error' className={classes.notifSpacing}>
                    {createError}
                  </Alert>
                )}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    id='no'
                    type='text'
                    label='Sr No.'
                    name='no'
                    autoComplete='no'
                    autoFocus
                    value={no}
                    onChange={(e) => setNo(e.target.value)}
                    style={{ width: '15%' }}
                  />
                  <TextField
                    variant='outlined'
                    margin='dense'
                    required
                    fullWidth
                    id='title'
                    type='text'
                    label='Title'
                    name='title'
                    autoComplete='title'
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>

                {/* Display Title */}
                <div style={{ display: 'flex', gap: '20px' }}>
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    id='displayTitle'
                    type='text'
                    label='Display Title (html markup)'
                    name='displayTitle'
                    autoComplete='displayTitle'
                    value={displayTitle}
                    onChange={(e) => setDisplayTitle(e.target.value)}
                  />
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    id='partModuleName'
                    type='text'
                    label='Part/Module Name &amp; No'
                    name='partModuleName'
                    autoComplete='partModuleName'
                    value={partModuleName}
                    onChange={(e) => setPartModuleName(e.target.value)}
                  />
                </div>

                <TextField
                  variant='outlined'
                  margin='dense'
                  multiline
                  rows='10'
                  fullWidth
                  id='shortDesc'
                  type='text'
                  label='Book Description'
                  name='shortDesc'
                  value={shortDesc}
                  onChange={(e) => setShortDesc(e.target.value)}
                />
                {/* Multi-column Row 1 */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  <FormControl
                    variant='outlined'
                    margin='dense'
                    style={{ width: '25%' }}
                    required
                    className={classes.formControl}>
                    <InputLabel id='bookCourse'>Course</InputLabel>
                    <Select
                      labelId='bookCourse'
                      id='course'
                      name='course'
                      value={course}
                      onChange={(e) => setCourse(e.target.value)}
                      label='Course *'>
                      {allCourses &&
                        allCourses.map((c) => (
                          <MenuItem key={c._id} value={c._id}>
                            {c.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <TextField
                    style={{ width: '25%' }}
                    variant='outlined'
                    margin='dense'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>₹</InputAdornment>
                      ),
                    }}
                    fullWidth
                    id='price'
                    type='text'
                    label='Book Price'
                    name='price'
                    autoComplete='price'
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                  {/* Upload PDF Download */}
                  <div
                    style={{
                      width: '50%',
                    }}>
                    {pdfUploading && (
                      <LinearProgress style={{ marginBottom: '5px' }} />
                    )}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        flexGrow: '1',
                      }}>
                      <TextField
                        variant='outlined'
                        margin='dense'
                        id='pdf'
                        style={{ flexGrow: '1' }}
                        fullwidth
                        type='text'
                        label='Upload PDF'
                        name='pdf'
                        autoComplete='pdf'
                        value={pdfDownload}
                        onChange={(e) => setPdfDownload(e.target.value)}
                      />
                      <Button
                        variant='contained'
                        style={{
                          minHeight: '39px',
                          position: 'relative',
                          top: '2px',
                        }}
                        component='label'
                        disableElevation
                        id='pdf-file'
                        onChange={(e) => uploadFileHandler(e, false, 'pdf')}>
                        Upload PDF
                        <input type='file' hidden />
                      </Button>
                    </div>
                  </div>
                </div>
                {/* Multi-column Row 2 */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  {/* Upload Book Cover */}
                  <div
                    style={{
                      flexGrow: '1',
                    }}>
                    {coverUploading && (
                      <LinearProgress style={{ marginBottom: '5px' }} />
                    )}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        flexGrow: '1',
                      }}>
                      <TextField
                        variant='outlined'
                        margin='dense'
                        id='cover'
                        style={{ flexGrow: '1' }}
                        fullwidth
                        type='text'
                        label='Upload Book Cover'
                        name='cover'
                        autoComplete='cover'
                        value={cover}
                        onChange={(e) => setCover(e.target.value)}
                      />
                      <Button
                        variant='contained'
                        style={{
                          minHeight: '39px',
                          position: 'relative',
                          top: '2px',
                        }}
                        component='label'
                        disableElevation
                        id='image-file'
                        onChange={(e) => uploadFileHandler(e, false, 'cover')}>
                        Upload Image
                        <input type='file' hidden />
                      </Button>
                    </div>
                  </div>
                  {/* Upload Video */}
                  <div
                    style={{
                      flexGrow: '1',
                    }}>
                    {videoUploading && (
                      <LinearProgress style={{ marginBottom: '5px' }} />
                    )}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        flexGrow: '1',
                      }}>
                      <TextField
                        variant='outlined'
                        margin='dense'
                        id='uploadIntroVideo'
                        style={{ flexGrow: '1' }}
                        fullwidth
                        type='text'
                        label='Upload Intro Video'
                        name='uploadIntroVideo'
                        autoComplete='cover'
                        value={introVideo}
                        onChange={(e) => setIntroVideo(e.target.value)}
                      />
                      <Button
                        variant='contained'
                        style={{
                          minHeight: '39px',
                          position: 'relative',
                          top: '2px',
                        }}
                        component='label'
                        disableElevation
                        id='image-file'
                        onChange={(e) => uploadFileHandler(e, false, 'video')}>
                        Upload Video
                        <input type='file' hidden />
                      </Button>
                    </div>
                  </div>
                </div>
                <Divider style={{ margin: '1.2rem 0' }} />
                {/* Chapters adder */}
                <Typography variant='h5' gutterBottom>
                  <strong>Add Chapters</strong>
                </Typography>
                <div style={{ display: 'flex', gap: '2rem' }}>
                  <Box
                    style={{
                      display: 'flex',
                      gap: '0.5rem',
                      flexDirection: 'column',
                    }}
                    width='100%'>
                    <TextField
                      variant='outlined'
                      margin='dense'
                      id='chapterAdder'
                      fullwidth
                      type='text'
                      label='Chapter Name'
                      name='chapterAdder'
                      autoComplete='chapters'
                      value={chapAdder}
                      onChange={(e) => setChapAdder(e.target.value)}
                    />
                    <Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      type='button'
                      onClick={addChapter}>
                      Add Chapter
                    </Button>
                  </Box>
                  <Box
                    component={Paper}
                    variant='outlined'
                    width='100%'
                    style={{ marginTop: '7px' }}
                    className={
                      chapters.length === 0 ? classes.chaptersListBox : null
                    }>
                    {chapters.length === 0 ? (
                      <div className={classes.noChaptersDisplay}>
                        <Icon component={ImportContactsIcon} fontSize='large' />
                        <Typography component='h5' variant='h5'>
                          No chapters yet
                        </Typography>
                      </div>
                    ) : (
                      <List
                        component='nav'
                        aria-label='secondary mailbox folders'>
                        {chapters.map((chap, i) => (
                          <ListItem button key={i}>
                            <ListItemAvatar>
                              <Avatar className={classes.chapterNo}>
                                {i + 1}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={chap} />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge='end'
                                color='secondary'
                                onClick={(e) => deleteChapter(chap)}>
                                <DeleteOutlineIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </Box>
                </div>
              </DialogContent>
            </form>
          </Dialog>
          {/* Update book dialog */}
          <Dialog
            fullScreen
            open={editPopup}
            onClose={handleEditPopupClose}
            aria-labelledby='form-dialog-title'
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={SlideTransition}>
            <form onSubmit={handleUpdateBook}>
              <AppBar className={classes.appBar} color='primary'>
                <Toolbar>
                  <IconButton
                    edge='start'
                    color='inherit'
                    onClick={handleEditPopupClose}
                    aria-label='close'>
                    <CloseIcon />
                  </IconButton>
                  <Typography variant='h6' className={classes.title}>
                    Edit Book — "{updateTitle}"
                  </Typography>
                  <Button autoFocus color='inherit' type='submit'>
                    Update
                  </Button>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <div className={classes.spacer}></div>
                {updateLoading && (
                  <LinearProgress
                    color='primary'
                    className={classes.notifSpacing}
                  />
                )}
                {updateError && (
                  <Alert severity='error' className={classes.notifSpacing}>
                    {updateError}
                  </Alert>
                )}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    id='no'
                    type='text'
                    label='Sr. No.'
                    name='no'
                    autoComplete='no'
                    autoFocus
                    value={updateNo}
                    onChange={(e) => setUpdateNo(e.target.value)}
                    style={{ width: '15%' }}
                  />
                  <TextField
                    variant='outlined'
                    margin='dense'
                    required
                    fullWidth
                    id='updateTitle'
                    type='text'
                    label='Title'
                    name='title'
                    autoComplete='title'
                    value={updateTitle}
                    onChange={(e) => setUpdateTitle(e.target.value)}
                  />
                </div>

                {/* Display Title */}
                <div style={{ display: 'flex', gap: '20px' }}>
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    id='updateDisplayTitle'
                    type='text'
                    label='Display Title'
                    name='updateDisplayTitle'
                    autoComplete='updateDisplayTitle'
                    value={updateDisplayTitle}
                    onChange={(e) => setUpdateDisplayTitle(e.target.value)}
                  />
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    id='updatePartModuleName'
                    type='text'
                    label='Part/Module Name &amp; No'
                    name='updatePartModuleName'
                    autoComplete='updatePartModuleName'
                    value={updatePartModuleName}
                    onChange={(e) => setUpdatePartModuleName(e.target.value)}
                  />
                </div>

                <TextField
                  variant='outlined'
                  margin='dense'
                  multiline
                  rows='10'
                  fullWidth
                  id='updateShortDesc'
                  type='text'
                  label='Book Description'
                  className={classes.markupText}
                  name='shortDesc'
                  value={updateShortDesc}
                  onChange={(e) => setUpdateShortDesc(e.target.value)}
                />
                {/* UPDATE - Multi-column Row 1 */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  <FormControl
                    variant='outlined'
                    margin='dense'
                    style={{ width: '25%' }}
                    required
                    className={classes.formControl}>
                    <InputLabel id='updateBookCourse'>Course</InputLabel>
                    <Select
                      labelId='bookCourse'
                      id='course'
                      name='course'
                      value={updateCourse}
                      onChange={(e) => setUpdateCourse(e.target.value)}
                      label='Course *'>
                      {allCourses &&
                        allCourses.map((c) => (
                          <MenuItem key={c._id} value={c._id}>
                            {c.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <TextField
                    style={{ width: '25%' }}
                    variant='outlined'
                    margin='dense'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>₹</InputAdornment>
                      ),
                    }}
                    fullWidth
                    id='price'
                    type='text'
                    label='Book Price'
                    name='price'
                    autoComplete='price'
                    value={updatePrice}
                    onChange={(e) => setUpdatePrice(e.target.value)}
                  />
                  {/* Upload PDF Download */}
                  <div
                    style={{
                      width: '50%',
                    }}>
                    {pdfUploading && (
                      <LinearProgress style={{ marginBottom: '5px' }} />
                    )}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        flexGrow: '1',
                      }}>
                      <TextField
                        variant='outlined'
                        margin='dense'
                        id='pdf'
                        style={{ flexGrow: '1' }}
                        fullwidth
                        type='text'
                        label='Upload PDF'
                        name='pdf'
                        autoComplete='pdf'
                        value={updatePdfDownload}
                        onChange={(e) => setUpdatePdfDownload(e.target.value)}
                      />
                      <Button
                        variant='contained'
                        style={{
                          minHeight: '39px',
                          position: 'relative',
                          top: '2px',
                        }}
                        component='label'
                        disableElevation
                        id='pdf-file'
                        onChange={(e) => uploadFileHandler(e, false, 'pdf')}>
                        Upload PDF
                        <input type='file' hidden />
                      </Button>
                    </div>
                  </div>
                </div>
                {/* Multi-column Row 2 */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  {/* Upload Book Cover */}
                  <div
                    style={{
                      flexGrow: '1',
                    }}>
                    {coverUploading && (
                      <LinearProgress style={{ marginBottom: '5px' }} />
                    )}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        flexGrow: '1',
                      }}>
                      <TextField
                        variant='outlined'
                        margin='dense'
                        id='cover'
                        style={{ flexGrow: '1' }}
                        fullwidth
                        type='text'
                        label='Upload Book Cover'
                        name='cover'
                        autoComplete='cover'
                        value={updateCover}
                        onChange={(e) => setUpdateCover(e.target.value)}
                      />
                      <Button
                        variant='contained'
                        style={{
                          minHeight: '39px',
                          position: 'relative',
                          top: '2px',
                        }}
                        component='label'
                        disableElevation
                        id='image-file'
                        onChange={(e) => uploadFileHandler(e, false, 'cover')}>
                        Upload Image
                        <input type='file' hidden />
                      </Button>
                    </div>
                  </div>
                  {/* Upload Video */}
                  <div
                    style={{
                      flexGrow: '1',
                    }}>
                    {videoUploading && (
                      <LinearProgress style={{ marginBottom: '5px' }} />
                    )}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        flexGrow: '1',
                      }}>
                      <TextField
                        variant='outlined'
                        margin='dense'
                        id='uploadIntroVideo'
                        style={{ flexGrow: '1' }}
                        fullwidth
                        type='text'
                        label='Upload Intro Video'
                        name='uploadIntroVideo'
                        autoComplete='introVideo'
                        value={updateIntroVideo}
                        onChange={(e) => setUpdateIntroVideo(e.target.value)}
                      />
                      <Button
                        variant='contained'
                        style={{
                          minHeight: '39px',
                          position: 'relative',
                          top: '2px',
                        }}
                        component='label'
                        disableElevation
                        id='image-file'
                        onChange={(e) => uploadFileHandler(e, false, 'video')}>
                        Upload Video
                        <input type='file' hidden />
                      </Button>
                    </div>
                  </div>
                </div>
                <Divider style={{ margin: '1.2rem 0' }} />
                {/* Chapters adder */}
                <Typography variant='h5' gutterBottom>
                  <strong>Add Chapters</strong>
                </Typography>
                <div style={{ display: 'flex', gap: '2rem' }}>
                  <Box
                    style={{
                      display: 'flex',
                      gap: '0.5rem',
                      flexDirection: 'column',
                    }}
                    width='100%'>
                    <TextField
                      variant='outlined'
                      margin='dense'
                      id='chapterAdder'
                      fullwidth
                      type='text'
                      label='Chapter Name'
                      name='chapterAdder'
                      autoComplete='chapters'
                      value={chapAdder}
                      onChange={(e) => setChapAdder(e.target.value)}
                    />
                    <Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      type='button'
                      onClick={updateChapter}>
                      Add Chapter
                    </Button>
                  </Box>
                  <Box
                    component={Paper}
                    variant='outlined'
                    width='100%'
                    style={{ marginTop: '7px' }}
                    className={
                      updateChapters.length === 0
                        ? classes.chaptersListBox
                        : null
                    }>
                    {updateChapters.length === 0 ? (
                      <div className={classes.noChaptersDisplay}>
                        <Icon component={ImportContactsIcon} fontSize='large' />
                        <Typography component='h5' variant='h5'>
                          No chapters yet
                        </Typography>
                      </div>
                    ) : (
                      <List
                        component='nav'
                        aria-label='secondary mailbox folders'>
                        {updateChapters.map((chap, i) => (
                          <ListItem button key={i}>
                            <ListItemAvatar>
                              <Avatar className={classes.chapterNo}>
                                {i + 1}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={chap} />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge='end'
                                color='secondary'
                                onClick={(e) => deleteUpdatedChapter(chap)}>
                                <DeleteOutlineIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </Box>
                </div>
              </DialogContent>
            </form>
          </Dialog>
          {/* Delete book dialog */}
          <Dialog
            TransitionComponent={ZoomTransition}
            open={deletePopup}
            onClose={handleDeletePopupClose}>
            <DialogTitle id='delete-dialog-title'>Delete Book</DialogTitle>
            <DialogContent>
              {deleteLoading && (
                <LinearProgress
                  color='secondary'
                  className={classes.progressSpacing}
                />
              )}
              {deleteError && <Alert severity='error'>{deleteError}</Alert>}
              <DialogContentText id='delete-dialog-description'>
                Are you sure? Deleting book <strong>{toDeleteBook.name}</strong>{' '}
                will remove this book and all it's pages permanently!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleDeletePopupClose}
                color='primary'
                autoFocus>
                Cancel
              </Button>
              <Button
                onClick={() => handleDeleteBook(toDeleteBook._id)}
                color='secondary'>
                Confirm Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </section>
    </>
  );
};

export default BooksScreen;
