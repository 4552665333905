export const BATCH_LIST_REQUEST = 'BATCH_LIST_REQUEST';
export const BATCH_LIST_SUCCESS = 'BATCH_LIST_SUCCESS';
export const BATCH_LIST_FAIL = 'BATCH_LIST_FAIL';

export const BATCH_CREATE_REQUEST = 'BATCH_CREATE_REQUEST';
export const BATCH_CREATE_SUCCESS = 'BATCH_CREATE_SUCCESS';
export const BATCH_CREATE_FAIL = 'BATCH_CREATE_FAIL';
export const BATCH_CREATE_RESET = 'BATCH_CREATE_RESET';

export const BATCH_UPDATE_REQUEST = 'BATCH_UPDATE_REQUEST';
export const BATCH_UPDATE_SUCCESS = 'BATCH_UPDATE_SUCCESS';
export const BATCH_UPDATE_FAIL = 'BATCH_UPDATE_FAIL';
export const BATCH_UPDATE_RESET = 'BATCH_UPDATE_RESET';

export const BATCH_DELETE_REQUEST = 'BATCH_DELETE_REQUEST';
export const BATCH_DELETE_SUCCESS = 'BATCH_DELETE_SUCCESS';
export const BATCH_DELETE_FAIL = 'BATCH_DELETE_FAIL';

export const BATCH_STATUS_REQUEST = 'BATCH_STATUS_REQUEST';
export const BATCH_STATUS_SUCCESS = 'BATCH_STATUS_SUCCESS';
export const BATCH_STATUS_FAIL = 'BATCH_STATUS_FAIL';
