/**
 * @fileOverview   Reducer for the Batch Schema to perform CRUD operations
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import {
  BATCH_LIST_REQUEST,
  BATCH_LIST_SUCCESS,
  BATCH_LIST_FAIL,
  BATCH_CREATE_REQUEST,
  BATCH_CREATE_SUCCESS,
  BATCH_CREATE_FAIL,
  BATCH_CREATE_RESET,
  BATCH_UPDATE_REQUEST,
  BATCH_UPDATE_SUCCESS,
  BATCH_UPDATE_FAIL,
  BATCH_UPDATE_RESET,
  BATCH_DELETE_REQUEST,
  BATCH_DELETE_SUCCESS,
  BATCH_DELETE_FAIL,
  BATCH_STATUS_REQUEST,
  BATCH_STATUS_SUCCESS,
  BATCH_STATUS_FAIL,
} from '../constants/batchConstants';

/**
 * Get the list of all batches
 *
 * @param {Object} state Initial state of batchesList in the store.
 * @param {Object} action Object with payload of all batches.
 * @return {Object} New state object
 */
export const batchesListReducer = (state = { batches: [] }, action) => {
  switch (action.type) {
    case BATCH_LIST_REQUEST:
      return { loading: true, batches: [] };
    case BATCH_LIST_SUCCESS:
      return { loading: false, batches: action.payload };
    case BATCH_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

/**
 * Create a new batch
 *
 * @param {Object} state Initial state of batch in the store.
 * @param {Object} action Object with payload of the newly created batch.
 * @return {Object} New state object
 */
export const batchCreateReducer = (state = { batch: {} }, action) => {
  switch (action.type) {
    case BATCH_CREATE_REQUEST:
      return { loading: true, ...state };
    case BATCH_CREATE_SUCCESS:
      return { loading: false, success: true, batch: action.payload };
    case BATCH_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case BATCH_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

/**
 * Update an existing batch
 *
 * @param {Object} state Initial state of batch in the store.
 * @param {Object} action Object with payload of the newly updated batch.
 * @return {Object} New state object
 */
export const batchUpdateReducer = (state = { batch: {} }, action) => {
  switch (action.type) {
    case BATCH_UPDATE_REQUEST:
      return { loading: true, ...state };
    case BATCH_UPDATE_SUCCESS:
      return { loading: false, success: true, batch: action.payload };
    case BATCH_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case BATCH_UPDATE_RESET:
      return { batch: {} };
    default:
      return state;
  }
};

/**
 * Delete an existing batch
 *
 * @param {Object} state Initial state of batch in the store.
 * @param {Object} action (optional) Object with payload of an error.
 * @return {Object} New state object
 */
export const batchDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_DELETE_REQUEST:
      return { loading: true };
    case BATCH_DELETE_SUCCESS:
      return { loading: false, success: true };
    case BATCH_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

/**
 * Activate/Deactivate batch
 *
 * @param {Object} state Initial state of batch in the store.
 * @param {Object} action (optional) Object with payload of an error.
 * @return {Object} New state object
 */
export const batchStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_STATUS_REQUEST:
      return { loading: true };
    case BATCH_STATUS_SUCCESS:
      return { loading: false, success: true };
    case BATCH_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
