import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LabsHeader from '../components/LabsHeader';
import { Link as RouterLink } from 'react-router-dom';
import {
  makeStyles,
  Button,
  Typography,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Checkbox,
  FormGroup,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
// import { Alert, AlertTitle } from '@material-ui/lab';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';
import {
  // currentQuestionLoader,
  listExamDetails,
  submitScores,
} from '../actions/examActions';
import { studentAuthCheck as studentAuthCheckAction } from '../actions/studentActions';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[100],
    minHeight: 'calc(100vh - 206px)',
    display: 'flex',
  },
  sidebar: {
    width: '14%',
    backgroundColor: 'white',
    borderRight: `1px solid ${grey[300]}`,
    paddingTop: '1rem',
  },
  sideSpace: {
    padding: '0 1rem',
    marginTop: '1rem',
    marginBottom: '0.7rem',
  },
  mainContent: {
    padding: '2rem',
    background: grey[100],
    width: '100%',
    minHeight: 'calc(100vh - 202px)',
    overflow: 'auto',
  },
  pageContent: {
    padding: '2rem',
    '& h2': {
      marginTop: '0',
      fontSize: '1.7rem',
      fontWeight: '500',
    },
    '& h3': {
      fontSize: '1.3rem',
      marginTop: '2.5rem',
    },
    '& h6': {
      fontSize: '1rem',
      marginTop: '0',
      marginBottom: '0.2rem',
      opacity: '0.4',
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
    '& img': {
      width: '100%!important',
      margin: '1rem 0',
    },
    '& li': {
      fontSize: '0.9rem',
      marginBottom: '0.7rem',
    },
    '& pre': {
      padding: '1.2rem',
      backgroundColor: '#f1f1f1',
      whiteSpace: 'pre-wrap',
    },
  },
  description: {
    padding: '2rem 0',
    fontSize: '1rem',
  },
  pageNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '36.5px',
  },
}));

const ExamSingleScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [loadedQuestion, setLoadedQuestion] = useState({});
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [inputtedAnswer, setInputtedAnswer] = useState(null);
  const [checkAnswer, setCheckAnswer] = useState(null);
  const [multipleOptionsSelected, setMultipleOptionsSelected] = useState([]);
  const [questionsAnswered, setQuestionsAnswered] = useState([]);
  const [examCompleted, setExamCompleted] = useState(false);
  const [finalResult, setFinalResult] = useState(null);
  const [studentPoints, setStudentPoints] = useState([]);

  const id = match.params.id;

  // console.log(multipleOptionsSelected);

  /* ---------------------------- Redux State Data ---------------------------- */
  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo: studentLoginInfo } = studentLogin;

  const studentAuthCheck = useSelector((state) => state.studentAuthCheck);
  const { studentInfo } = studentAuthCheck;

  const examDetails = useSelector((state) => state.examDetails);
  const { loading, error, exam } = examDetails;

  /* --------------------------- Life Cycle Methods --------------------------- */
  useEffect(() => {
    if (studentLoginInfo) {
      dispatch(studentAuthCheckAction());
      dispatch(listExamDetails(id));
    } else {
      history.push('/login');
    }

    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [dispatch, history, studentLoginInfo, id, currentQuestion]);

  useEffect(() => {
    if (exam) {
      setLoadedQuestion(
        exam.questions.filter((asm) => +asm.questionNo === +currentQuestion)[0]
      );

      if (studentInfo && studentInfo.points && exam._id) {
        const alreadyTaken = studentInfo.points.find(
          (p) => p.examId.toString() === exam._id.toString()
        );

        if (alreadyTaken) {
          setExamCompleted(true);
          setFinalResult(alreadyTaken);
        } else {
          setExamCompleted(false);
          setFinalResult(null);
        }
      }

      /* --------------------------- Set Student Points --------------------------- */
      if (studentInfo && studentInfo.points) {
        // console.log(studentInfo.points);
        setStudentPoints(studentInfo.points.find((e) => e.examId === exam._id));
      }
    }
  }, [exam, checkAnswer]);

  useEffect(() => {
    setCheckAnswer(null);
    setSelectedAnswer(null);
  }, [loadedQuestion]);

  /* ------------------------------ Check Answer ------------------------------ */
  const handleAnswerCheck = () => {
    if (
      loadedQuestion.type === 'choice' &&
      !loadedQuestion.multipleSelectable
    ) {
      return loadedQuestion.answerInput === selectedAnswer;
      // setDisplayCorrectAnswer(loadedQuestion.answerInput.toString());
    }

    if (loadedQuestion.type === 'choice' && loadedQuestion.multipleSelectable) {
      // console.log(loadedQuestion.answerInput.split('||').sort().join('||'));
      // console.log(multipleOptionsSelected.sort().join('||'));
      return (
        loadedQuestion.answerInput.split('||').sort().join('||') ===
        multipleOptionsSelected.sort().join('||')
      );
    }

    if (loadedQuestion.type === 'input') {
      if (inputtedAnswer) {
        if (loadedQuestion.answerInputCaseSensitive) {
          return (
            loadedQuestion.answerInput.trim().toLowerCase() ===
            inputtedAnswer.trim().toLowerCase()
          );
        } else {
          return loadedQuestion.answerInput.trim() === inputtedAnswer.trim();
        }
      }
    }
  };

  /* ------------------------------ Submit Answer ----------------------------- */
  const submitAnswer = () => {
    setMultipleOptionsSelected([]);
    const result = handleAnswerCheck();
    const answer = {
      questionId: loadedQuestion.questionNo,
      selectedAnswer:
        loadedQuestion.type === 'choice' && loadedQuestion.multipleSelectable
          ? multipleOptionsSelected.sort().join(',')
          : loadedQuestion.type === 'choice' &&
            !loadedQuestion.multipleSelectable
          ? selectedAnswer
          : inputtedAnswer,
      result: result ? 'correct' : 'incorrect',
      points: result ? 10 : 0,
    };

    setQuestionsAnswered([...questionsAnswered, answer]);

    if (currentQuestion < +exam.questionCount) {
      setCurrentQuestion(+currentQuestion + 1);
    }
  };

  /* ---- Check if question is answered (to disable 'submit answer' button) --- */
  const isQuestionAnswered = () => {
    return questionsAnswered.map((q) => q.questionId).includes(currentQuestion);
  };

  /* --------------------------- Finish examination --------------------------- */
  const handleFinishExam = (e) => {
    e.preventDefault();

    const score = questionsAnswered.reduce(
      (acc, currVal) => acc + currVal.points,
      0
    );

    const average = score / +exam.questionCount;

    const finalResult = {
      examId: exam._id,
      questions: questionsAnswered,
      score,
      average,
      date: new Date().toString().substring(0, 15),
    };

    dispatch(submitScores(finalResult));

    setFinalResult(finalResult);
    setExamCompleted(true);

    dispatch(studentAuthCheckAction());
  };

  // console.log(studentInfo);
  // console.log(studentPoints);

  /* ---------------------- Handle multi-choice selection --------------------- */
  const handleMultipleChoiceSelection = (value, checked) => {
    if (checked) {
      setMultipleOptionsSelected([...multipleOptionsSelected, value]);
    } else {
      setMultipleOptionsSelected(
        multipleOptionsSelected.filter((o) => o !== value)
      );
    }
  };

  /* ----------------------------------- JSX ---------------------------------- */
  return (
    <>
      <LabsHeader
        pageTitle={exam ? `Exam ${exam.no} - ${exam.title}` : 'Exam'}
        backBtn={{ action: () => history.goBack() }}
      />

      {exam && (
        <section className={classes.section}>
          <div className={classes.mainContent}>
            <div style={{ width: '70%', margin: '0 auto' }}>
              <Paper className={classes.pageContent}>
                {/* ------------------------------- EXAM RESULT ------------------------------ */}
                {examCompleted ? (
                  <>
                    <Typography
                      variant='h5'
                      color='default'
                      style={{ fontSize: '1.3rem' }}
                    >
                      <strong>Exam:</strong> {exam.title}
                    </Typography>
                    <Typography variant='h5' style={{ fontSize: '1.3rem' }}>
                      <strong>Status:</strong> Completed
                    </Typography>
                    <div style={{ height: '1rem' }} />
                    <hr style={{ color: 'lightgray' }} />
                    <div style={{ height: '2rem' }} />
                    <Typography variant='h4'>
                      Your Score:{' '}
                      <strong>
                        {(finalResult.score / (exam.questionCount * 10)) * 100}%
                      </strong>
                    </Typography>
                    <div style={{ height: '4rem' }}></div>
                    <Button
                      component={RouterLink}
                      to={`/exams/${exam._id}/results`}
                      variant='contained'
                      fullWidth
                      color='primary'
                    >
                      View Detailed Result
                    </Button>
                  </>
                ) : loadedQuestion ? (
                  <>
                    <h6>Question {loadedQuestion.questionNo}</h6>
                    <h2>{loadedQuestion.title}</h2>
                    {loadedQuestion.questionBody && (
                      <Typography
                        variant='body2'
                        style={{
                          marginBottom: '1.5rem',
                          fontSize: '1rem',
                          opacity: '0.8',
                        }}
                      >
                        {loadedQuestion.questionBody}
                      </Typography>
                    )}
                    {loadedQuestion.image && (
                      <img
                        src={loadedQuestion.image}
                        style={{
                          maxWidth:
                            loadedQuestion.imageSize && loadedQuestion.imageSize
                              ? `${loadedQuestion.imageSize}%`
                              : '90%',
                          marginBottom: '2rem',
                        }}
                      />
                    )}
                    <form onSubmit={(e) => handleFinishExam(e)}>
                      {/* Single-Choice */}
                      {loadedQuestion &&
                        loadedQuestion.type === 'choice' &&
                        !loadedQuestion.multipleSelectable && (
                          <FormControl component='fieldset'>
                            <RadioGroup
                              value={selectedAnswer}
                              onChange={(e) =>
                                setSelectedAnswer(e.target.value)
                              }
                            >
                              {loadedQuestion.options &&
                                loadedQuestion.options.map((option) => (
                                  <FormControlLabel
                                    key={option}
                                    value={option}
                                    control={<Radio color='primary' />}
                                    label={option}
                                  />
                                ))}
                            </RadioGroup>
                            <Button
                              type='button'
                              variant='outlined'
                              color='primary'
                              className={classes.button}
                              onClick={() => submitAnswer()}
                              style={{ marginTop: '2rem' }}
                              disabled={isQuestionAnswered()}
                            >
                              Submit Answer
                            </Button>
                          </FormControl>
                        )}

                      {/* Multi-Choice */}
                      {loadedQuestion &&
                        loadedQuestion.type === 'choice' &&
                        loadedQuestion.multipleSelectable && (
                          <FormControl
                            component='fieldset'
                            className={classes.formControl}
                          >
                            <FormGroup>
                              {loadedQuestion.options &&
                                loadedQuestion.options.map((option) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        color='primary'
                                        key={option}
                                        onChange={(e) =>
                                          handleMultipleChoiceSelection(
                                            e.target.value,
                                            e.target.checked
                                          )
                                        }
                                        value={option}
                                      />
                                    }
                                    label={option}
                                  />
                                ))}
                            </FormGroup>
                            <Button
                              type='button'
                              variant='outlined'
                              color='primary'
                              className={classes.button}
                              onClick={() => submitAnswer()}
                              style={{ marginTop: '2rem' }}
                              disabled={isQuestionAnswered()}
                            >
                              Submit Answer
                            </Button>
                          </FormControl>
                        )}

                      {/* Input Answer */}
                      {loadedQuestion && loadedQuestion.type === 'input' && (
                        <>
                          <TextField
                            variant='outlined'
                            margin='dense'
                            fullWidth
                            multiline
                            rows='5'
                            id='inputtedAnswer'
                            type='text'
                            label='Type your answer'
                            name='inputtedAnswer'
                            autoFocus
                            value={inputtedAnswer}
                            onChange={(e) => setInputtedAnswer(e.target.value)}
                          />
                          <Button
                            type='button'
                            variant='outlined'
                            color='primary'
                            className={classes.button}
                            onClick={() => submitAnswer()}
                            style={{ marginTop: '2rem' }}
                            disabled={isQuestionAnswered()}
                          >
                            Submit Answer
                          </Button>
                        </>
                      )}

                      {/* ---------------------------- Final Submission ---------------------------- */}
                      {currentQuestion === +exam.questionCount &&
                        questionsAnswered.length === +exam.questionCount && (
                          <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            fullWidth={TextTrackCue}
                            className={classes.button}
                            style={{ marginTop: '2rem' }}
                          >
                            Complete Exam and Exit
                          </Button>
                        )}
                    </form>
                  </>
                ) : (
                  <p>No question selected</p>
                )}
              </Paper>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ExamSingleScreen;
