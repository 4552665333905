import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import LabsHeader from '../components/LabsHeader';
import {
  makeStyles,
  Button,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Icon,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';
import { listBookDetails } from '../actions/bookActions';
import {
  listPageDetails,
  getNextPageDetails,
  getPrevPageDetails,
} from '../actions/pageActions';
import {
  PAGE_NEXT_REQUEST,
  PAGE_NEXT_RESET,
  PAGE_PREV_RESET,
} from '../constants/pageConstants';

import { setAddrForResources } from '../utils/imageMapper';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[100],
    minHeight: 'calc(100vh - 206px)',
    display: 'flex',
  },
  sidebar: {
    width: '20%',
    backgroundColor: 'white',
    borderRight: `1px solid ${grey[300]}`,
    paddingTop: '1rem',
  },
  sideSpace: {
    padding: '0 1rem',
    marginTop: '1rem',
    marginBottom: '0.7rem',
  },
  mainContent: {
    padding: '2rem',
    background: grey[100],
    width: '80%',
    height: 'calc(100vh - 202px)',
    overflow: 'auto',
  },
  pageContent: {
    '& video': {
      padding: '3rem',
    },
    '& h2': {
      marginTop: '0',
      fontSize: '2rem',
      fontWeight: '700',
    },
    '& h3': {
      fontSize: '1.3rem',
      marginTop: '2.5rem',
    },
    '& p': {
      fontSize: '0.9rem',
      lineHeight: '1.7',
    },
    '& img': {
      width: '100%!important',
      margin: '1rem 0',
    },
    '& li': {
      fontSize: '0.9rem',
      marginBottom: '0.7rem',
    },
    '& pre': {
      padding: '1.2rem',
      backgroundColor: '#f1f1f1',
      whiteSpace: 'pre-wrap',
    },
  },
  description: {
    padding: '2rem 0',
    fontSize: '1rem',
  },
  pageNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '36.5px',
  },
}));

const PageDetailsScreen = ({ history, match }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const bookId = match.params.bookId;
  const pageId = match.params.pageId;

  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo } = studentLogin;

  const bookDetails = useSelector((state) => state.bookDetails);
  const { loading, error, book } = bookDetails;

  const pagesList = useSelector((state) => state.pagesList);
  const {
    loading: allPagesLoading,
    error: allPagesError,
    pages: allPages,
  } = pagesList;

  const pageDetails = useSelector((state) => state.pageDetails);
  const {
    loading: pageDetailsLoading,
    error: pageLoadingError,
    page,
  } = pageDetails;

  const prevPageData = useSelector((state) => state.prevPage);
  const {
    loading: prevPageLoading,
    error: prevPageError,
    success: prevPageSuccess,
    prevPage,
  } = prevPageData;

  const nextPageData = useSelector((state) => state.nextPage);
  const {
    loading: nextPageLoading,
    error: nextPageError,
    success: nextPageSuccess,
    nextPage,
  } = nextPageData;

  useEffect(() => {
    dispatch({ type: PAGE_PREV_RESET });
    dispatch({ type: PAGE_NEXT_RESET });
  }, [dispatch, PAGE_PREV_RESET, PAGE_NEXT_RESET]);

  useEffect(() => {
    if (studentInfo) {
      dispatch(listBookDetails(bookId));
      dispatch(listPageDetails(pageId));
    } else {
      history.push('/login');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [dispatch, history, studentInfo, bookId, pageId]);

  useEffect(() => {
    if (page && page.no) {
      dispatch(getPrevPageDetails(bookId, +page.no - 1));
      dispatch(getNextPageDetails(bookId, +page.no + 1));
    }
  }, [dispatch, page]);

  console.log(page);

  return (
    <>
      <LabsHeader
        pageTitle={book && page ? book.title + ' - ' + page.title : ''}
        backBtn={{ action: () => history.push('/dashboard') }}
      />
      {book && allPages && pageDetails && (
        <section className={classes.section}>
          <div className={classes.sidebar}>
            <List>
              <ListItem
                key={book._id}
                button
                onClick={() => history.push(`/book/${book._id}`)}>
                <ListItemIcon>
                  <TurnedInNotIcon />
                </ListItemIcon>
                <ListItemText primary='Book Details' />
              </ListItem>
              <div className={classes.sideSpace}>
                <Typography variant='h6' style={{ fontSize: '1.2rem' }}>
                  Table of Contents
                </Typography>
              </div>
              {book &&
                book.pages.map((pg) => (
                  <ListItem
                    key={pg._id}
                    button
                    selected={pageId && pg._id === pageId}
                    onClick={() =>
                      history.push(`/book/${book._id}/page/${pg._id}`)
                    }>
                    <ListItemText primary={pg.title} />
                  </ListItem>
                ))}
            </List>
          </div>
          <div className={classes.mainContent}>
            <div
              className={classes.pageNavigation}
              style={{ marginBottom: '0.5rem' }}>
              {prevPageSuccess && prevPage && prevPage._id ? (
                <Button
                  component={RouterLink}
                  to={`/book/${bookId}/page/${prevPage._id}`}>
                  Previous
                </Button>
              ) : (
                <div></div>
              )}
              {nextPageSuccess && nextPage && nextPage._id ? (
                <Button
                  component={RouterLink}
                  to={`/book/${bookId}/page/${nextPage._id}`}>
                  Next
                </Button>
              ) : (
                <div></div>
              )}
            </div>

            {/* Actual Page Content */}
            <Paper
              className={classes.pageContent}
              style={page && !page.useHtmlContent ? { padding: '2rem' } : {}}>
              <h2
                style={
                  page && page.useHtmlContent
                    ? { padding: '2rem', margin: '0' }
                    : {}
                }>
                {page && page.title}
              </h2>

              {/* SHOW WSIWIG CONTENT */}
              {page && !page.useHtmlContent && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: page && page.content,
                  }}></div>
              )}

              {/* SHOW HTML CONTENT INSTEAD */}
              {page && page.useHtmlContent && (
                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        page &&
                        page.htmlContent &&
                        page.htmlContent.replaceAll(
                          'WIN_LOC',
                          setAddrForResources()
                        ),
                    }}></div>
                </div>
              )}
            </Paper>
            {/* END Actual Page */}

            <div
              className={classes.pageNavigation}
              style={{ marginTop: '0.5rem' }}>
              {prevPageSuccess && prevPage && prevPage._id ? (
                <Button
                  component={RouterLink}
                  to={`/book/${bookId}/page/${prevPage._id}`}>
                  Previous
                </Button>
              ) : (
                <div></div>
              )}
              {nextPageSuccess && nextPage && nextPage._id ? (
                <Button
                  component={RouterLink}
                  to={`/book/${bookId}/page/${nextPage._id}`}>
                  Next
                </Button>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default PageDetailsScreen;
