import {
  VIDEO_LIST_REQUEST,
  VIDEO_LIST_SUCCESS,
  VIDEO_LIST_FAIL,
  VIDEO_LIST_RESET,
  VIDEO_DETAILS_REQUEST,
  VIDEO_DETAILS_SUCCESS,
  VIDEO_DETAILS_FAIL,
  VIDEO_DETAILS_RESET,
  VIDEO_CREATE_REQUEST,
  VIDEO_CREATE_SUCCESS,
  VIDEO_CREATE_FAIL,
  VIDEO_CREATE_RESET,
  VIDEO_UPDATE_REQUEST,
  VIDEO_UPDATE_SUCCESS,
  VIDEO_UPDATE_FAIL,
  VIDEO_UPDATE_RESET,
  VIDEO_DELETE_REQUEST,
  VIDEO_DELETE_SUCCESS,
  VIDEO_DELETE_FAIL,
  VIDEO_NEXT_REQUEST,
  VIDEO_NEXT_SUCCESS,
  VIDEO_NEXT_FAIL,
  VIDEO_NEXT_RESET,
  VIDEO_PREV_REQUEST,
  VIDEO_PREV_SUCCESS,
  VIDEO_PREV_FAIL,
  VIDEO_PREV_RESET,
  VIDEO_LIST_BY_COURSE_REQUEST,
  VIDEO_LIST_BY_COURSE_SUCCESS,
  VIDEO_LIST_BY_COURSE_FAIL,
  VIDEO_LIST_BY_COURSE_RESET,
  VIDEO_LANGUAGE_ENGLISH,
  VIDEO_LANGUAGE_HINDI,
} from '../constants/videoConstants';

export const videoListReducer = (state = { videos: [] }, action) => {
  switch (action.type) {
    case VIDEO_LIST_REQUEST:
      return { loading: true, videos: [] };
    case VIDEO_LIST_SUCCESS:
      return { loading: false, videos: action.payload };
    case VIDEO_LIST_FAIL:
      return { loading: false, error: action.payload };
    case VIDEO_LIST_RESET:
      return { videos: [] };
    default:
      return state;
  }
};

export const videoListByCourseReducer = (state = { videos: [] }, action) => {
  switch (action.type) {
    case VIDEO_LIST_BY_COURSE_REQUEST:
      return { loading: true, videos: [] };
    case VIDEO_LIST_BY_COURSE_SUCCESS:
      return { loading: false, videos: action.payload };
    case VIDEO_LIST_BY_COURSE_FAIL:
      return { loading: false, error: action.payload };
    case VIDEO_LIST_BY_COURSE_RESET:
      return { videos: [] };
    default:
      return state;
  }
};

export const videoDetailsReducer = (state = { video: {} }, action) => {
  switch (action.type) {
    case VIDEO_DETAILS_REQUEST:
      return { ...state, loading: true };
    case VIDEO_DETAILS_SUCCESS:
      return { loading: false, video: action.payload };
    case VIDEO_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case VIDEO_DETAILS_RESET:
      return { video: {} };
    default:
      return state;
  }
};

export const videoCreateReducer = (state = { video: {} }, action) => {
  switch (action.type) {
    case VIDEO_CREATE_REQUEST:
      return { loading: true };
    case VIDEO_CREATE_SUCCESS:
      return { loading: false, success: true, video: action.payload };
    case VIDEO_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case VIDEO_CREATE_RESET:
      return { video: {} };
    default:
      return state;
  }
};

export const videoUpdateReducer = (state = { video: {} }, action) => {
  switch (action.type) {
    case VIDEO_UPDATE_REQUEST:
      return { loading: true, ...state };
    case VIDEO_UPDATE_SUCCESS:
      return { loading: false, success: true, video: action.payload };
    case VIDEO_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case VIDEO_UPDATE_RESET:
      return { video: {} };
    default:
      return state;
  }
};

export const videoDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case VIDEO_DELETE_REQUEST:
      return { loading: true };
    case VIDEO_DELETE_SUCCESS:
      return { loading: false, success: true };
    case VIDEO_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const prevVideoReducer = (state = { prevVideo: {} }, action) => {
  switch (action.type) {
    case VIDEO_PREV_REQUEST:
      return { loading: true };
    case VIDEO_PREV_SUCCESS:
      return { loading: false, success: true, prevVideo: action.payload };
    case VIDEO_PREV_FAIL:
      return { loading: false, error: action.payload };
    case VIDEO_PREV_RESET:
      return { prev: {} };
    default:
      return state;
  }
};

export const nextVideoReducer = (state = { nextVideo: {} }, action) => {
  switch (action.type) {
    case VIDEO_NEXT_REQUEST:
      return { loading: true };
    case VIDEO_NEXT_SUCCESS:
      return { loading: false, success: true, nextVideo: action.payload };
    case VIDEO_NEXT_FAIL:
      return { loading: false, error: action.payload };
    case VIDEO_NEXT_RESET:
      return { next: {} };
    default:
      return state;
  }
};

export const videoLanguageSelectorReducer = (
  state = { currentLanguage: 'eng' },
  action
) => {
  switch (action.type) {
    case VIDEO_LANGUAGE_ENGLISH:
      return { currentLanguage: 'eng' };
    case VIDEO_LANGUAGE_HINDI:
      return { currentLanguage: 'hin' };
    default:
      return state;
  }
};
