import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  CircularProgress,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Select,
  Paper,
  Chip,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Tooltip,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import { ComputerOutlined, RouterOutlined } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import LabsHeader from '../components/LabsHeader';
import InfoTable from '../components/InfoTable';
import { listCourses as listCoursesAction } from '../actions/courseActions';
import {
  listLabs as listLabsAction,
  createLab as createLabAction,
  updateLab as updateLabAction,
  deleteLab as deleteLabAction,
} from '../actions/labActions';
import {
  LAB_CREATE_RESET,
  LAB_UPDATE_RESET,
} from '../constants/labConstants';
import {
  SlideTransition,
  ZoomTransition,
} from '../components/Transitions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[200],
    padding: '50px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: 'calc(100vh - 206px)',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  appBar: {
    position: 'relative',
  },
  chip: {
    margin: 2,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  notifSpacing: {
    marginBottom: '10px',
  },
  spacer: {
    height: '20px',
  },
  markupText: {
    '& textarea': {
      fontFamily: 'monospace',
    },
  },
}));

/**
 * Component for showing/adding/editing labs (user side)
 */
const LabsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Dialog State
  const [createPopup, setCreatePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [uploading, setUploading] = useState(false);

  // Form and Local State
  const [no, setNo] = useState('');
  const [index, setIndex] = useState('');
  const [name, setName] = useState('');
  const [diagram, setDiagram] = useState('');
  const [labGuide, setLabGuide] = useState('');
  const [altLabGuide, setAltLabGuide] = useState(''); // ALT LAB GUIDE
  const [showPadd, setShowPadd] = useState(true); // SHOW PADDING
  const [disableWatermark, setDisableWatermark] = useState(false); // DISABLE WATERMARK
  const [video, setVideo] = useState('');
  const [diagramMap, setDiagramMap] = useState('');
  const [course, setCourse] = useState('');
  const [mdInitialConf, setMdInitialConf] = useState('');
  const [mdLabName, setMdLabName] = useState('');
  const [mdCourseName, setMdCourseName] = useState('');
  const [mdIouLab, setMdIouLab] = useState('');
  const [mdVmMachines, setMdVmMachines] = useState('');
  const [mdType, setMdType] = useState('');
  const [mdEvePorts, setMdEvePorts] = useState('');
  const [mdEveNodes, setMdEveNodes] = useState('');
  const [mdEveLabName, setMdEveLabName] = useState('');
  const [mdAllNodes, setMdAllNodes] = useState(true); // NEW -> EVE-NG All Nodes
  const [updateNo, setUpdateNo] = useState('');
  const [updateIndex, setUpdateIndex] = useState('');
  const [updateName, setUpdateName] = useState('');
  const [updateDiagram, setUpdateDiagram] = useState('');
  const [updateLabGuide, setUpdateLabGuide] = useState('');
  const [updateAltLabGuide, setUpdateAltLabGuide] = useState(''); // UPDATE ALT LAB GUIDE
  const [updateShowPadd, setUpdateShowPadd] = useState(true); // SHOW PADDING
  const [updateDisableWatermark, setUpdateDisableWatermark] =
    useState(false); // DISABLE WATERMARK
  const [updateVideo, setUpdateVideo] = useState(null);
  const [updateDiagramMap, setUpdateDiagramMap] = useState('');
  const [updateCourse, setUpdateCourse] = useState('');
  const [updateMdInitialConf, setUpdateMdInitialConf] = useState('');
  const [updateMdLabName, setUpdateMdLabName] = useState('');
  const [updateMdCourseName, setUpdateMdCourseName] = useState('');
  const [updateMdIouLab, setUpdateMdIouLab] = useState('');
  const [updateMdVmMachines, setUpdateMdVmMachines] = useState('');
  const [updateMdType, setUpdateMdType] = useState('');
  const [updateMdEvePorts, setUpdateMdEvePorts] = useState('');
  const [updateMdEveNodes, setUpdateMdEveNodes] = useState('');
  const [updateMdEveLabName, setUpdateMdEveLabName] = useState('');
  const [updateMdAllNodes, setUpdateMdAllNodes] = useState(true); // NEW -> EVE-NG All Nodes
  const [updateLabId, setUpdateLabId] = useState('');
  const [toDeleteLab, setToDeleteLab] = useState('');

  // Redux State Getters
  const useLogin = useSelector((state) => state.userLogin);
  const { userInfo } = useLogin;
  const labsList = useSelector((state) => state.labsList);
  const { loading, error, labs } = labsList;
  const coursesList = useSelector((state) => state.coursesList);
  const {
    loading: allCoursesLoading,
    error: allCoursesError,
    courses: allCourses,
  } = coursesList;
  const labCreate = useSelector((state) => state.labCreate);
  const {
    loading: createLoading,
    error: createError,
    success: createSuccess,
    lab: createdLab,
  } = labCreate;
  const labUpdate = useSelector((state) => state.labUpdate);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    lab: updatedLab,
  } = labUpdate;
  const labDelete = useSelector((state) => state.labDelete);
  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
  } = labDelete;

  // Lifecycle events
  useEffect(() => {
    if (userInfo) {
      dispatch(listLabsAction());
      dispatch(listCoursesAction());
    } else {
      history.push('/admin/login');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
    if (createSuccess) {
      handleCreatePopupClose();
      setCreatePopup(false);
      setNo('');
      setIndex('');
      setName('');
      setDiagram('');
      setLabGuide('');
      setAltLabGuide('');
      setShowPadd(true);
      setDisableWatermark(false);
      setDiagramMap('');
      setCourse('');
      setVideo('');
      setMdInitialConf('');
      setMdLabName('');
      setMdCourseName('');
      setMdIouLab('');
      setMdVmMachines('');
      setMdType('');
      setMdEvePorts('');
      setMdEveNodes('');
      setMdEveLabName('');
      setMdAllNodes(true);
      dispatch({ type: LAB_CREATE_RESET });
    }
    if (updateSuccess) {
      handleEditPopupClose();
      setUpdateNo('');
      setUpdateIndex('');
      setUpdateName('');
      setUpdateDiagram('');
      setUpdateLabGuide('');
      setUpdateAltLabGuide('');
      setUpdateShowPadd(true);
      setUpdateDisableWatermark(false);
      setUpdateDiagramMap('');
      setUpdateCourse('');
      setUpdateVideo('');
      setUpdateMdInitialConf('');
      setUpdateMdLabName('');
      setUpdateMdCourseName('');
      setUpdateMdIouLab('');
      setUpdateMdVmMachines('');
      setUpdateMdType('');
      setUpdateMdEvePorts('');
      setUpdateMdEveNodes('');
      setUpdateMdEveLabName('');
      setUpdateMdAllNodes(true);
      dispatch({ type: LAB_UPDATE_RESET });
    }
    if (deleteSuccess) {
      handleDeletePopupClose();
      setToDeleteLab({});
    }
  }, [
    dispatch,
    history,
    userInfo,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    createdLab,
    updatedLab,
  ]);

  // Popup Management
  const handleCreatePopupClick = () => {
    setCreatePopup(true);
  };
  const handleCreatePopupClose = () => {
    setCreatePopup(false);
  };
  const handleEditPopupClick = () => {
    setEditPopup(true);
  };
  const handleEditPopupClose = () => {
    setEditPopup(false);
    setUpdateNo('');
    setUpdateIndex('');
    setUpdateName('');
    setUpdateDiagram('');
    setUpdateLabGuide('');
    setUpdateAltLabGuide('');
    setUpdateShowPadd(true);
    setUpdateDisableWatermark(false);
    setUpdateVideo('');
    setUpdateDiagramMap('');
    setUpdateCourse('');
    setUpdateMdInitialConf('');
    setUpdateMdLabName('');
    setUpdateMdCourseName('');
    setUpdateMdIouLab('');
    setUpdateMdVmMachines('');
    setUpdateMdType('');
    setUpdateMdEvePorts('');
    setUpdateMdEveNodes('');
    setUpdateMdEveLabName('');
    setUpdateMdAllNodes(true);
  };
  const handleDeletePopupClick = (lab) => {
    setToDeleteLab(lab);
    setDeletePopup(true);
  };
  const handleDeletePopupClose = () => {
    setDeletePopup(false);
  };

  // Action Handlers
  const handleCreateLab = (e) => {
    e.preventDefault();
    const formData = {
      no,
      index,
      name,
      diagram,
      labGuide,
      altLabGuide,
      showPadd,
      disableWatermark,
      diagramMap,
      video,
      course,
      info: {
        initialConf: mdInitialConf,
        lab: mdLabName,
        course: mdCourseName,
        iou: mdIouLab,
        vmMachines: mdVmMachines,
        type: mdType,
        evePorts: mdEvePorts,
        eveNodes: mdEveNodes,
        eveLabName: mdEveLabName,
        allNodes: mdAllNodes,
      },
    };
    dispatch(createLabAction(formData));
  };
  const handleEditLab = (_e, id) => {
    labs.forEach((lab) => {
      if (lab._id === id) {
        setUpdateLabId(lab._id);
        setUpdateNo(lab.no);
        setUpdateIndex(lab.index);
        setUpdateName(lab.name);
        setUpdateDiagram(lab.diagram);
        setUpdateLabGuide(lab.labGuide);
        setUpdateAltLabGuide(lab.altLabGuide);
        setUpdateShowPadd(lab.showPadd);
        setUpdateDisableWatermark(lab.disableWatermark);
        setUpdateVideo(lab.video);
        setUpdateDiagramMap(lab.diagramMap);
        setUpdateCourse(lab.course._id);
        setUpdateMdInitialConf(lab.info.initialConf);
        setUpdateMdLabName(lab.info.lab);
        setUpdateMdCourseName(lab.info.course);
        setUpdateMdIouLab(lab.info.iou);
        setUpdateMdVmMachines(lab.info.vmMachines);
        setUpdateMdType(lab.info.type);
        setUpdateMdEvePorts(lab.info.evePorts);
        setUpdateMdEveNodes(lab.info.eveNodes);
        setUpdateMdEveLabName(lab.info.eveLabName);
        setUpdateMdAllNodes(lab.info.allNodes);
      }
    });
    handleEditPopupClick();
  };
  const handleUpdateLab = (e) => {
    e.preventDefault();
    const formData = {
      _id: updateLabId,
      no: updateNo,
      index: updateIndex,
      name: updateName,
      diagram: updateDiagram,
      labGuide: updateLabGuide,
      altLabGuide: updateAltLabGuide,
      showPadd: updateShowPadd,
      disableWatermark: updateDisableWatermark,
      diagramMap: updateDiagramMap,
      video: updateVideo,
      course: updateCourse,
      info: {
        initialConf: updateMdInitialConf,
        lab: updateMdLabName,
        course: updateMdCourseName,
        iou: updateMdIouLab,
        vmMachines: updateMdVmMachines,
        type: updateMdType,
        evePorts: updateMdEvePorts,
        eveNodes: updateMdEveNodes,
        eveLabName: updateMdEveLabName,
        allNodes: updateMdAllNodes,
      },
    };
    dispatch(updateLabAction(formData));
  };
  const handleDeleteLab = (id) => {
    dispatch(deleteLabAction(id));
    handleDeletePopupClose();
  };

  const uploadFileHandler = async (e, update = false) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    setUploading(true);
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post(
        '/api/upload',
        formData,
        config
      );
      if (update) {
        setUpdateDiagram(data);
      } else {
        setDiagram(data);
      }
      setUploading(false);
    } catch (err) {
      console.error(err);
      setUploading(false);
    }
  };

  return (
    <>
      <LabsHeader
        pageTitle={
          userInfo && userInfo.role === 'admin'
            ? `${labs ? 'Labs (' + labs.length + ' total)' : ''}`
            : 'Labs'
        }
        backBtn={{ action: () => history.push('/admin/dashboard') }}
        secondaryBtn={{
          label: 'Add New Lab',
          action: () => handleCreatePopupClick(),
          style: 'contained',
        }}
      />
      <section className={classes.section}>
        <Container>
          {loading && allCoursesLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 255px)',
              }}
            >
              <CircularProgress />
            </div>
          ) : error || allCoursesError ? (
            <Alert severity="error">{error || allCoursesError}</Alert>
          ) : (
            <InfoTable
              columns={['no.', 'name', 'course', 'type', 'actions']}
              rows={
                labs &&
                labs.map((lab) => ({
                  _id: lab._id,
                  no: `Lab ${lab.no}`,
                  name: (
                    <span style={{ fontWeight: '500' }}>
                      {lab.name}
                    </span>
                  ),
                  course: (
                    <Chip
                      label={lab.course && lab.course.name}
                      variant="outlined"
                    />
                  ),
                  type:
                    lab.info.type === 'telnet' ? (
                      <Tooltip
                        arrow
                        placement="right"
                        title="IOU/Telnet"
                      >
                        <RouterOutlined color="secondary" />
                      </Tooltip>
                    ) : lab.info.type === 'vnc' ? (
                      <Tooltip arrow placement="right" title="VNC">
                        <ComputerOutlined color="secondary" />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        arrow
                        placement="right"
                        title="IOU/Telnet + VNC"
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '3px',
                          }}
                        >
                          <ComputerOutlined color="secondary" />
                          <RouterOutlined color="secondary" />
                        </div>
                      </Tooltip>
                    ),
                  actions: [
                    ...(userInfo && userInfo.accessLevel >= 7
                      ? [
                          {
                            kind: 'action',
                            label: 'Edit',
                            type: 'primary',
                            action: (e) => handleEditLab(e, lab._id),
                          },
                        ]
                      : []),
                    ...(userInfo && userInfo.role === 'admin'
                      ? [
                          {
                            kind: 'action',
                            label: 'Delete',
                            type: 'secondary',
                            action: () => handleDeletePopupClick(lab),
                          },
                        ]
                      : []),
                  ],
                }))
              }
            />
          )}
          {/* Add new labs dialog */}
          <Dialog
            fullScreen
            open={createPopup}
            onClose={handleCreatePopupClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={SlideTransition}
          >
            <form onSubmit={handleCreateLab}>
              <AppBar className={classes.appBar} color="primary">
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleCreatePopupClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    Add New Lab
                  </Typography>
                  <Button color="inherit" type="submit">
                    Add Lab
                  </Button>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <DialogContentText style={{ marginTop: '20px' }}>
                  Create a new Lab. Please follow the correct HTML
                  formatting for lab guide and diagram map HTML
                  information.
                </DialogContentText>
                {createLoading && (
                  <LinearProgress
                    color="primary"
                    className={classes.notifSpacing}
                  />
                )}
                {createError && (
                  <Alert
                    severity="error"
                    className={classes.notifSpacing}
                  >
                    {createError}
                  </Alert>
                )}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="no"
                    type="text"
                    label="Position No."
                    name="no"
                    autoComplete="no"
                    autoFocus
                    value={no}
                    onChange={(e) => setNo(e.target.value)}
                    style={{ width: '15%' }}
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="index"
                    type="text"
                    label="Index No."
                    name="index"
                    autoComplete="index"
                    autoFocus
                    value={index}
                    onChange={(e) => setIndex(e.target.value)}
                    style={{ width: '15%' }}
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="name"
                    type="text"
                    label="Name"
                    name="name"
                    autoComplete="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <TextField
                  variant="outlined"
                  margin="dense"
                  multiline
                  rows="10"
                  fullWidth
                  id="labGuide"
                  type="text"
                  label="Lab Guide"
                  name="labGuide"
                  className={classes.markupText}
                  autoComplete="labGuide"
                  value={labGuide}
                  onChange={(e) => setLabGuide(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  multiline
                  rows="10"
                  fullWidth
                  id="altLabGuide"
                  type="text"
                  label="Alt Lab Guide"
                  helperText="Old pure html lab guides will go here"
                  name="altLabGuide"
                  className={classes.markupText}
                  autoComplete="labGuide"
                  value={labGuide}
                  onChange={(e) => setAltLabGuide(e.target.value)}
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      label="Show padding"
                      labelPlacement="right"
                      control={
                        <Switch
                          checked={showPadd}
                          onChange={(e) =>
                            setShowPadd(e.target.checked)
                          }
                          name="showPadd"
                          color="primary"
                        />
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      label="Disable built in watermark"
                      labelPlacement="right"
                      control={
                        <Switch
                          checked={disableWatermark}
                          onChange={(e) =>
                            setDisableWatermark(e.target.checked)
                          }
                          name="disableWatermark"
                          color="primary"
                        />
                      }
                    />
                  </FormGroup>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    style={{ flexGrow: '1' }}
                    required
                    className={classes.formControl}
                  >
                    <InputLabel id="labCourse">Course</InputLabel>
                    <Select
                      labelId="labCourse"
                      id="course"
                      name="course"
                      value={course}
                      onChange={(e) => setCourse(e.target.value)}
                      label="Course *"
                    >
                      {allCourses &&
                        allCourses.map((c) => (
                          <MenuItem key={c._id} value={c._id}>
                            {c.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <div
                    style={{
                      flexGrow: '1',
                    }}
                  >
                    {uploading && (
                      <LinearProgress
                        style={{ marginBottom: '5px' }}
                      />
                    )}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        flexGrow: '1',
                      }}
                    >
                      <TextField
                        variant="outlined"
                        margin="dense"
                        id="diagram"
                        style={{ flexGrow: '1' }}
                        fullwidth
                        type="text"
                        label="Upload Lab Topology Diagram"
                        name="diagram"
                        autoComplete="diagram"
                        value={diagram}
                        onChange={(e) => setDiagram(e.target.value)}
                      />
                      <Button
                        variant="contained"
                        style={{
                          minHeight: '39px',
                          position: 'relative',
                          top: '2px',
                        }}
                        component="label"
                        disableElevation
                        id="image-file"
                        onChange={uploadFileHandler}
                      >
                        Upload Diagram
                        <input type="file" hidden />
                      </Button>
                    </div>
                  </div>
                  {/* <TextField
                    variant="outlined"
                    margin="dense"
                    id="video"
                    type="text"
                    label="Video Link"
                    name="video"
                    autoComplete="video"
                    value={video}
                    onChange={(e) => setVideo(e.target.value)}
                  /> */}
                </div>
                <TextField
                  variant="outlined"
                  margin="dense"
                  multiline
                  fullWidth
                  id="diagramMap"
                  type="text"
                  rows="6"
                  label="Diagram Map HTML"
                  className={classes.markupText}
                  name="diagramMap"
                  autoComplete="diagramMap"
                  value={diagramMap}
                  onChange={(e) => setDiagramMap(e.target.value)}
                />
                <Box
                  component={Paper}
                  variant="outlined"
                  style={{
                    padding: '20px 30px 30px',
                    margin: '20px 0',
                  }}
                >
                  <Typography variant="h6" component="h6">
                    Metadata
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      marginTop: '5px',
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      required
                      size="small"
                      style={{ width: '25%' }}
                      className={classes.formControl}
                    >
                      <InputLabel id="labType">Lab Type</InputLabel>
                      <Select
                        labelId="labType"
                        id="mdType"
                        name="mdType"
                        value={mdType}
                        onChange={(e) => setMdType(e.target.value)}
                        label="Lab Type *"
                      >
                        <MenuItem key="1-telnet" value="telnet">
                          Telnet
                        </MenuItem>
                        <MenuItem key="1-vnc" value="vnc">
                          VNC
                        </MenuItem>
                        <MenuItem
                          key="1-telnet-vnc"
                          value="telnet-vnc"
                        >
                          Telnet-VNC
                        </MenuItem>
                        <MenuItem key="1-net-vnc" value="net-vnc">
                          Net-VNC
                        </MenuItem>
                        <MenuItem
                          key="1-eve-telnet-vnc"
                          value="eve-telnet-vnc"
                        >
                          EVE-Telnet-VNC
                        </MenuItem>
                        {/* NEW CODE */}
                        <MenuItem
                          key="1-eve-telnet-vnc"
                          value="eve-telnet-ext-vnc"
                        >
                          EVE-Telnet + External VNC
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      style={{ width: '75%' }}
                      multiline
                      id="mdVmMachines"
                      type="text"
                      label="VM Machine Names (comma separated)"
                      name="mdVmMachines"
                      autoComplete="mdVmMachines"
                      value={mdVmMachines}
                      onChange={(e) =>
                        setMdVmMachines(e.target.value)
                      }
                    />
                  </div>
                  {/* ##################### */}
                  {/* START ------ NEW EVE + EXTERNAL VNC CODE ------ (NEW LAB) */}
                  <div
                    style={{
                      display:
                        mdType === 'eve-telnet-ext-vnc'
                          ? 'flex'
                          : 'none',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      id="mdEveLabName"
                      type="text"
                      label="EVE Lab Name"
                      name="mdEveLabName"
                      autoComplete="mdEveLabName"
                      value={mdEveLabName}
                      onChange={(e) =>
                        setMdEveLabName(e.target.value)
                      }
                    />
                  </div>
                  {/* END ------ NEW EVE + EXTERNAL VNC CODE ------ */}
                  {/* ##################### */}
                  <div
                    style={{
                      display:
                        mdType === 'telnet' || mdType === 'telnet-vnc'
                          ? 'flex'
                          : 'none',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <TextField
                      variant="outlined"
                      margin="dense"
                      multiline
                      fullWidth
                      id="mdLabName"
                      type="text"
                      label="IOU Lab Name"
                      name="mdLabName"
                      autoComplete="mdLabName"
                      value={mdLabName}
                      onChange={(e) => setMdLabName(e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      margin="dense"
                      multiline
                      fullWidth
                      id="mdInitialConf"
                      type="text"
                      label="Initial Configuration"
                      name="mdInitialConf"
                      autoComplete="mdInitialConf"
                      value={mdInitialConf}
                      onChange={(e) =>
                        setMdInitialConf(e.target.value)
                      }
                    />
                    <TextField
                      variant="outlined"
                      margin="dense"
                      multiline
                      fullWidth
                      id="mdCourseName"
                      type="text"
                      label="Course Name"
                      name="mdCourseName"
                      autoComplete="mdCourseName"
                      value={mdCourseName}
                      onChange={(e) =>
                        setMdCourseName(e.target.value)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display:
                        mdType === 'eve-telnet-vnc' ? 'flex' : 'none',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      id="mdEvePorts"
                      type="text"
                      label="EVE Fixed Ports List (comma separated)"
                      name="mdEvePorts"
                      autoComplete="mdEvePorts"
                      value={mdEvePorts}
                      onChange={(e) => setMdEvePorts(e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      id="mdEveNodes"
                      type="text"
                      label="EVE Nodes List (comma separated)"
                      name="mdEveNodes"
                      autoComplete="mdEveNodes"
                      value={mdEveNodes}
                      onChange={(e) => setMdEveNodes(e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      id="mdEveLabName"
                      type="text"
                      label="EVE Lab Name"
                      name="mdEveLabName"
                      autoComplete="mdEveLabName"
                      value={mdEveLabName}
                      onChange={(e) =>
                        setMdEveLabName(e.target.value)
                      }
                    />
                    {/* NEW -> EVENG Switch Code */}
                    <FormGroup>
                      <FormControlLabel
                        label="Start All Nodes"
                        labelPlacement="top"
                        control={
                          <Switch
                            checked={mdAllNodes}
                            onChange={(e) =>
                              setMdAllNodes(e.target.checked)
                            }
                            name="allNodes"
                            color="primary"
                          />
                        }
                      />
                    </FormGroup>
                  </div>
                </Box>
              </DialogContent>
            </form>
          </Dialog>
          {/* Update lab dialog */}
          <Dialog
            fullScreen
            open={editPopup}
            onClose={handleEditPopupClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={SlideTransition}
          >
            <form onSubmit={handleUpdateLab}>
              <AppBar className={classes.appBar} color="primary">
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleEditPopupClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    Edit Lab — "{updateName}"
                  </Typography>
                  <Button autoFocus color="inherit" type="submit">
                    Update
                  </Button>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <div className={classes.spacer}></div>
                {updateLoading && (
                  <LinearProgress
                    color="primary"
                    className={classes.notifSpacing}
                  />
                )}
                {updateError && (
                  <Alert
                    severity="error"
                    className={classes.notifSpacing}
                  >
                    {updateError}
                  </Alert>
                )}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="no"
                    type="text"
                    label="Position No."
                    name="no"
                    autoComplete="no"
                    autoFocus
                    value={updateNo}
                    onChange={(e) => setUpdateNo(e.target.value)}
                    style={{ width: '15%' }}
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="index"
                    type="text"
                    label="Index No."
                    name="index"
                    autoComplete="index"
                    autoFocus
                    value={updateIndex}
                    onChange={(e) => setUpdateIndex(e.target.value)}
                    style={{ width: '15%' }}
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="name"
                    type="text"
                    label="Name"
                    name="name"
                    autoComplete="name"
                    value={updateName}
                    onChange={(e) => setUpdateName(e.target.value)}
                  />
                </div>
                <TextField
                  variant="outlined"
                  margin="dense"
                  multiline
                  rows="10"
                  fullWidth
                  id="labGuide"
                  type="text"
                  label="Lab Guide"
                  className={classes.markupText}
                  name="labGuide"
                  autoComplete="labGuide"
                  value={updateLabGuide}
                  onChange={(e) => setUpdateLabGuide(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  multiline
                  rows="10"
                  fullWidth
                  id="updateAltLabGuide"
                  helperText="Old pure html lab guides will go here"
                  type="text"
                  label="Alt Lab Guide"
                  className={classes.markupText}
                  name="updateAltLabGuide"
                  autoComplete="altLabGuide"
                  value={updateAltLabGuide}
                  onChange={(e) =>
                    setUpdateAltLabGuide(e.target.value)
                  }
                />
                {/* BUILT IN WATERMARK CONTROL */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      label="Show padding"
                      labelPlacement="right"
                      control={
                        <Switch
                          checked={updateShowPadd}
                          onChange={(e) =>
                            setUpdateShowPadd(e.target.checked)
                          }
                          name="showUpdatePadd"
                          color="primary"
                        />
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      label="Disable Built-in Watermark"
                      labelPlacement="right"
                      control={
                        <Switch
                          checked={updateDisableWatermark}
                          onChange={(e) =>
                            setUpdateDisableWatermark(
                              e.target.checked
                            )
                          }
                          name="updateDisableWatermark"
                          color="primary"
                        />
                      }
                    />
                  </FormGroup>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <FormControl
                    variant="outlined"
                    style={{ flexGrow: '1' }}
                    required
                    margin="dense"
                    className={classes.formControl}
                  >
                    <InputLabel id="labCourse">Course</InputLabel>
                    <Select
                      labelId="labCourse"
                      id="course"
                      name="course"
                      value={updateCourse}
                      onChange={(e) =>
                        setUpdateCourse(e.target.value)
                      }
                      label="Course *"
                    >
                      {allCourses &&
                        allCourses.map((c) => (
                          <MenuItem key={c._id} value={c._id}>
                            {c.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <div
                    style={{
                      flexGrow: '1',
                    }}
                  >
                    {uploading && (
                      <LinearProgress
                        style={{ marginBottom: '5px' }}
                      />
                    )}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        flexGrow: '1',
                      }}
                    >
                      <TextField
                        variant="outlined"
                        margin="dense"
                        id="diagram"
                        type="text"
                        label="Upload Lab Topology Diagram"
                        name="diagram"
                        autoComplete="diagram"
                        value={updateDiagram}
                        onChange={(e) =>
                          setUpdateDiagram(e.target.value)
                        }
                        style={{ flexGrow: '1' }}
                        fullwidth
                      />
                      <Button
                        variant="contained"
                        style={{
                          minHeight: '39px',
                          position: 'relative',
                          top: '2px',
                        }}
                        component="label"
                        disableElevation
                        id="image-file"
                        onChange={(e) => uploadFileHandler(e, true)}
                      >
                        Upload Diagram
                        <input type="file" hidden />
                      </Button>
                    </div>
                  </div>
                  {/* <TextField
                    variant="outlined"
                    margin="dense"
                    id="video"
                    type="text"
                    label="Video Link"
                    name="video"
                    autoComplete="video"
                    value={updateVideo}
                    onChange={(e) => setUpdateVideo(e.target.value)}
                  /> */}
                </div>
                <TextField
                  variant="outlined"
                  margin="dense"
                  multiline
                  fullWidth
                  id="diagramMap"
                  type="text"
                  rows="6"
                  label="Diagram Map HTML"
                  className={classes.markupText}
                  name="diagramMap"
                  autoComplete="diagramMap"
                  value={updateDiagramMap}
                  onChange={(e) =>
                    setUpdateDiagramMap(e.target.value)
                  }
                />
                <Box
                  component={Paper}
                  variant="outlined"
                  style={{
                    padding: '20px 30px 30px',
                    margin: '20px 0',
                  }}
                >
                  <Typography variant="h6" component="h6">
                    Metadata
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      marginTop: '5px',
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      required
                      size="small"
                      style={{ width: '25%' }}
                      className={classes.formControl}
                    >
                      <InputLabel id="labType">Lab Type</InputLabel>
                      <Select
                        labelId="labType"
                        id="mdType"
                        name="mdType"
                        value={updateMdType}
                        onChange={(e) =>
                          setUpdateMdType(e.target.value)
                        }
                        label="Lab Type *"
                      >
                        <MenuItem key="1-telnet" value="telnet">
                          Telnet
                        </MenuItem>
                        <MenuItem key="1-vnc" value="vnc">
                          VNC
                        </MenuItem>
                        <MenuItem
                          key="1-telnet-vnc"
                          value="telnet-vnc"
                        >
                          Telnet-VNC
                        </MenuItem>
                        <MenuItem key="1-net-vnc" value="net-vnc">
                          Net-VNC
                        </MenuItem>
                        <MenuItem
                          key="1-eve-telnet-vnc"
                          value="eve-telnet-vnc"
                        >
                          EVE-Telnet-VNC
                        </MenuItem>
                        {/* NEW CODE */}
                        <MenuItem
                          key="1-eve-telnet-ext-vnc"
                          value="eve-telnet-ext-vnc"
                        >
                          EVE-Telnet + External VNC
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      style={{ width: '75%' }}
                      multiline
                      id="mdVmMachines"
                      type="text"
                      label="VM Machine Names (comma separated)"
                      name="mdVmMachines"
                      autoComplete="mdVmMachines"
                      value={updateMdVmMachines}
                      onChange={(e) =>
                        setUpdateMdVmMachines(e.target.value)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display:
                        updateMdType === 'telnet' ||
                        updateMdType === 'telnet-vnc'
                          ? 'flex'
                          : 'none',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <TextField
                      variant="outlined"
                      margin="dense"
                      multiline
                      fullWidth
                      id="mdLabName"
                      type="text"
                      label="IOU Lab Name"
                      name="mdLabName"
                      autoComplete="mdLabName"
                      value={updateMdLabName}
                      onChange={(e) =>
                        setUpdateMdLabName(e.target.value)
                      }
                    />
                    <TextField
                      variant="outlined"
                      margin="dense"
                      multiline
                      fullWidth
                      id="mdInitialConf"
                      type="text"
                      label="Initial Configuration"
                      name="mdInitialConf"
                      autoComplete="mdInitialConf"
                      value={updateMdInitialConf}
                      onChange={(e) =>
                        setUpdateMdInitialConf(e.target.value)
                      }
                    />
                    <TextField
                      variant="outlined"
                      margin="dense"
                      multiline
                      fullWidth
                      id="mdCourseName"
                      type="text"
                      label="Course Name"
                      name="mdCourseName"
                      autoComplete="mdCourseName"
                      value={updateMdCourseName}
                      onChange={(e) =>
                        setUpdateMdCourseName(e.target.value)
                      }
                    />
                  </div>
                  {/* ##################### */}
                  {/* START ------ NEW EVE + EXTERNAL VNC CODE ------ (UPDATE LAB) */}
                  <div
                    style={{
                      display:
                        updateMdType === 'eve-telnet-ext-vnc'
                          ? 'flex'
                          : 'none',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      id="mdUpdateEveLabName"
                      type="text"
                      label="EVE Lab Name"
                      name="mdUpdateEveLabName"
                      autoComplete="mdUpdateEveLabName"
                      value={updateMdEveLabName}
                      onChange={(e) =>
                        setUpdateMdEveLabName(e.target.value)
                      }
                    />
                  </div>
                  {/* ^^^^ END ------ NEW EVE + EXTERNAL VNC CODE ------ */}
                  {/* ##################### */}
                  <div
                    style={{
                      display:
                        updateMdType === 'eve-telnet-vnc'
                          ? 'flex'
                          : 'none',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      id="mdUpdateEvePorts"
                      type="text"
                      label="EVE Fixed Ports List (comma separated)"
                      name="mdUpdateEvePorts"
                      autoComplete="mdUpdateEvePorts"
                      value={updateMdEvePorts}
                      onChange={(e) =>
                        setUpdateMdEvePorts(e.target.value)
                      }
                    />
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      id="mdUpdateEveNodes"
                      type="text"
                      label="EVE Nodes List (comma separated)"
                      name="mdUpdateEveNodes"
                      autoComplete="mdUpdateEveNodes"
                      value={updateMdEveNodes}
                      onChange={(e) =>
                        setUpdateMdEveNodes(e.target.value)
                      }
                    />
                    <TextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      id="mdUpdateEveLabName"
                      type="text"
                      label="EVE Lab Name"
                      name="mdUpdateEveLabName"
                      autoComplete="mdUpdateEveLabName"
                      value={updateMdEveLabName}
                      onChange={(e) =>
                        setUpdateMdEveLabName(e.target.value)
                      }
                    />
                    {/* NEW -> EVENG Switch Code */}
                    <FormGroup>
                      <FormControlLabel
                        label="Start All Nodes"
                        labelPlacement="top"
                        control={
                          <Switch
                            checked={updateMdAllNodes}
                            onChange={(e) =>
                              setUpdateMdAllNodes(e.target.checked)
                            }
                            name="allNodes"
                            color="primary"
                          />
                        }
                      />
                    </FormGroup>
                  </div>
                </Box>
              </DialogContent>
            </form>
          </Dialog>
          {/* Delete lab dialog */}
          <Dialog
            TransitionComponent={ZoomTransition}
            open={deletePopup}
            onClose={handleDeletePopupClose}
          >
            <DialogTitle id="delete-dialog-title">
              Delete Lab
            </DialogTitle>
            <DialogContent>
              {deleteLoading && (
                <LinearProgress
                  color="secondary"
                  className={classes.progressSpacing}
                />
              )}
              {deleteError && (
                <Alert severity="error">{deleteError}</Alert>
              )}
              <DialogContentText id="delete-dialog-description">
                Are you sure? Deleting lab{' '}
                <strong>{toDeleteLab.name}</strong> will remove this
                lab and all it's details permanently!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleDeletePopupClose}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleDeleteLab(toDeleteLab._id)}
                color="secondary"
              >
                Confirm Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </section>
    </>
  );
};

export default LabsScreen;
