import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  CircularProgress,
  Container,
  Tab,
  AppBar,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { TabContext, TabList, TabPanel, Alert } from '@material-ui/lab';
import LabsHeader from '../components/LabsHeader';
import {
  listLabDetails,
  startLab,
  stopLab,
  resetLab,
  wipeLab,
  powerOnLab,
  powerOffLab,
  checkBootStatus,
  startNodes,
  stopNodes,
  wipeNodes,
} from '../actions/labActions';
import LabGuide from '../components/LabGuide';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';
import { mapper, setAddrForResources } from '../utils/imageMapper';

const useStyles = makeStyles((_theme) => ({
  topology: {
    padding: '60px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  root: {
    flexGrow: 1,
  },
  labguide: {
    padding: '60px 0',
  },
  tabbled: {
    background: grey[100],
    userSelect: 'none',
  },
  labGuideArea: {
    padding: '60px 0',
    background: grey[200],
    userSelect: 'none',
    '& img': {
      maxWidth: '100%',
    },
  },
}));

const LabDetailsScreen = ({ match, history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Local State
  const [tabValue, setTabValue] = useState('1');
  const [diagramMapCode, setDiagramMapCode] = useState(null);

  // Redux State Getters
  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo } = studentLogin;
  const labDetails = useSelector((state) => state.labDetails);
  const { loading, error, lab } = labDetails;

  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  // Lifecycle events
  useEffect(() => {
    if (studentInfo) {
      dispatch(listLabDetails(match.params.id));
    } else {
      history.push('/');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [match.params.id, dispatch, studentInfo, history]);

  useEffect(() => {
    document.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
    return () =>
      document.removeEventListener('contextmenu', (event) => {
        event.preventDefault();
      });
  }, []);

  // Second use effect for boot status check and diagram mapping
  useEffect(() => {
    if (studentInfo && Object.entries(lab.info).length !== 0) {
      dispatch(
        checkBootStatus({
          ips:
            studentInfo.network.ips && studentInfo.network.ips.length !== 0
              ? studentInfo.network.ips
              : null,
          ports:
            studentInfo.network.ports && studentInfo.network.ports.length !== 0
              ? studentInfo.network.ports
              : null,
          type: lab.info.type,
          vmMachines: lab.info.vmMachines,
        })
      );
    }
    if (studentInfo && (studentInfo.network.ips || studentInfo.network.ports)) {
      setDiagramMapCode(
        mapper(
          lab.info.type,
          studentInfo.network.ips,
          studentInfo.network.ports,
          lab.diagramMap,
          lab.info.evePorts ? lab.info.evePorts : null,
          studentInfo.network.singleIp
        )
      );
    }
  }, [lab, lab.info, studentInfo, dispatch]);

  // Power On and Off Functions
  let vmPayload = {};
  if (lab && (lab.info.type === 'telnet' || lab.info.type === 'telnet-vnc')) {
    vmPayload = {
      ips: studentInfo.network.ips,
      ports: null,
      vmMachinesNames: lab.info.vmMachines,
      type: lab.info.type,
    };
  } else if (lab && lab.info.type === 'vnc') {
    vmPayload = {
      ips: null,
      ports: studentInfo.network.ports,
      vmMachinesNames: lab.info.vmMachines,
      type: lab.info.type,
    };
  } else if (lab && lab.info.type === 'eve-telnet-vnc') {
    vmPayload = {
      ips: studentInfo.network.ips,
      ports: null,
      vmMachinesNames: lab.info.vmMachines,
      type: lab.info.type,
      evePorts: lab.info.evePorts,
      eveNodes: lab.info.eveNodes,
      eveLabName: lab.info.eveLabName,
      allNodes: Boolean(lab.info.allNodes),
    };
  } else if (lab && lab.info.type === 'eve-telnet-ext-vnc') {
    vmPayload = {
      ips: null,
      singleIpAddr: studentInfo.network.singleIp,
      ports: studentInfo.network.ports,
      vmMachinesNames: lab.info.vmMachines,
      type: lab.info.type,
      eveLabName: lab.info.eveLabName,
    };
    // console.log(vmPayload);
  } else {
    vmPayload = {
      ips: studentInfo.network.ips,
      ports: studentInfo.network.ports,
      vmMachinesNames: lab.info.vmMachines,
      type: lab.info.type,
    };
  }

  // console.log('FROM LABDETAILS', lab);

  const poweringActions = {
    poweron() {
      dispatch(powerOnLab(vmPayload));
    },
    poweroff() {
      dispatch(powerOffLab(vmPayload));
    },
  };

  // IOU Functions
  const iouPayload = {
    ips: studentInfo.network.ips,
    type: 'telnet',
    initialConfig: lab.info.initialConf,
    iouLab: lab.info.lab,
  };
  const iouActions = {};
  if (lab && (lab.info.type === 'telnet' || lab.info.type === 'telnet-vnc')) {
    iouActions.start = () => {
      dispatch(startLab(iouPayload));
    };
    iouActions.stop = () => {
      dispatch(stopLab(iouPayload));
    };
    iouActions.reset = () => {
      dispatch(resetLab(iouPayload));
    };
    iouActions.wipe = () => {
      dispatch(wipeLab(iouPayload));
    };
  }

  // console.log(studentInfo);

  // Diagram Link Mapping
  // const ipRegEx = RegExp(
  //   '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}'
  // );

  // console.log(diagramMapCode);

  console.log("FROM THE LAB DETAILS", lab.disableWatermark)

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'calc(100vh - 255px)',
          }}
        >
          <CircularProgress />
        </div>
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <>
          <LabsHeader
            pageTitle={`Lab ${lab.no}: ${lab.name}`}
            controls={true}
            needsIou={
              lab &&
              (lab.info.type === 'telnet' || lab.info.type === 'telnet-vnc')
                ? true
                : false
            }
            iouActions={
              lab &&
              (lab.info.type === 'telnet' || lab.info.type === 'telnet-vnc')
                ? iouActions
                : null
            }
            poweringActions={poweringActions}
            // backBtn={{ action: history.goBack }}
            backBtn={{ action: () => history.push('/labs') }}
            quaternaryBtn={
              lab && lab.info.type === 'eve-telnet-vnc'
                ? {
                    style: 'outlined',
                    action: {
                      start: () =>
                        dispatch(
                          startNodes({
                            ip: studentInfo.network.ips[0],
                            nodes: lab.info.eveNodes,
                            lab: lab.info.eveLabName,
                            command: 'start',
                            all: Boolean(lab.info.allNodes),
                          })
                        ),
                      stop: () =>
                        dispatch(
                          stopNodes({
                            ip: studentInfo.network.ips[0],
                            nodes: lab.info.eveNodes,
                            lab: lab.info.eveLabName,
                            command: 'stop',
                            all: Boolean(lab.info.allNodes),
                          })
                        ),
                      wipe: () =>
                        dispatch(
                          wipeNodes({
                            ip: studentInfo.network.ips[0],
                            nodes: lab.info.eveNodes,
                            lab: lab.info.eveLabName,
                            command: 'wipe',
                            all: Boolean(lab.info.allNodes),
                          })
                        ),
                    },
                  }
                : null
            }
            labGuideLink={`/lab/${match.params.id}/guide`}
          />
          <div
            style={{
              height: '100%',
              // background: grey[100],
              userSelect: 'none',
            }}
            className={`nosel prhdn`}
          >
            <TabContext value={tabValue}>
              <AppBar
                position="sticky"
                color="default"
                elevation={0}
                style={{
                  zIndex: 1,
                  top: '64px',
                  borderBottom: `1px solid ${grey[300]}`,
                }}
              >
                <TabList
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  centered
                >
                  <Tab label="Topology" value="1" />
                  <Tab label="Lab Guide" value="2" />
                </TabList>
              </AppBar>
              <TabPanel value="1">
                <Container className="prhdn">
                  <div className={classes.topology}>
                    <img
                      src={lab.diagram}
                      alt="Lab Topology Diagram"
                      useMap="#image-map"
                    />
                    <div
                      dangerouslySetInnerHTML={{ __html: diagramMapCode }}
                    ></div>
                  </div>
                </Container>
              </TabPanel>
              <TabPanel value="2" className={classes.labGuideArea}>
                <Container>
                  <div>
                    <LabGuide
                      content={
                        lab.labGuide &&
                        lab.labGuide.replaceAll(
                          'WIN_LOC',
                          setAddrForResources()
                        )
                      }
                      padded={lab && lab.showPadd && lab.showPadd}
                      disableWatermark={lab && lab.disableWatermark}
                    />
                  </div>
                </Container>
              </TabPanel>
            </TabContext>
          </div>
        </>
      )}
    </>
  );
};

export default LabDetailsScreen;
