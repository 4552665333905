/**
 * @fileOverview   Reducer to control the UI/Interface
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import {
  HEADER_FOOTER_SHOW,
  HEADER_FOOTER_HIDE,
} from '../constants/interfaceConstants';

/**
 * Hide/Show header and footer
 *
 * @param {Object} state Initial state of batchesList in the store.
 * @param {Object} action Object with payload of all batches.
 * @return {Object} New state object
 */
export const headerFooterDisplayReducer = (
  state = { header: true, footer: true },
  action
) => {
  switch (action.type) {
    case HEADER_FOOTER_SHOW:
      return { header: true, footer: true };
    case HEADER_FOOTER_HIDE:
      return { header: false, footer: false };
    default:
      return state;
  }
};
