/**
 * @fileOverview   Reducer for the Books Schema to perform CRUD operations
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import {
  BOOK_LIST_REQUEST,
  BOOK_LIST_SUCCESS,
  BOOK_LIST_FAIL,
  BOOK_LIST_RESET,
  // BOOK_INDEX_REQUEST,
  // BOOK_INDEX_SUCCESS,
  // BOOK_INDEX_FAIL,
  // BOOK_INDEX_RESET,
  BOOK_DETAILS_REQUEST,
  BOOK_DETAILS_SUCCESS,
  BOOK_DETAILS_FAIL,
  BOOK_DETAILS_RESET,
  BOOK_CREATE_REQUEST,
  BOOK_CREATE_SUCCESS,
  BOOK_CREATE_FAIL,
  BOOK_CREATE_RESET,
  BOOK_UPDATE_REQUEST,
  BOOK_UPDATE_SUCCESS,
  BOOK_UPDATE_FAIL,
  BOOK_UPDATE_RESET,
  BOOK_DELETE_REQUEST,
  BOOK_DELETE_SUCCESS,
  BOOK_DELETE_FAIL,
  BOOK_DETAILS_BY_COURSE_REQUEST,
  BOOK_DETAILS_BY_COURSE_FAIL,
  BOOK_DETAILS_BY_COURSE_SUCCESS,
  BOOK_DETAILS_BY_COURSE_RESET,
} from '../constants/bookConstants';

/**
 * Get all books
 *
 * @param {Object} state Initial state of books in the store.
 * @param {Object} action Object with payload of the fetched books.
 * @return {Object} New state object
 */
export const booksListReducer = (state = { books: [] }, action) => {
  switch (action.type) {
    case BOOK_LIST_REQUEST:
      return { loading: true, books: [] };
    case BOOK_LIST_SUCCESS:
      return { loading: false, books: action.payload };
    case BOOK_LIST_FAIL:
      return { loading: false, error: action.payload };
    case BOOK_LIST_RESET:
      return { books: [] };
    default:
      return state;
  }
};

/**
 * Get data of a single book
 *
 * @param {Object} state Initial state of book in the store.
 * @param {Object} action Object with payload of the single book.
 * @return {Object} New state object
 */
export const bookDetailsReducer = (
  state = { book: { chapters: [], pages: [] } },
  action
) => {
  switch (action.type) {
    case BOOK_DETAILS_REQUEST:
      return { ...state, loading: true };
    case BOOK_DETAILS_SUCCESS:
      return { loading: false, book: action.payload };
    case BOOK_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case BOOK_DETAILS_RESET:
      return { chapters: [], pages: [] };
    default:
      return state;
  }
};

/**
 * Create a new book
 *
 * @param {Object} state Initial state of book in the store.
 * @param {Object} action Object with payload of the newly created book.
 * @return {Object} New state object
 */
export const bookCreateReducer = (
  state = { chapters: [], pages: [] },
  action
) => {
  switch (action.type) {
    case BOOK_CREATE_REQUEST:
      return { loading: true };
    case BOOK_CREATE_SUCCESS:
      return { loading: false, success: true, book: action.payload };
    case BOOK_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case BOOK_CREATE_RESET:
      return { chapters: [], pages: [] };
    default:
      return state;
  }
};

/**
 * Update an existing book
 *
 * @param {Object} state Initial state of book in the store.
 * @param {Object} action Object with payload of the newly updated book.
 * @return {Object} New state object
 */
export const bookUpdateReducer = (
  state = { chapters: [], pages: [] },
  action
) => {
  switch (action.type) {
    case BOOK_UPDATE_REQUEST:
      return { loading: true, ...state };
    case BOOK_UPDATE_SUCCESS:
      return { loading: false, success: true, book: action.payload };
    case BOOK_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case BOOK_UPDATE_RESET:
      return { chapters: [], pages: [] };
    default:
      return state;
  }
};

/**
 * Delete an existing book
 *
 * @param {Object} state Initial state of book in the store.
 * @param {Object} action (optional) Object with payload of an optional error.
 * @return {Object} New state object
 */
export const bookDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BOOK_DELETE_REQUEST:
      return { loading: true };
    case BOOK_DELETE_SUCCESS:
      return { loading: false, success: true };
    case BOOK_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const bookDetailsByCourseReducer = (
  state = { book: { chapters: [], pages: [] } },
  action
) => {
  switch (action.type) {
    case BOOK_DETAILS_BY_COURSE_REQUEST:
      return { loading: true, books: [] };
    case BOOK_DETAILS_BY_COURSE_SUCCESS:
      return { loading: false, books: action.payload };
    case BOOK_DETAILS_BY_COURSE_FAIL:
      return { loading: false, error: action.payload };
    case BOOK_DETAILS_BY_COURSE_RESET:
      return { books: [] };
    default:
      return state;
  }
};
