import axios from 'axios';
import {
  EXAM_LIST_REQUEST,
  EXAM_LIST_SUCCESS,
  EXAM_LIST_FAIL,
  EXAM_DETAILS_REQUEST,
  EXAM_DETAILS_SUCCESS,
  EXAM_DETAILS_FAIL,
  EXAM_CREATE_REQUEST,
  EXAM_CREATE_SUCCESS,
  EXAM_CREATE_FAIL,
  EXAM_UPDATE_REQUEST,
  EXAM_UPDATE_SUCCESS,
  EXAM_UPDATE_FAIL,
  EXAM_DELETE_REQUEST,
  EXAM_DELETE_SUCCESS,
  EXAM_DELETE_FAIL,
  EXAMS_BY_COURSE_REQUEST,
  EXAMS_BY_COURSE_SUCCESS,
  EXAMS_BY_COURSE_FAIL,
  EXAM_SUBMIT_SCORE_REQUEST,
  EXAM_SUBMIT_SCORE_SUCCESS,
  EXAM_SUBMIT_SCORE_FAIL,
  EXAM_GET_POINTS_REQUEST,
  EXAM_GET_POINTS_SUCESS,
  EXAM_GET_POINTS_FAIL,
} from '../constants/examsConstants';
import { CURRENT_ASSESSMENT_QUESTION } from '../constants/assessmentsConstants';
import { snackActions } from '../utils/SnackBarUtils';

export const listExams = () => async (dispatch, getState) => {
  try {
    dispatch({ type: EXAM_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/api/exams', config);

    dispatch({ type: EXAM_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: EXAM_LIST_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listExamDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: EXAM_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const {
      studentLogin: { studentInfo },
    } = getState();

    if (userInfo) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`/api/exams/${id}`, config);

      dispatch({ type: EXAM_DETAILS_SUCCESS, payload: data });
    } else if (studentInfo) {
      const config = {
        headers: {
          Authorization: `Bearer ${studentInfo.token}`,
        },
      };

      const { data } = await axios.get(`/api/exams/${id}/student`, config);

      dispatch({ type: EXAM_DETAILS_SUCCESS, payload: data });
    }
  } catch (err) {
    dispatch({
      type: EXAM_DETAILS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const createExam = (exam) => async (dispatch, getState) => {
  try {
    dispatch({ type: EXAM_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/exams`, exam, config);

    dispatch({ type: EXAM_CREATE_SUCCESS, payload: data });
    snackActions.success(`${data.title} exam created.`);
  } catch (err) {
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;

    dispatch({
      type: EXAM_CREATE_FAIL,
      payload: errMessage,
    });

    snackActions.error(errMessage);
  }
};

export const updateExam = (exam) => async (dispatch, getState) => {
  try {
    dispatch({ type: EXAM_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/exams/${exam._id}`, exam, config);

    dispatch({ type: EXAM_UPDATE_SUCCESS, payload: data });

    snackActions.success(`${data.title} exam updated.`);
  } catch (err) {
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;

    dispatch({
      type: EXAM_UPDATE_FAIL,
      payload: errMessage,
    });

    snackActions.error(errMessage);
  }
};

export const deleteExam = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: EXAM_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/exams/${id}`, config);

    dispatch({ type: EXAM_DELETE_SUCCESS });

    snackActions.success(`Exam deleted.`);
  } catch (err) {
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;

    dispatch({
      type: EXAM_DELETE_FAIL,
      payload: errMessage,
    });

    snackActions.error(errMessage);
  }
};

export const currentQuestionLoader = (question) => async (dispatch) => {
  dispatch({ type: CURRENT_ASSESSMENT_QUESTION, payload: question });
};

export const listExamsByCourse = () => async (dispatch, getState) => {
  try {
    dispatch({ type: EXAMS_BY_COURSE_REQUEST });

    const {
      studentLogin: { studentInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${studentInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/exams/course/${studentInfo.course._id}`,
      config
    );

    dispatch({ type: EXAMS_BY_COURSE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: EXAMS_BY_COURSE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const submitScores = (point) => async (dispatch, getState) => {
  try {
    dispatch({ type: EXAM_SUBMIT_SCORE_REQUEST });

    const {
      studentLogin: { studentInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${studentInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/students/exam`, point, config);

    dispatch({ type: EXAM_SUBMIT_SCORE_SUCCESS, payload: data.points });
  } catch (err) {
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;

    dispatch({
      type: EXAM_SUBMIT_SCORE_FAIL,
      payload: errMessage,
    });

    // snackActions.error(errMessage);
  }
};

export const getStudentPoints = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: EXAM_GET_POINTS_REQUEST });

    const {
      studentLogin: { studentInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${studentInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/students/${id}/points`, config);

    dispatch({ type: EXAM_GET_POINTS_SUCESS, payload: data.points });
  } catch (err) {
    const errMessage =
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message;

    dispatch({
      type: EXAM_GET_POINTS_FAIL,
      payload: errMessage,
    });
  }
};
