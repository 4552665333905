import { useMemo, createRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { blue, red, green } from '@material-ui/core/colors';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import DateFnsUtils from '@date-io/date-fns';
import Header from './components/Header';
import Footer from './components/Footer';
import LabsScreen from './screens/LabsScreen';
import LabDetailsScreen from './screens/LabDetailsScreen';
import LabGuideScreen from './screens/LabGuideScreen';
import UserLoginScreen from './screens/UserLoginScreen';
import UserListScreen from './screens/UsersScreen';
import CoursesScreen from './screens/CoursesScreen';
import BatchesScreen from './screens/BatchesScreen';
import StudentsScreen from './screens/StudentScreen';
import BulkGenerateScreen from './screens/BulkGenerateScreen';
import StudentLoginScreen from './screens/StudentLoginScreen';
import StudentLabsScreen from './screens/StudentLabsScreen';
import DashboardScreen from './screens/DashboardScreen';
import StudentProfileScreen from './screens/StudentProfileScreen';
import BooksScreen from './screens/BooksScreen';
import PagesScreen from './screens/PagesScreen';
import StudentDashboardScreen from './screens/StudentDashboardScreen';
import BookDetailsScreen from './screens/BookDetailsScreen';
import PageDetailsScreen from './screens/PageDetailsScreen';
import StudentCertificateScreen from './screens/StudentCertificateScreen';
import StudentFeedbackScreen from './screens/StudentFeedbackScreen';
import FeedbacksScreen from './screens/FeedbacksScreen';
import FeedbackDetailsScreen from './screens/FeedbackDetailsScreen';
import StudentRegistrationScreen from './screens/StudentRegistrationScreen';
import StudentAssessmentScreen from './screens/StudentAssessmentScreen';
import StudentExamScreen from './screens/StudentExamScreen';
import StudentExamResultsScreen from './screens/StudentExamResultsScreen';
import StudentSingleExamResultScreen from './screens/StudentSingleExamResultScreen';
import TrainerOnDemand from './screens/TrainerOnDemandScreen';
import VideosScreen from './screens/VideosScreen';
import StudentVideoListScreen from './screens/StudentVideoListScreen';
import StudentVideoScreen from './screens/StudentVideoScreen';

import { SnackbarUtilsConfigurator } from './utils/SnackBarUtils';
import {
  studentAuthCheck as studentAuthCheckAction,
  logout as studentLogout,
} from './actions/studentActions';
import {
  userAuthCheck as userAuthCheckAction,
  logout as userLogout,
} from './actions/userActions';

import AssessmentScreen from './screens/AssessmentScreen';
import AssessmentSingleScreen from './screens/AssessmentSingleScreen';
import ExamScreen from './screens/ExamScreen';
import ExamSingleScreen from './screens/ExamSingleScreen';
import RegistrationsManagementScreen from './screens/RegistrationsManagementScreen';
// import './index.css';

const App = () => {
  const dispatch = useDispatch();

  // add action to all snackbars
  const notistackRef = createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const headerFooterDisplay = useSelector((state) => state.headerFooterDisplay);
  const { header, footer } = headerFooterDisplay;

  useEffect(() => {}, [header, footer]);

  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo } = studentLogin;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const studentAuthCheck = useSelector((state) => state.studentAuthCheck);
  const { error: studentAuthError } = studentAuthCheck;
  const userAuthCheck = useSelector((state) => state.userAuthCheck);
  const { error: userAuthError } = userAuthCheck;

  useEffect(() => {
    if (studentInfo && studentInfo.token) {
      dispatch(studentAuthCheckAction());
    } else if (userInfo && userInfo.token) {
      dispatch(userAuthCheckAction());
    }
    if (studentAuthError) {
      dispatch(studentLogout());
    } else if (userAuthError) {
      dispatch(userLogout());
    }
  }, [dispatch, studentInfo, studentAuthError, userAuthError]);

  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: {
            main: blue['A400'],
          },
          secondary: {
            main: red[500],
          },
          error: {
            main: red[500],
          },
          success: {
            main: green[500],
          },
        },
      }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <SnackbarProvider
          maxSnack={1}
          ref={notistackRef}
          action={(key) => (
            <IconButton
              size="small"
              onClick={onClickDismiss(key)}
              color="inherit">
              <ClearIcon />
            </IconButton>
          )}>
          <SnackbarUtilsConfigurator />
          <Router>
            <CssBaseline />
            {header ? <Header /> : null}
            <main style={{ minHeight: 'calc(100vh - 124px)' }}>
              <Route
                exact
                path="/videos/:courseId/:videoId"
                component={StudentVideoScreen}
              />
              <Route exact path="/videos" component={StudentVideoListScreen} />
              <Route
                exact
                path="/trainer-on-demand"
                component={TrainerOnDemand}
              />
              <Route
                exact
                path="/exams/:id/results"
                component={StudentSingleExamResultScreen}
              />
              <Route
                path="/exam-results"
                component={StudentExamResultsScreen}
                exact
              />
              <Route exact path="/exams/:id" component={ExamSingleScreen} />
              <Route exact path="/exams" component={StudentExamScreen} />

              <Route
                exact
                path="/assessments/:id"
                component={AssessmentSingleScreen}
              />
              <Route
                exact
                path="/assessments"
                component={StudentAssessmentScreen}
              />
              <Route
                path="/registration"
                component={StudentRegistrationScreen}
              />
              <Route
                path="/admin/feedback/:id"
                component={FeedbackDetailsScreen}
              />
              <Route path="/admin/feedbacks" component={FeedbacksScreen} />
              <Route path="/feedback" exact component={StudentFeedbackScreen} />
              <Route
                path="/certificate"
                exact
                component={StudentCertificateScreen}
              />
              <Route
                path="/book/:bookId/page/:pageId"
                component={PageDetailsScreen}
              />
              <Route path="/book/:id" exact component={BookDetailsScreen} />
              <Route path="/profile" component={StudentProfileScreen} />
              <Route path="/lab/:id" component={LabDetailsScreen} />
              <Route path="/lab/:id/guide" component={LabGuideScreen} />
              <Route path="/labs" component={StudentLabsScreen} />
              <Route path="/dashboard" component={StudentDashboardScreen} />
              <Route
                path="/admin/registrations"
                component={RegistrationsManagementScreen}
              />
              <Route path="/admin/videos" component={VideosScreen} />
              <Route path="/admin/exams" component={ExamScreen} />
              <Route path="/admin/assessments" component={AssessmentScreen} />
              <Route path="/admin/pages" component={PagesScreen} />
              <Route path="/admin/books" component={BooksScreen} />
              <Route path="/admin/users" component={UserListScreen} />
              <Route path="/admin/login" component={UserLoginScreen} />
              <Route path="/admin/courses" component={CoursesScreen} />
              <Route path="/admin/batches" component={BatchesScreen} />
              <Route path="/admin/students" component={StudentsScreen} />
              <Route path="/admin/labs" component={LabsScreen} />
              <Route
                path="/admin/dashboard"
                exact
                component={DashboardScreen}
              />
              <Route
                path="/admin/studentgenerate"
                component={BulkGenerateScreen}
              />
              <Route path="/" exact component={StudentLoginScreen} />
            </main>
            {footer ? <Footer /> : null}
          </Router>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
