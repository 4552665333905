import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import LabsHeader from '../components/LabsHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Grid,
  Icon,
} from '@material-ui/core';
import {
  ImportContactsTwoTone,
  EventNoteTwoTone,
  WebAssetTwoTone,
  AssignmentIndTwoTone,
  PeopleAltTwoTone,
  BookTwoTone,
  FeedbackTwoTone,
  HelpTwoTone,
  DoneAllTwoTone,
  ContactPhoneTwoTone,
  VideocamTwoTone,
} from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { getStatistics } from '../actions/userActions';
import { pullMachines as pullMachinesAction } from '../actions/userActions';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[100],
    padding: '50px 30px',
    minHeight: 'calc(100vh - 206px)',
  },
  iconTitle: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '& svg': {
      marginRight: '0.6rem',
    },
  },
  disabled: {
    opacity: '0.7',
    userSelect: 'none',
  },
}));

const allowedEmails = [
  'vagish@rstforum.net',
  'chinmay@rstforum.net',
  'rustom@rstforum.net',
  'rahul@rstforum.co.in',
];

const DashboardScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const getStat = useSelector((state) => state.getStat);
  const { stats } = getStat;

  const pullMachines = useSelector((state) => state.pullMachines);
  const { loading, error, success } = pullMachines;

  useEffect(() => {
    if (userInfo && userInfo.role === 'admin') {
      dispatch(getStatistics());
    }
  }, [dispatch, userInfo]);

  /* ------------------------------- Update VMS ------------------------------- */
  const updateVmsList = () => {
    dispatch(pullMachinesAction());
  };

  return (
    <>
      <LabsHeader
        pageTitle="Dashboard"
        customJSX={
          <>
            {userInfo && userInfo.role === 'admin' && (
              <Button
                variant="contained"
                color="primary"
                onClick={updateVmsList}
                disabled={loading}>
                Update VMs List
              </Button>
            )}
          </>
        }
      />
      <section className={classes.section}>
        <Grid container spacing={3}>
          {userInfo && userInfo.role === 'admin' && (
            <Grid item xs={3}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h3">
                    <div className={classes.iconTitle}>
                      Courses
                      <Icon
                        component={ImportContactsTwoTone}
                        fontSize="large"
                        color="primary"
                      />
                    </div>
                  </Typography>
                  <Typography variant="h4" component="h4" color="secondary">
                    {stats && stats.courses}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    component={RouterLink}
                    to="/admin/courses"
                    size="small"
                    color="primary">
                    Manage Courses
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}
          {userInfo && userInfo.role !== 'staff' && (
            <>
              <Grid item xs={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h3">
                      <div className={classes.iconTitle}>
                        Labs
                        <Icon
                          component={WebAssetTwoTone}
                          fontSize="large"
                          color="primary"
                        />
                      </div>
                    </Typography>
                    {userInfo && userInfo.role === 'admin' && (
                      <Typography variant="h4" component="h4" color="secondary">
                        {stats && stats.labs}
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions>
                    <Button
                      component={RouterLink}
                      to="/admin/labs"
                      size="small"
                      color="primary">
                      Manage Labs
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h3">
                      <div className={classes.iconTitle}>
                        Batches
                        <Icon
                          component={EventNoteTwoTone}
                          fontSize="large"
                          color="primary"
                        />
                      </div>
                    </Typography>
                    {userInfo && userInfo.role === 'admin' && (
                      <Typography variant="h4" component="h4" color="secondary">
                        {stats && stats.batches}
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions>
                    <Button
                      component={RouterLink}
                      to="/admin/batches"
                      size="small"
                      color="primary">
                      Manage Batches
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h3">
                      <div className={classes.iconTitle}>
                        Students
                        <Icon
                          component={PeopleAltTwoTone}
                          fontSize="large"
                          color="primary"
                        />
                      </div>
                    </Typography>
                    {userInfo && userInfo.role === 'admin' && (
                      <Typography variant="h4" component="h4" color="secondary">
                        {stats && stats.students}
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions>
                    <Button
                      component={RouterLink}
                      to="/admin/students"
                      size="small"
                      color="primary">
                      Manage Students
                    </Button>
                    <Button
                      component={RouterLink}
                      to="/admin/studentgenerate"
                      size="small"
                      color="primary">
                      Generate
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h3">
                      <div className={classes.iconTitle}>
                        Books
                        <Icon
                          component={BookTwoTone}
                          fontSize="large"
                          color="primary"
                        />
                      </div>
                    </Typography>
                    {userInfo && userInfo.role === 'admin' && (
                      <Typography variant="h4" component="h4" color="secondary">
                        {stats && stats.books}
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      component={RouterLink}
                      to="/admin/books">
                      Manage Books
                    </Button>
                    <Button
                      component={RouterLink}
                      to="/admin/pages"
                      size="small"
                      color="primary">
                      Manage Pages
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              {/* Assessments */}
              <Grid item xs={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h3">
                      <div className={classes.iconTitle}>
                        Assessments
                        <Icon
                          component={HelpTwoTone}
                          fontSize="large"
                          color="primary"
                        />
                      </div>
                    </Typography>
                    {userInfo && userInfo.role === 'admin' && (
                      <Typography variant="h4" component="h4" color="secondary">
                        {stats && stats.assessments}
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      component={RouterLink}
                      to="/admin/assessments">
                      Manage Assessments
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              {/* Exams */}
              <Grid item xs={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h3">
                      <div className={classes.iconTitle}>
                        Exams
                        <Icon
                          component={DoneAllTwoTone}
                          fontSize="large"
                          color="primary"
                        />
                      </div>
                    </Typography>
                    {userInfo && userInfo.role === 'admin' && (
                      <Typography variant="h4" component="h4" color="secondary">
                        {stats && stats.exams}
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      component={RouterLink}
                      to="/admin/exams">
                      Manage Exams
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </>
          )}
          {userInfo && userInfo.role === 'admin' && (
            <Grid item xs={3}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h3">
                    <div className={classes.iconTitle}>
                      Trainers
                      <Icon
                        component={AssignmentIndTwoTone}
                        fontSize="large"
                        color="primary"
                      />
                    </div>
                  </Typography>
                  <Typography variant="h4" component="h4" color="secondary">
                    {stats && stats.users}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    component={RouterLink}
                    to="/admin/users"
                    size="small"
                    color="primary">
                    Manage Trainers
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {userInfo && userInfo.role === 'admin' && (
            <Grid item xs={3}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h3">
                    <div className={classes.iconTitle}>
                      Feedback
                      <Icon
                        component={FeedbackTwoTone}
                        fontSize="large"
                        color="primary"
                      />
                    </div>
                  </Typography>
                  <Typography variant="h4" component="h4" color="secondary">
                    {stats && stats.feedbacks}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    component={RouterLink}
                    to="/admin/feedbacks"
                    size="small"
                    color="primary">
                    View Student Feedbacks
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}

          {userInfo &&
            (userInfo.role === 'staff' ||
              allowedEmails.includes(userInfo.email)) && (
              <Grid item xs={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h3">
                      <div className={classes.iconTitle}>
                        Student Registrations
                        <Icon
                          component={ContactPhoneTwoTone}
                          fontSize="large"
                          color="primary"
                        />
                      </div>
                    </Typography>
                    <Typography variant="h4" component="h4" color="secondary">
                      {stats && stats.registrations}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      component={RouterLink}
                      to="/admin/registrations"
                      size="small"
                      color="primary">
                      Manage Student Registrations
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )}

          {userInfo && userInfo.role === 'admin' && (
            <Grid item xs={3}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h3">
                    <div className={classes.iconTitle}>
                      Videos
                      <Icon
                        component={VideocamTwoTone}
                        fontSize="large"
                        color="primary"
                      />
                    </div>
                  </Typography>
                  <Typography variant="h4" component="h4" color="secondary">
                    {stats && stats.videos}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    component={RouterLink}
                    to="/admin/videos"
                    size="small"
                    color="primary">
                    Manage Videos
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          )}
        </Grid>
      </section>
    </>
  );
};

export default DashboardScreen;
