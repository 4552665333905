import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LabsHeader from '../components/LabsHeader';
import {
  makeStyles,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Grid,
  Icon,
  Box,
} from '@material-ui/core';
import {
  ImportContactsTwoTone,
  AssessmentTwoTone,
  WebAssetTwoTone,
  AssignmentTwoTone,
  VerifiedUserTwoTone,
  Note,
  FeedbackTwoTone,
  OndemandVideoTwoTone,
  ContactsTwoTone,
  PlayCircleFilledTwoTone,
} from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';

const useStyles = makeStyles((_theme) => ({
  section: {
    backgroundColor: grey[100],
    padding: '50px 30px',
    minHeight: 'calc(100vh - 206px)',
  },
  iconTitle: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '& svg': {
      marginRight: '0.6rem',
    },
  },
  disabled: {
    opacity: '0.7',
    userSelect: 'none',
  },
  warning: {
    background: '#e0e0e0',
    borderTop: '5px solid #003a6d',
    padding: '2rem 0',
    borderBottom: '1px solid #c6c6c6',
  },
}));

const StudentDashboardScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo } = studentLogin;

  useEffect(() => {
    if (!studentInfo) {
      history.push('/login');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [dispatch, history, studentInfo]);

  console.log(studentInfo);

  return (
    <>
      <LabsHeader
        pageTitle={`Dashboard ${studentInfo && '- ' + studentInfo.course.name}`}
      />
      <section className={classes.section}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '2rem',
          }}>
          {/* Videos Column */}
          <div style={{ width: '50%' }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: 'rgba(0,0,0,0.8)',
                    boxShadow:
                      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                  }}
                  elevation={false}>
                  <CardContent style={{ padding: '0' }}>
                    {studentInfo &&
                    (studentInfo.course.introVideoYouTube ||
                      studentInfo.course.introVideoLocal) ? (
                      <>
                        <div
                          style={{
                            padding: '0',
                            aspectRatio: '16 / 9',
                            minHeight: '195px',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: studentInfo.course.introVideoYouTube,
                          }}></div>
                        <div
                          style={{
                            padding: '0.7rem 1rem',
                            backgroundColor: 'white',
                          }}>
                          <p
                            style={{
                              fontSize: '0.9rem',
                              margin: '0',
                              fontWeight: '500',
                              textAlign: 'center',
                            }}>
                            Course Introduction Video
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '195px',
                          }}>
                          <Icon
                            component={PlayCircleFilledTwoTone}
                            color='primary'
                            style={{ fontSize: '5rem', opacity: '0.6' }}
                          />
                        </div>
                        <div
                          style={{
                            padding: '0.8rem 2rem',
                            backgroundColor: 'white',
                          }}>
                          <p style={{ fontSize: '0.9rem' }}>
                            Course Introduction Video
                          </p>
                        </div>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  style={{
                    backgroundColor: 'rgba(0,0,0,0.8)',
                    boxShadow:
                      '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                  }}
                  elevation={false}>
                  <CardContent style={{ padding: '0' }}>
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          minHeight: '195px',
                        }}>
                        <video
                          width='100%'
                          poster='https://labs.rstforum.net/uploads/image-1643116095773.png'
                          controls>
                          <source src='' />
                          {/* <source src="https://labs.rstforum.net/uploads/videos/vid-plat-intro-half.mp4" /> */}
                        </video>
                      </div>
                      <div
                        style={{
                          padding: '0.7rem 1rem',
                          backgroundColor: 'white',
                        }}>
                        <p
                          style={{
                            fontSize: '0.9rem',
                            margin: '0',
                            fontWeight: '500',
                            textAlign: 'center',
                          }}>
                          Platform Introduction Video
                        </p>
                      </div>
                    </>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {/* NEW CHANGES */}
            <div style={{ height: '3rem' }}></div>
            {/* ----------------------- Note for corporate students ---------------------- */}
            <Grid item xs={12}>
              {studentInfo && studentInfo.corporateStudent && (
                <Card>
                  <CardContent>
                    <Typography variant='h5' component='h3'>
                      <div className={classes.iconTitle}>
                        Copyright Information
                        <Icon
                          component={Note}
                          fontSize='large'
                          color='primary'
                        />
                      </div>
                    </Typography>
                    <Box>
                      <p style={{ fontSize: '1rem', textAlign: 'justify' }}>
                        This site/platform is RSTForum's proprietary property
                        and all source code, databases, functionality, software,
                        website designs, audio, video, text, photographs, and
                        graphics on the Site/Platform (collectively, the
                        "Content") are owned and controlled by RSTForum and are
                        protected by copyright laws of India, the United States,
                        foreign jusrisdictions, and international conventions.
                        Strict Legal action will be taken against any
                        person(s)/group/organization who infringes or abets
                        infringment of our copyright.
                      </p>
                      {/* <ul style={{ fontSize: '1rem' }}>
                        <li>
                          Video recordings will not be provided due to copyright
                          reasons.
                        </li>
                        <li>Video recordings are not allowed.</li>
                      </ul> */}
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Grid>
          </div>
          <div style={{ width: '50%' }}>
            <Grid container spacing={3}>
              {/* ---------------------------------- Labs ---------------------------------- */}
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    <Typography variant='h5' component='h3'>
                      <div className={classes.iconTitle}>
                        Labs
                        <Icon
                          component={WebAssetTwoTone}
                          fontSize='large'
                          color='primary'
                        />
                      </div>
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      component={RouterLink}
                      to='/labs'
                      size='small'
                      color='primary'>
                      Access Labs
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              {/* ---------------------------------- Book ---------------------------------- */}
              <Grid item xs={6}>
                <Card
                  className={
                    studentInfo && studentInfo.bookAccess ? '' : 'disabled'
                  }>
                  <CardContent>
                    <Typography variant='h5' component='h3'>
                      <div className={classes.iconTitle}>
                        Book
                        <Icon
                          component={ImportContactsTwoTone}
                          fontSize='large'
                          color='primary'
                        />
                      </div>
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      component={RouterLink}
                      to={
                        studentInfo && studentInfo.bookAccess
                          ? `/book/${
                              studentInfo && studentInfo.course.books
                                ? studentInfo.course.books[0]
                                : ''
                            }`
                          : null
                      }
                      disabled={
                        studentInfo && studentInfo.bookAccess ? false : true
                      }
                      size='small'
                      color='primary'>
                      Read Book
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              {/* --------------------------------- Videos --------------------------------- */}
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    <Typography variant='h5' component='h3'>
                      <div className={classes.iconTitle}>
                        Videos
                        <Icon
                          component={OndemandVideoTwoTone}
                          fontSize='large'
                          color='primary'
                        />
                      </div>
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      component={RouterLink}
                      to={`/videos`}
                      size='small'
                      color='primary'>
                      Video Library
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              {/* ------------------------------- Assessments ------------------------------ */}
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    <Typography variant='h5' component='h3'>
                      <div className={classes.iconTitle}>
                        Assessments
                        <Icon
                          component={AssignmentTwoTone}
                          fontSize='large'
                          color='primary'
                        />
                      </div>
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      component={RouterLink}
                      to='/assessments'
                      size='small'
                      color='primary'>
                      View Assessments
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              {/* ---------------------------------- Exams --------------------------------- */}
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    <Typography variant='h5' component='h3'>
                      <div className={classes.iconTitle}>
                        Exams
                        <Icon
                          component={AssessmentTwoTone}
                          fontSize='large'
                          color='primary'
                        />
                      </div>
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      component={RouterLink}
                      to='/exams'
                      size='small'
                      color='primary'>
                      Take Exams
                    </Button>
                    <Button
                      component={RouterLink}
                      to='/exam-results'
                      size='small'
                      color='default'>
                      Past Results
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              {/* ------------------------------- Certificate ------------------------------ */}
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    <Typography variant='h5' component='h3'>
                      <div className={classes.iconTitle}>
                        Certificate
                        <Icon
                          component={VerifiedUserTwoTone}
                          fontSize='large'
                          color='primary'
                        />
                      </div>
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      component={RouterLink}
                      disabled={
                        studentInfo && !studentInfo.batch?.enableCertificates
                      }
                      to='/certificate'
                      size='small'
                      color='primary'>
                      View and Download
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              {/* -------------------------------- Feedback -------------------------------- */}
              {studentInfo && studentInfo.corporateStudent && (
                <Grid item xs={6}>
                  <Card
                    className={
                      studentInfo && studentInfo.corporateStudent
                        ? ''
                        : classes.disabled
                    }>
                    <CardContent>
                      <Typography variant='h5' component='h3'>
                        <div className={classes.iconTitle}>
                          Feedback
                          <Icon
                            component={FeedbackTwoTone}
                            fontSize='large'
                            color='primary'
                          />
                        </div>
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        disabled={studentInfo && !studentInfo.corporateStudent}
                        component={RouterLink}
                        to='/feedback'
                        size='small'
                        color='primary'>
                        Provide Feedback
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              )}

              {/* ---------------------------- Trainer on-demand --------------------------- */}
              {studentInfo && studentInfo.corporateStudent && (
                <Grid item xs={6}>
                  <Card>
                    <CardContent>
                      <Typography variant='h5' component='h3'>
                        <div className={classes.iconTitle}>
                          Trainer On-Demand
                          <Icon
                            component={ContactsTwoTone}
                            fontSize='large'
                            color='primary'
                          />
                        </div>
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        component={RouterLink}
                        to='/trainer-on-demand'
                        size='small'
                        color='primary'>
                        Trainer on-demand
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              )}

              {/* END */}
            </Grid>
          </div>
        </div>
      </section>
    </>
  );
};

export default StudentDashboardScreen;
