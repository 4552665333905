import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  CircularProgress,
  Button,
  Paper,
  Typography,
  Divider,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { grey } from '@material-ui/core/colors';
import LabsHeader from '../components/LabsHeader';
import { listFeedbackDetails } from '../actions/feedbackActions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';

const useStyles = makeStyles((_theme) => ({
  section: {
    backgroundColor: grey[200],
    padding: '50px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: 'calc(100vh - 206px)',
  },
}));

/**
 * Component for showing/adding/editing courses
 */
const FeedbackDetailsScreen = ({ history, match }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const feedbackId = match.params.id;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const feedbackDetails = useSelector((state) => state.feedbackDetails);
  const { loading, error, feedback } = feedbackDetails;

  useEffect(() => {
    if (userInfo) {
      dispatch(listFeedbackDetails(feedbackId));
    } else {
      history.push('/login');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [dispatch, history, userInfo, feedbackId]);

  console.log(feedback);

  return (
    <>
      <LabsHeader
        pageTitle='Feedback Details'
        backBtn={{ action: history.goBack }}
      />
      <section className={classes.section}>
        <Container>
          <Paper style={{ padding: '3rem' }}>
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: 'calc(100vh - 255px)',
                }}>
                <CircularProgress />
              </div>
            ) : error ? (
              <Alert severity='error'>{error}</Alert>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    justifyContent: 'space-between',
                  }}>
                  {/* Student Name */}
                  <div>
                    <Typography variant='h5'>Student Name:</Typography>
                    <Typography variant='h6'>
                      <strong>
                        {feedback && feedback.student
                          ? feedback.student.name
                          : '---'}
                      </strong>
                    </Typography>
                  </div>
                  {/* Company Name */}
                  <div>
                    <Typography variant='h5'>Company Name:</Typography>
                    <Typography variant='h6'>
                      <strong>
                        {feedback && feedback.companyName
                          ? feedback.companyName
                          : '---'}
                      </strong>
                    </Typography>
                  </div>
                </div>
                <Divider style={{ margin: '2rem 0' }} />
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    justifyContent: 'space-between',
                  }}>
                  {/* Course Name */}
                  <div>
                    <Typography variant='h5'>Course Name:</Typography>
                    <Typography variant='h6'>
                      <strong>
                        {/* {feedback && feedback.course && feedback.course.name} */}
                        {(feedback &&
                          feedback.student?.batch?.courseNameOnCertificate) ||
                          (feedback && feedback.course && feedback.course.name)}
                      </strong>
                    </Typography>
                  </div>
                  {/* Trainer Name */}
                  <div>
                    <Typography variant='h5'>Trainer Name:</Typography>
                    <Typography variant='h6'>
                      <strong>
                        {/* {feedback &&
                          feedback.trainerName &&
                          feedback.trainerName} */}
                        {(feedback &&
                          feedback.trainerName &&
                          feedback.trainerName) ||
                          (feedback && feedback.student?.trainer?.name)}
                      </strong>
                    </Typography>
                  </div>
                </div>
                <Divider style={{ margin: '2rem 0' }} />
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    justifyContent: 'space-between',
                  }}>
                  {/* Training Start Date */}
                  <div>
                    <Typography variant='h5'>Date:</Typography>
                    <Typography variant='h6'>
                      <strong>
                        {feedback &&
                          feedback.startDate &&
                          feedback.startDate.split('T')[0]}
                      </strong>
                    </Typography>
                  </div>

                  {/* Training End Date */}
                  {/* <div>
                    <Typography variant="h5">End Date:</Typography>
                    <Typography variant="h6">
                      <strong>
                        {feedback && feedback.endDate && feedback.endDate}
                      </strong>
                    </Typography>
                  </div> */}
                </div>
                <Divider style={{ margin: '2rem 0' }} />
                <div>
                  <Typography variant='h5' style={{ marginBottom: '1.5rem' }}>
                    Instructor Feedback
                  </Typography>
                  {feedback &&
                    feedback.instructorFeedback &&
                    feedback.instructorFeedback.map((f, i) => (
                      <div key={f.id} style={{ marginBottom: '1rem' }}>
                        <p
                          style={{
                            fontSize: '1.3rem',
                            marginBottom: 0,
                            marginTop: 0,
                          }}>
                          {i + 1}. {f.question}
                        </p>
                        <Typography variant='h6' style={{ margin: '0' }}>
                          <strong>
                            {f.answer &&
                              f.answer[0].toUpperCase() + f.answer.substring(1)}
                          </strong>
                        </Typography>
                      </div>
                    ))}
                </div>
                <Divider style={{ margin: '2rem 0' }} />
                <div>
                  <Typography variant='h5' style={{ marginBottom: '1.5rem' }}>
                    Training Feedback
                  </Typography>
                  {feedback &&
                    feedback.trainingFeedback &&
                    feedback.trainingFeedback.map((f, i) => (
                      <div key={f.id} style={{ marginBottom: '1rem' }}>
                        <p
                          style={{
                            fontSize: '1.3rem',
                            marginBottom: 0,
                            marginTop: 0,
                          }}>
                          {i + 1}: {f.question}
                        </p>
                        <Typography variant='h6' style={{ margin: '0' }}>
                          <strong>
                            {f.answer &&
                              f.answer[0].toUpperCase() + f.answer.substring(1)}
                          </strong>
                        </Typography>
                      </div>
                    ))}
                </div>
              </>
            )}
          </Paper>
        </Container>
      </section>
    </>
  );
};

export default FeedbackDetailsScreen;
