export const LAB_LIST_REQUEST = 'LAB_LIST_REQUEST';
export const LAB_LIST_SUCCESS = 'LAB_LIST_SUCCESS';
export const LAB_LIST_FAIL = 'LAB_LIST_FAIL';
export const LAB_LIST_RESET = 'LAB_LIST_RESET';

export const LAB_DETAILS_REQUEST = 'LAB_DETAILS_REQUEST';
export const LAB_DETAILS_SUCCESS = 'LAB_DETAILS_SUCCESS';
export const LAB_DETAILS_FAIL = 'LAB_DETAILS_FAIL';
export const LAB_DETAILS_RESET = 'LAB_DETAILS_RESET';

export const LAB_CREATE_REQUEST = 'LAB_CREATE_REQUEST';
export const LAB_CREATE_SUCCESS = 'LAB_CREATE_SUCCESS';
export const LAB_CREATE_FAIL = 'LAB_CREATE_FAIL';
export const LAB_CREATE_RESET = 'LAB_CREATE_RESET';

export const LAB_UPDATE_REQUEST = 'LAB_UPDATE_REQUEST';
export const LAB_UPDATE_SUCCESS = 'LAB_UPDATE_SUCCESS';
export const LAB_UPDATE_FAIL = 'LAB_UPDATE_FAIL';
export const LAB_UPDATE_RESET = 'LAB_UPDATE_RESET';

export const LAB_DELETE_REQUEST = 'LAB_DELETE_REQUEST';
export const LAB_DELETE_SUCCESS = 'LAB_DELETE_SUCCESS';
export const LAB_DELETE_FAIL = 'LAB_DELETE_FAIL';

export const LABS_BY_COURSE_REQUEST = 'LABS_BY_COURSE_REQUEST';
export const LABS_BY_COURSE_SUCCESS = 'LABS_BY_COURSE_SUCCESS';
export const LABS_BY_COURSE_FAIL = 'LABS_BY_COURSE_FAIL';

export const LAB_START_IOU_REQUEST = 'LAB_START_IOU_REQUEST';
export const LAB_START_IOU_SUCCESS = 'LAB_START_IOU_SUCCESS';
export const LAB_START_IOU_FAIL = 'LAB_START_IOU_FAIL';
export const LAB_START_IOU_RESET = 'LAB_START_IOU_RESET';
export const LAB_STOP_IOU_REQUEST = 'LAB_STOP_IOU_REQUEST';
export const LAB_STOP_IOU_SUCCESS = 'LAB_STOP_IOU_SUCCESS';
export const LAB_STOP_IOU_FAIL = 'LAB_STOP_IOU_FAIL';
export const LAB_STOP_IOU_RESET = 'LAB_STOP_IOU_RESET';
export const LAB_RESET_IOU_REQUEST = 'LAB_RESET_IOU_REQUEST';
export const LAB_RESET_IOU_SUCCESS = 'LAB_RESET_IOU_SUCCESS';
export const LAB_RESET_IOU_FAIL = 'LAB_RESET_IOU_FAIL';
export const LAB_RESET_IOU_RESET = 'LAB_RESET_IOU_RESET';
export const LAB_WIPE_IOU_REQUEST = 'LAB_WIPE_IOU_REQUEST';
export const LAB_WIPE_IOU_SUCCESS = 'LAB_WIPE_IOU_SUCCESS';
export const LAB_WIPE_IOU_FAIL = 'LAB_WIPE_IOU_FAIL';
export const LAB_WIPE_IOU_RESET = 'LAB_WIPE_IOU_RESET';

export const LAB_POWER_ON_REQUEST = 'LAB_POWER_ON_REQUEST';
export const LAB_POWER_ON_SUCCESS = 'LAB_POWER_ON_SUCCESS';
export const LAB_POWER_ON_FAIL = 'LAB_POWER_ON_FAIL';
export const LAB_POWER_ON_RESET = 'LAB_POWER_ON_RESET';
export const LAB_POWER_OFF_REQUEST = 'LAB_POWER_OFF_REQUEST';
export const LAB_POWER_OFF_SUCCESS = 'LAB_POWER_OFF_SUCCESS';
export const LAB_POWER_OFF_FAIL = 'LAB_POWER_OFF_FAIL';
export const LAB_POWER_OFF_RESET = 'LAB_POWER_OFF_RESET';

export const LAB_POWER_CHECK_REQUEST = 'LAB_POWER_CHECK_REQUEST';
export const LAB_POWER_CHECK_SUCCESS = 'LAB_POWER_CHECK_SUCCESS';
export const LAB_POWER_CHECK_FAIL = 'LAB_POWER_CHECK_FAIL';

export const LAB_START_NODES_REQUEST = 'LAB_START_NODES_REQUEST';
export const LAB_START_NODES_SUCCESS = 'LAB_START_NODES_SUCCESS';
export const LAB_START_NODES_FAIL = 'LAB_START_NODES_FAIL';

export const LAB_STOP_NODES_REQUEST = 'LAB_START_NODES_REQUEST';
export const LAB_STOP_NODES_SUCCESS = 'LAB_START_NODES_SUCCESS';
export const LAB_STOP_NODES_FAIL = 'LAB_START_NODES_FAIL';

export const LAB_WIPE_NODES_REQUEST = 'LAB_WIPE_NODES_REQUEST';
export const LAB_WIPE_NODES_SUCCESS = 'LAB_WIPE_NODES_SUCCESS';
export const LAB_WIPE_NODES_FAIL = 'LAB_WIPE_NODES_FAIL';
