import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  CircularProgress,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Button,
  Select,
  Chip,
  Input,
  InputLabel,
  MenuItem,
  Switch,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
import LabsHeader from '../components/LabsHeader';
import InfoTable from '../components/InfoTable';
import { listCourses as listCoursesAction } from '../actions/courseActions';
import {
  listUsers as listUsersAction,
  createUser as createUserAction,
  updateUser as updateUserAction,
  deleteUser as deleteUserAction,
} from '../actions/userActions';
import {
  USER_CREATE_RESET,
  USER_UPDATE_RESET,
} from '../constants/userConstants';
import { SlideTransition, ZoomTransition } from '../components/Transitions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[200],
    padding: '50px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: 'calc(100vh - 206px)',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  notifSpacing: {
    marginBottom: '10px',
  },
}));

/**
 * Component for showing/adding/editing courses
 */
const UsersScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Dialog State
  const [createPopup, setCreatePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);

  // Form and Local State
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userRole, setUserRole] = useState('trainer');
  const [courses, setCourses] = useState([]);
  const [activeStatus, setActiveStatus] = useState(true);
  const [accessLevel, setAccessLevel] = useState(5);
  const [phone, setPhone] = useState('');
  const [updateName, setUpdateName] = useState('');
  const [updateEmail, setUpdateEmail] = useState('');
  const [updatePassword, setUpdatePassword] = useState('');
  const [updateUserRole, setUpdateUserRole] = useState('trainer');
  const [updateCourses, setUpdateCourses] = useState([]);
  const [updateActiveStatus, setUpdateActiveStatus] = useState(true);
  const [updateAccessLevel, setUpdateAccessLevel] = useState(5);
  const [updatePhone, setUpdatePhone] = useState('');
  const [updateUserId, setUpdateUserId] = useState('');
  const [toDeleteUser, setToDeleteUser] = useState('');

  // Redux State Getters
  const useLogin = useSelector((state) => state.userLogin);
  const { userInfo } = useLogin;
  const userList = useSelector((state) => state.userList);
  const { loading, error, users } = userList;
  const coursesList = useSelector((state) => state.coursesList);
  const {
    loading: allCoursesLoading,
    error: allCoursesError,
    courses: allCourses,
  } = coursesList;
  const userCreate = useSelector((state) => state.userCreate);
  const {
    loading: createLoading,
    error: createError,
    success: createSuccess,
    user: createdUser,
  } = userCreate;
  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    user: updatedUser,
  } = userUpdate;
  const userDelete = useSelector((state) => state.userDelete);
  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
  } = userDelete;

  // Lifecycle events
  useEffect(() => {
    if (userInfo && userInfo.role === 'admin') {
      dispatch(listUsersAction());
      dispatch(listCoursesAction());
    } else {
      history.push('/admin/login');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
    if (createSuccess) {
      handleCreatePopupClose();
      setName('');
      setEmail('');
      setPassword('');
      setUserRole('trainer');
      setAccessLevel(5);
      setPhone('');
      setCourses([]);
      setActiveStatus(true);
      dispatch({ type: USER_CREATE_RESET });
    }
    if (updateSuccess) {
      handleEditPopupClose();
      setUpdateName('');
      setUpdateEmail('');
      setUpdatePassword('');
      setUpdateUserRole('trainer');
      setUpdateAccessLevel(5);
      setUpdatePhone('');
      setUpdateCourses([]);
      setUpdateActiveStatus(true);
      dispatch({ type: USER_UPDATE_RESET });
    }
    if (deleteSuccess) {
      handleDeletePopupClose();
      setToDeleteUser({});
    }
  }, [
    dispatch,
    history,
    userInfo,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    createdUser,
    updatedUser,
  ]);

  // Popup Management
  const handleCreatePopupClick = () => {
    setCreatePopup(true);
  };
  const handleCreatePopupClose = () => {
    setCreatePopup(false);
  };
  const handleEditPopupClick = () => {
    setEditPopup(true);
  };
  const handleEditPopupClose = () => {
    setEditPopup(false);
    setUpdateName('');
    setUpdateEmail('');
    setUpdatePassword('');
    setUpdateUserRole('trainer');
    setUpdateAccessLevel(5);
    setUpdatePhone('');
    setUpdateCourses([]);
    setUpdateActiveStatus(true);
  };
  const handleDeletePopupClick = (user) => {
    setDeletePopup(true);
    setToDeleteUser(user);
  };
  const handleDeletePopupClose = () => {
    setDeletePopup(false);
  };

  // Action Handlers
  const handleCreateUser = (e) => {
    e.preventDefault();
    const formData = {
      name,
      email,
      password,
      role: userRole,
      courses,
      active: activeStatus,
      accessLevel,
      phone,
    };
    dispatch(createUserAction(formData));
  };
  const handleEditUser = (e, id) => {
    users.forEach((user) => {
      if (user._id === id) {
        setUpdateUserId(user._id);
        setUpdateName(user.name);
        setUpdateEmail(user.email);
        setUpdateUserRole(user.role);
        setUpdateCourses(user.courses);
        setUpdateActiveStatus(user.active);
        setUpdateAccessLevel(user.accessLevel);
      }
    });
    handleEditPopupClick();
  };
  const handleUpdateUser = (e) => {
    e.preventDefault();
    const formData = {
      _id: updateUserId,
      name: updateName,
      email: updateEmail,
      role: updateUserRole,
      password: updatePassword,
      courses: updateCourses,
      active: updateActiveStatus,
      accessLevel: updateAccessLevel,
      phone: updatePhone,
    };
    dispatch(updateUserAction(formData));
  };
  const handleDeleteUser = (id) => {
    dispatch(deleteUserAction(id));
    handleDeletePopupClose();
  };

  // Helpers
  const displayCourseChips = (courses) => {
    const cs = courses
      .map((c) =>
        allCourses.filter((cr) => cr._id === c)[0]
          ? allCourses.filter((cr) => cr._id === c)[0].name
          : null
      )
      .map((c) => <Chip label={c} variant="outline" size="small" />);
    if (cs.length > 4) {
      const truncated = cs.slice(0, 4);
      truncated.push(<Chip label="More" variant="outline" size="small" />);
      return truncated;
    } else {
      return cs;
    }
  };

  return (
    <>
      <LabsHeader
        pageTitle={
          userInfo && userInfo.role === 'admin'
            ? `${users ? 'Trainers (' + users.length + ' total)' : ''}`
            : 'Trainers'
        }
        backBtn={{ action: () => history.push('/admin/dashboard') }}
        secondaryBtn={{
          label: 'Add New Trainer',
          action: () => handleCreatePopupClick(),
          style: 'contained',
        }}
      />
      <section className={classes.section}>
        <Container>
          {loading || allCoursesLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 255px)',
              }}
            >
              <CircularProgress />
            </div>
          ) : error || allCoursesError ? (
            <Alert severity="error">{error || allCoursesError}</Alert>
          ) : (
            <InfoTable
              columns={['name', 'role', 'courses', 'actions']}
              rows={
                users &&
                users.map((user) => ({
                  _id: user._id,
                  name: user.name,
                  role: user.role,
                  course: allCourses ? (
                    <div style={{ display: 'flex', gap: '10px' }}>
                      {displayCourseChips(user.courses)}
                    </div>
                  ) : null,
                  actions: [
                    {
                      kind: 'action',
                      label: 'Edit',
                      type: 'primary',
                      action: (e) => handleEditUser(e, user._id),
                    },
                    {
                      kind: 'action',
                      label: 'Delete',
                      type: 'secondary',
                      action: () => handleDeletePopupClick(user),
                    },
                  ],
                }))
              }
            />
          )}
          {/* Add new user dialog */}
          <Dialog
            open={createPopup}
            onClose={handleCreatePopupClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={SlideTransition}
          >
            <form onSubmit={handleCreateUser}>
              <DialogTitle id="form-dialog-title">Add New User</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Create a new Trainer, Staff Member or an Administrator. Please
                  note, Administrators will have the same privileges as
                  yourself.
                </DialogContentText>
                {createLoading && (
                  <LinearProgress
                    color="primary"
                    className={classes.notifSpacing}
                  />
                )}
                {createError && (
                  <Alert severity="error" className={classes.notifSpacing}>
                    {createError}
                  </Alert>
                )}
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="name"
                  type="text"
                  label="Name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="email"
                  type="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  id="phone"
                  type="tel"
                  label="Phone No."
                  name="phone"
                  autoComplete="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div style={{ display: 'flex', gap: '10px', marginTop: '3px' }}>
                  <FormControl
                    variant="outlined"
                    style={{ width: '100%' }}
                    margin="dense"
                    className={classes.formControl}
                  >
                    <InputLabel id="userRole">Role</InputLabel>
                    <Select
                      labelId="userRole"
                      id="role"
                      name="role"
                      value={userRole}
                      onChange={(e) => setUserRole(e.target.value)}
                      label="Role"
                    >
                      <MenuItem value={'trainer'}>Trainer</MenuItem>
                      <MenuItem value={'staff'}>Staff</MenuItem>
                      <MenuItem value={'admin'}>Admin</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    name="accessLevel"
                    label="Access Level"
                    type="number"
                    id="accessLevel"
                    value={accessLevel}
                    onChange={(e) => setAccessLevel(e.target.value)}
                  />
                  <FormControlLabel
                    value="top"
                    control={
                      <Switch
                        checked={activeStatus}
                        onChange={(e) => setActiveStatus(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Active"
                    labelPlacement="end"
                  />
                </div>
                {/* Courses Multi-Select */}
                <Divider style={{ margin: '20px 0 10px' }} />
                {!allCoursesError && (
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel id="selectCourses">Select Courses</InputLabel>
                    <Select
                      labelId="selectCourses"
                      variant="outlined"
                      id="courses"
                      multiple
                      value={courses}
                      onChange={(e) => setCourses(e.target.value)}
                      input={<Input id="courseChip" />}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={
                                allCourses.filter((cr) => cr._id === value)[0]
                                  ? allCourses.filter(
                                      (cr) => cr._id === value
                                    )[0].name
                                  : null
                              }
                              className={classes.chip}
                            />
                          ))}
                        </div>
                      )}
                    >
                      {allCourses.map((course) => (
                        <MenuItem key={course._id} value={course._id}>
                          {course.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCreatePopupClose} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Create User
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          {/* Update user dialog */}
          <Dialog
            open={editPopup}
            onClose={handleEditPopupClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={SlideTransition}
          >
            <form onSubmit={handleUpdateUser}>
              <DialogTitle id="form-dialog-title">
                Edit User — {updateName}
              </DialogTitle>
              <DialogContent>
                {updateLoading && (
                  <LinearProgress
                    color="primary"
                    className={classes.notifSpacing}
                  />
                )}
                {updateError && (
                  <Alert severity="error" className={classes.notifSpacing}>
                    {updateError}
                  </Alert>
                )}
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="name"
                  type="text"
                  label="Name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  value={updateName}
                  onChange={(e) => setUpdateName(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="email"
                  type="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={updateEmail}
                  onChange={(e) => setUpdateEmail(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  id="phone"
                  type="tel"
                  label="Phone No."
                  name="phone"
                  autoComplete="phone"
                  value={updatePhone}
                  onChange={(e) => setUpdatePhone(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={updatePassword}
                  onChange={(e) => setUpdatePassword(e.target.value)}
                />
                <div style={{ display: 'flex', gap: '10px', marginTop: '3px' }}>
                  <FormControl
                    variant="outlined"
                    style={{ width: '100%' }}
                    margin="dense"
                    className={classes.formControl}
                  >
                    <InputLabel id="userRole">Role</InputLabel>
                    <Select
                      labelId="userRole"
                      id="role"
                      name="role"
                      value={updateUserRole}
                      onChange={(e) => setUpdateUserRole(e.target.value)}
                      label="Role"
                    >
                      <MenuItem value={'trainer'}>Trainer</MenuItem>
                      <MenuItem value={'staff'}>Staff</MenuItem>
                      <MenuItem value={'admin'}>Admin</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    name="accessLevel"
                    label="Access Level"
                    type="number"
                    id="accessLevel"
                    value={updateAccessLevel}
                    onChange={(e) => setUpdateAccessLevel(e.target.value)}
                  />
                  <FormControlLabel
                    value="top"
                    control={
                      <Switch
                        checked={updateActiveStatus}
                        onChange={(e) =>
                          setUpdateActiveStatus(e.target.checked)
                        }
                        color="primary"
                      />
                    }
                    label="Active"
                    labelPlacement="end"
                  />
                </div>
                {/* Courses Multi-Select */}
                <Divider style={{ margin: '20px 0 10px' }} />
                {!allCoursesError && (
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel id="selectCourses">Select Courses</InputLabel>
                    <Select
                      labelId="selectCourses"
                      variant="outlined"
                      id="courses"
                      multiple
                      value={updateCourses}
                      onChange={(e) => setUpdateCourses(e.target.value)}
                      input={<Input id="courseChip" />}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={
                                allCourses.filter((cr) => cr._id === value)[0]
                                  ? allCourses.filter(
                                      (cr) => cr._id === value
                                    )[0].name
                                  : null
                              }
                              className={classes.chip}
                            />
                          ))}
                        </div>
                      )}
                    >
                      {allCourses.map((course) => (
                        <MenuItem key={course._id} value={course._id}>
                          {course.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleEditPopupClose} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Update
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          {/* Delete user dialog */}
          <Dialog
            TransitionComponent={ZoomTransition}
            open={deletePopup}
            onClose={handleDeletePopupClose}
          >
            <DialogTitle id="delete-dialog-title">Delete User</DialogTitle>
            <DialogContent>
              {deleteLoading && <LinearProgress color="secondary" />}
              {deleteError && <Alert severity="error">{deleteError}</Alert>}
              <DialogContentText id="delete-dialog-description">
                Are you sure? Deleting user <strong>{toDeleteUser.name}</strong>{' '}
                will affect all the batches and labs associated with them. This
                action is permanent!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleDeletePopupClose}
                color="primary"
                autoFocus
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleDeleteUser(toDeleteUser._id)}
                color="secondary"
              >
                Confirm Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </section>
    </>
  );
};

export default UsersScreen;
