export const STUDENT_LOGIN_REQUEST = 'STUDENT_LOGIN_REQUEST';
export const STUDENT_LOGIN_SUCCESS = 'STUDENT_LOGIN_SUCCESS';
export const STUDENT_LOGIN_FAIL = 'STUDENT_LOGIN_FAIL';
export const STUDENT_LOGOUT = 'STUDENT_LOGOUT';

export const STUDENT_LIST_REQUEST = 'STUDENT_LIST_REQUEST';
export const STUDENT_LIST_SUCCESS = 'STUDENT_LIST_SUCCESS';
export const STUDENT_LIST_FAIL = 'STUDENT_LIST_FAIL';
export const STUDENT_LIST_RESET = 'STUDENT_LIST_RESET';

export const STUDENT_CREATE_REQUEST = 'STUDENT_CREATE_REQUEST';
export const STUDENT_CREATE_SUCCESS = 'STUDENT_CREATE_SUCCESS';
export const STUDENT_CREATE_FAIL = 'STUDENT_CREATE_FAIL';
export const STUDENT_CREATE_RESET = 'STUDENT_CREATE_RESET';

export const STUDENT_UPDATE_REQUEST = 'STUDENT_UPDATE_REQUEST';
export const STUDENT_UPDATE_SUCCESS = 'STUDENT_UPDATE_SUCCESS';
export const STUDENT_UPDATE_FAIL = 'STUDENT_UPDATE_FAIL';
export const STUDENT_UPDATE_RESET = 'STUDENT_UPDATE_RESET';

export const STUDENT_DELETE_REQUEST = 'STUDENT_DELETE_REQUEST';
export const STUDENT_DELETE_SUCCESS = 'STUDENT_DELETE_SUCCESS';
export const STUDENT_DELETE_FAIL = 'STUDENT_DELETE_FAIL';

export const BULK_GEN_STUDENT_REQUEST = 'BULK_GEN_STUDENT_REQUEST';
export const BULK_GEN_STUDENT_SUCCESS = 'BULK_GEN_STUDENT_SUCCESS';
export const BULK_GEN_STUDENT_FAIL = 'BULK_GEN_STUDENT_FAIL';
export const BULK_GEN_STUDENTS_RESET = 'BULK_GEN_STUDENTS_RESET';

export const STUDENT_STATUS_REQUEST = 'STUDENT_STATUS_REQUEST';
export const STUDENT_STATUS_SUCCESS = 'STUDENT_STATUS_SUCCESS';
export const STUDENT_STATUS_FAIL = 'STUDENT_STATUS_FAIL';

export const STUDENT_AUTH_CHECK_REQUEST = 'STUDENT_AUTH_CHECK_REQUEST';
export const STUDENT_AUTH_CHECK_SUCCESS = 'STUDENT_AUTH_CHECK_SUCCESS';
export const STUDENT_AUTH_CHECK_FAIL = 'STUDENT_AUTH_CHECK_FAIL';
export const STUDENT_AUTH_CHECK_RESET = 'STUDENT_AUTH_CHECK_RESET';

export const STUDENT_PROFILE_REQUEST = 'STUDENT_PROFILE_REQUEST';
export const STUDENT_PROFILE_SUCCESS = 'STUDENT_PROFILE_SUCCESS';
export const STUDENT_PROFILE_FAIL = 'STUDENT_PROFILE_FAIL';
export const STUDENT_PROFILE_RESET = 'STUDENT_PROFILE_RESET';

export const STUDENT_PROFILE_UPDATE_REQUEST = 'STUDENT_PROFILE_UPDATE_REQUEST';
export const STUDENT_PROFILE_UPDATE_SUCCESS = 'STUDENT_PROFILE_UPDATE_SUCCESS';
export const STUDENT_PROFILE_UPDATE_FAIL = 'STUDENT_PROFILE_UPDATE_FAIL';
