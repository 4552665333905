export const PAGE_LIST_REQUEST = 'PAGE_LIST_REQUEST';
export const PAGE_LIST_SUCCESS = 'PAGE_LIST_SUCCESS';
export const PAGE_LIST_FAIL = 'PAGE_LIST_FAIL';
export const PAGE_LIST_RESET = 'PAGE_LIST_RESET';

export const PAGE_DETAILS_REQUEST = 'PAGE_DETAILS_REQUEST';
export const PAGE_DETAILS_SUCCESS = 'PAGE_DETAILS_SUCCESS';
export const PAGE_DETAILS_FAIL = 'PAGE_DETAILS_FAIL';
export const PAGE_DETAILS_RESET = 'PAGE_DETAILS_RESET';

export const PAGE_CREATE_REQUEST = 'PAGE_CREATE_REQUEST';
export const PAGE_CREATE_SUCCESS = 'PAGE_CREATE_SUCCESS';
export const PAGE_CREATE_FAIL = 'PAGE_CREATE_FAIL';
export const PAGE_CREATE_RESET = 'PAGE_CREATE_RESET';

export const PAGE_UPDATE_REQUEST = 'PAGE_UPDATE_REQUEST';
export const PAGE_UPDATE_SUCCESS = 'PAGE_UPDATE_SUCCESS';
export const PAGE_UPDATE_FAIL = 'PAGE_UPDATE_FAIL';
export const PAGE_UPDATE_RESET = 'PAGE_UPDATE_RESET';

export const PAGE_DELETE_REQUEST = 'PAGE_DELETE_REQUEST';
export const PAGE_DELETE_SUCCESS = 'PAGE_DELETE_SUCCESS';
export const PAGE_DELETE_FAIL = 'PAGE_DELETE_FAIL';

export const PAGE_PREV_REQUEST = 'PAGE_PREV_REQUEST';
export const PAGE_PREV_SUCCESS = 'PAGE_PREV_SUCCESS';
export const PAGE_PREV_FAIL = 'PAGE_PREV_FAIL';
export const PAGE_PREV_RESET = 'PAGE_PREV_RESET';

export const PAGE_NEXT_REQUEST = 'PAGE_NEXT_REQUEST';
export const PAGE_NEXT_SUCCESS = 'PAGE_NEXT_SUCCESS';
export const PAGE_NEXT_FAIL = 'PAGE_NEXT_FAIL';
export const PAGE_NEXT_RESET = 'PAGE_NEXT_RESET';
