import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  CircularProgress,
  Chip,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { grey } from '@material-ui/core/colors';
import LabsHeader from '../components/LabsHeader';
import InfoTable from '../components/InfoTable';
import { listAssessmentsByCourse as listAssessmentsByCourseAction } from '../actions/assessmentActions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';

const useStyles = makeStyles((theme) => ({
  section: {
    padding: '50px 20px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: grey[200],
    minHeight: 'calc(100vh - 202px)',
    userSelect: 'none',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  appBar: {
    position: 'relative',
  },
  chip: {
    margin: 2,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  notifSpacing: {
    marginBottom: '10px',
  },
  spacer: {
    height: '20px',
  },
}));

const StudentAssessmentScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  /* --------------------------- Redux State Getters -------------------------- */
  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo } = studentLogin;
  const assessmentsByCourse = useSelector((state) => state.assessmentsByCourse);
  const { loading, error, assessments } = assessmentsByCourse;

  /* ---------------------------- Lifecycle events ---------------------------- */
  useEffect(() => {
    if (studentInfo) {
      dispatch(listAssessmentsByCourseAction());
    } else {
      history.push('/');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [dispatch, history, studentInfo]);

  useEffect(() => {
    document.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
    return () =>
      document.removeEventListener('contextmenu', (event) => {
        event.preventDefault();
      });
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                     JSX                                    */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <LabsHeader
        pageTitle={`${studentInfo && studentInfo.course.name} Assessments`}
        vmInfo={false}
        backBtn={{ action: () => history.push('/dashboard') }}
      />
      <section className={classes.section}>
        <Container>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 255px)',
              }}
            >
              <CircularProgress />
            </div>
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : (
            <InfoTable
              columns={['no.', 'title', 'course', 'actions']}
              rows={
                assessments &&
                assessments.map((assessment) => ({
                  _id: assessment._id,
                  no: `Assessment ${assessment.no}`,
                  title: (
                    <span style={{ fontWeight: '500' }}>
                      {assessment.title}
                    </span>
                  ),
                  course: (
                    <Chip label={assessment.course.name} variant="outlined" />
                  ),
                  actions: [
                    {
                      kind: 'nav',
                      label: 'Start Assessment',
                      type: 'primary',
                      link: `/assessments/${assessment._id}`,
                    },
                  ],
                }))
              }
            />
          )}
        </Container>
      </section>
    </>
  );
};

export default StudentAssessmentScreen;
