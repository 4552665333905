/**
 * @fileOverview   Reducer for the Users Schema to perform CRUD operations
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_CREATE_RESET,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_AUTH_CHECK_REQUEST,
  USER_AUTH_CHECK_SUCCESS,
  USER_AUTH_CHECK_FAIL,
  USER_AUTH_CHECK_RESET,
  GET_STAT_REQUEST,
  GET_STAT_SUCCESS,
  GET_STAT_FAIL,
  GET_STAT_RESET,
  PULL_MACHINES_REQUEST,
  PULL_MACHINES_SUCCESS,
  PULL_MACHINES_FAIL,
} from '../constants/userConstants';

/**
 * Login user and get user data and new token from backend
 *
 * @param {Object} state Initial state of userLogin in the store.
 * @param {Object} action Object with payload with the logged in user data or an error
 * @return {Object} New state object
 */
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

/**
 * Get the list of all users
 *
 * @param {Object} state Initial state of userList in the store.
 * @param {Object} action Object with payload of all users or an error.
 * @return {Object} New state object
 */
export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case USER_LIST_RESET:
      return { users: [] };
    default:
      return state;
  }
};

/**
 * Create a new user
 *
 * @param {Object} state Initial state of user in the store.
 * @param {Object} action Object with payload of the newly created user.
 * @return {Object} New state object
 */
export const userCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CREATE_REQUEST:
      return { loading: true };
    case USER_CREATE_SUCCESS:
      return { loading: false, success: true, user: action.payload };
    case USER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

/**
 * Update an existing user
 *
 * @param {Object} state Initial state of user in the store.
 * @param {Object} action Object with payload of the newly updated user.
 * @return {Object} New state object
 */
export const userUpdateReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true, ...state };
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true, user: action.payload };
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_RESET:
      return { course: {} };
    default:
      return state;
  }
};

/**
 * Delete an existing user
 *
 * @param {Object} state Initial state of user in the store.
 * @param {Object} action (optional) Object with payload of an optional error.
 * @return {Object} New state object
 */
export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

/**
 * Check user's authentication state on page load
 *
 * @param {Object} state Initial state of user in the store.
 * @param {Object} action (optional) Object with payload of an optional error.
 * @return {Object} New state object
 */
export const userAuthCheckReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_AUTH_CHECK_REQUEST:
      return { loading: true };
    case USER_AUTH_CHECK_SUCCESS:
      return { loading: false, success: true };
    case USER_AUTH_CHECK_FAIL:
      return { loading: false, error: action.payload };
    case USER_AUTH_CHECK_RESET:
      return {};
    default:
      return state;
  }
};

/**
 * Get Statistics
 *
 * @param {Object} state Initial state of data stat in the store.
 * @param {Object} action (optional) Object with payload of an optional error.
 * @return {Object} New state object
 */
export const getStatReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_STAT_REQUEST:
      return { loading: true };
    case GET_STAT_SUCCESS:
      return { loading: false, stats: action.payload };
    case GET_STAT_FAIL:
      return { loading: false, error: action.payload };
    case GET_STAT_RESET:
      return {};
    default:
      return state;
  }
};

export const pullMachinesReducer = (state = {}, action) => {
  switch (action.type) {
    case PULL_MACHINES_REQUEST:
      return { loading: true };
    case PULL_MACHINES_SUCCESS:
      return { loading: false, success: true };
    case PULL_MACHINES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
