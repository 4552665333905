export const ASSESSMENT_LIST_REQUEST = 'ASSESSMENT_LIST_REQUEST';
export const ASSESSMENT_LIST_SUCCESS = 'ASSESSMENT_LIST_SUCCESS';
export const ASSESSMENT_LIST_FAIL = 'ASSESSMENT_LIST_FAIL';
export const ASSESSMENT_LIST_RESET = 'ASSESSMENT_LIST_RESET';

export const ASSESSMENT_DETAILS_REQUEST = 'ASSESSMENT_DETAILS_REQUEST';
export const ASSESSMENT_DETAILS_SUCCESS = 'ASSESSMENT_DETAILS_SUCCESS';
export const ASSESSMENT_DETAILS_FAIL = 'ASSESSMENT_DETAILS_FAIL';
export const ASSESSMENT_DETAILS_RESET = 'ASSESSMENT_DETAILS_RESET';

export const ASSESSMENT_CREATE_REQUEST = 'ASSESSMENT_CREATE_REQUEST';
export const ASSESSMENT_CREATE_SUCCESS = 'ASSESSMENT_CREATE_SUCCESS';
export const ASSESSMENT_CREATE_FAIL = 'ASSESSMENT_CREATE_FAIL';
export const ASSESSMENT_CREATE_RESET = 'ASSESSMENT_CREATE_RESET';

export const ASSESSMENT_UPDATE_REQUEST = 'ASSESSMENT_UPDATE_REQUEST';
export const ASSESSMENT_UPDATE_SUCCESS = 'ASSESSMENT_UPDATE_SUCCESS';
export const ASSESSMENT_UPDATE_FAIL = 'ASSESSMENT_UPDATE_FAIL';
export const ASSESSMENT_UPDATE_RESET = 'ASSESSMENT_UPDATE_RESET';

export const ASSESSMENT_DELETE_REQUEST = 'ASSESSMENT_DELETE_REQUEST';
export const ASSESSMENT_DELETE_SUCCESS = 'ASSESSMENT_DELETE_SUCCESS';
export const ASSESSMENT_DELETE_FAIL = 'ASSESSMENT_DELETE_FAIL';

export const CURRENT_ASSESSMENT_QUESTION = 'CURRENT_ASSESSMENT_QUESTION';

export const ASSESSMENTS_BY_COURSE_REQUEST = 'ASSESSMENTS_BY_COURSE_REQUEST';
export const ASSESSMENTS_BY_COURSE_SUCCESS = 'ASSESSMENTS_BY_COURSE_SUCCESS';
export const ASSESSMENTS_BY_COURSE_FAIL = 'ASSESSMENTS_BY_COURSE_FAIL';
