import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  CircularProgress,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Input,
  Chip,
  FormHelperText,
  Divider,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
import LabsHeader from '../components/LabsHeader';
import InfoTable from '../components/InfoTable';
import {
  listCourses as listCoursesAction,
  createCourse as createCourseAction,
  updateCourse as updateCourseAction,
  deleteCourse as deleteCourseAction,
} from '../actions/courseActions';
import { listBooks } from '../actions/bookActions';
import {
  COURSE_CREATE_RESET,
  COURSE_UPDATE_RESET,
} from '../constants/courseConstants';
import { SlideTransition, ZoomTransition } from '../components/Transitions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[200],
    padding: '50px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: 'calc(100vh - 206px)',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  notifSpacing: {
    marginBottom: '10px',
  },
}));

/**
 * Component for showing/adding/editing courses
 */
const CoursesScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Dialog State
  const [createPopup, setCreatePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);

  // Form and Local State
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [controls, setControls] = useState('');
  const [books, setBooks] = useState([]);
  const [introVideoYouTube, setIntroVideoYouTube] = useState('');
  const [introVideoLocal, setIntroVideoLocal] = useState('');

  const [updateName, setUpdateName] = useState('');
  const [updateSlug, setUpdateSlug] = useState('');
  const [updateControls, setUpdateControls] = useState('');
  const [updateBooks, setUpdateBooks] = useState([]);
  const [updateCourseId, setUpdateCourseId] = useState('');
  const [toDeleteCourse, setToDeleteCourse] = useState('');
  const [updateIntroVideoYouTube, setUpdateIntroVideoYouTube] = useState('');
  const [updateIntroVideoLocal, setUpdateIntroVideoLocal] = useState('');

  // Redux State Getters
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const coursesList = useSelector((state) => state.coursesList);
  const { loading, error, courses } = coursesList;
  const courseCreate = useSelector((state) => state.courseCreate);
  const {
    loading: createLoading,
    error: createError,
    success: createSuccess,
    course: createdCourse,
  } = courseCreate;
  const courseUpdate = useSelector((state) => state.courseUpdate);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    course: updatedCourse,
  } = courseUpdate;
  const courseDelete = useSelector((state) => state.courseDelete);
  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
  } = courseDelete;
  const booksList = useSelector((state) => state.booksList);
  const {
    loading: booksLoading,
    error: booksError,
    books: allBooks,
  } = booksList;

  // Lifecycle events
  useEffect(() => {
    if (userInfo && userInfo.role === 'admin') {
      dispatch(listCoursesAction());
      dispatch(listBooks());
    } else {
      history.push('/admin/login');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
    if (createSuccess) {
      handleCreatePopupClose();
      setName('');
      setSlug('');
      setControls('');
      setBooks([]);
      setIntroVideoLocal('');
      setIntroVideoYouTube('');
      dispatch({ type: COURSE_CREATE_RESET });
    }
    if (updateSuccess) {
      handleEditPopupClose();
      setUpdateName('');
      setUpdateSlug('');
      setUpdateControls('');
      setUpdateBooks([]);
      setUpdateIntroVideoYouTube('');
      setUpdateIntroVideoLocal('');
      dispatch({ type: COURSE_UPDATE_RESET });
    }
    if (deleteSuccess) {
      handleDeletePopupClose();
      setToDeleteCourse({});
    }
  }, [
    dispatch,
    history,
    userInfo,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    createdCourse,
    updatedCourse,
  ]);

  // Popup Management
  const handleCreatePopupClick = () => {
    setCreatePopup(true);
  };
  const handleCreatePopupClose = () => {
    setCreatePopup(false);
  };
  const handleEditPopupClick = () => {
    setEditPopup(true);
  };
  const handleEditPopupClose = () => {
    setEditPopup(false);
    setUpdateName('');
    setUpdateSlug('');
    setUpdateControls('');
    setUpdateBooks([]);
    setUpdateIntroVideoLocal('');
    setUpdateIntroVideoYouTube('');
  };
  const handleDeletePopupClick = (course) => {
    setDeletePopup(true);
    setToDeleteCourse(course);
  };
  const handleDeletePopupClose = () => {
    setDeletePopup(false);
  };

  // Action Handlers
  const handleCreateCourse = (e) => {
    e.preventDefault();
    const formData = {
      name,
      slug,
      controls,
      books,
    };
    dispatch(createCourseAction(formData));
  };
  const handleEditCourse = (e, id) => {
    courses.forEach((course) => {
      if (course._id === id) {
        setUpdateCourseId(course._id);
        setUpdateName(course.name);
        setUpdateSlug(course.slug);
        setUpdateControls(course.controls);
        setUpdateBooks(course.books);
        setUpdateIntroVideoYouTube(course.introVideoYouTube);
        setUpdateIntroVideoLocal(course.introVideoLocal);
      }
    });
    handleEditPopupClick();
  };
  const handleUpdateCourse = (e) => {
    e.preventDefault();
    const formData = {
      _id: updateCourseId,
      name: updateName,
      slug: updateSlug,
      controls: updateControls,
      books: updateBooks,
      introVideoYouTube: updateIntroVideoYouTube,
      introVideoLocal: updateIntroVideoLocal,
    };
    dispatch(updateCourseAction(formData));
  };
  const handleDeleteCourse = (id) => {
    dispatch(deleteCourseAction(id));
    handleDeletePopupClose();
  };

  return (
    <>
      <LabsHeader
        pageTitle={
          userInfo && userInfo.role === 'admin'
            ? `${courses ? 'Courses (' + courses.length + ' total)' : ''}`
            : 'Courses'
        }
        backBtn={{ action: () => history.push('/admin/dashboard') }}
        secondaryBtn={{
          label: 'Add New Course',
          action: () => handleCreatePopupClick(),
          style: 'contained',
        }}
      />
      <section className={classes.section}>
        <Container>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 255px)',
              }}>
              <CircularProgress />
            </div>
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : (
            <InfoTable
              columns={['name', 'controls', 'actions']}
              rows={courses.map((course) => ({
                _id: course._id,
                name: course.name,
                controls: course.controls.toUpperCase(),
                actions: [
                  {
                    kind: 'action',
                    label: 'Edit',
                    type: 'primary',
                    action: (e) => handleEditCourse(e, course._id),
                  },
                  {
                    kind: 'action',
                    label: 'Delete',
                    type: 'secondary',
                    action: () => handleDeletePopupClick(course),
                  },
                ],
              }))}
            />
          )}
          {/* Add new course dialog */}
          <Dialog
            TransitionComponent={SlideTransition}
            open={createPopup}
            onClose={handleCreatePopupClose}
            disableBackdropClick
            disableEscapeKeyDown>
            <form onSubmit={handleCreateCourse}>
              <DialogTitle id="form-dialog-title">Add New Course</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Create a new course. Courses can be assigned to Trainers. Labs
                  can be created and attached to courses.
                </DialogContentText>
                {createLoading && (
                  <LinearProgress
                    color="primary"
                    className={classes.notifSpacing}
                  />
                )}
                {createError && (
                  <Alert severity="error" className={classes.notifSpacing}>
                    {createError}
                  </Alert>
                )}
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="name"
                  type="text"
                  label="Name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="slug"
                  type="text"
                  label="Slug"
                  name="slug"
                  autoComplete="slug"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                />
                <div style={{ display: 'flex', gap: '10px', marginTop: '3px' }}>
                  <FormControl
                    variant="outlined"
                    style={{ width: '100%' }}
                    margin="dense"
                    className={classes.formControl}>
                    <InputLabel id="controls">Controls</InputLabel>
                    <Select
                      labelId="controls"
                      id="controls"
                      name="controls"
                      value={controls}
                      onChange={(e) => setControls(e.target.value)}
                      label="Controls">
                      <MenuItem value={'iou'}>IOU</MenuItem>
                      <MenuItem value={'vnc'}>VNC</MenuItem>
                      <MenuItem value={'iou-vnc'}>IOU-VNC</MenuItem>
                      <MenuItem value={'read-only'}>Read-Only</MenuItem>
                    </Select>
                    <FormHelperText>
                      Controls available for this course
                    </FormHelperText>
                  </FormControl>
                </div>
                <Divider style={{ margin: '1rem 0' }} />
                {!booksError && (
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel id="selectBooks">Select Books</InputLabel>
                    <Select
                      labelId="selectBooks"
                      variant="outlined"
                      id="books"
                      multiple
                      value={books}
                      onChange={(e) => setBooks(e.target.value)}
                      input={<Input id="booksChip" />}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={
                                allBooks.filter((bk) => bk._id === value)[0]
                                  ? allBooks.filter((bk) => bk._id === value)[0]
                                      .title
                                  : null
                              }
                              className={classes.chip}
                            />
                          ))}
                        </div>
                      )}>
                      {allBooks.map((book) => (
                        <MenuItem key={book._id} value={book._id}>
                          {book.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {/* VIDEO */}
                <Divider style={{ margin: '1rem 0' }} />
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  id="introVideoYouTube"
                  type="text"
                  label="YouTube Video YouTube Embed Code"
                  name="introVideoYouTube"
                  autoComplete="introVideoYouTube"
                  value={introVideoYouTube}
                  onChange={(e) => setIntroVideoYouTube(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  id="introVideoLocal"
                  type="text"
                  label="YouTube Video Url"
                  name="introVideoLocal"
                  autoComplete="introVideoLocal"
                  value={introVideoLocal}
                  onChange={(e) => setIntroVideoLocal(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCreatePopupClose} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Create Course
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          {/* Edit course dialog */}
          <Dialog
            TransitionComponent={SlideTransition}
            open={editPopup}
            fullWidth
            onClose={handleEditPopupClose}
            disableBackdropClick
            disableEscapeKeyDown>
            <form onSubmit={handleUpdateCourse}>
              <DialogTitle id="form-dialog-title">
                Edit Course — {updateName}
              </DialogTitle>
              <DialogContent>
                {updateLoading && (
                  <LinearProgress
                    color="primary"
                    className={classes.notifSpacing}
                  />
                )}
                {updateError && (
                  <Alert severity="error" className={classes.notifSpacing}>
                    {updateError}
                  </Alert>
                )}
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="name"
                  type="text"
                  label="Name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  value={updateName}
                  onChange={(e) => setUpdateName(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  id="slug"
                  type="text"
                  label="Slug"
                  name="slug"
                  autoComplete="slug"
                  value={updateSlug}
                  onChange={(e) => setUpdateSlug(e.target.value)}
                />
                <div style={{ display: 'flex', gap: '10px', marginTop: '3px' }}>
                  <FormControl
                    variant="outlined"
                    style={{ width: '100%' }}
                    margin="dense"
                    className={classes.formControl}>
                    <InputLabel id="controls">Controls</InputLabel>
                    <Select
                      labelId="controls"
                      id="controls"
                      name="controls"
                      value={updateControls}
                      onChange={(e) => setUpdateControls(e.target.value)}
                      label="Controls">
                      <MenuItem value={'iou'}>IOU</MenuItem>
                      <MenuItem value={'vnc'}>VNC</MenuItem>
                      <MenuItem value={'iou-vnc'}>IOU-VNC</MenuItem>
                      <MenuItem value={'read-only'}>Read-Only</MenuItem>
                    </Select>
                    <FormHelperText>
                      Controls available for this course
                    </FormHelperText>
                  </FormControl>
                </div>
                {!booksError && (
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel id="selectBooks">Select Books</InputLabel>
                    <Select
                      labelId="selectBooks"
                      variant="outlined"
                      id="updateBooks"
                      multiple
                      value={updateBooks}
                      onChange={(e) => setUpdateBooks(e.target.value)}
                      input={<Input id="booksChip" />}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={
                                allBooks.filter((bk) => bk._id === value)[0]
                                  ? allBooks.filter((bk) => bk._id === value)[0]
                                      .title
                                  : null
                              }
                              className={classes.chip}
                            />
                          ))}
                        </div>
                      )}>
                      {allBooks.map((book) => (
                        <MenuItem key={book._id} value={book._id}>
                          {book.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {/* VIDEO */}
                <Divider style={{ margin: '1rem 0' }} />
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  id="introVideoYouTube"
                  type="text"
                  label="YouTube Video YouTube Embed Code"
                  name="introVideoYouTube"
                  autoComplete="introVideoYouTube"
                  value={updateIntroVideoYouTube}
                  onChange={(e) => setUpdateIntroVideoYouTube(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  id="introVideoLocal"
                  type="text"
                  label="YouTube Video Url"
                  name="introVideoLocal"
                  autoComplete="introVideoLocal"
                  value={updateIntroVideoLocal}
                  onChange={(e) => setUpdateIntroVideoLocal(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleEditPopupClose} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Update
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          {/* Delete course dialog */}
          <Dialog
            TransitionComponent={ZoomTransition}
            open={deletePopup}
            onClose={handleDeletePopupClose}>
            <DialogTitle id="delete-dialog-title">Delete Course</DialogTitle>
            <DialogContent>
              {deleteLoading && <LinearProgress color="secondary" />}
              {deleteError && <Alert severity="error">{deleteError}</Alert>}
              <DialogContentText id="delete-dialog-description">
                Are you sure? Deleting <strong>{toDeleteCourse.name}</strong>{' '}
                will affect all the labs, students and batches associated with
                it. This action is permanent!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleDeletePopupClose}
                color="primary"
                autoFocus>
                Cancel
              </Button>
              <Button
                onClick={() => handleDeleteCourse(toDeleteCourse._id)}
                color="secondary">
                Confirm Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </section>
    </>
  );
};

export default CoursesScreen;
