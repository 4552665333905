import { vncPortNoGen } from './genVNCPorts';

export const setWindowAddrForTelnet = () => {
	const windowAddr = window.location.origin;

	if (+windowAddr.split('.')[2] <= 3) {
		return '192.168.1.231';
	} else if (
		+windowAddr.split('.')[2] >= 64 &&
		+windowAddr.split('.')[2] <= 71
	) {
		return '192.168.71.231';
	} else if (+windowAddr.split('.')[0].split('/')[2] === 45) {
		return '103.204.135.53';
	} else {
		return 'connect.rstforum.net';
	}
};

export const setWindowAddrForTelnetRequests = () => {
	const windowAddr = window.location.origin;

	if (+windowAddr.split('.')[2] <= 3) {
		return 'http://192.168.1.231';
	} else if (
		+windowAddr.split('.')[2] >= 64 &&
		+windowAddr.split('.')[2] <= 71
	) {
		return 'http://192.168.71.231';
	} else if (+windowAddr.split('.')[0].split('/')[2] === 45) {
		return 'http://103.204.135.53';
	} else {
		return 'https://connect.rstforum.net';
	}
};

export const setAddrForResources = () => {
	const windowAddr = window.location.origin;

	if (+windowAddr.split('.')[2] <= 3) {
		return 'https://192.168.1.230:9001';
	} else if (
		+windowAddr.split('.')[2] >= 64 &&
		+windowAddr.split('.')[2] <= 71
	) {
		return 'https://192.168.71.230:9001';
	} else if (+windowAddr.split('.')[0].split('/')[2] === 45) {
		return 'https://103.204.135.53';
	} else {
		return 'https://labs.rstforum.net';
	}
};

export const mapper = (
	type,
	ips,
	ports,
	code,
	evePorts,
	singleIpAddr = null
) => {
	let diagramCode = '';
	if (type === 'telnet') {
		if (ips.length > 0) {
			diagramCode = code
				.replaceAll('IP_ADDRESS', ips[0])
				.replaceAll('WINDOW_LOC', setWindowAddrForTelnet() + ':9099');
		}
	} else if (type === 'vnc') {
		if (ports.length > 0) {
			diagramCode = code;
			for (let i = 0; i < ports.length; i++) {
				diagramCode = diagramCode
					.replace('IP_ADDRESS', ports[i])
					.replace('IP_ADDRESS', ports[i])
					.replaceAll('WINLOCNOPORT', setWindowAddrForTelnet());
			}
		}
	} else if (type === 'net-vnc') {
		diagramCode = code;
		if (ips.length > 0) {
			const vPort = ips[0].split('.').slice(2).join('');
			diagramCode = code
				.replaceAll('IP_ADDRESS', ips[0])
				.replaceAll('WINDOW_LOC', setWindowAddrForTelnet() + ':9099')
				.replaceAll('WINLOCNOPORT', setWindowAddrForTelnet())
				.replaceAll('NETVNCPORT', vPort);
		}
	} else if (type === 'telnet-vnc') {
		diagramCode = code;
		if (ips.length > 0 && ports.length > 0) {
			const vPort = ips[0].split('.').slice(2).join('');
			diagramCode = code
				.replaceAll('IP_ADDRESS', ips[0])
				.replaceAll('WINDOW_LOC', setWindowAddrForTelnet() + ':9099')
				.replaceAll('WINLOCNOPORT', setWindowAddrForTelnet())
				.replaceAll('NETVNCPORT', vPort)
				.replaceAll('M2IPVNC', ports[0]);
		}
	} else if (type === 'eve-telnet-vnc') {
		// const vals = { 5: 15, 6: 30, 7: 45 };
		// // const mid_num = +ips[0].split(".")[3][1]
		// const loct = ips[0].split('.')[ips[0].split('.').length - 1];
		// const midNum = +loct[loct.length - 2];
		// const lastNum = +loct[loct.length - 1];

		// let eports = evePorts.split(',').map((ep) => +ep);
		let eports = evePorts.split(',');
		// let ipNum = +ips[0].split('.')[3];
		// console.log(eports, ipNum);
		if (ips.length > 0) {
			diagramCode = code
				.replaceAll('IP_ADDRESS', ips[0])
				.replaceAll('WINDOW_LOC', setWindowAddrForTelnet() + ':9099')
				.replaceAll('WINLOCNOPORT', setWindowAddrForTelnet());

			for (let i = 0; i < eports.length; i++) {
				// const listenPort =
				//   eports[i] + vals[midNum] * (lastNum === 0 ? 10 : lastNum - 1);
				// console.log(
				//   `diagramCode = diagramCode.replace('EPORT', ${listenPort})`
				// );
				const listenPort = vncPortNoGen(eports[i], ips[0]);
				console.log(String(listenPort));
				diagramCode = diagramCode.replace('EPORT', listenPort);
				diagramCode = diagramCode.replace('EPORT', listenPort);
			}
		}
	} else if (type === 'eve-telnet-ext-vnc') {
		diagramCode = code
			.replaceAll('IP_ADDRESS', singleIpAddr)
			.replaceAll('WINDOW_LOC', setWindowAddrForTelnet() + ':9099')
			.replaceAll('WINLOCNOPORT', setWindowAddrForTelnet());

		for (let i = 0; i < ports.length; i++) {
			diagramCode = diagramCode.replace('VNCPORT', ports[i]);
		}
	}
	return diagramCode;
};
