import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  CircularProgress,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Select,
  Paper,
  Chip,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar,
  Divider,
  ListItemSecondaryAction,
  Icon,
} from '@material-ui/core';
import { grey, blue } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import LabsHeader from '../components/LabsHeader';
import InfoTable from '../components/InfoTable';
import { listBooks as listBooksAction } from '../actions/bookActions';
import { listCourses as listCoursesAction } from '../actions/courseActions';
import {
  listVideos as listVideosAction,
  createVideo as createVideoAction,
  updateVideo as updateVideoAction,
  deleteVideo as deleteVideoAction,
} from '../actions/videoActions';
// import {
//   BOOK_CREATE_RESET,
//   BOOK_UPDATE_RESET,
// } from '../constants/bookConstants';
import { SlideTransition, ZoomTransition } from '../components/Transitions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[200],
    padding: '50px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: 'calc(100vh - 206px)',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  appBar: {
    position: 'relative',
  },
  chip: {
    margin: 2,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  notifSpacing: {
    marginBottom: '10px',
  },
  spacer: {
    height: '20px',
  },
  markupText: {
    '& textarea': {
      fontFamily: 'monospace',
    },
  },
  chaptersList: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  chaptersListBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  chapterNo: {
    color: theme.palette.getContrastText(blue[100]),
    backgroundColor: blue[100],
  },
  noChaptersDisplay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '0.3',
    flexDirection: 'column',
  },
}));

const BooksScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Dialog State
  const [createPopup, setCreatePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [videoEnglishUploading, setVideoEnglishUploading] = useState(false);
  const [videoHindiUploading, setVideoHindiUploading] = useState(false);

  // Form and Local State
  const [no, setNo] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [englishVideoUrl, setEnglishVideoUrl] = useState('');
  const [hindiVideoUrl, setHindiVideoUrl] = useState('');
  const [englishVideoUrlYouTube, setEnglishVideoUrlYouTube] = useState('');
  const [hindiVideoUrlYouTube, setHindiVideoUrlYouTube] = useState('');
  const [course, setCourse] = useState('');
  const [book, setBook] = useState('');

  const [updateNo, setUpdateNo] = useState('');
  const [updateTitle, setUpdateTitle] = useState('');
  const [updateDescription, setUpdateDescription] = useState('');
  const [updateEnglishVideoUrl, setUpdateEnglishVideoUrl] = useState('');
  const [updateHindiVideoUrl, setUpdateHindiVideoUrl] = useState('');
  const [updateEnglishVideoUrlYouTube, setUpdateEnglishVideoUrlYouTube] =
    useState('');
  const [updateHindiVideoUrlYouTube, setUpdateHindiVideoUrlYouTube] =
    useState('');
  const [updateCourse, setUpdateCourse] = useState('');
  const [updateBook, setUpdateBook] = useState('');

  const [updateVideoId, setUpdateVideoId] = useState('');
  const [toDeleteVideo, setToDeleteVideo] = useState('');

  // Redux State Getters
  const useLogin = useSelector((state) => state.userLogin);
  const { userInfo } = useLogin;
  const booksList = useSelector((state) => state.booksList);
  const { loading, error, books } = booksList;
  const coursesList = useSelector((state) => state.coursesList);
  const {
    loading: allCoursesLoading,
    error: allCoursesError,
    courses: allCourses,
  } = coursesList;
  const videosList = useSelector((state) => state.videosList);
  const {
    loading: allVideosLoading,
    error: allVideosError,
    videos,
  } = videosList;
  const videoCreate = useSelector((state) => state.videoCreate);
  const {
    loading: createLoading,
    error: createError,
    success: createSuccess,
    video: createdVideo,
  } = videoCreate;
  const videoUpdate = useSelector((state) => state.videoUpdate);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    video: updatedVideo,
  } = videoUpdate;
  const videoDelete = useSelector((state) => state.videoDelete);
  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
  } = videoDelete;

  // Lifecycle events
  useEffect(() => {
    if (userInfo) {
      dispatch(listBooksAction());
      dispatch(listCoursesAction());
      dispatch(listVideosAction());
    } else {
      history.push('/admin/login');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
    if (createSuccess) {
      handleCreatePopupClose();
      setCreatePopup(false);
      setNo('');
      setTitle('');
      setDescription('');
      setEnglishVideoUrl('');
      setHindiVideoUrl('');
      setEnglishVideoUrlYouTube('');
      setHindiVideoUrlYouTube('');
      setCourse('');
      setBook('');
      // dispatch({ type: BOOK_CREATE_RESET });
    }
    if (updateSuccess) {
      handleEditPopupClose();
      setUpdateNo('');
      setUpdateTitle('');
      setUpdateDescription('');
      setUpdateEnglishVideoUrl('');
      setUpdateHindiVideoUrl('');
      setUpdateEnglishVideoUrlYouTube('');
      setUpdateHindiVideoUrlYouTube('');
      setUpdateCourse('');
      setUpdateBook('');
      // dispatch({ type: BOOK_UPDATE_RESET });
    }
    if (deleteSuccess) {
      handleDeletePopupClose();
      setToDeleteVideo({});
    }
  }, [
    dispatch,
    history,
    userInfo,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    createdVideo,
    updatedVideo,
  ]);

  // Popup Management
  const handleCreatePopupClick = () => {
    setCreatePopup(true);
  };
  const handleCreatePopupClose = () => {
    setCreatePopup(false);
  };
  const handleEditPopupClick = () => {
    setEditPopup(true);
  };
  const handleEditPopupClose = () => {
    setEditPopup(false);
    setUpdateNo('');
    setUpdateTitle('');
    setUpdateDescription('');
    setUpdateEnglishVideoUrl('');
    setUpdateHindiVideoUrl('');
    setUpdateCourse('');
    setUpdateBook('');
    setUpdateEnglishVideoUrlYouTube('');
    setUpdateHindiVideoUrlYouTube('');
  };
  const handleDeletePopupClick = (video) => {
    setToDeleteVideo(video);
    setDeletePopup(true);
  };
  const handleDeletePopupClose = () => {
    setDeletePopup(false);
  };

  // Action Handlers
  const handleCreateVideo = (e) => {
    e.preventDefault();
    const formData = {
      no,
      title,
      description,
      englishVideoUrl,
      hindiVideoUrl,
      englishVideoUrlYouTube,
      hindiVideoUrlYouTube,
      course,
      book,
    };
    dispatch(createVideoAction(formData));
  };

  const handleEditVideo = (_e, id) => {
    videos.forEach((video) => {
      if (video._id === id) {
        setUpdateVideoId(video._id);
        setUpdateNo(video.no);
        setUpdateTitle(video.title);
        setUpdateDescription(video.description);
        setUpdateEnglishVideoUrl(video.englishVideoUrl);
        setUpdateHindiVideoUrl(video.hindiVideoUrl);
        setUpdateEnglishVideoUrlYouTube(video.englishVideoUrlYouTube);
        setUpdateHindiVideoUrlYouTube(video.hindiVideoUrlYouTube);
        setUpdateCourse(video.course._id);
        setUpdateBook(video.book._id);
      }
    });
    handleEditPopupClick();
  };

  const handleUpdateVideo = (e) => {
    e.preventDefault();
    const formData = {
      _id: updateVideoId,
      no: updateNo,
      title: updateTitle,
      description: updateDescription,
      englishVideoUrl: updateEnglishVideoUrl,
      hindiVideoUrl: updateHindiVideoUrl,
      englishVideoUrlYouTube: updateEnglishVideoUrlYouTube,
      hindiVideoUrlYouTube: updateHindiVideoUrlYouTube,
      course: updateCourse,
      book: updateBook,
    };
    dispatch(updateVideoAction(formData));
  };

  const handleDeleteVideo = (id) => {
    dispatch(deleteVideoAction(id));
    handleDeletePopupClose();
  };

  const uploadVideoHandler = async (e, update, language) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);

    if (language === 'english') {
      setVideoEnglishUploading(true);
      try {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
        const { data } = await axios.post('/api/upload', formData, config);

        if (update) {
          setUpdateEnglishVideoUrl(data);
        } else {
          console.log('Reached here - ENGLISH');
          setEnglishVideoUrl(data);
        }

        setVideoEnglishUploading(false);
      } catch (err) {
        console.error(err);
        setVideoEnglishUploading(false);
      }
    }
    if (language === 'hindi') {
      setVideoHindiUploading(true);
      try {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
        const { data } = await axios.post('/api/upload', formData, config);

        if (update) {
          setUpdateHindiVideoUrl(data);
        } else {
          console.log('Reached here - HINDI');
          setHindiVideoUrl(data);
        }

        setVideoHindiUploading(false);
      } catch (err) {
        console.error(err);
        setVideoHindiUploading(false);
      }
    }
  };

  return (
    <>
      <LabsHeader
        pageTitle={
          userInfo && userInfo.role === 'admin'
            ? `${videos ? 'Videos (' + videos.length + ' total)' : ''}`
            : 'Videos'
        }
        backBtn={{ action: () => history.push('/admin/dashboard') }}
        tertiaryBtn={{
          label: 'Add New Video',
          action: () => handleCreatePopupClick(),
          style: 'contained',
        }}
      />
      <section className={classes.section}>
        <Container>
          {loading && allVideosLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 255px)',
              }}>
              <CircularProgress />
            </div>
          ) : error || allVideosError ? (
            <Alert severity="error">{error || allVideosError}</Alert>
          ) : (
            <InfoTable
              columns={['no', 'title', 'course', 'book', 'actions']}
              rows={
                videos &&
                videos.map((video) => ({
                  _id: video._id,
                  no: video.no,
                  title: (
                    <span style={{ fontWeight: '500' }}>{video.title}</span>
                  ),
                  course: <Chip label={video.course.name} variant="outlined" />,
                  book: <Chip label={video.book.title} variant="outlined" />,
                  actions: [
                    ...(userInfo && userInfo.accessLevel >= 7
                      ? [
                          {
                            kind: 'action',
                            label: 'Edit',
                            type: 'primary',
                            action: (e) => handleEditVideo(e, video._id),
                          },
                        ]
                      : []),
                    ...(userInfo && userInfo.role === 'admin'
                      ? [
                          {
                            kind: 'action',
                            label: 'Delete',
                            type: 'secondary',
                            action: () => handleDeletePopupClick(video),
                          },
                        ]
                      : []),
                  ],
                }))
              }
            />
          )}

          {/* Add new videos dialog */}
          <Dialog
            fullScreen
            open={createPopup}
            onClose={handleCreatePopupClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={SlideTransition}>
            <form onSubmit={handleCreateVideo}>
              <AppBar className={classes.appBar} color="primary">
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleCreatePopupClose}
                    aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    Add New Video
                  </Typography>
                  <Button color="inherit" type="submit">
                    Add Video
                  </Button>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <DialogContentText style={{ marginTop: '20px' }}>
                  Create a new Video. Please follow the correct HTML formatting
                  for the book.
                </DialogContentText>
                {createLoading && (
                  <LinearProgress
                    color="primary"
                    className={classes.notifSpacing}
                  />
                )}
                {createError && (
                  <Alert severity="error" className={classes.notifSpacing}>
                    {createError}
                  </Alert>
                )}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required
                    id="no"
                    type="text"
                    label="Video No."
                    name="no"
                    autoComplete="no"
                    autoFocus
                    value={no}
                    onChange={(e) => setNo(e.target.value)}
                    style={{ width: '15%' }}
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="title"
                    type="text"
                    label="Video Title"
                    name="title"
                    autoComplete="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>

                {/* Video Description */}
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  multiline
                  rows="4"
                  id="description"
                  type="text"
                  label="Video Description"
                  name="description"
                  autoComplete="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />

                {/* Video Upload Fields (English and Hindi) */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  {/* Upload English Video */}
                  <div
                    style={{
                      flexGrow: '1',
                    }}>
                    {videoEnglishUploading && (
                      <LinearProgress style={{ marginBottom: '5px' }} />
                    )}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        flexGrow: '1',
                      }}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        id="englishVideoUrl"
                        style={{ flexGrow: '1' }}
                        fullwidth
                        type="text"
                        label="Upload English Video"
                        name="englishVideoUrl"
                        autoComplete="englishVideoUrl"
                        value={englishVideoUrl}
                        onChange={(e) => setEnglishVideoUrl(e.target.value)}
                      />
                      <Button
                        variant="contained"
                        style={{
                          minHeight: '39px',
                          position: 'relative',
                          top: '2px',
                        }}
                        component="label"
                        disableElevation
                        id="englishVideo"
                        onChange={(e) =>
                          uploadVideoHandler(e, false, 'english')
                        }>
                        Upload
                        <input type="file" hidden />
                      </Button>
                    </div>
                  </div>
                  {/* Upload Hindi Video */}
                  <div
                    style={{
                      flexGrow: '1',
                    }}>
                    {videoHindiUploading && (
                      <LinearProgress style={{ marginBottom: '5px' }} />
                    )}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        flexGrow: '1',
                      }}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        id="hindiVideoUrl"
                        style={{ flexGrow: '1' }}
                        fullwidth
                        type="text"
                        label="Upload Hindi Video"
                        name="hindiVideoUrl"
                        autoComplete="hindiVideoUrl"
                        value={hindiVideoUrl}
                        onChange={(e) => setHindiVideoUrl(e.target.value)}
                      />
                      <Button
                        variant="contained"
                        style={{
                          minHeight: '39px',
                          position: 'relative',
                          top: '2px',
                        }}
                        component="label"
                        disableElevation
                        id="hindiVideo"
                        onChange={(e) => uploadVideoHandler(e, false, 'hindi')}>
                        Upload
                        <input type="file" hidden />
                      </Button>
                    </div>
                  </div>
                </div>

                {/* YouTube Video Embed Fields (English and Hindi) */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    multiline
                    rows="3"
                    fullWidth
                    id="videoEnglishYT"
                    type="text"
                    label="English YouTube Embed Code"
                    name="videoEnglishYT"
                    autoComplete="videoEnglishYT"
                    value={englishVideoUrlYouTube}
                    onChange={(e) => setEnglishVideoUrlYouTube(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    multiline
                    rows="3"
                    fullWidth
                    id="videoHindiYT"
                    type="text"
                    label="Hindi YouTube Embed Code"
                    name="videoHindiYT"
                    autoComplete="videoHindiYT"
                    value={hindiVideoUrlYouTube}
                    onChange={(e) => setHindiVideoUrlYouTube(e.target.value)}
                  />
                </div>

                {/* Course and Book */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    style={{ width: '50%' }}
                    required
                    className={classes.formControl}>
                    <InputLabel id="videoCourse">Course</InputLabel>
                    <Select
                      labelId="videoCourse"
                      id="course"
                      name="course"
                      value={course}
                      onChange={(e) => setCourse(e.target.value)}
                      label="Course *">
                      {allCourses &&
                        allCourses.map((c) => (
                          <MenuItem key={c._id} value={c._id}>
                            {c.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    style={{ width: '50%' }}
                    required
                    className={classes.formControl}>
                    <InputLabel id="videoBook">Book</InputLabel>
                    <Select
                      labelId="videoBook"
                      id="book"
                      name="book"
                      value={book}
                      onChange={(e) => setBook(e.target.value)}
                      label="Book *">
                      {books &&
                        books.map((c) => (
                          <MenuItem key={c._id} value={c._id}>
                            {c.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </DialogContent>
            </form>
          </Dialog>

          {/* Update Video Dialog */}
          <Dialog
            fullScreen
            open={editPopup}
            onClose={handleEditPopupClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={SlideTransition}>
            <form onSubmit={handleUpdateVideo}>
              <AppBar className={classes.appBar} color="primary">
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleEditPopupClose}
                    aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    Edit Video — "{updateTitle}"
                  </Typography>
                  <Button color="inherit" type="submit">
                    Update
                  </Button>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <div className={classes.spacer}></div>
                {updateLoading && (
                  <LinearProgress
                    color="primary"
                    className={classes.notifSpacing}
                  />
                )}
                {updateError && (
                  <Alert severity="error" className={classes.notifSpacing}>
                    {updateError}
                  </Alert>
                )}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="no"
                    type="text"
                    label="Video No."
                    name="no"
                    autoComplete="no"
                    autoFocus
                    value={updateNo}
                    onChange={(e) => setUpdateNo(e.target.value)}
                    style={{ width: '15%' }}
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="title"
                    type="text"
                    label="Video Title"
                    name="title"
                    autoComplete="title"
                    value={updateTitle}
                    onChange={(e) => setUpdateTitle(e.target.value)}
                  />
                </div>

                {/* Video Description */}
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  multiline
                  rows="4"
                  id="description"
                  type="text"
                  label="Video Description"
                  name="description"
                  autoComplete="description"
                  value={updateDescription}
                  onChange={(e) => setUpdateDescription(e.target.value)}
                />

                {/* Video Upload Fields (English and Hindi) */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  {/* Upload English Video */}
                  <div
                    style={{
                      flexGrow: '1',
                    }}>
                    {videoEnglishUploading && (
                      <LinearProgress style={{ marginBottom: '5px' }} />
                    )}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        flexGrow: '1',
                      }}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        id="englishVideoUrl"
                        style={{ flexGrow: '1' }}
                        fullwidth
                        type="text"
                        label="Upload English Video"
                        name="englishVideoUrl"
                        autoComplete="englishVideoUrl"
                        value={updateEnglishVideoUrl}
                        onChange={(e) =>
                          setUpdateEnglishVideoUrl(e.target.value)
                        }
                      />
                      <Button
                        variant="contained"
                        style={{
                          minHeight: '39px',
                          position: 'relative',
                          top: '2px',
                        }}
                        component="label"
                        disableElevation
                        id="englishVideo"
                        onChange={(e) =>
                          uploadVideoHandler(e, false, 'english')
                        }>
                        Upload
                        <input type="file" hidden />
                      </Button>
                    </div>
                  </div>
                  {/* Upload Hindi Video */}
                  <div
                    style={{
                      flexGrow: '1',
                    }}>
                    {videoHindiUploading && (
                      <LinearProgress style={{ marginBottom: '5px' }} />
                    )}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        flexGrow: '1',
                      }}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        id="hindiVideoUrl"
                        style={{ flexGrow: '1' }}
                        fullwidth
                        type="text"
                        label="Upload Hindi Video"
                        name="hindiVideoUrl"
                        autoComplete="hindiVideoUrl"
                        value={updateHindiVideoUrl}
                        onChange={(e) => setUpdateHindiVideoUrl(e.target.value)}
                      />
                      <Button
                        variant="contained"
                        style={{
                          minHeight: '39px',
                          position: 'relative',
                          top: '2px',
                        }}
                        component="label"
                        disableElevation
                        id="hindiVideo"
                        onChange={(e) => uploadVideoHandler(e, false, 'hindi')}>
                        Upload
                        <input type="file" hidden />
                      </Button>
                    </div>
                  </div>
                </div>

                {/* YouTube Video Embed Fields (English and Hindi) */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    multiline
                    rows="3"
                    fullWidth
                    id="videoEnglishYT"
                    type="text"
                    label="English YouTube Embed Code"
                    name="videoEnglishYT"
                    autoComplete="videoEnglishYT"
                    value={updateEnglishVideoUrlYouTube}
                    onChange={(e) =>
                      setUpdateEnglishVideoUrlYouTube(e.target.value)
                    }
                  />
                  <TextField
                    variant="outlined"
                    margin="dense"
                    multiline
                    rows="3"
                    fullWidth
                    id="videoHindiYT"
                    type="text"
                    label="Hindi YouTube Embed Code"
                    name="videoHindiYT"
                    autoComplete="videoHindiYT"
                    value={updateHindiVideoUrlYouTube}
                    onChange={(e) =>
                      setUpdateHindiVideoUrlYouTube(e.target.value)
                    }
                  />
                </div>

                {/* Course and Book */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    style={{ width: '50%' }}
                    required
                    className={classes.formControl}>
                    <InputLabel id="videoCourse">Course</InputLabel>
                    <Select
                      labelId="videoCourse"
                      id="course"
                      name="course"
                      value={updateCourse}
                      onChange={(e) => setUpdateCourse(e.target.value)}
                      label="Course *">
                      {allCourses &&
                        allCourses.map((c) => (
                          <MenuItem key={c._id} value={c._id}>
                            {c.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    style={{ width: '50%' }}
                    required
                    className={classes.formControl}>
                    <InputLabel id="videoBook">Book</InputLabel>
                    <Select
                      labelId="videoBook"
                      id="book"
                      name="book"
                      value={updateBook}
                      onChange={(e) => setUpdateBook(e.target.value)}
                      label="Book *">
                      {books &&
                        books.map((c) => (
                          <MenuItem key={c._id} value={c._id}>
                            {c.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </DialogContent>
            </form>
          </Dialog>

          {/* Delete Video Dialogue */}
          <Dialog
            TransitionComponent={ZoomTransition}
            open={deletePopup}
            onClose={handleDeletePopupClose}>
            <DialogTitle id="delete-dialog-title">Delete Video</DialogTitle>
            <DialogContent>
              {deleteLoading && (
                <LinearProgress
                  color="secondary"
                  className={classes.progressSpacing}
                />
              )}
              {deleteError && <Alert severity="error">{deleteError}</Alert>}
              <DialogContentText id="delete-dialog-description">
                Are you sure you want to delete this video?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleDeletePopupClose}
                color="primary"
                autoFocus>
                Cancel
              </Button>
              <Button
                onClick={() => handleDeleteVideo(toDeleteVideo._id)}
                color="secondary">
                Confirm Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </section>
    </>
  );
};

export default BooksScreen;
