import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Typography,
  Link,
  Button,
  IconButton,
  Avatar,
  Divider,
  MenuItem,
  Menu,
  Tooltip,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { blue, red } from '@material-ui/core/colors';
import { logout } from '../actions/userActions';
import { logout as studentLogout } from '../actions/studentActions';
import Logo from '../logo.svg';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: '19px',
    fontWeight: '500',
    top: '2px',
    position: 'relative',
    opacity: '0.7',
    color: '#ffffff',
    userSelect: 'none',
  },
  logo: {
    height: '35px',
    marginTop: '2px',
  },
  header: {
    zIndex: 999,
    padding: '0 20px',
  },
  darkBlue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[900],
  },
  dangerRed: {
    color: red[900],
  },
  menuInfo: {
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      fontSize: '14px',
      textTransform: 'capitalize',
    },
  },
  branding: {
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
    '& span': {
      fontSize: '18px',
      fontWeight: '500',
      color: blue[900],
    },
  },
  headerSpacing: {
    justifyContent: 'space-between',
  },
}));

const Header = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo } = studentLogin;

  const classes = useStyles();
  // const [auth, setAuth] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null);
  const [studentAnchorEl, setStudentAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const studentOpen = Boolean(studentAnchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleStudentMenu = (event) => {
    setStudentAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleStudentClose = () => {
    setStudentAnchorEl(null);
  };

  const logoutHandler = () => {
    dispatch(logout());
    setAnchorEl(null);
  };
  const studentLogoutHandler = () => {
    dispatch(studentLogout());
    setStudentAnchorEl(null);
  };

  return (
    <AppBar className={`${classes.header} prhdn`} elevation={1}>
      <Toolbar disableGutters className={classes.headerSpacing}>
        <div>
          <div className={classes.branding}>
            <Link
              component={RouterLink}
              to={userInfo ? '/admin/dashboard' : '/'}
            >
              <img src={Logo} className={classes.logo} alt="RST Forum" />
            </Link>
            {/* Place title here for left alignment */}
          </div>
        </div>
        <Typography className={classes.title} variant="h6" noWrap>
          Learning Delivery Platform
        </Typography>
        {userInfo &&
        (userInfo.role === 'admin' ||
          userInfo.role === 'trainer' ||
          userInfo.role === 'staff') ? (
          <div>
            <Tooltip title="Menu" placement="left" arrow>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
              >
                <Avatar className={classes.darkBlue}>{userInfo.name[0]}</Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem>
                <div className={classes.menuInfo}>
                  <strong>{userInfo.name}</strong>
                  <span>{userInfo.role}</span>
                </div>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleClose}
                component={RouterLink}
                to="/admin/dashboard"
              >
                Dashboard
              </MenuItem>
              {userInfo && userInfo.role === 'admin' && (
                <MenuItem
                  onClick={handleClose}
                  component={RouterLink}
                  to="/admin/users"
                >
                  Trainers
                </MenuItem>
              )}
              <MenuItem
                onClick={handleClose}
                component={RouterLink}
                to="/admin/labs"
              >
                Labs
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                component={RouterLink}
                to="/admin/batches"
              >
                Batches
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                component={RouterLink}
                to="/admin/books"
              >
                Books
              </MenuItem>
              {userInfo && userInfo.role === 'admin' && (
                <MenuItem
                  onClick={handleClose}
                  component={RouterLink}
                  to="/admin/courses"
                >
                  Courses
                </MenuItem>
              )}
              <MenuItem
                onClick={handleClose}
                component={RouterLink}
                to="/admin/students"
              >
                Students
              </MenuItem>
              <MenuItem onClick={logoutHandler} className={classes.dangerRed}>
                Logout
              </MenuItem>
            </Menu>
          </div>
        ) : studentInfo ? (
          <div>
            <Tooltip title="Menu" placement="left" arrow>
              <IconButton
                aria-label="account of current student"
                onClick={handleStudentMenu}
              >
                <Avatar className={classes.darkBlue}>
                  <AccountCircleIcon />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              id="student-menu-appbar"
              anchorEl={studentAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={studentOpen}
              onClose={handleStudentClose}
            >
              <MenuItem>
                <div className={classes.menuInfo}>
                  <strong>{studentInfo.username}</strong>
                  <span>{studentInfo.batch.name}</span>
                </div>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleStudentClose}
                component={RouterLink}
                to="/"
              >
                Labs
              </MenuItem>
              <MenuItem
                onClick={handleStudentClose}
                component={RouterLink}
                to="/books"
                disabled
              >
                Books
              </MenuItem>
              <MenuItem
                onClick={handleStudentClose}
                component={RouterLink}
                to="/profile"
              >
                Profile
              </MenuItem>
              <MenuItem
                onClick={studentLogoutHandler}
                className={classes.dangerRed}
              >
                Logout
              </MenuItem>
            </Menu>
          </div>
        ) : (
          <Button color="inherit" component={RouterLink} to="/admin/login">
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
