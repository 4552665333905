import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  CircularProgress,
  Chip,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { grey } from '@material-ui/core/colors';
import LabsHeader from '../components/LabsHeader';
import InfoTable from '../components/InfoTable';
import { listFeedbacks, deleteFeedback } from '../actions/feedbackActions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';

const useStyles = makeStyles((theme) => ({
  section: {
    padding: '50px 20px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: grey[200],
    minHeight: 'calc(100vh - 202px)',
    userSelect: 'none',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  appBar: {
    position: 'relative',
  },
  chip: {
    margin: 2,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  notifSpacing: {
    marginBottom: '10px',
  },
  spacer: {
    height: '20px',
  },
}));

/**
 * Component for showing/adding/editing labs (user side)
 */
const FeedbacksScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Redux State Getters
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const feedbacksList = useSelector((state) => state.feedbacksList);
  const { loading, error, feedbacks } = feedbacksList;

  // Lifecycle events
  useEffect(() => {
    if (userInfo) {
      dispatch(listFeedbacks());
    } else {
      history.push('/');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [dispatch, history, userInfo]);

  console.log(feedbacks);

  return (
    <>
      <LabsHeader
        pageTitle={`Student Feedbacks`}
        backBtn={{ action: () => history.push('/admin/dashboard') }}
      />
      <section className={classes.section}>
        <Container>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 255px)',
              }}
            >
              <CircularProgress />
            </div>
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : (
            <InfoTable
              columns={['name', 'course', 'actions']}
              rows={
                feedbacks &&
                feedbacks.map((feedback) => ({
                  _id: feedback._id,
                  name: (
                    <span style={{ fontWeight: '500' }}>
                      {feedback.student ? feedback.student.name : '---'}
                    </span>
                  ),
                  course: (
                    <Chip label={feedback.course.name} variant="outlined" />
                  ),
                  actions: [
                    {
                      kind: 'nav',
                      label: 'View Details',
                      type: 'primary',
                      link: `/admin/feedback/${feedback._id}`,
                    },
                    {
                      kind: 'action',
                      label: 'Delete',
                      type: 'secondary',
                      action: () => dispatch(deleteFeedback(feedback._id)),
                    },
                  ],
                }))
              }
            />
          )}
        </Container>
      </section>
    </>
  );
};

export default FeedbacksScreen;
