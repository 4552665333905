import axios from 'axios';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Paper,
  Button,
  LinearProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import { currentQuestionLoader } from '../actions/assessmentActions';
import { CURRENT_ASSESSMENT_QUESTION } from '../constants/assessmentsConstants';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: '1rem',
    flex: 1,
  },
  spacer: {
    height: '20px',
  },
  markupText: {
    '& textarea': {
      fontFamily: 'monospace',
    },
  },
}));

const AssessmentQuestion = ({ callback, existingQuestion = null }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  /* -------------------------- Form and Local State -------------------------- */
  const [title, setTitle] = useState('');
  const [questionNo, setQuestionNo] = useState('');
  const [options, setOptions] = useState([]);
  const [currentOption, setCurrentOption] = useState('');
  const [type, setType] = useState('choice');
  const [answerInput, setAnswerInput] = useState('');
  const [image, setImage] = useState('');
  const [imageUploading, setImageUploading] = useState(false);
  const [questionBody, setQuestionBody] = useState('');
  const [imageSize, setImageSize] = useState('');
  const [answerInputCaseSensitive, setAnswerInputCaseSensitive] =
    useState(false);
  const [multipleSelectable, setMultipleSelectable] = useState(false);

  /* --------------------------- Redux State Getters -------------------------- */
  const assessmentQuestion = useSelector((state) => state.assessmentQuestion);
  const { success, question } = assessmentQuestion;

  /* -------------------------------------------------------------------------- */
  /*                              Lifecycle events                              */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (question) {
      setTitle(question.title);
      setQuestionBody(question.questionBody);
      setQuestionNo(question.questionNo);
      setType(question.type);
      setAnswerInput(question.answerInput);
      setImage(question.image);
      setOptions(question.options);
      setImageSize(question.imageSize);
      setAnswerInputCaseSensitive(question.answerInputCaseSensitive);
      setMultipleSelectable(question.multipleSelectable);
    }
  }, [question]);

  /* ----------------------------- Action Handlers ---------------------------- */
  const uploadImageHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();

    formData.append('image', file);
    setImageUploading(true);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const { data } = await axios.post('/api/upload', formData, config);
      setImage(data);
      setImageUploading(false);
    } catch (err) {
      console.error(err);
      setImageUploading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    callback({
      title,
      questionBody,
      questionNo,
      type,
      answerInput,
      image,
      options,
      imageSize,
      answerInputCaseSensitive,
      multipleSelectable,
    });

    // Reset all the fields
    setTitle('');
    setQuestionBody('');
    setQuestionNo('');
    setType('choice');
    setAnswerInput('');
    setImage('');
    setOptions([]);
    setImageSize('');
    setAnswerInputCaseSensitive(false);
    setMultipleSelectable(false);
    dispatch({ type: CURRENT_ASSESSMENT_QUESTION, payload: { options: [] } });
  };

  /* -------------------------------------------------------------------------- */
  /*                                     JSX                                    */
  /* -------------------------------------------------------------------------- */
  return (
    <div>
      <Paper variant="outlined" style={{ padding: '1rem', marginTop: '1rem' }}>
        <Typography variant="h6" className={classes.title}>
          Add / Update Question
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <FormControl
            variant="outlined"
            margin="dense"
            style={{ width: '30%' }}
            className={classes.formControl}
          >
            <InputLabel id="questionTypeLabel">Question Type</InputLabel>
            <Select
              labelId="questionTypeLabel"
              id="questionType"
              name="questionType"
              value={type}
              onChange={(e) => setType(e.target.value)}
              label="Question Type"
            >
              <MenuItem key="1" value="choice">
                Multiple Choice
              </MenuItem>
              <MenuItem key="2" value="input">
                User Input
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            id="questionNo"
            type="number"
            label="Question No."
            name="questionNo"
            autoComplete="no"
            value={questionNo}
            onChange={(e) => setQuestionNo(e.target.value)}
            style={{ width: '15%' }}
          />

          {/* ------------------------------ Upload Image ------------------------------ */}
          <div
            style={{
              flexGrow: '1',
            }}
          >
            {imageUploading && (
              <LinearProgress style={{ marginBottom: '5px' }} />
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                flexGrow: '1',
              }}
            >
              <TextField
                variant="outlined"
                margin="dense"
                id="image"
                style={{ flexGrow: '1' }}
                fullwidth
                type="text"
                label="Upload Image"
                name="image"
                autoComplete="image"
                value={image}
                onChange={(e) => setImage(e.target.value)}
              />
              <Button
                variant="contained"
                style={{
                  minHeight: '39px',
                  position: 'relative',
                  top: '2px',
                }}
                component="label"
                id="image-file"
                onChange={(e) => uploadImageHandler(e)}
                type="button"
                disableElevation
              >
                Upload Image
                <input type="file" hidden />
              </Button>
            </div>
          </div>

          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            id="imageSize"
            type="number"
            label="Image Size (%)"
            name="imageSize"
            value={imageSize}
            onChange={(e) => setImageSize(e.target.value)}
            style={{ width: '15%' }}
          />
        </div>

        {/* ------------------- Question and Optional Question Body ------------------ */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            multiline
            rows="2"
            id="title"
            type="text"
            label="Question"
            name="title"
            autoComplete="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            multiline
            rows="4"
            id="body"
            type="text"
            label="Additional details about the question (optional)"
            name="body"
            value={questionBody}
            onChange={(e) => setQuestionBody(e.target.value)}
          />
        </div>

        {/* --------------------------------- Options -------------------------------- */}
        {type === 'choice' && (
          <>
            {/* --------------------------- Show options added --------------------------- */}
            <Paper variant="outlined" style={{ marginTop: '0.5rem' }}>
              <List component="nav">
                {options.length > 0 ? (
                  <>
                    {options.map((option, idx) => (
                      <ListItem
                        key={option}
                        button
                        onClick={(e) =>
                          setOptions(options.filter((o) => o !== option))
                        }
                      >
                        <ListItemIcon>
                          <p
                            style={{
                              margin: '3px 20px 0px 0px',
                              padding: 0,
                            }}
                          >
                            Option {idx + 1}:
                          </p>
                        </ListItemIcon>
                        <ListItemText primary={option} />
                      </ListItem>
                    ))}
                  </>
                ) : (
                  <ListItem>
                    <ListItemText primary="No options added" />
                  </ListItem>
                )}
              </List>
            </Paper>

            {/* ------------------------------- Add Options ------------------------------ */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <TextField
                variant="outlined"
                margin="dense"
                type="text"
                autoComplete="no"
                style={{ flexGrow: '1' }}
                fullwidth
                label="Option"
                value={currentOption}
                onChange={(e) => setCurrentOption(e.target.value)}
              />
              <Button
                variant="contained"
                style={{
                  minHeight: '39px',
                  position: 'relative',
                  top: '2px',
                }}
                component="label"
                id="image-file"
                onClick={(e) => {
                  setOptions([...options, currentOption]);
                  setCurrentOption('');
                }}
                type="button"
                disableElevation
              >
                Add Option
              </Button>
            </div>
            {/* -------------------- Allow multiple options selection ------------------- */}
            <FormGroup style={{ marginBottom: '1rem' }}>
              <FormControlLabel
                label="Allow selection of more than one option"
                labelPlacement="right"
                control={
                  <Switch
                    checked={multipleSelectable}
                    onChange={(e) => setMultipleSelectable(e.target.checked)}
                    name="multipleSelectable"
                    color="primary"
                  />
                }
                style={{ width: '25%' }}
              />
            </FormGroup>
          </>
        )}

        <TextField
          variant="filled"
          margin="dense"
          fullWidth
          multiline
          rows="4"
          id="answer"
          type="answer"
          label="Answer to this question"
          name="answer"
          autoComplete="answer"
          value={answerInput}
          helperText={multipleSelectable && 'Separate answers with two pipes - ||'}
          onChange={(e) => setAnswerInput(e.target.value)}
        />
        {type === 'input' && (
          <FormGroup>
            <FormControlLabel
              label="Answer is case-sensitive"
              labelPlacement="right"
              control={
                <Switch
                  checked={answerInputCaseSensitive}
                  onChange={(e) =>
                    setAnswerInputCaseSensitive(e.target.checked)
                  }
                  name="caseSensitive"
                  color="primary"
                />
              }
              style={{ width: '25%' }}
            />
          </FormGroup>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
            onClick={submitHandler}
          >
            Add / Update Question
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default AssessmentQuestion;
