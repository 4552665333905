import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((_theme) => ({
  footer: {
    padding: '20px 0',
    backgroundColor: grey[200],
  },
  copyright: {
    textAlign: 'center',
    marginBottom: 0,
  },
}));

const Footer = () => {
  const [disclaimer, setDisclaimer] = useState(false);
  const classes = useStyles();

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDisclaimer(value);
  };

  return (
    <>
      <footer className={classes.footer}>
        <Typography variant="body2" className={classes.copyright} gutterBottom>
          Copyright © {new Date().getFullYear()}. RST Forum. All Rights
          Reserved.{' '}
          <Link onClick={toggleDrawer(true)} style={{ cursor: 'pointer' }}>
            View Copyright Notice
          </Link>
        </Typography>
        <Drawer anchor="bottom" open={disclaimer} onClose={toggleDrawer(false)}>
          <Container>
            <Typography
              variant="body2"
              style={{ marginBottom: 0, padding: '1.2rem' }}
            >
              <strong>Copyright Notice:</strong>
              <br /> This site/platform is our proprietary property and all
              source code, databases, functionality, software, website designs,
              audio, video, text, photographs, and graphics on the Site/Platform
              (collectively, the "Content") are owned and controlled by us and
              are protected by copyright laws of India, the United States,
              foreign jusrisdictions, and international conventions. Strict
              Legal action will be taken against any
              person(s)/group/organization who infringes or abets infringment of
              our copyright.
            </Typography>
          </Container>
        </Drawer>
      </footer>
    </>
  );
};

export default Footer;
