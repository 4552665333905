import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  LinearProgress,
  Button,
  Box,
  FormControl,
  FormControlLabel,
  Paper,
  Typography,
  Radio,
  RadioGroup,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import LabsHeader from '../components/LabsHeader';
import { createFeedback } from '../actions/feedbackActions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[200],
    padding: '50px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: 'calc(100vh - 206px)',
  },
  question: {
    paddingBottom: '2rem',
  },
  notifSpacing: {
    marginTop: '1rem',
  },
}));

/**
 * Component for showing/adding/editing courses
 */
const StudentFeedbackScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [trainerName, setTrainerName] = useState('');
  const [companyName, setCompanyName] = useState('');
  // Instructor Form State
  const [inst1, setInst1] = useState('');
  const [inst2, setInst2] = useState('');
  const [inst3, setInst3] = useState('');
  const [inst4, setInst4] = useState('');
  const [inst5, setInst5] = useState('');
  const [inst6, setInst6] = useState('');
  const [inst7, setInst7] = useState('');
  const [inst8, setInst8] = useState('');
  const [inst9, setInst9] = useState('');
  const [inst10, setInst10] = useState('');
  // Training Form State
  const [train1, setTrain1] = useState('');
  const [train2, setTrain2] = useState('');
  const [train3, setTrain3] = useState('');
  const [train4, setTrain4] = useState('');
  const [train5, setTrain5] = useState('');
  const [train6, setTrain6] = useState('');
  const [train7, setTrain7] = useState('');
  const [train8, setTrain8] = useState('');
  const [train9, setTrain9] = useState('');
  const [train10, setTrain10] = useState('');
  const [train11, setTrain11] = useState('');
  const [train12, setTrain12] = useState('');

  const [successCompletion, setSuccessCompletion] = useState(false);

  // Redux State Getters
  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo } = studentLogin;
  const feedbackCreate = useSelector((state) => state.feedbackCreate);
  const { loading, success, error } = feedbackCreate;

  // Date change handlers
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  // Lifecycle events
  useEffect(() => {
    if (!studentInfo) {
      history.push('/admin/login');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
    if (success) {
      setSuccessCompletion(true);
      setStartDate(new Date());
      setEndDate(new Date());
      setTrainerName('');
      setCompanyName('');
      setInst1('');
      setInst2('');
      setInst3('');
      setInst4('');
      setInst5('');
      setInst6('');
      setInst7('');
      setInst8('');
      setInst9('');
      setInst10('');
      setTrain1('');
      setTrain2('');
      setTrain3('');
      setTrain4('');
      setTrain5('');
      setTrain6('');
      setTrain7('');
      setTrain8('');
      setTrain9('');
      setTrain10('');
      setTrain11('');
      setTrain12('');
      document.body.scrollTop = 0;
      history.push('/certificate');
    }
  }, [dispatch, history, studentInfo, success]);

  console.log(studentInfo);

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = {
      student: studentInfo._id,
      course: studentInfo.course._id,
      startDate,
      endDate,
      trainerName,
      companyName,
      instructorFeedback: [
        {
          question:
            'Presents proper instruction based on knowledge of the subject matter and course goals.',
          answer: inst1,
        },
        {
          question:
            'The presentation was well organized with a clear summarization of the subject.',
          answer: inst2,
        },
        {
          question: 'Free flow of communication and interaction.',
          answer: inst3,
        },
        {
          question: 'Leaves no room for misunderstanding.',
          answer: inst4,
        },
        {
          question: 'Persistent, understanding and repeats as necessary.',
          answer: inst5,
        },
        {
          question:
            'Highly enthusiastic, show passion, and has positive frame of mind.',
          answer: inst6,
        },
        {
          question: 'Stresses on basic theories to create a proper base.',
          answer: inst7,
        },
        {
          question: 'Encourages and helps candidates to excel in subject.',
          answer: inst8,
        },
        // {
        //   question: 'Makes sure all candidates are focused and learning.',
        //   answer: inst9,
        // },
        {
          question: 'Provides real life practical examples.',
          answer: inst10,
        },
      ],
      trainingFeedback: [
        {
          question: 'Was the course content relevant to the course goal?',
          answer: train1,
        },
        {
          question: 'All topics were covered during the training?',
          answer: train2,
        },
        {
          question: 'Were you satisfied with the teaching methods used?',
          answer: train3,
        },
        {
          question: 'How were the Topics covered?',
          answer: train4,
        },
        {
          question: 'How were the Concepts articulated &amp; demonstrated?',
          answer: train5,
        },
        {
          question: 'Were the slides and other learning aids beneficial?',
          answer: train6,
        },
        // {
        //   question: 'How would you rate practical sessions?',
        //   answer: train7,
        // },
        // {
        //   question:
        //     'Did practical session include all concepts learnt in classroom.',
        //   answer: train8,
        // },
        // {
        //   question: 'Were practical scenarios based on real life.',
        //   answer: train9,
        // },
        // {
        //   question:
        //     'Were you happy with the amount of time spent on practical.',
        //   answer: train10,
        // },
        // {
        //   question:
        //     'Did the Training and practical session help you professionally.',
        //   answer: train11,
        // },
        {
          question: 'Will you refer your friends for this course.',
          answer: train12,
        },
      ],
    };
    dispatch(createFeedback(formData));
  };

  return (
    <>
      <LabsHeader
        pageTitle="Training Feedback"
        backBtn={{ action: () => history.push('/dashboard') }}
      />
      <section className={classes.section}>
        <Container>
          <Box component={Paper} padding="30px">
            <Typography
              component="h2"
              variant="h5"
              style={{ marginBottom: '2rem' }}>
              Feedback Form
            </Typography>
            <form onSubmit={submitHandler}>
              <div style={{ display: 'flex', gap: '2rem', width: '100%' }}>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="startDatePicker"
                      label="Training Starting Date"
                      format="MM/dd/yyyy"
                      value={startDate}
                      onChange={handleStartDateChange}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="endDatePicker"
                      label="Training Ending Date"
                      format="MM/dd/yyyy"
                      value={endDate}
                      onChange={handleEndDateChange}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                {/* <div>
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    required
                    size="small"
                    className={classes.formControl}
                  >
                    <InputLabel id="trainerName">Select Trainer</InputLabel>
                    <Select
                      labelId="trainerName"
                      id="trainerSelection"
                      name="trainerSelection"
                      value={trainerName}
                      onChange={(e) => setTrainerName(e.target.value)}
                      label="Select Trainer"
                    >
                      <MenuItem key="vagishdwivedi" value="Vagish Dwivedi">
                        Vagish Dwivedi
                      </MenuItem>
                      <MenuItem key="chinmaydwivedi" value="Chinmay Dwivedi">
                        Chinmay Dwivedi
                      </MenuItem>
                      <MenuItem
                        key="rustombillimoria"
                        value="Rustom Billimoria"
                      >
                        Rustom Billimoria
                      </MenuItem>
                      <MenuItem key="rahulsharma" value="Rahul Sharma">
                        Rahul Sharma
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div> */}
              </div>
              {/* Company Name */}
              <div>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="companyName"
                  type="text"
                  label="Company Name"
                  name="companyName"
                  autoComplete="companyName"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  style={{ marginTop: '1.5rem' }}
                />
              </div>
              <Typography
                component="h5"
                variant="h6"
                style={{ marginBottom: '1.2rem', marginTop: '1.5rem' }}>
                Instructor Feedback
              </Typography>
              {/* Instructor Question 1 */}
              <div className={classes.question}>
                <Typography variant="h6">
                  1. Presents proper instruction based on knowledge of the
                  subject matter and course goals.
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="inst1"
                    value={inst1}
                    onChange={(e) => setInst1(e.target.value)}>
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {/* Instructor Question 2 */}
              <div className={classes.question}>
                <Typography variant="h6">
                  2. The presentation was well organized with a clear
                  summarization of the subject.
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="inst2"
                    value={inst2}
                    onChange={(e) => setInst2(e.target.value)}>
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {/* Instructor Question 3 */}
              <div className={classes.question}>
                <Typography variant="h6">
                  3. Free flow of communication and interaction.
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="inst3"
                    value={inst3}
                    onChange={(e) => setInst3(e.target.value)}>
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {/* Instructor Question 4 */}
              <div className={classes.question}>
                <Typography variant="h6">
                  4. Leaves no room for misunderstanding.
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="inst4"
                    value={inst4}
                    onChange={(e) => setInst4(e.target.value)}>
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {/* Instructor Question 5 */}
              <div className={classes.question}>
                <Typography variant="h6">
                  5. Persistent, understanding and repeats as necessary.
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="inst5"
                    value={inst5}
                    onChange={(e) => setInst5(e.target.value)}>
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {/* Instructor Question 6 */}
              <div className={classes.question}>
                <Typography variant="h6">
                  6. Highly enthusiastic, show passion, and has positive frame
                  of mind.
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="inst6"
                    value={inst6}
                    onChange={(e) => setInst6(e.target.value)}>
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {/* Instructor Question 7 */}
              <div className={classes.question}>
                <Typography variant="h6">
                  7. Stresses on basic theories to create a proper base.
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="inst7"
                    value={inst7}
                    onChange={(e) => setInst7(e.target.value)}>
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {/* Instructor Question 8 */}
              <div className={classes.question}>
                <Typography variant="h6">
                  8. Encourages and helps candidates to excel in subject.
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="inst8"
                    value={inst8}
                    onChange={(e) => setInst8(e.target.value)}>
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {/* Instructor Question 9 */}
              {/* <div className={classes.question}>
                <Typography variant="h6">
                  9. Makes eye contact throughout the training process.
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="inst9"
                    value={inst9}
                    onChange={(e) => setInst9(e.target.value)}
                  >
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div> */}
              {/* Instructor Question 10 */}
              {/* <div className={classes.question}>
                <Typography variant="h6">
                  10. Makes sure all candidates are focused and learning.
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="inst10"
                    value={inst10}
                    onChange={(e) => setInst10(e.target.value)}
                  >
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <Divider /> */}
              {/* Part 2 - Training Feedback */}
              <Typography
                component="h5"
                variant="h6"
                style={{ marginBottom: '2rem', marginTop: '2rem' }}>
                Training Feedback
              </Typography>
              {/* Training Question 1 */}
              <div className={classes.question}>
                <Typography variant="h6">
                  1. Was the course content relevant to the course goal?
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="train1"
                    value={train1}
                    onChange={(e) => setTrain1(e.target.value)}>
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {/* Training Question 2 */}
              <div className={classes.question}>
                <Typography variant="h6">
                  2. All topics were covered during the training?
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="train2"
                    value={train2}
                    onChange={(e) => setTrain2(e.target.value)}>
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {/* Training Question 3 */}
              <div className={classes.question}>
                <Typography variant="h6">
                  3. Were you satisfied with the teaching methods used?
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="train3"
                    value={train3}
                    onChange={(e) => setTrain3(e.target.value)}>
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {/* Training Question 4 */}
              <div className={classes.question}>
                <Typography variant="h6">
                  4. How were the Topics covered?
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="train4"
                    value={train4}
                    onChange={(e) => setTrain4(e.target.value)}>
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {/* Training Question 5 */}
              <div className={classes.question}>
                <Typography variant="h6">
                  5. How were the Concepts articulated &amp; demonstrated?
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="train5"
                    value={train5}
                    onChange={(e) => setTrain5(e.target.value)}>
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {/* Training Question 6 */}
              <div className={classes.question}>
                <Typography variant="h6">
                  6. Were the slides and other learning aids beneficial?
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="train6"
                    value={train6}
                    onChange={(e) => setTrain6(e.target.value)}>
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {/* Training Question 7 */}
              {/* <div className={classes.question}>
                <Typography variant="h6">
                  7. How would you rate practical sessions?
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="train7"
                    value={train7}
                    onChange={(e) => setTrain7(e.target.value)}
                  >
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div> */}
              {/* Training Question 8 */}
              {/* <div className={classes.question}>
                <Typography variant="h6">
                  8. Did practical session include all concepts learnt in
                  classroom.
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="train8"
                    value={train8}
                    onChange={(e) => setTrain8(e.target.value)}
                  >
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div> */}
              {/* Training Question 9 */}
              {/* <div className={classes.question}>
                <Typography variant="h6">
                  9. Were practical scenarios based on real life.
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="train9"
                    value={train9}
                    onChange={(e) => setTrain9(e.target.value)}
                  >
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div> */}
              {/* Training Question 10 */}
              {/* <div className={classes.question}>
                <Typography variant="h6">
                  10. Were you happy with the amount of time spent on practical.
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="train10"
                    value={train10}
                    onChange={(e) => setTrain10(e.target.value)}
                  >
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div> */}
              {/* Training Question 11 */}
              {/* <div className={classes.question}>
                <Typography variant="h6">
                  11. Did the Training and practical session help you
                  professionally.
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="train11"
                    value={train11}
                    onChange={(e) => setTrain11(e.target.value)}
                  >
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div> */}
              {/* Training Question 12 */}
              <div className={classes.question}>
                <Typography variant="h6">
                  7. Will you refer your friends for this course.
                </Typography>
                <FormControl required component="fieldset">
                  <RadioGroup
                    row
                    name="train12"
                    value={train12}
                    onChange={(e) => setTrain12(e.target.value)}>
                    <FormControlLabel
                      value="superb"
                      control={<Radio required color="primary" />}
                      label="Superb"
                    />
                    <FormControlLabel
                      value="good"
                      control={<Radio required color="primary" />}
                      label="Good"
                    />
                    <FormControlLabel
                      value="fair"
                      control={<Radio required color="primary" />}
                      label="Fair"
                    />
                    <FormControlLabel
                      value="poor"
                      control={<Radio required color="primary" />}
                      label="Poor"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className={classes.spacer}></div>
              <Button type="submit" variant="contained" color="primary">
                Submit Feedback
              </Button>
            </form>
            {loading && (
              <LinearProgress
                color="primary"
                className={classes.notifSpacing}
              />
            )}
            {error && (
              <Alert severity="error" className={classes.notifSpacing}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" className={classes.notifSpacing}>
                Feedback submitted successfully
              </Alert>
            )}
          </Box>
        </Container>
      </section>
    </>
  );
};

export default StudentFeedbackScreen;
