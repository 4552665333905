/**
 * @fileOverview   Reducer for the Labs Schema to perform CRUD operations
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import {
  LAB_LIST_REQUEST,
  LAB_LIST_SUCCESS,
  LAB_LIST_FAIL,
  LAB_LIST_RESET,
  LAB_DETAILS_REQUEST,
  LAB_DETAILS_SUCCESS,
  LAB_DETAILS_FAIL,
  LAB_DETAILS_RESET,
  LAB_CREATE_REQUEST,
  LAB_CREATE_SUCCESS,
  LAB_CREATE_FAIL,
  LAB_CREATE_RESET,
  LAB_UPDATE_REQUEST,
  LAB_UPDATE_SUCCESS,
  LAB_UPDATE_FAIL,
  LAB_UPDATE_RESET,
  LAB_DELETE_REQUEST,
  LAB_DELETE_SUCCESS,
  LAB_DELETE_FAIL,
  LABS_BY_COURSE_REQUEST,
  LABS_BY_COURSE_SUCCESS,
  LABS_BY_COURSE_FAIL,
  LAB_START_IOU_REQUEST,
  LAB_START_IOU_SUCCESS,
  LAB_START_IOU_FAIL,
  LAB_START_IOU_RESET,
  LAB_STOP_IOU_REQUEST,
  LAB_STOP_IOU_SUCCESS,
  LAB_STOP_IOU_FAIL,
  LAB_STOP_IOU_RESET,
  LAB_RESET_IOU_REQUEST,
  LAB_RESET_IOU_SUCCESS,
  LAB_RESET_IOU_FAIL,
  LAB_RESET_IOU_RESET,
  LAB_WIPE_IOU_REQUEST,
  LAB_WIPE_IOU_SUCCESS,
  LAB_WIPE_IOU_FAIL,
  LAB_WIPE_IOU_RESET,
  LAB_POWER_ON_REQUEST,
  LAB_POWER_ON_SUCCESS,
  LAB_POWER_ON_FAIL,
  LAB_POWER_ON_RESET,
  LAB_POWER_OFF_REQUEST,
  LAB_POWER_OFF_SUCCESS,
  LAB_POWER_OFF_FAIL,
  LAB_POWER_OFF_RESET,
  LAB_POWER_CHECK_REQUEST,
  LAB_POWER_CHECK_SUCCESS,
  LAB_POWER_CHECK_FAIL,
  LAB_START_NODES_REQUEST,
  LAB_START_NODES_SUCCESS,
  LAB_START_NODES_FAIL,
  LAB_STOP_NODES_REQUEST,
  LAB_STOP_NODES_SUCCESS,
  LAB_STOP_NODES_FAIL,
  LAB_WIPE_NODES_REQUEST,
  LAB_WIPE_NODES_SUCCESS,
  LAB_WIPE_NODES_FAIL
} from '../constants/labConstants';

/**
 * Get all labs
 *
 * @param {Object} state Initial state of labs in the store.
 * @param {Object} action Object with payload of the fetched labs.
 * @return {Object} New state object
 */
export const labsListReducer = (state = { labs: [] }, action) => {
  switch (action.type) {
    case LAB_LIST_REQUEST:
      return { loading: true, labs: [] };
    case LAB_LIST_SUCCESS:
      return { loading: false, labs: action.payload };
    case LAB_LIST_FAIL:
      return { loading: false, error: action.payload };
    case LAB_LIST_RESET:
      return { labs: [] };
    default:
      return state;
  }
};

/**
 * Get data of a single lab
 *
 * @param {Object} state Initial state of lab in the store.
 * @param {Object} action Object with payload of the newly created user.
 * @return {Object} New state object
 */
export const labDetailsReducer = (
  state = { lab: { course: {}, info: {} } },
  action
) => {
  switch (action.type) {
    case LAB_DETAILS_REQUEST:
      return { ...state, loading: true };
    case LAB_DETAILS_SUCCESS:
      return { loading: false, lab: action.payload };
    case LAB_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case LAB_DETAILS_RESET:
      return { course: {}, info: {} };
    default:
      return state;
  }
};

/**
 * Create a new lab
 *
 * @param {Object} state Initial state of lab in the store.
 * @param {Object} action Object with payload of the newly created lab.
 * @return {Object} New state object
 */
export const labCreateReducer = (state = { course: {}, info: {} }, action) => {
  switch (action.type) {
    case LAB_CREATE_REQUEST:
      return { loading: true };
    case LAB_CREATE_SUCCESS:
      console.log('I JUST GOT DISPATCHED');
      return { loading: false, success: true, lab: action.payload };
    case LAB_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case LAB_CREATE_RESET:
      return { course: {}, info: {} };
    default:
      return state;
  }
};

/**
 * Update an existing lab
 *
 * @param {Object} state Initial state of lab in the store.
 * @param {Object} action Object with payload of the newly updated lab.
 * @return {Object} New state object
 */
export const labUpdateReducer = (state = { course: {}, info: {} }, action) => {
  switch (action.type) {
    case LAB_UPDATE_REQUEST:
      return { loading: true, ...state };
    case LAB_UPDATE_SUCCESS:
      return { loading: false, success: true, lab: action.payload };
    case LAB_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case LAB_UPDATE_RESET:
      return { course: {}, info: {} };
    default:
      return state;
  }
};

/**
 * Delete an existing lab
 *
 * @param {Object} state Initial state of lab in the store.
 * @param {Object} action (optional) Object with payload of an optional error.
 * @return {Object} New state object
 */
export const labDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case LAB_DELETE_REQUEST:
      return { loading: true };
    case LAB_DELETE_SUCCESS:
      return { loading: false, success: true };
    case LAB_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

/**
 * Get all labs by Course
 *
 * @param {Object} state Initial state of labs in the store.
 * @param {Object} action Object with payload of the fetched labs.
 * @return {Object} New state object
 */
export const labsListByCourseReducer = (state = { labs: [] }, action) => {
  switch (action.type) {
    case LABS_BY_COURSE_REQUEST:
      return { loading: true, labs: [] };
    case LABS_BY_COURSE_SUCCESS:
      return { loading: false, labs: action.payload };
    case LABS_BY_COURSE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

/**
 * Start Lab
 */
export const labIouReducer = (state = { success: false }, action) => {
  switch (action.type) {
    case LAB_START_IOU_REQUEST:
      return { loading: true, success: false };
    case LAB_START_IOU_SUCCESS:
      return { loading: false, success: true };
    case LAB_START_IOU_FAIL:
      return { loading: false, success: false };
    case LAB_START_IOU_RESET:
      return {};
    case LAB_STOP_IOU_REQUEST:
      return { loading: true, success: false };
    case LAB_STOP_IOU_SUCCESS:
      return { loading: false, success: true };
    case LAB_STOP_IOU_FAIL:
      return { loading: false, success: false };
    case LAB_STOP_IOU_RESET:
      return {};
    case LAB_RESET_IOU_REQUEST:
      return { loading: true, success: false };
    case LAB_RESET_IOU_SUCCESS:
      return { loading: false, success: true };
    case LAB_RESET_IOU_FAIL:
      return { loading: false, success: false };
    case LAB_RESET_IOU_RESET:
      return {};
    case LAB_WIPE_IOU_REQUEST:
      return { loading: true, success: false };
    case LAB_WIPE_IOU_SUCCESS:
      return { loading: false, success: true };
    case LAB_WIPE_IOU_FAIL:
      return { loading: false, success: false };
    case LAB_WIPE_IOU_RESET:
      return {};
    default:
      return state;
  }
};

export const labVMStateReducer = (state = { status: 'off' }, action) => {
  switch (action.type) {
    case LAB_POWER_ON_REQUEST:
      return { loading: true, status: 'off' };
    case LAB_POWER_ON_SUCCESS:
      return { loading: false, success: true, status: 'on' };
    case LAB_POWER_ON_FAIL:
      return { loading: false, error: action.payload };
    case LAB_POWER_ON_RESET:
      return {};
    case LAB_POWER_OFF_REQUEST:
      return { loading: true, status: 'off' };
    case LAB_POWER_OFF_SUCCESS:
      return { loading: false, success: true, status: 'off' };
    case LAB_POWER_OFF_FAIL:
      return { loading: false, error: action.payload };
    case LAB_POWER_OFF_RESET:
      return {};
    case LAB_POWER_CHECK_REQUEST:
      return { loading: true };
    case LAB_POWER_CHECK_SUCCESS:
      return { loading: false, status: action.payload ? 'on' : 'off' };
    case LAB_POWER_CHECK_FAIL:
      return { loadin: false, error: action.payload };
    default:
      return state;
  }
};

/**
 * Start EveNg Nodes
 *
 * @param {Object} state Initial state of labs in the store.
 * @param {Object} action Object with payload of the fetched labs.
 * @return {Object} New state object
 */
export const eveNgNodesReducer = (state = {}, action) => {
  switch (action.type) {
    case LAB_START_NODES_REQUEST:
      return { loading: true };
    case LAB_START_NODES_SUCCESS:
      return { loading: false, sucess: true };
    case LAB_START_NODES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

/**
 * Stop EveNg Nodes
 *
 * @param {Object} state Initial state of labs in the store.
 * @param {Object} action Object with payload of the fetched labs.
 * @return {Object} New state object
 */
export const eveNgNodesStopReducer = (state = {}, action) => {
  switch (action.type) {
    case LAB_STOP_NODES_REQUEST:
      return { loading: true };
    case LAB_STOP_NODES_SUCCESS:
      return { loading: false, sucess: true };
    case LAB_STOP_NODES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

/**
 * Wipe EveNg Nodes
 *
 * @param {Object} state Initial state of labs in the store.
 * @param {Object} action Object with payload of the fetched labs.
 * @return {Object} New state object
 */
 export const eveNgNodesWipeReducer = (state = {}, action) => {
  switch (action.type) {
    case LAB_WIPE_NODES_REQUEST:
      return { loading: true };
    case LAB_WIPE_NODES_SUCCESS:
      return { loading: false, sucess: true };
    case LAB_WIPE_NODES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
