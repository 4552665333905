import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  CircularProgress,
  Paper,
  Typography,
  Button,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { grey } from '@material-ui/core/colors';
import LabsHeader from '../components/LabsHeader';
import InfoTable from '../components/InfoTable';
import {
  listExamsByCourse as listExamsByCourseAction,
  getStudentPoints as getStudentPointsAction,
} from '../actions/examActions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';

const useStyles = makeStyles((theme) => ({
  section: {
    padding: '50px 20px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: grey[200],
    minHeight: 'calc(100vh - 202px)',
    userSelect: 'none',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  appBar: {
    position: 'relative',
  },
  chip: {
    margin: 2,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  notifSpacing: {
    marginBottom: '10px',
  },
  spacer: {
    height: '20px',
  },
}));

const StudentExamResultsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  /* --------------------------- Redux State Getters -------------------------- */
  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo } = studentLogin;

  const examsByCourse = useSelector((state) => state.examsByCourse);
  const { loading, error, exams } = examsByCourse;

  const examGetStudentPoints = useSelector(
    (state) => state.examGetStudentPoints
  );
  const {
    loading: resultLoading,
    error: errorLoading,
    points,
  } = examGetStudentPoints;

  console.log(points);

  /* ---------------------------- Lifecycle events ---------------------------- */
  useEffect(() => {
    if (studentInfo) {
      dispatch(listExamsByCourseAction());
      dispatch(getStudentPointsAction(studentInfo._id));
    } else {
      history.push('/');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [dispatch, history, studentInfo]);

  useEffect(() => {
    document.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
    return () =>
      document.removeEventListener('contextmenu', (event) => {
        event.preventDefault();
      });
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                     JSX                                    */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <LabsHeader
        pageTitle={`${studentInfo && studentInfo.course.name} Exams Results`}
        vmInfo={false}
        backBtn={{ action: () => history.goBack() }}
      />
      <section className={classes.section}>
        <Container>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 255px)',
              }}
            >
              <CircularProgress />
            </div>
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : points && points.length > 0 ? (
            <InfoTable
              columns={['no.', 'title', 'score', 'actions']}
              rows={
                exams &&
                points &&
                exams.map((exam) => ({
                  index: +exam.no, // fix for the sorting problem. If not present component breaks.
                  _id: exam._id,
                  no: `Exam ${exam.no}`,
                  title: (
                    <span style={{ fontWeight: '500' }}>{exam.title}</span>
                  ),
                  result: (
                    <>
                      <strong>
                        {points.find((p) => p.examId === exam._id) &&
                          points.find((p) => p.examId === exam._id).score}{' '}
                        / {+exam.questionCount * 10}
                      </strong>
                    </>
                  ),
                  actions: [
                    {
                      kind: 'nav',
                      label: 'Detailed Results',
                      type: 'primary',
                      link: `/exams/${exam._id}/results`,
                    },
                  ],
                }))
              }
            />
          ) : (
            <Paper style={{ padding: '4rem 2rem' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h5" style={{ marginBottom: '1rem' }}>
                  <strong>No exams taken yet</strong>
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to="/exams"
                >
                  View Available Exams
                </Button>
              </div>
            </Paper>
          )}
        </Container>
      </section>
    </>
  );
};

export default StudentExamResultsScreen;
