export const VIDEO_LIST_REQUEST = 'VIDEO_LIST_REQUEST';
export const VIDEO_LIST_SUCCESS = 'VIDEO_LIST_SUCCESS';
export const VIDEO_LIST_FAIL = 'VIDEO_LIST_FAIL';
export const VIDEO_LIST_RESET = 'VIDEO_LIST_RESET';

export const VIDEO_LIST_BY_COURSE_REQUEST = 'VIDEO_LIST_REQUEST';
export const VIDEO_LIST_BY_COURSE_SUCCESS = 'VIDEO_LIST_SUCCESS';
export const VIDEO_LIST_BY_COURSE_FAIL = 'VIDEO_LIST_FAIL';
export const VIDEO_LIST_BY_COURSE_RESET = 'VIDEO_LIST_RESET';

export const VIDEO_INDEX_REQUEST = 'VIDEO_INDEX_REQUEST';
export const VIDEO_INDEX_SUCCESS = 'VIDEO_INDEX_SUCCESS';
export const VIDEO_INDEX_FAIL = 'VIDEO_INDEX_FAIL';
export const VIDEO_INDEX_RESET = 'VIDEO_INDEX_RESET';

export const VIDEO_DETAILS_REQUEST = 'VIDEO_DETAILS_REQUEST';
export const VIDEO_DETAILS_SUCCESS = 'VIDEO_DETAILS_SUCCESS';
export const VIDEO_DETAILS_FAIL = 'VIDEO_DETAILS_FAIL';
export const VIDEO_DETAILS_RESET = 'VIDEO_DETAILS_RESET';

export const VIDEO_CREATE_REQUEST = 'VIDEO_CREATE_REQUEST';
export const VIDEO_CREATE_SUCCESS = 'VIDEO_CREATE_SUCCESS';
export const VIDEO_CREATE_FAIL = 'VIDEO_CREATE_FAIL';
export const VIDEO_CREATE_RESET = 'VIDEO_CREATE_RESET';

export const VIDEO_UPDATE_REQUEST = 'VIDEO_UPDATE_REQUEST';
export const VIDEO_UPDATE_SUCCESS = 'VIDEO_UPDATE_SUCCESS';
export const VIDEO_UPDATE_FAIL = 'VIDEO_UPDATE_FAIL';
export const VIDEO_UPDATE_RESET = 'VIDEO_UPDATE_RESET';

export const VIDEO_DELETE_REQUEST = 'VIDEO_DELETE_REQUEST';
export const VIDEO_DELETE_SUCCESS = 'VIDEO_DELETE_SUCCESS';
export const VIDEO_DELETE_FAIL = 'VIDEO_DELETE_FAIL';

export const VIDEO_PREV_REQUEST = 'VIDEO_PREV_REQUEST';
export const VIDEO_PREV_SUCCESS = 'VIDEO_PREV_SUCCESS';
export const VIDEO_PREV_FAIL = 'VIDEO_PREV_FAIL';
export const VIDEO_PREV_RESET = 'VIDEO_PREV_RESET';

export const VIDEO_NEXT_REQUEST = 'VIDEO_NEXT_REQUEST';
export const VIDEO_NEXT_SUCCESS = 'VIDEO_NEXT_SUCCESS';
export const VIDEO_NEXT_FAIL = 'VIDEO_NEXT_FAIL';
export const VIDEO_NEXT_RESET = 'VIDEO_NEXT_RESET';

export const VIDEO_LANGUAGE_ENGLISH = 'VIDEO_LANGUAGE_ENGLISH';
export const VIDEO_LANGUAGE_HINDI = 'VIDEO_LANGUAGE_HINDI';
