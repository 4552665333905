/**
 * @fileOverview   Reducer for the Pages Schema to perform CRUD operations
 *
 * @version 1.0.0
 * @author [Rahul Sharma](https://github.com/rahul4200)
 */

import {
  PAGE_LIST_REQUEST,
  PAGE_LIST_SUCCESS,
  PAGE_LIST_FAIL,
  PAGE_LIST_RESET,
  PAGE_DETAILS_REQUEST,
  PAGE_DETAILS_SUCCESS,
  PAGE_DETAILS_FAIL,
  PAGE_DETAILS_RESET,
  PAGE_CREATE_REQUEST,
  PAGE_CREATE_SUCCESS,
  PAGE_CREATE_FAIL,
  PAGE_CREATE_RESET,
  PAGE_UPDATE_REQUEST,
  PAGE_UPDATE_SUCCESS,
  PAGE_UPDATE_FAIL,
  PAGE_UPDATE_RESET,
  PAGE_DELETE_REQUEST,
  PAGE_DELETE_SUCCESS,
  PAGE_DELETE_FAIL,
  PAGE_PREV_REQUEST,
  PAGE_PREV_SUCCESS,
  PAGE_PREV_FAIL,
  PAGE_PREV_RESET,
  PAGE_NEXT_REQUEST,
  PAGE_NEXT_SUCCESS,
  PAGE_NEXT_FAIL,
  PAGE_NEXT_RESET,
} from '../constants/pageConstants';

/**
 * Get all pages
 *
 * @param {Object} state Initial state of pages in the store.
 * @param {Object} action Object with payload of the fetched pages.
 * @return {Object} New state object
 */
export const pagesListReducer = (state = { pages: [] }, action) => {
  switch (action.type) {
    case PAGE_LIST_REQUEST:
      return { loading: true, pages: [] };
    case PAGE_LIST_SUCCESS:
      return { loading: false, pages: action.payload };
    case PAGE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PAGE_LIST_RESET:
      return { pages: [] };
    default:
      return state;
  }
};

/**
 * Get data of a single page
 *
 * @param {Object} state Initial state of page in the store.
 * @param {Object} action Object with payload of the single page.
 * @return {Object} New state object
 */
export const pageDetailsReducer = (state = { page: {} }, action) => {
  switch (action.type) {
    case PAGE_DETAILS_REQUEST:
      return { ...state, loading: true };
    case PAGE_DETAILS_SUCCESS:
      return { loading: false, page: action.payload };
    case PAGE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case PAGE_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

/**
 * Create a new page
 *
 * @param {Object} state Initial state of page in the store.
 * @param {Object} action Object with payload of the newly created page.
 * @return {Object} New state object
 */
export const pageCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PAGE_CREATE_REQUEST:
      return { loading: true };
    case PAGE_CREATE_SUCCESS:
      return { loading: false, success: true, page: action.payload };
    case PAGE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PAGE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

/**
 * Update an existing page
 *
 * @param {Object} state Initial state of page in the store.
 * @param {Object} action Object with payload of the newly updated page.
 * @return {Object} New state object
 */
export const pageUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PAGE_UPDATE_REQUEST:
      return { loading: true, ...state };
    case PAGE_UPDATE_SUCCESS:
      return { loading: false, success: true, page: action.payload };
    case PAGE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PAGE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

/**
 * Delete an existing page
 *
 * @param {Object} state Initial state of page in the store.
 * @param {Object} action (optional) Object with payload of an optional error.
 * @return {Object} New state object
 */
export const pageDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PAGE_DELETE_REQUEST:
      return { loading: true };
    case PAGE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PAGE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

/**
 * Get previous page information based on page no and book id
 *
 * @param {Object} state Initial state of the object in the store.
 * @param {Object} action (optional) Object with payload of an optional error.
 * @return {Object} New state object
 */
export const prevPageReducer = (state = { prevPage: {} }, action) => {
  switch (action.type) {
    case PAGE_PREV_REQUEST:
      return { loading: true };
    case PAGE_PREV_SUCCESS:
      return { loading: false, success: true, prevPage: action.payload };
    case PAGE_PREV_FAIL:
      return { loading: false, error: action.payload };
    case PAGE_PREV_RESET:
      return { prev: {} };
    default:
      return state;
  }
};

/**
 * Get next page information based on page no and book id
 *
 * @param {Object} state Initial state of the object in the store.
 * @param {Object} action (optional) Object with payload of an optional error.
 * @return {Object} New state object
 */
export const nextPageReducer = (state = { nextPage: {} }, action) => {
  switch (action.type) {
    case PAGE_NEXT_REQUEST:
      return { loading: true };
    case PAGE_NEXT_SUCCESS:
      return { loading: false, success: true, nextPage: action.payload };
    case PAGE_NEXT_FAIL:
      return { loading: false, error: action.payload };
    case PAGE_NEXT_RESET:
      return { next: {} };
    default:
      return state;
  }
};
