import { useEffect, useState } from 'react';
import LabsHeader from '../components/LabsHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  Box,
  Paper,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Avatar,
  LinearProgress,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Alert } from '@material-ui/lab';
import { grey } from '@material-ui/core/colors';
import { getProfile, updateProfile } from '../actions/studentActions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey['200'],
    padding: '50px 30px',
    minHeight: 'calc(100vh - 202px)',
  },
  centering: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  icon: {
    color: theme.palette.getContrastText(blue[700]),
    backgroundColor: blue['A400'],
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: '0.5rem',
  },
}));

const StudentProfileScreen = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  // const [password, setPassword] = useState('');

  const studentLogin = useSelector((state) => state.studentLogin);
  const { studentInfo } = studentLogin;
  const studentProfile = useSelector((state) => state.studentProfile);
  const { loading, success, error, profile } = studentProfile;
  const studentUdpateProfile = useSelector(
    (state) => state.studentUdpateProfile
  );
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = studentUdpateProfile;

  console.log(studentInfo);

  useEffect(() => {
    if (studentInfo) {
      dispatch(getProfile());
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
  }, [dispatch, studentInfo, updateSuccess]);

  useEffect(() => {
    if (success) {
      setUsername(profile.username);
      setName(profile.name);
      setEmail(profile.email);
      setPhone(profile.phone);
    }
  }, [success, profile]);

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    const formData = {
      name,
      phone,
      email,
      // password: password || null,
    };
    dispatch(updateProfile(formData));
  };

  return (
    <>
      <LabsHeader
        pageTitle='Profile'
        backBtn={{ action: () => history.push('/labs') }}
      />
      <section className={classes.section}>
        <Container>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 255px)',
              }}>
              <CircularProgress />
            </div>
          ) : error ? (
            <Alert severity='error'>{error}</Alert>
          ) : (
            <div className={classes.centering}>
              <Box component={Paper} padding='30px' style={{ width: '650px' }}>
                <div className={classes.centering}>
                  <Avatar className={classes.icon}>
                    <AccountCircleIcon style={{ fontSize: '2.5rem' }} />
                  </Avatar>
                  <Typography
                    variant='h5'
                    component='h2'
                    align='center'
                    style={{ marginBottom: '1.5rem', fontSize: '700' }}>
                    Update Profile
                  </Typography>
                </div>
                {updateLoading && (
                  <LinearProgress
                    color='primary'
                    className={classes.notifSpacing}
                  />
                )}
                {updateError && (
                  <Alert severity='error' className={classes.notifSpacing}>
                    {updateError}
                  </Alert>
                )}
                <form onSubmit={handleUpdateProfile}>
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    type='text'
                    id='username'
                    label='Username'
                    name='username'
                    disabled
                    value={username}
                  />
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    id='name'
                    type='text'
                    label='Full Name'
                    name='name'
                    autoComplete='name'
                    autoFocus
                    value={name}
                    disabled={studentInfo.name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <div
                    style={{
                      display: 'flex',
                      gap: '20px',
                    }}>
                    <TextField
                      variant='outlined'
                      margin='dense'
                      fullWidth
                      id='email'
                      type='email'
                      label='Email Address'
                      name='email'
                      autoComplete='email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                      variant='outlined'
                      margin='dense'
                      fullWidth
                      id='phone'
                      type='tel'
                      label='Phone No.'
                      name='phone'
                      autoComplete='phone'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  {/* <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    id='password'
                    type='password'
                    label='Change Password'
                    name='password'
                    autoComplete='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  /> */}
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    style={{ marginTop: '1rem' }}>
                    Update Profile
                  </Button>
                </form>
              </Box>
            </div>
          )}
        </Container>
      </section>
    </>
  );
};

export default StudentProfileScreen;
