export const FEEDBACK_LIST_REQUEST = 'FEEDBACK_LIST_REQUEST';
export const FEEDBACK_LIST_SUCCESS = 'FEEDBACK_LIST_SUCCESS';
export const FEEDBACK_LIST_FAIL = 'FEEDBACK_LIST_FAIL';
export const FEEDBACK_LIST_RESET = 'FEEDBACK_LIST_RESET';

export const FEEDBACK_DETAILS_REQUEST = 'FEEDBACK_DETAILS_REQUEST';
export const FEEDBACK_DETAILS_SUCCESS = 'FEEDBACK_DETAILS_SUCCESS';
export const FEEDBACK_DETAILS_FAIL = 'FEEDBACK_DETAILS_FAIL';
export const FEEDBACK_DETAILS_RESET = 'FEEDBACK_DETAILS_RESET';

export const FEEDBACK_CREATE_REQUEST = 'FEEDBACK_CREATE_REQUEST';
export const FEEDBACK_CREATE_SUCCESS = 'FEEDBACK_CREATE_SUCCESS';
export const FEEDBACK_CREATE_FAIL = 'FEEDBACK_CREATE_FAIL';
export const FEEDBACK_CREATE_RESET = 'FEEDBACK_CREATE_RESET';

export const FEEDBACK_DELETE_REQUEST = 'FEEDBACK_DELETE_REQUEST';
export const FEEDBACK_DELETE_SUCCESS = 'FEEDBACK_DELETE_SUCCESS';
export const FEEDBACK_DELETE_FAIL = 'FEEDBACK_DELETE_FAIL';
