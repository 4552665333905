export const EXAM_LIST_REQUEST = 'EXAM_LIST_REQUEST';
export const EXAM_LIST_SUCCESS = 'EXAM_LIST_SUCCESS';
export const EXAM_LIST_FAIL = 'EXAM_LIST_FAIL';
export const EXAM_LIST_RESET = 'EXAM_LIST_RESET';

export const EXAM_DETAILS_REQUEST = 'EXAM_DETAILS_REQUEST';
export const EXAM_DETAILS_SUCCESS = 'EXAM_DETAILS_SUCCESS';
export const EXAM_DETAILS_FAIL = 'EXAM_DETAILS_FAIL';
export const EXAM_DETAILS_RESET = 'EXAM_DETAILS_RESET';

export const EXAM_CREATE_REQUEST = 'EXAM_CREATE_REQUEST';
export const EXAM_CREATE_SUCCESS = 'EXAM_CREATE_SUCCESS';
export const EXAM_CREATE_FAIL = 'EXAM_CREATE_FAIL';
export const EXAM_CREATE_RESET = 'EXAM_CREATE_RESET';

export const EXAM_UPDATE_REQUEST = 'EXAM_UPDATE_REQUEST';
export const EXAM_UPDATE_SUCCESS = 'EXAM_UPDATE_SUCCESS';
export const EXAM_UPDATE_FAIL = 'EXAM_UPDATE_FAIL';
export const EXAM_UPDATE_RESET = 'EXAM_UPDATE_RESET';

export const EXAM_DELETE_REQUEST = 'EXAM_DELETE_REQUEST';
export const EXAM_DELETE_SUCCESS = 'EXAM_DELETE_SUCCESS';
export const EXAM_DELETE_FAIL = 'EXAM_DELETE_FAIL';

export const CURRENT_EXAM_QUESTION = 'CURRENT_EXAM_QUESTION';

export const EXAMS_BY_COURSE_REQUEST = 'EXAMS_BY_COURSE_REQUEST';
export const EXAMS_BY_COURSE_SUCCESS = 'EXAMS_BY_COURSE_SUCCESS';
export const EXAMS_BY_COURSE_FAIL = 'EXAMS_BY_COURSE_FAIL';

export const EXAM_SUBMIT_SCORE_REQUEST = 'EXAM_SUBMIT_SCORE_REQUEST';
export const EXAM_SUBMIT_SCORE_SUCCESS = 'EXAM_SUBMIT_SCORE_REQUEST';
export const EXAM_SUBMIT_SCORE_FAIL = 'EXAM_SUBMIT_SCORE_REQUEST';

export const EXAM_GET_POINTS_REQUEST = 'EXAM_GET_POINTS_REQUEST';
export const EXAM_GET_POINTS_SUCESS = 'EXAM_GET_POINTS_SUCESS';
export const EXAM_GET_POINTS_FAIL = 'EXAM_GET_POINTS_FAIL';
