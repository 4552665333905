import { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Container,
  CircularProgress,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  FormControlLabel,
  Switch,
  Divider,
  Chip,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { format } from 'date-fns';
import Alert from '@material-ui/lab/Alert';
import { KeyboardDatePicker } from '@material-ui/pickers';
import LabsHeader from '../components/LabsHeader';
import InfoTable from '../components/InfoTable';
import {
  listBatches as listBatchesAction,
  createBatch as createBatchAction,
  updateBatch as updateBatchAction,
  deleteBatch as deleteBatchAction,
  updateBatchStatus as updateBatchStatusAction,
} from '../actions/batchActions';
import {
  BATCH_CREATE_RESET,
  BATCH_UPDATE_RESET,
} from '../constants/batchConstants';
import { listCourses as listCoursesAction } from '../actions/courseActions';
import { listUsers as listUsersAction } from '../actions/userActions';
import { SlideTransition, ZoomTransition } from '../components/Transitions';
import { HEADER_FOOTER_SHOW } from '../constants/interfaceConstants';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: grey[200],
    padding: '50px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: 'calc(100vh - 206px)',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  notifSpacing: {
    marginBottom: '10px',
  },
}));

/**
 * Component for showing/adding/editing batches
 */
const BatchesScreen = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Dialog State
  const [createPopup, setCreatePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);

  // Form and Local State
  const [name, setName] = useState('');
  const [course, setCourse] = useState('');
  const [active, setActive] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [updateName, setUpdateName] = useState('');
  const [updateCourse, setUpdateCourse] = useState('');
  const [updateActive, setUpdateActive] = useState('');
  const [updateStartDate, setUpdateStartDate] = useState('');
  const [updateBatchId, setUpdateBatchId] = useState('');
  const [toDeleteBatch, setToDeleteBatch] = useState('');

  const [enableCertificates, setEnableCertificates] = useState(false);
  const [courseNameOnCertificate, setCourseNameOnCertificate] = useState('');
  const [courseDateOnCertificate, setCourseDateOnCertificate] = useState('');
  const [isCorporateTrainingBatch, setIsCorporateTrainingBatch] = useState('');
  const [companyNameOnCertificate, setCompanyNameOnCertificate] = useState('');

  const [updateEnableCertificates, setUpdateEnableCertificates] =
    useState(false);
  const [updateCourseNameOnCertificate, setUpdateCourseNameOnCertificate] =
    useState('');
  const [updateCourseDateOnCertificate, setUpdateCourseDateOnCertificate] =
    useState('');
  const [updateIsCorporateTrainingBatch, setUpdateIsCorporateTrainingBatch] =
    useState('');
  const [updateCompanyNameOnCertificate, setUpdateCompanyNameOnCertificate] =
    useState('');

  // Redux State Getters
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const batchesList = useSelector((state) => state.batchesList);
  const { loading, error, batches } = batchesList;
  const batchCreate = useSelector((state) => state.batchCreate);
  const {
    loading: createLoading,
    error: createError,
    success: createSuccess,
    batch: createdBatch,
  } = batchCreate;
  const batchUpdate = useSelector((state) => state.batchUpdate);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    batch: updatedBatch,
  } = batchUpdate;
  const batchDelete = useSelector((state) => state.batchDelete);
  const {
    loading: deleteLoading,
    error: deleteError,
    success: deleteSuccess,
  } = batchDelete;
  const coursesList = useSelector((state) => state.coursesList);
  const batchStatus = useSelector((state) => state.batchStatus);
  const {
    error: batchStatusError,
    // loading: batchStatusLoading,
    success: batchStatusSuccess,
  } = batchStatus;
  const {
    courses: allCourses,
    loading: allCoursesLoading,
    error: allCoursesError,
  } = coursesList;

  // Lifecycle events
  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role === 'admin' || userInfo.role === 'trainer')
    ) {
      dispatch(listBatchesAction());
      dispatch(listUsersAction());
      dispatch(listCoursesAction());
    } else {
      history.push('/admin/login');
    }
    dispatch({ type: HEADER_FOOTER_SHOW });
    if (createSuccess) {
      handleCreatePopupClose();
      setName('');
      setCourse('');
      setActive(true);
      setStartDate('');
      setEnableCertificates(false);
      setCourseNameOnCertificate('');
      setCourseDateOnCertificate('');
      setIsCorporateTrainingBatch(false);
      setCompanyNameOnCertificate('');
      dispatch({ type: BATCH_CREATE_RESET });
    }
    if (updateSuccess) {
      handleEditPopupClose();
      setUpdateName('');
      setUpdateCourse('');
      setUpdateActive(true);
      setUpdateStartDate('');
      setUpdateEnableCertificates(false);
      setUpdateCourseNameOnCertificate('');
      setUpdateCourseDateOnCertificate('');
      setUpdateIsCorporateTrainingBatch(false);
      setUpdateCompanyNameOnCertificate('');
      dispatch({ type: BATCH_UPDATE_RESET });
    }
    if (deleteSuccess) {
      handleDeletePopupClose();
      setToDeleteBatch({});
    }
  }, [
    dispatch,
    history,
    userInfo,
    createSuccess,
    updateSuccess,
    deleteSuccess,
    batchStatusSuccess,
    createdBatch,
    updatedBatch,
  ]);

  // Popup Management
  const handleCreatePopupClick = () => {
    setCreatePopup(true);
  };
  const handleCreatePopupClose = () => {
    setCreatePopup(false);
  };
  const handleEditPopupClick = () => {
    setEditPopup(true);
  };
  const handleEditPopupClose = () => {
    setEditPopup(false);
    setUpdateName('');
    setUpdateCourse('');
    setUpdateActive(true);
    setUpdateStartDate('');
    setUpdateEnableCertificates(false);
    setUpdateCourseNameOnCertificate('');
    setUpdateCourseDateOnCertificate('');
    setUpdateIsCorporateTrainingBatch(false);
    setUpdateCompanyNameOnCertificate('');
  };
  const handleDeletePopupClick = (batch) => {
    setDeletePopup(true);
    setToDeleteBatch(batch);
  };
  const handleDeletePopupClose = () => {
    setDeletePopup(false);
  };

  // Action Handlers
  const handleCreateBatch = (e) => {
    e.preventDefault();
    const formData = {
      name,
      courseId: course,
      activeStatus: active,
      startDate,
      enableCertificates,
      courseNameOnCertificate,
      courseDateOnCertificate,
      isCorporateTrainingBatch,
      companyNameOnCertificate,
    };
    dispatch(createBatchAction(formData));
  };
  const handleEditBatch = (e, id) => {
    batches.forEach((batch) => {
      if (batch._id === id) {
        setUpdateBatchId(batch._id);
        setUpdateName(batch.name);
        setUpdateCourse(batch.course._id);
        setUpdateActive(batch.active);
        setUpdateStartDate(batch.startDate);
        setUpdateEnableCertificates(batch.enableCertificates);
        setUpdateCourseNameOnCertificate(batch.courseNameOnCertificate);
        setUpdateCourseDateOnCertificate(batch.courseDateOnCertificate);
        setUpdateIsCorporateTrainingBatch(batch.isCorporateTrainingBatch);
        setUpdateCompanyNameOnCertificate(batch.companyNameOnCertificate);
      }
    });
    handleEditPopupClick();
  };
  const handleUpdateBatch = (e) => {
    e.preventDefault();
    const formData = {
      _id: updateBatchId,
      name: updateName,
      courseId: updateCourse,
      activeStatus: updateActive,
      startDate: updateStartDate,
      enableCertificates: updateEnableCertificates,
      courseNameOnCertificate: updateCourseNameOnCertificate,
      courseDateOnCertificate: updateCourseDateOnCertificate,
      isCorporateTrainingBatch: updateIsCorporateTrainingBatch,
      companyNameOnCertificate: updateCompanyNameOnCertificate,
    };
    dispatch(updateBatchAction(formData));
  };
  const handleDeleteBatch = (id) => {
    dispatch(deleteBatchAction(id));
    handleDeletePopupClose();
  };
  const handleUpdateBatchStatus = (id) => {
    dispatch(updateBatchStatusAction(id));
  };

  return (
    <>
      <LabsHeader
        pageTitle={
          userInfo && userInfo.role === 'admin'
            ? `${batches ? 'Batches (' + batches.length + ' total)' : ''}`
            : 'Batches'
        }
        backBtn={{ action: () => history.push('/admin/dashboard') }}
        tertiaryBtn={{
          label: 'Add New Batch',
          action: () => handleCreatePopupClick(),
          style: 'contained',
        }}
        secondaryBtn={{
          label: 'Generate Students',
          link: '/admin/studentgenerate',
          color: 'secondary',
          style: 'contained',
        }}
      />
      <section className={classes.section}>
        <Container>
          {batchStatusError && (
            <Alert severity='error' style={{ marginBottom: '20px' }}>
              {batchStatusError}
            </Alert>
          )}
          {loading || allCoursesLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(100vh - 255px)',
              }}>
              <CircularProgress />
            </div>
          ) : error || allCoursesError ? (
            <Alert severity='error' style={{ marginBottom: '20px' }}>
              {error || allCoursesError}
            </Alert>
          ) : (
            <InfoTable
              columns={[
                'name',
                'course',
                'No. of students',
                'active',
                'certificates',
                'start date',
                'actions',
              ]}
              rows={
                batches &&
                batches.map((batch) => ({
                  _id: batch._id,
                  name: batch.name,
                  course: (
                    <span style={{ fontWeight: '500' }}>
                      {batch.course === null ? 'Null' : batch.course.name}
                    </span>
                  ),
                  students: batch.students.length,
                  active: (
                    <Chip
                      size='small'
                      label={batch.active ? 'Active' : 'Inactive'}
                      color={batch.active ? 'primary' : 'secondary'}
                      clickable
                      onClick={() => handleUpdateBatchStatus(batch._id)}
                    />
                  ),
                  certificates: (
                    <Chip
                      size='small'
                      variant='outlined'
                      label={batch.enableCertificates ? 'Enabled' : 'Disabled'}
                      color={batch.enableCertificates ? 'primary' : 'secondary'}
                    />
                  ),
                  startDate: format(new Date(batch.startDate), 'PPP'),
                  actions: [
                    {
                      kind: 'action',
                      label: 'Edit',
                      type: 'primary',
                      action: (e) => handleEditBatch(e, batch._id),
                    },
                    {
                      kind: 'action',
                      label: 'Delete',
                      type: 'secondary',
                      action: () => handleDeletePopupClick(batch),
                    },
                  ],
                }))
              }
            />
          )}
          {/* Add new batch dialog */}
          <Dialog
            open={createPopup}
            TransitionComponent={SlideTransition}
            onClose={handleCreatePopupClose}
            disableBackdropClick
            disableEscapeKeyDown>
            <form onSubmit={handleCreateBatch}>
              <DialogTitle id='form-dialog-title'>Create New Batch</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Create a new batch. Students should be generated and assigned
                  to batches. Deleting a batch will delete all the students
                  assigned to it.
                </DialogContentText>
                {createLoading && (
                  <LinearProgress
                    color='primary'
                    className={classes.notifSpacing}
                  />
                )}
                {createError && (
                  <Alert severity='error' className={classes.notifSpacing}>
                    {createError}
                  </Alert>
                )}
                <TextField
                  variant='outlined'
                  margin='dense'
                  required
                  fullWidth
                  id='name'
                  type='text'
                  label='Name'
                  name='name'
                  autoComplete='name'
                  autoFocus
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />

                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    marginTop: '3px',
                    alignItems: 'center',
                  }}>
                  <FormControl
                    variant='outlined'
                    style={{ width: '70%' }}
                    margin='dense'
                    className={classes.formControl}>
                    <InputLabel id='batchCourse'>Course</InputLabel>
                    <Select
                      labelId='batchCourse'
                      id='course'
                      name='course'
                      value={course}
                      onChange={(e) => setCourse(e.target.value)}
                      label='Course'>
                      {allCourses && userInfo && userInfo.role === 'admin'
                        ? allCourses.map((c) => (
                            <MenuItem key={c._id} value={c._id}>
                              {c.name}
                            </MenuItem>
                          ))
                        : allCourses &&
                          allCourses
                            .filter((c) => userInfo.courses.includes(c._id))
                            .map((c) => (
                              <MenuItem key={c._id} value={c._id}>
                                {c.name}
                              </MenuItem>
                            ))}
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    value='top'
                    style={{ alignSelf: 'center', margin: '4px auto 0' }}
                    control={
                      <Switch
                        checked={active}
                        onChange={(e) => setActive(e.target.checked)}
                        color='primary'
                      />
                    }
                    label='Active'
                    labelPlacement='end'
                  />
                </div>
                <Divider style={{ margin: '20px 0 10px' }} />
                <KeyboardDatePicker
                  margin='normal'
                  id='date-picker-dialog'
                  label='Starting Date'
                  format='dd/MM/yyyy'
                  required
                  fullWidth
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />

                <Divider style={{ margin: '20px 0 10px' }} />

                {/* NEW CERTIFICATES STUFF */}
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    marginTop: '3px',
                    alignItems: 'center',
                  }}>
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    id='certificateCourseName'
                    type='text'
                    label='Course Name on Certificate'
                    name='certificateCourseName'
                    autoComplete='certificateCourseName'
                    value={courseNameOnCertificate}
                    onChange={(e) => setCourseNameOnCertificate(e.target.value)}
                  />
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    id='certificateCourseDate'
                    type='text'
                    label='Course Date on Certificate'
                    name='certificateCourseDate'
                    autoComplete='certificateCourseDate'
                    value={courseDateOnCertificate}
                    onChange={(e) => setCourseDateOnCertificate(e.target.value)}
                  />
                </div>

                <div>
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    id='companyNameOnCert'
                    type='text'
                    label='Company Name/Message on Certificate'
                    name='companyNameOnCert'
                    autoComplete='companyNameOnCert'
                    value={companyNameOnCertificate}
                    onChange={(e) =>
                      setCompanyNameOnCertificate(e.target.value)
                    }
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    marginTop: '3px',
                    alignItems: 'center',
                  }}>
                  <FormControlLabel
                    value='top'
                    style={{ alignSelf: 'center', margin: '4px auto 0' }}
                    control={
                      <Switch
                        checked={enableCertificates}
                        onChange={(e) =>
                          setEnableCertificates(e.target.checked)
                        }
                        color='primary'
                      />
                    }
                    label='Enable Certificates'
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    value='top'
                    style={{ alignSelf: 'center', margin: '4px auto 0' }}
                    control={
                      <Switch
                        checked={isCorporateTrainingBatch}
                        onChange={(e) =>
                          setIsCorporateTrainingBatch(e.target.checked)
                        }
                        color='primary'
                      />
                    }
                    label='Is Corporate Training'
                    labelPlacement='end'
                  />
                </div>
                {/* END NEW CERTIFCATE STUFF */}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCreatePopupClose} color='secondary'>
                  Cancel
                </Button>
                <Button type='submit' color='primary'>
                  Create Batch
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          {/* Edit batch dialog */}
          <Dialog
            open={editPopup}
            TransitionComponent={SlideTransition}
            onClose={handleEditPopupClose}
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth>
            <form onSubmit={handleUpdateBatch}>
              <DialogTitle id='form-dialog-title'>
                Update Batch — {updateName}
              </DialogTitle>
              <DialogContent>
                {updateLoading && (
                  <LinearProgress
                    color='primary'
                    className={classes.notifSpacing}
                  />
                )}
                {updateError && (
                  <Alert severity='error' className={classes.notifSpacing}>
                    {updateError}
                  </Alert>
                )}
                <TextField
                  variant='outlined'
                  margin='dense'
                  required
                  fullWidth
                  id='name'
                  type='text'
                  label='Name'
                  name='name'
                  autoComplete='name'
                  autoFocus
                  value={updateName}
                  onChange={(e) => setUpdateName(e.target.value)}
                />
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    marginTop: '3px',
                    alignItems: 'center',
                  }}>
                  <FormControl
                    variant='outlined'
                    style={{ width: '70%' }}
                    margin='dense'
                    className={classes.formControl}>
                    <InputLabel id='userRole'>Course</InputLabel>
                    <Select
                      labelId='userRole'
                      id='role'
                      name='role'
                      value={updateCourse}
                      onChange={(e) => setUpdateCourse(e.target.value)}
                      label='Course'>
                      {allCourses && userInfo && userInfo.role === 'admin'
                        ? allCourses &&
                          allCourses.map((c) => (
                            <MenuItem key={c._id} value={c._id}>
                              {c.name}
                            </MenuItem>
                          ))
                        : allCourses
                            .filter((c) => userInfo.courses.includes(c._id))
                            .map((c) => (
                              <MenuItem key={c._id} value={c._id}>
                                {c.name}
                              </MenuItem>
                            ))}
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    value='top'
                    style={{ alignSelf: 'center', margin: '4px auto 0' }}
                    control={
                      <Switch
                        checked={updateActive}
                        onChange={(e) => setUpdateActive(e.target.checked)}
                        color='primary'
                      />
                    }
                    label='Active'
                    labelPlacement='end'
                  />
                </div>
                <Divider style={{ margin: '20px 0 10px' }} />
                <KeyboardDatePicker
                  margin='normal'
                  id='date-picker-dialog'
                  label='Starting Date'
                  format='dd/MM/yyyy'
                  required
                  fullWidth
                  value={updateStartDate}
                  onChange={(date) => setUpdateStartDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />

                <Divider style={{ margin: '20px 0 10px' }} />

                {/* NEW CERTIFICATES STUFF */}
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    marginTop: '3px',
                    alignItems: 'center',
                  }}>
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    id='updateCertificateCourseName'
                    type='text'
                    label='Course Name on Certificate'
                    name='updateCertificateCourseName'
                    autoComplete='updateCertificateCourseName'
                    value={updateCourseNameOnCertificate}
                    onChange={(e) =>
                      setUpdateCourseNameOnCertificate(e.target.value)
                    }
                  />
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    id='updateCertificateCourseDate'
                    type='text'
                    label='Course Date on Certificate'
                    name='updateCertificateCourseDate'
                    autoComplete='updateCertificateCourseDate'
                    value={updateCourseDateOnCertificate}
                    onChange={(e) =>
                      setUpdateCourseDateOnCertificate(e.target.value)
                    }
                  />
                </div>

                <div>
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    id='updateCompanyNameOnCert'
                    type='text'
                    label='Company Name/Message on Certificate'
                    name='updateCompanyNameOnCert'
                    autoComplete='updateCompanyNameOnCert'
                    value={updateCompanyNameOnCertificate}
                    onChange={(e) =>
                      setUpdateCompanyNameOnCertificate(e.target.value)
                    }
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    marginTop: '3px',
                    alignItems: 'center',
                  }}>
                  <FormControlLabel
                    value='top'
                    style={{ alignSelf: 'center', margin: '4px auto 0' }}
                    control={
                      <Switch
                        checked={updateEnableCertificates}
                        onChange={(e) =>
                          setUpdateEnableCertificates(e.target.checked)
                        }
                        color='primary'
                      />
                    }
                    label='Enable Certificates'
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    value='top'
                    style={{ alignSelf: 'center', margin: '4px auto 0' }}
                    control={
                      <Switch
                        checked={updateIsCorporateTrainingBatch}
                        onChange={(e) =>
                          setUpdateIsCorporateTrainingBatch(e.target.checked)
                        }
                        color='primary'
                      />
                    }
                    label='Is Corporate Training'
                    labelPlacement='end'
                  />
                </div>
                {/* END NEW CERTIFCATE STUFF */}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleEditPopupClose} color='secondary'>
                  Cancel
                </Button>
                <Button type='submit' color='primary'>
                  Update
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          {/* Delete batch dialog */}
          <Dialog
            TransitionComponent={ZoomTransition}
            open={deletePopup}
            onClose={handleDeletePopupClose}>
            <DialogTitle id='delete-dialog-title'>Delete Batch</DialogTitle>
            <DialogContent>
              {deleteLoading && <LinearProgress color='secondary' />}
              {deleteError && <Alert severity='error'>{deleteError}</Alert>}
              <DialogContentText id='delete-dialog-description'>
                Are you sure? Deleting batch{' '}
                <strong>{toDeleteBatch.name}</strong> will delete all the
                students associated with them. This action is permanent!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleDeletePopupClose}
                color='primary'
                autoFocus>
                Cancel
              </Button>
              <Button
                onClick={() => handleDeleteBatch(toDeleteBatch._id)}
                color='secondary'>
                Confirm Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </section>
    </>
  );
};

export default BatchesScreen;
