export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';
export const USER_LIST_RESET = 'USER_LIST_RESET';

export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_FAIL = 'USER_CREATE_FAIL';
export const USER_CREATE_RESET = 'USER_CREATE_RESET';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';

export const USER_AUTH_CHECK_REQUEST = 'USER_AUTH_CHECK_REQUEST';
export const USER_AUTH_CHECK_SUCCESS = 'USER_AUTH_CHECK_SUCCESS';
export const USER_AUTH_CHECK_FAIL = 'USER_AUTH_CHECK_FAIL';
export const USER_AUTH_CHECK_RESET = 'USER_AUTH_CHECK_RESET';

export const GET_STAT_REQUEST = 'GET_STAT_REQUEST';
export const GET_STAT_SUCCESS = 'GET_STAT_SUCCESS';
export const GET_STAT_FAIL = 'GET_STAT_FAIL';
export const GET_STAT_RESET = 'GET_STAT_RESET';

export const PULL_MACHINES_REQUEST = 'PULL_MACHINES_REQUEST';
export const PULL_MACHINES_SUCCESS = 'PULL_MACHINES_SUCCESS';
export const PULL_MACHINES_FAIL = 'PULL_MACHINES_FAIL';
