import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import PowerIcon from '@material-ui/icons/Power';
import BackIcon from '@material-ui/icons/ArrowBack';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

const useStyles = makeStyles((theme) => ({
  section: {
    // padding: '25px 20px',
    marginTop: '64px',
    boxShadow: `0 0 2px 1px ${grey[300]}`,
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // height: '81px',
  },
  title: {
    fontWeight: '500',
  },
  controls: {
    display: 'flex',
  },
  btnGreen: {
    backgroundColor: `${green[300]}!important`,
  },
  btnRed: {
    backgroundColor: `${red[300]}!important`,
  },
  spacing: {
    marginLeft: '15px',
  },
  multiColumn: {
    display: 'flex',
  },
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const LabsHeader = ({
  pageTitle = '',
  vmInfo = false,
  labGuideLink = '/',
  controls = false,
  needsIou = false,
  iouActions = null,
  poweringActions = null,
  backBtn = false,
  secondaryBtn = false,
  tertiaryBtn = false,
  quaternaryBtn = false,
  customJSX = false,
  size,
}) => {
  const [vmStatus, setVmStatus] = useState('off');
  const classes = useStyles();

  const labVMState = useSelector((state) => state.labVMState);
  const { loading, status } = labVMState;

  useEffect(() => {
    if (!loading && status) {
      setVmStatus(status);
    }
  }, [status, loading]);

  return (
    <Box
      className={`${classes.section} prhdn`}
      bgcolor={grey[100]}
      style={{
        padding: size === 'small' ? '18px 20px' : '23px 20px',
        position: 'relative',
        zIndex: 5,
      }}
    >
      <div className={classes.backBtn}>
        {backBtn && backBtn.action ? (
          <IconButton
            size="small"
            color="primary"
            style={{ marginRight: '10px' }}
            onClick={backBtn.action}
          >
            <BackIcon />
          </IconButton>
        ) : backBtn && backBtn.link ? (
          <IconButton
            size="small"
            color="primary"
            style={{ marginRight: '10px' }}
            component={RouterLink}
            to={backBtn.link}
          >
            <BackIcon />
          </IconButton>
        ) : null}
        <Typography component="h1" variant="h5" className={classes.title}>
          {pageTitle === undefined ? ' ' : pageTitle}
        </Typography>
      </div>
      {vmInfo && (
        <div>
          <Chip
            variant="outlined"
            icon={
              vmStatus ? (
                <CheckCircleRoundedIcon />
              ) : (
                <RemoveCircleRoundedIcon />
              )
            }
            color={vmStatus ? 'primary' : 'default'}
            label={`Virtual Machine: ${vmStatus ? 'ON' : 'OFF'}`}
          />
        </div>
      )}

      {controls && (
        <div className={classes.controls}>
          {/* Open lab guide in new tab */}
          {/* <Button
            variant="contained"
            component={RouterLink}
            to={labGuideLink}
            color="primary"
            disableElevation
            startIcon={<BookIcon />}
            // style={{ marginRight: '20px' }}
          >
            Lab Guide
          </Button> */}
          {needsIou ? (
            <>
              {/* Start/Stop lab btns */}
              <ButtonGroup
                color="default"
                disableElevation
                className={classes.spacing}
              >
                <Button onClick={iouActions.start}>Start</Button>
                <Button onClick={iouActions.stop}>Stop</Button>
              </ButtonGroup>
              {/* Wipe/Reset lab btns */}
              <ButtonGroup
                color="secondary"
                disableElevation
                className={classes.spacing}
              >
                <Button onClick={iouActions.wipe}>Wipe</Button>
                <Button onClick={iouActions.reset}>Reset</Button>
              </ButtonGroup>
            </>
          ) : null}
          <div
            style={{
              display: quaternaryBtn ? 'flex' : 'none',
              gap: '20px',
            }}
          >
            {quaternaryBtn && quaternaryBtn.action ? (
              <ButtonGroup
                color="default"
                disableElevation
                className={classes.spacing}
              >
                <Button
                  onClick={quaternaryBtn.action.start}
                  variant={quaternaryBtn.style || 'contained'}
                  color={quaternaryBtn.color || 'primary'}
                >
                  Start
                </Button>
                <Button
                  onClick={quaternaryBtn.action.stop}
                  variant={quaternaryBtn.style || 'contained'}
                  color={quaternaryBtn.color || 'secondary'}
                >
                  Stop
                </Button>
                <Button
                  onClick={quaternaryBtn.action.wipe}
                  variant={quaternaryBtn.style || 'contained'}
                  color={quaternaryBtn.color || 'secondary'}
                >
                  Wipe
                </Button>
              </ButtonGroup>
            ) : quaternaryBtn && quaternaryBtn.link ? (
              <Button component={RouterLink} to={quaternaryBtn.link}>
                {quaternaryBtn.label}
              </Button>
            ) : null}
          </div>
          {/* Power on/off toggle btns */}
          <ToggleButtonGroup
            size="small"
            value={vmStatus}
            exclusive
            className={classes.spacing}
          >
            <ToggleButton
              value="on"
              onClick={poweringActions.poweron}
              className={vmStatus === 'on' ? classes.btnGreen : null}
            >
              <PowerIcon />
              On
            </ToggleButton>
            <ToggleButton
              value="off"
              onClick={poweringActions.poweroff}
              className={vmStatus === 'off' ? classes.btnRed : null}
            >
              <PowerOffIcon />
              Off
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      )}

      <div
        style={{
          display: secondaryBtn || tertiaryBtn ? 'flex' : 'none',
          gap: '20px',
        }}
      >
        {secondaryBtn && secondaryBtn.action ? (
          <Button
            onClick={secondaryBtn.action}
            variant={secondaryBtn.style || 'contained'}
            color={secondaryBtn.color || 'primary'}
          >
            {secondaryBtn.label}
          </Button>
        ) : secondaryBtn && secondaryBtn.link ? (
          <Button component={RouterLink} to={secondaryBtn.link}>
            {secondaryBtn.label}
          </Button>
        ) : null}

        {tertiaryBtn && tertiaryBtn.action ? (
          <Button
            onClick={tertiaryBtn.action}
            variant={tertiaryBtn.style || 'contained'}
            color={tertiaryBtn.color || 'primary'}
          >
            {tertiaryBtn.label}
          </Button>
        ) : tertiaryBtn && tertiaryBtn.link ? (
          <Button component={RouterLink} to={tertiaryBtn.link}>
            {tertiaryBtn.label}
          </Button>
        ) : null}
      </div>

      {/* Custom JSX */}
      {customJSX && customJSX}
    </Box>
  );
};

export default LabsHeader;
